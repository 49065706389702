import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MarketplaceContext from './context';

// Libs
import { useActiveListings, useAddress, useBuyNow, useMarketplace } from '@thirdweb-dev/react';
import { ListingType } from '@thirdweb-dev/sdk';
import { useDispatch } from 'react-redux';
// Actions
import { setForSaleListings, setMyListings } from 'store/actions';
// Toast
import Toast from 'components/Common/Toast';
import ErrorToast from 'components/Toast/ErrorToast';
import BuyAssetToast from 'components/Toast/BuyAssetToast';

function MarketplaceProvider({ children }) {
    // Redux
    const dispatch = useDispatch();
    // Hooks
    const address = useAddress();
    const navigate = useNavigate();
    const marketplace = useMarketplace(process.env.REACT_APP_MARKETPLACE_CONTRACT_ADDRESS);
    // States
    const [assetName, setAssetName] = useState('');

    //  GET LISTINGS
    const { data: listings, isLoading: isLoadingForSale } = useActiveListings(marketplace, {
        start: 0,
        count: 1000000
    });

    //  GET MY LISTINGS
    const { data: myListings, isLoading: isLoadingMyListings } = useActiveListings(marketplace, {
        seller: address,
        start: 0,
        count: 1000000
    });

    //  BUY ASSETS
    const { mutate: buyNow, isLoading: isProcessingToBuy, status } = useBuyNow(marketplace);

    const buyAsset = async (listingId, assetName) => {
        setAssetName(assetName);
        buyNow({ id: listingId, type: ListingType.Direct, buyAmount: 1 });
    };

    useEffect(() => {
        dispatch(setForSaleListings(listings));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listings, address]);

    useEffect(() => {
        dispatch(setMyListings(myListings));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [myListings, address]);

    useEffect(() => {
        if (status === 'error' || status === 'success') {
            navigate('/assets', { replace: true });
            if (status === 'success') {
                Toast.success(<BuyAssetToast message={assetName} success />, 'buySuccess');
            } else if (status === 'error') {
                let message = 'Transaction failed, check for more info in the metamask';
                Toast.error(<ErrorToast error message={message} />, 'buyError');
            }
        }
    }, [status, navigate, assetName]);

    return (
        <MarketplaceContext.Provider
            value={{
                isProcessingToBuy,
                isLoadingForSale,
                isLoadingMyListings,
                buyAsset
            }}>
            {children}
        </MarketplaceContext.Provider>
    );
}

function useMP() {
    const context = React.useContext(MarketplaceContext);

    if (context === undefined) {
        throw new Error('useMP must be used within a MarketplaceProvider');
    }

    return context;
}

export { MarketplaceProvider, useMP };
