export const panels = {
    0: { page: 1, panel: 1 },
    1: { page: 1, panel: 2 },
    2: { page: 1, panel: 3 },
    3: { page: 1, panel: 4 },
    4: { page: 1 },
    5: { page: 2, panel: 1 },
    6: { page: 2, panel: 2 },
    7: { page: 2, panel: 3 },
    8: { page: 2 },
    9: { page: 3, panel: 1 },
    10: { page: 3, panel: 2 },
    11: { page: 3, panel: 3 },
    12: { page: 3, panel: 4 },
    13: { page: 3, panel: 5 },
    14: { page: 3 },
    15: { page: 4, panel: 1 },
    16: { page: 4, panel: 2 },
    17: { page: 4, panel: 3 },
    18: { page: 4, panel: 4 },
    19: { page: 4 },
    20: { page: 5, panel: 1 },
    21: { page: 5 },
    22: { page: 6, panel: 1 },
    23: { page: 6, panel: 2 },
    24: { page: 6, panel: 3 },
    25: { page: 6, panel: 4 },
    26: { page: 6, panel: 5 },
    27: { page: 6, panel: 6 },
    28: { page: 6, panel: 7 },
    29: { page: 6 },
    30: { page: 7, panel: 1 },
    31: { page: 7, panel: 2 },
    32: { page: 7, panel: 3 },
    33: { page: 7 },
    34: { page: 8, panel: 1 },
    35: { page: 8, panel: 2 },
    36: { page: 8, panel: 3 },
    37: { page: 8 },
    38: { page: 9, panel: 1 },
    39: { page: 9, panel: 2 },
    40: { page: 9, panel: 3 },
    41: { page: 9, panel: 4 },
    42: { page: 9, panel: 5 },
    43: { page: 9, panel: 6 },
    44: { page: 9 },
    45: { page: 10, panel: 1 },
    46: { page: 10, panel: 2 },
    47: { page: 10, panel: 3 },
    48: { page: 10, panel: 4 },
    49: { page: 10 },
    50: { page: 11, panel: 1 },
    51: { page: 11, panel: 2 },
    52: { page: 11, panel: 3 },
    53: { page: 11 },
    54: { page: 12, panel: 1 },
    55: { page: 12, panel: 2 },
    56: { page: 12, panel: 3 },
    57: { page: 12, panel: 4 },
    58: { page: 12 },
    59: { page: 13, panel: 1 },
    60: { page: 13, panel: 2 },
    61: { page: 13, panel: 3 },
    62: { page: 13 },
    63: { page: 14, panel: 1 },
    64: { page: 14, panel: 2 },
    65: { page: 14, panel: 3 },
    66: { page: 14, panel: 4 },
    67: { page: 14, panel: 5 },
    68: { page: 14 },
    69: { page: 15, panel: 1 },
    70: { page: 15, panel: 2 },
    71: { page: 15, panel: 3 },
    72: { page: 15, panel: 4 },
    73: { page: 15, panel: 5 },
    74: { page: 15 },
    75: { page: 16, panel: 1 },
    76: { page: 16, panel: 2 },
    77: { page: 16, panel: 3 },
    78: { page: 16 },
    79: { page: 17, panel: 1 },
    80: { page: 17, panel: 2 },
    81: { page: 17, panel: 3 },
    82: { page: 17, panel: 4 },
    83: { page: 17, panel: 5 },
    84: { page: 17, panel: 6 },
    85: { page: 17 },
    86: { page: 18, panel: 1 },
    87: { page: 18, panel: 2 },
    88: { page: 18, panel: 3 },
    89: { page: 18, panel: 4 },
    90: { page: 18, panel: 5 },
    91: { page: 18 },
    92: { page: 19, panel: 1 },
    93: { page: 19, panel: 2 },
    94: { page: 19, panel: 3 },
    95: { page: 19, panel: 4 },
    96: { page: 19 },
    97: { page: 20, panel: 1 },
    98: { page: 20, panel: 2 },
    99: { page: 20, panel: 3 },
    100: { page: 20, panel: 4 },
    101: { page: 20 },
    102: { page: 21, panel: 1 },
    103: { page: 21, panel: 2 },
    104: { page: 21, panel: 3 },
    105: { page: 21, panel: 4 },
    106: { page: 21, panel: 5 },
    107: { page: 21, panel: 6 },
    108: { page: 21, panel: 7 },
    109: { page: 21 },
    110: { page: 22, panel: 1 },
    111: { page: 22, panel: 2 },
    112: { page: 22, panel: 3 },
    113: { page: 22, panel: 4 },
    114: { page: 22, panel: 5 },
    115: { page: 22, panel: 6 },
    116: { page: 22, panel: 7 },
    117: { page: 22 },
    118: { page: 23, panel: 1 },
    119: { page: 23, panel: 2 },
    120: { page: 23, panel: 3 },
    121: { page: 23, panel: 4 },
    122: { page: 23, panel: 5 },
    123: { page: 23, panel: 6 },
    124: { page: 23, panel: 7 },
    125: { page: 23, panel: 8 },
    126: { page: 23 },
    127: { page: 24, panel: 1 },
    128: { page: 24, panel: 2 },
    129: { page: 24, panel: 3 },
    130: { page: 24, panel: 4 },
    131: { page: 24, panel: 5 },
    132: { page: 24, panel: 6 },
    133: { page: 24, panel: 7 },
    134: { page: 24, panel: 8 },
    135: { page: 24, panel: 9 },
    136: { page: 24 },
    137: { page: 25, panel: 1 },
    138: { page: 25, panel: 2 },
    139: { page: 25, panel: 3 },
    140: { page: 25, panel: 4 },
    141: { page: 25, panel: 5 },
    142: { page: 25, panel: 6 },
    143: { page: 25, panel: 7 },
    144: { page: 25 },
    145: { page: 26, panel: 1 },
    146: { page: 26, panel: 2 },
    147: { page: 26, panel: 3 },
    148: { page: 26, panel: 4 },
    149: { page: 26, panel: 5 },
    150: { page: 26, panel: 6 },
    151: { page: 26, panel: 7 },
    152: { page: 26, panel: 8 },
    153: { page: 26 },
    154: { page: 27, panel: 1 },
    155: { page: 27, panel: 2 },
    156: { page: 27, panel: 3 },
    157: { page: 27 },
    158: { page: 28, panel: 1 },
    159: { page: 28 },
    160: { page: 29, panel: 1 },
    161: { page: 29, panel: 2 },
    162: { page: 29, panel: 3 },
    163: { page: 29, panel: 4 },
    164: { page: 29, panel: 5 },
    165: { page: 29, panel: 6 },
    166: { page: 29, panel: 7 },
    167: { page: 29 },
    168: { page: 30, panel: 1 },
    169: { page: 30, panel: 2 },
    170: { page: 30, panel: 3 },
    171: { page: 30, panel: 4 },
    172: { page: 30 },
    173: { page: 31, panel: 1 },
    174: { page: 31, panel: 2 },
    175: { page: 31, panel: 3 },
    176: { page: 31, panel: 4 },
    177: { page: 31, panel: 5 },
    178: { page: 31, panel: 6 },
    179: { page: 31 },
    180: { page: 32, panel: 1 },
    181: { page: 32, panel: 2 },
    182: { page: 32, panel: 3 },
    183: { page: 32 },
    184: { page: 33, panel: 1 },
    185: { page: 33, panel: 2 },
    186: { page: 33, panel: 3 },
    187: { page: 33, panel: 4 },
    188: { page: 33 },
    189: { page: 34, panel: 1 },
    190: { page: 34, panel: 2 },
    191: { page: 34, panel: 3 },
    192: { page: 34, panel: 4 },
    193: { page: 34, panel: 5 },
    194: { page: 34 }
};

export const pages = {
    1: 4,
    2: 8,
    3: 14,
    4: 19,
    5: 21,
    6: 29,
    7: 33,
    8: 37,
    9: 44,
    10: 49,
    11: 53,
    12: 58,
    13: 62,
    14: 68,
    15: 74,
    16: 78,
    17: 85,
    18: 91,
    19: 96,
    20: 101,
    21: 109,
    22: 117,
    23: 126,
    24: 136,
    25: 144,
    26: 153,
    27: 157,
    28: 159,
    29: 167,
    30: 172,
    31: 179,
    32: 183,
    33: 188,
    34: 194
};

export const data = {
    4: {
        0: {
            style: {
                width: 166,
                height: 44.8,
                top: 5,
                left: 4
            },
            image: {
                minted: require('assets/images/episode1/page1/2142-01-01-01.png'),
                unMinted: {
                    color: require('assets/images/episode1/page1/pixelized/2142-01-01-01.png'),
                    bw: require('assets/images/episode1/page1/pixelized-b-w/2142-01-01-01.png')
                }
            }
        },
        1: {
            style: {
                width: 166,
                height: 44.62,
                top: 53.5,
                left: 4
            },
            image: {
                minted: require('assets/images/episode1/page1/2142-01-01-02.png'),
                unMinted: {
                    color: require('assets/images/episode1/page1/pixelized/2142-01-01-02.png'),
                    bw: require('assets/images/episode1/page1/pixelized-b-w/2142-01-01-01.png')
                }
            }
        },
        2: {
            style: {
                width: 166,
                height: 45,
                top: 102,
                left: 4
            },
            image: {
                minted: require('assets/images/episode1/page1/2142-01-01-03.png'),
                unMinted: {
                    color: require('assets/images/episode1/page1/pixelized/2142-01-01-03.png'),
                    bw: require('assets/images/episode1/page1/pixelized-b-w/2142-01-01-03.png')
                }
            }
        },
        3: {
            style: {
                width: 166,
                height: 83,
                left: 4,
                bottom: 5
            },
            image: {
                minted: require('assets/images/episode1/page1/2142-01-01-04.png'),
                unMinted: {
                    color: require('assets/images/episode1/page1/pixelized/2142-01-01-04.png'),
                    bw: require('assets/images/episode1/page1/pixelized-b-w/2142-01-01-04.png')
                }
            }
        }
    },
    8: {
        5: {
            style: {
                width: 166,
                height: 223,
                top: 6,
                left: 4
            },
            image: {
                minted: require('assets/images/episode1/page2/2142-01-02-01.png'),
                unMinted: {
                    color: require('assets/images/episode1/page2/pixelized/2142-01-02-01.png'),
                    bw: require('assets/images/episode1/page2/pixelized-b-w/2142-01-02-01.png')
                }
            }
        },
        6: {
            style: {
                width: 158,
                height: 42,
                top: 166,
                left: 8
            },
            image: {
                minted: require('assets/images/episode1/page2/2142-01-02-02.png'),
                unMinted: {
                    color: require('assets/images/episode1/page2/pixelized/2142-01-02-02.png'),
                    bw: require('assets/images/episode1/page2/pixelized-b-w/2142-01-02-02.png')
                }
            }
        },
        7: {
            style: {
                width: 158,
                height: 42,
                bottom: 5,
                left: 8
            },
            image: {
                minted: require('assets/images/episode1/page2/2142-01-02-03.png'),
                unMinted: {
                    color: require('assets/images/episode1/page2/pixelized/2142-01-02-03.png'),
                    bw: require('assets/images/episode1/page2/pixelized-b-w/2142-01-02-03.png')
                }
            }
        }
    },
    14: {
        9: {
            style: {
                width: 80,
                height: 71.17,
                top: 5.15,
                left: 4
            },
            image: {
                minted: require('assets/images/episode1/page3/2142-01-03-01.png'),
                unMinted: {
                    color: require('assets/images/episode1/page3/pixelized/2142-01-03-01.png'),
                    bw: require('assets/images/episode1/page3/pixelized-b-w/2142-01-03-01.png')
                }
            }
        },
        10: {
            style: {
                width: 80,
                height: 40,
                top: 79,
                left: 4
            },
            image: {
                minted: require('assets/images/episode1/page3/2142-01-03-02.png'),
                unMinted: {
                    color: require('assets/images/episode1/page3/pixelized/2142-01-03-02.png'),
                    bw: require('assets/images/episode1/page3/pixelized-b-w/2142-01-03-02.png')
                }
            }
        },
        11: {
            style: {
                width: 80,
                height: 115,
                top: 5,
                right: 5
            },
            image: {
                minted: require('assets/images/episode1/page3/2142-01-03-03.png'),
                unMinted: {
                    color: require('assets/images/episode1/page3/pixelized/2142-01-03-03.png'),
                    bw: require('assets/images/episode1/page3/pixelized-b-w/2142-01-03-03.png')
                }
            }
        },
        12: {
            style: {
                width: 165,
                height: 40,
                top: 123.5,
                left: 4
            },
            image: {
                minted: require('assets/images/episode1/page3/2142-01-03-04.png'),
                unMinted: {
                    color: require('assets/images/episode1/page3/pixelized/2142-01-03-04.png'),
                    bw: require('assets/images/episode1/page3/pixelized-b-w/2142-01-03-04.png')
                }
            }
        },
        13: {
            style: {
                width: 165,
                height: 67,
                bottom: 5,
                left: 4
            },
            image: {
                minted: require('assets/images/episode1/page3/2142-01-03-05.png'),
                unMinted: {
                    color: require('assets/images/episode1/page3/pixelized/2142-01-03-05.png'),
                    bw: require('assets/images/episode1/page3/pixelized-b-w/2142-01-03-05.png')
                }
            }
        }
    },
    19: {
        15: {
            style: {
                width: 166,
                height: 38.2,
                left: 4,
                top: 6
            },
            image: {
                minted: require('assets/images/episode1/page4/2142-01-04-02.png'),
                unMinted: {
                    color: require('assets/images/episode1/page4/pixelized/2142-01-04-02.png'),
                    bw: require('assets/images/episode1/page4/pixelized-b-w/2142-01-04-02.png')
                }
            }
        },
        16: {
            style: {
                width: 166,
                height: 30.4,
                left: 4,
                top: 47
            },
            image: {
                minted: require('assets/images/episode1/page4/2142-01-04-01.png'),
                unMinted: {
                    color: require('assets/images/episode1/page4/pixelized/2142-01-04-01.png'),
                    bw: require('assets/images/episode1/page4/pixelized-b-w/2142-01-04-01.png')
                }
            }
        },
        17: {
            style: {
                width: 166,
                height: 51.48,
                left: 4,
                top: 81.3
            },
            image: {
                minted: require('assets/images/episode1/page4/2142-01-04-03.png'),
                unMinted: {
                    color: require('assets/images/episode1/page4/pixelized/2142-01-04-03.png'),
                    bw: require('assets/images/episode1/page4/pixelized-b-w/2142-01-04-03.png')
                }
            }
        },
        18: {
            style: {
                width: 166,
                height: 98.47,
                bottom: 5,
                left: 4
            },
            image: {
                minted: require('assets/images/episode1/page4/2142-01-04-04.png'),
                unMinted: {
                    color: require('assets/images/episode1/page4/pixelized/2142-01-04-04.png'),
                    bw: require('assets/images/episode1/page4/pixelized-b-w/2142-01-04-04.png')
                }
            }
        }
    },
    21: {
        20: {
            style: {
                width: 166,
                height: 228.01,
                left: 4,
                top: 6
            },
            image: {
                minted: require('assets/images/episode1/page5/2142-01-05-01.png'),
                unMinted: {
                    color: require('assets/images/episode1/page5/pixelized/2142-01-05-01.png'),
                    bw: require('assets/images/episode1/page5/pixelized-b-w/2142-01-05-01.png')
                }
            }
        }
    },
    29: {
        22: {
            style: {
                width: 103.69,
                height: 98.22,
                left: 4,
                top: 5
            },
            image: {
                minted: require('assets/images/episode1/page6/2142-01-06-01.png'),
                unMinted: {
                    color: require('assets/images/episode1/page6/pixelized/2142-01-06-01.png'),
                    bw: require('assets/images/episode1/page6/pixelized-b-w/2142-01-06-01.png')
                }
            }
        },
        23: {
            style: {
                width: 61,
                height: 25.05,
                left: 107.51,
                top: 5
            },
            image: {
                minted: require('assets/images/episode1/page6/2142-01-06-02.png'),
                unMinted: {
                    color: require('assets/images/episode1/page6/pixelized/2142-01-06-02.png'),
                    bw: require('assets/images/episode1/page6/pixelized-b-w/2142-01-06-02.png')
                }
            }
        },
        24: {
            style: {
                width: 66,
                height: 46.51,
                left: 103,
                top: 17
            },
            image: {
                minted: require('assets/images/episode1/page6/2142-01-06-03.png'),
                unMinted: {
                    color: require('assets/images/episode1/page6/pixelized/2142-01-06-03.png'),
                    bw: require('assets/images/episode1/page6/pixelized-b-w/2142-01-06-03.png')
                }
            }
        },
        25: {
            style: {
                width: 63.38,
                height: 125.96,
                left: 4,
                bottom: 5
            },
            image: {
                minted: require('assets/images/episode1/page6/2142-01-06-05.png'),
                unMinted: {
                    color: require('assets/images/episode1/page6/pixelized/2142-01-06-05.png'),
                    bw: require('assets/images/episode1/page6/pixelized-b-w/2142-01-06-05.png')
                }
            }
        },
        26: {
            style: {
                width: 72,
                height: 53.51,
                left: 96.11,
                top: 49.71
            },
            image: {
                minted: require('assets/images/episode1/page6/2142-01-06-04.png'),
                unMinted: {
                    color: require('assets/images/episode1/page6/pixelized/2142-01-06-04.png'),
                    bw: require('assets/images/episode1/page6/pixelized-b-w/2142-01-06-04.png')
                }
            }
        },
        27: {
            style: {
                width: 72.64,
                height: 125.87,
                left: 50,
                bottom: 5
            },
            image: {
                minted: require('assets/images/episode1/page6/2142-01-06-06.png'),
                unMinted: {
                    color: require('assets/images/episode1/page6/pixelized/2142-01-06-06.png'),
                    bw: require('assets/images/episode1/page6/pixelized-b-w/2142-01-06-06.png')
                }
            }
        },
        28: {
            style: {
                width: 62,
                height: 125.87,
                left: 106.71,
                bottom: 5
            },
            image: {
                minted: require('assets/images/episode1/page6/2142-01-06-07.png'),
                unMinted: {
                    color: require('assets/images/episode1/page6/pixelized/2142-01-06-07.png'),
                    bw: require('assets/images/episode1/page6/pixelized-b-w/2142-01-06-07.png')
                }
            }
        }
    },
    33: {
        30: {
            style: {
                width: 166,
                height: 137.7,
                left: 4,
                top: 5
            },
            image: {
                minted: require('assets/images/episode1/page7/2142-01-07-01.png'),
                unMinted: {
                    color: require('assets/images/episode1/page7/pixelized/2142-01-07-01.png'),
                    bw: require('assets/images/episode1/page7/pixelized-b-w/2142-01-07-01.png')
                }
            }
        },
        31: {
            style: {
                width: 166,
                height: 65.57,
                left: 4,
                top: 146
            },
            image: {
                minted: require('assets/images/episode1/page7/2142-01-07-02.png'),
                unMinted: {
                    color: require('assets/images/episode1/page7/pixelized/2142-01-07-02.png'),
                    bw: require('assets/images/episode1/page7/pixelized-b-w/2142-01-07-02.png')
                }
            }
        },
        32: {
            style: {
                width: 166,
                height: 72.4,
                left: 4,
                bottom: 5
            },
            image: {
                minted: require('assets/images/episode1/page7/2142-01-07-03.png'),
                unMinted: {
                    color: require('assets/images/episode1/page7/pixelized/2142-01-07-03.png'),
                    bw: require('assets/images/episode1/page7/pixelized-b-w/2142-01-07-03.png')
                }
            }
        }
    },
    37: {
        34: {
            style: {
                width: 166,
                height: 81.71,
                left: 4,
                top: 8
            },
            image: {
                minted: require('assets/images/episode1/page8/2142-01-08-01.png'),
                unMinted: {
                    color: require('assets/images/episode1/page8/pixelized/2142-01-08-01.png'),
                    bw: require('assets/images/episode1/page8/pixelized-b-w/2142-01-08-01.png')
                }
            }
        },
        35: {
            style: {
                width: 167,
                height: 114.58,
                left: 0,
                top: 48
            },
            image: {
                minted: require('assets/images/episode1/page8/2142-01-08-02.png'),
                unMinted: {
                    color: require('assets/images/episode1/page8/pixelized/2142-01-08-02.png'),
                    bw: require('assets/images/episode1/page8/pixelized-b-w/2142-01-08-02.png')
                }
            }
        },
        36: {
            style: {
                width: 166,
                height: 89.82,
                left: 4,
                bottom: 9
            },
            image: {
                minted: require('assets/images/episode1/page8/2142-01-08-03.png'),
                unMinted: {
                    color: require('assets/images/episode1/page8/pixelized/2142-01-08-03.png'),
                    bw: require('assets/images/episode1/page8/pixelized-b-w/2142-01-08-03.png')
                }
            }
        }
    },
    44: {
        38: {
            style: {
                width: 54.32,
                height: 76.4,
                left: 4,
                top: 6
            },
            image: {
                minted: require('assets/images/episode1/page9/2142-01-09-01.png'),
                unMinted: {
                    color: require('assets/images/episode1/page9/pixelized/2142-01-09-01.png'),
                    bw: require('assets/images/episode1/page9/pixelized-b-w/2142-01-09-01.png')
                }
            }
        },
        39: {
            style: {
                width: 52.88,
                height: 76.31,
                left: 60.5,
                top: 6
            },
            image: {
                minted: require('assets/images/episode1/page9/2142-01-09-02.png'),
                unMinted: {
                    color: require('assets/images/episode1/page9/pixelized/2142-01-09-02.png'),
                    bw: require('assets/images/episode1/page9/pixelized-b-w/2142-01-09-02.png')
                }
            }
        },
        40: {
            style: {
                width: 53,
                height: 76.31,
                left: 116,
                top: 6
            },
            image: {
                minted: require('assets/images/episode1/page9/2142-01-09-03.png'),
                unMinted: {
                    color: require('assets/images/episode1/page9/pixelized/2142-01-09-03.png'),
                    bw: require('assets/images/episode1/page9/pixelized-b-w/2142-01-09-03.png')
                }
            }
        },
        41: {
            style: {
                width: 81.25,
                height: 70.84,
                left: 4,
                top: 85
            },
            image: {
                minted: require('assets/images/episode1/page9/2142-01-09-05.png'),
                unMinted: {
                    color: require('assets/images/episode1/page9/pixelized/2142-01-09-05.png'),
                    bw: require('assets/images/episode1/page9/pixelized-b-w/2142-01-09-05.png')
                }
            }
        },
        42: {
            style: {
                width: 81.97,
                height: 70.84,
                left: 87.8,
                top: 85.09
            },
            image: {
                minted: require('assets/images/episode1/page9/2142-01-09-04.png'),
                unMinted: {
                    color: require('assets/images/episode1/page9/pixelized/2142-01-09-04.png'),
                    bw: require('assets/images/episode1/page9/pixelized-b-w/2142-01-09-04.png')
                }
            }
        },
        43: {
            style: {
                width: 165.46,
                height: 74.79,
                left: 4,
                bottom: 6
            },
            image: {
                minted: require('assets/images/episode1/page9/2142-01-09-06.png'),
                unMinted: {
                    color: require('assets/images/episode1/page9/pixelized/2142-01-09-06.png'),
                    bw: require('assets/images/episode1/page9/pixelized-b-w/2142-01-09-06.png')
                }
            }
        }
    },
    49: {
        45: {
            style: {
                width: 163.4,
                height: 69.75,
                left: 5,
                top: 8
            },
            image: {
                minted: require('assets/images/episode1/page10/2142-01-10-01.png'),
                unMinted: {
                    color: require('assets/images/episode1/page10/pixelized/2142-01-10-01.png'),
                    bw: require('assets/images/episode1/page10/pixelized-b-w/2142-01-10-01.png')
                }
            }
        },
        46: {
            style: {
                width: 66.83,
                height: 57.71,
                left: 5,
                top: 81
            },
            image: {
                minted: require('assets/images/episode1/page10/2142-01-10-02.png'),
                unMinted: {
                    color: require('assets/images/episode1/page10/pixelized/2142-01-10-02.png'),
                    bw: require('assets/images/episode1/page10/pixelized-b-w/2142-01-10-02.png')
                }
            }
        },
        47: {
            style: {
                width: 164.28,
                height: 85.59,
                left: 6.72,
                top: 73.5,
                zIndex: 1
            },
            image: {
                minted: require('assets/images/episode1/page10/2142-01-10-03.png'),
                unMinted: {
                    color: require('assets/images/episode1/page10/pixelized/2142-01-10-03.png'),
                    bw: require('assets/images/episode1/page10/pixelized-b-w/2142-01-10-03.png')
                }
            }
        },
        48: {
            style: {
                width: 163.31,
                height: 91.08,
                left: 5,
                bottom: 7
            },
            image: {
                minted: require('assets/images/episode1/page10/2142-01-10-04.png'),
                unMinted: {
                    color: require('assets/images/episode1/page10/pixelized/2142-01-10-04.png'),
                    bw: require('assets/images/episode1/page10/pixelized-b-w/2142-01-10-04.png')
                }
            }
        }
    },
    53: {
        50: {
            style: {
                width: 166,
                height: 71.14,
                left: 4,
                top: 5
            },
            image: {
                minted: require('assets/images/episode1/page11/2142-01-11-01.png'),
                unMinted: {
                    color: require('assets/images/episode1/page11/pixelized/2142-01-11-01.png'),
                    bw: require('assets/images/episode1/page11/pixelized-b-w/2142-01-11-01.png')
                }
            }
        },
        51: {
            style: {
                width: 166,
                height: 51.83,
                left: 4,
                top: 80.63
            },
            image: {
                minted: require('assets/images/episode1/page11/2142-01-11-02.png'),
                unMinted: {
                    color: require('assets/images/episode1/page11/pixelized/2142-01-11-02.png'),
                    bw: require('assets/images/episode1/page11/pixelized-b-w/2142-01-11-02.png')
                }
            }
        },
        52: {
            style: {
                width: 166,
                height: 99,
                left: 4,
                bottom: 5
            },
            image: {
                minted: require('assets/images/episode1/page11/2142-01-11-03.png'),
                unMinted: {
                    color: require('assets/images/episode1/page11/pixelized/2142-01-11-03.png'),
                    bw: require('assets/images/episode1/page11/pixelized-b-w/2142-01-11-03.png')
                }
            }
        }
    },
    58: {
        54: {
            style: {
                width: 160.19,
                height: 80.96,
                left: 7,
                top: 10
            },
            image: {
                minted: require('assets/images/episode1/page12/2142-01-12-01.png'),
                unMinted: {
                    color: require('assets/images/episode1/page12/pixelized/2142-01-12-01.png'),
                    bw: require('assets/images/episode1/page12/pixelized-b-w/2142-01-12-01.png')
                }
            }
        },
        55: {
            style: {
                width: 160,
                height: 136.35,
                left: 7,
                bottom: 10.91
            },
            image: {
                minted: require('assets/images/episode1/page12/2142-01-12-04.png'),
                unMinted: {
                    color: require('assets/images/episode1/page12/pixelized/2142-01-12-04.png'),
                    bw: require('assets/images/episode1/page12/pixelized-b-w/2142-01-12-04.png')
                }
            }
        },
        56: {
            style: {
                width: 52.7,
                height: 58.17,
                left: 4,
                top: 83.16
            },
            image: {
                minted: require('assets/images/episode1/page12/2142-01-12-02.png'),
                unMinted: {
                    color: require('assets/images/episode1/page12/pixelized/2142-01-12-02.png'),
                    bw: require('assets/images/episode1/page12/pixelized-b-w/2142-01-12-02.png')
                }
            }
        },
        57: {
            style: {
                width: 110,
                height: 65.79,
                left: 60,
                top: 79.87
            },
            image: {
                minted: require('assets/images/episode1/page12/2142-01-12-03.png'),
                unMinted: {
                    color: require('assets/images/episode1/page12/pixelized/2142-01-12-03.png'),
                    bw: require('assets/images/episode1/page12/pixelized-b-w/2142-01-12-03.png')
                }
            }
        }
    },
    62: {
        59: {
            style: {
                width: 166,
                height: 99.22,
                left: 4,
                top: 5
            },
            image: {
                minted: require('assets/images/episode1/page13/2142-01-13-01.png'),
                unMinted: {
                    color: require('assets/images/episode1/page13/pixelized/2142-01-13-01.png'),
                    bw: require('assets/images/episode1/page13/pixelized-b-w/2142-01-13-01.png')
                }
            }
        },
        60: {
            style: {
                width: 166,
                height: 119.8,
                top: 35.74,
                left: 4
            },
            image: {
                minted: require('assets/images/episode1/page13/2142-01-13-02.png'),
                unMinted: {
                    color: require('assets/images/episode1/page13/pixelized/2142-01-13-02.png'),
                    bw: require('assets/images/episode1/page13/pixelized-b-w/2142-01-13-02.png')
                }
            }
        },
        61: {
            style: {
                width: 166,
                height: 107.22,
                left: 4,
                bottom: 5.38
            },
            image: {
                minted: require('assets/images/episode1/page13/2142-01-13-03.png'),
                unMinted: {
                    color: require('assets/images/episode1/page13/pixelized/2142-01-13-03.png'),
                    bw: require('assets/images/episode1/page13/pixelized-b-w/2142-01-13-03.png')
                }
            }
        }
    },
    68: {
        63: {
            style: {
                width: 161,
                height: 140,
                left: 5,
                top: 9
            },
            image: {
                minted: require('assets/images/episode1/page14/2142-01-14-01.png'),
                unMinted: {
                    color: require('assets/images/episode1/page14/pixelized/2142-01-14-01.png'),
                    bw: require('assets/images/episode1/page14/pixelized-b-w/2142-01-14-01.png')
                }
            }
        },
        64: {
            style: {
                width: 108,
                height: 60,
                left: 2,
                top: 91
            },
            image: {
                minted: require('assets/images/episode1/page14/2142-01-14-02.png'),
                unMinted: {
                    color: require('assets/images/episode1/page14/pixelized/2142-01-14-02.png'),
                    bw: require('assets/images/episode1/page14/pixelized-b-w/2142-01-14-02.png')
                }
            }
        },
        65: {
            style: {
                width: 57,
                height: 60,
                right: 3,
                top: 100,
                zIndex: 2
            },
            image: {
                minted: require('assets/images/episode1/page14/2142-01-14-03.png'),
                unMinted: {
                    color: require('assets/images/episode1/page14/pixelized/2142-01-14-03.png'),
                    bw: require('assets/images/episode1/page14/pixelized-b-w/2142-01-14-03.png')
                }
            }
        },
        66: {
            style: {
                width: 97,
                height: 78,
                left: 5,
                bottom: 9,
                zIndex: 1
            },
            image: {
                minted: require('assets/images/episode1/page14/2142-01-14-04.png'),
                unMinted: {
                    color: require('assets/images/episode1/page14/pixelized/2142-01-14-04.png'),
                    bw: require('assets/images/episode1/page14/pixelized-b-w/2142-01-14-04.png')
                }
            }
        },
        67: {
            style: {
                width: 90,
                height: 78,
                right: 7,
                bottom: 9
            },
            image: {
                minted: require('assets/images/episode1/page14/2142-01-14-05.png'),
                unMinted: {
                    color: require('assets/images/episode1/page14/pixelized/2142-01-14-05.webp'),
                    bw: require('assets/images/episode1/page14/pixelized-b-w/2142-01-14-05.webp')
                }
            }
        }
    },
    74: {
        69: {
            style: {
                width: 162.54,
                height: 79.29,
                left: 5.69,
                top: 8.57
            },
            image: {
                minted: require('assets/images/episode1/page15/2142-01-15-01.png'),
                unMinted: {
                    color: require('assets/images/episode1/page15/pixelized/2142-01-15-01.png'),
                    bw: require('assets/images/episode1/page15/pixelized-b-w/2142-01-15-01.png')
                }
            }
        },
        70: {
            style: {
                width: 176,
                height: 237,
                left: -1,
                bottom: 2.58
            },
            image: {
                minted: require('assets/images/episode1/page15/2142-01-15-05.png'),
                unMinted: {
                    color: require('assets/images/episode1/page15/pixelized/2142-01-15-05.png'),
                    bw: require('assets/images/episode1/page15/pixelized-b-w/2142-01-15-05.png')
                }
            }
        },
        71: {
            style: {
                width: 61.69,
                height: 58.17,
                left: 3.49,
                top: 77.56
            },
            image: {
                minted: require('assets/images/episode1/page15/2142-01-15-02.png'),
                unMinted: {
                    color: require('assets/images/episode1/page15/pixelized/2142-01-15-02.png'),
                    bw: require('assets/images/episode1/page15/pixelized-b-w/2142-01-15-02.png')
                }
            }
        },
        72: {
            style: {
                width: 48.84,
                height: 58.43,
                left: 51.98,
                top: 83.45
            },
            image: {
                minted: require('assets/images/episode1/page15/2142-01-15-03.png'),
                unMinted: {
                    color: require('assets/images/episode1/page15/pixelized/2142-01-15-03.png'),
                    bw: require('assets/images/episode1/page15/pixelized-b-w/2142-01-15-03.png')
                }
            }
        },
        73: {
            style: {
                width: 78.76,
                height: 55,
                left: 90.78,
                top: 77.78
            },
            image: {
                minted: require('assets/images/episode1/page15/2142-01-15-04.png'),
                unMinted: {
                    color: require('assets/images/episode1/page15/pixelized/2142-01-15-04.png'),
                    bw: require('assets/images/episode1/page15/pixelized-b-w/2142-01-15-04.png')
                }
            }
        }
    },
    78: {
        75: {
            style: {
                width: 165.64,
                height: 168.78,
                left: 4.18,
                top: 6
            },
            image: {
                minted: require('assets/images/episode1/page16/2142-01-16-01.png'),
                unMinted: {
                    color: require('assets/images/episode1/page16/pixelized/2142-01-16-01.png'),
                    bw: require('assets/images/episode1/page16/pixelized-b-w/2142-01-16-01.png')
                }
            }
        },
        76: {
            style: {
                width: 109.71,
                height: 58.89,
                left: 56.43,
                top: 112.39
            },
            image: {
                minted: require('assets/images/episode1/page16/2142-01-16-02.png'),
                unMinted: {
                    color: require('assets/images/episode1/page16/pixelized/2142-01-16-02.png'),
                    bw: require('assets/images/episode1/page16/pixelized-b-w/2142-01-16-02.png')
                }
            }
        },
        77: {
            style: {
                width: 166,
                height: 56.65,
                left: 4,
                bottom: 5.28
            },
            image: {
                minted: require('assets/images/episode1/page16/2142-01-16-03.png'),
                unMinted: {
                    color: require('assets/images/episode1/page16/pixelized/2142-01-16-03.png'),
                    bw: require('assets/images/episode1/page16/pixelized-b-w/2142-01-16-03.png')
                }
            }
        }
    },
    85: {
        79: {
            style: {
                width: 56.91,
                height: 57.78,
                left: 7,
                top: 7.75
            },
            image: {
                minted: require('assets/images/episode1/page17/2142-01-17-01.png'),
                unMinted: {
                    color: require('assets/images/episode1/page17/pixelized/2142-01-17-01.png'),
                    bw: require('assets/images/episode1/page17/pixelized-b-w/2142-01-17-01.png')
                }
            }
        },
        80: {
            style: {
                width: 65.74,
                height: 62.33,
                left: 46,
                top: 7.75
            },
            image: {
                minted: require('assets/images/episode1/page17/2142-01-17-02.png'),
                unMinted: {
                    color: require('assets/images/episode1/page17/pixelized/2142-01-17-02.png'),
                    bw: require('assets/images/episode1/page17/pixelized-b-w/2142-01-17-02.png')
                }
            }
        },
        81: {
            style: {
                width: 80.25,
                height: 76.23,
                left: 92.75,
                top: 1.17
            },
            image: {
                minted: require('assets/images/episode1/page17/2142-01-17-03.png'),
                unMinted: {
                    color: require('assets/images/episode1/page17/pixelized/2142-01-17-03.png'),
                    bw: require('assets/images/episode1/page17/pixelized-b-w/2142-01-17-03.png')
                }
            }
        },
        82: {
            style: {
                width: 55.95,
                height: 51.57,
                left: 7,
                top: 65
            },
            image: {
                minted: require('assets/images/episode1/page17/2142-01-17-04.png'),
                unMinted: {
                    color: require('assets/images/episode1/page17/pixelized/2142-01-17-04.png'),
                    bw: require('assets/images/episode1/page17/pixelized-b-w/2142-01-17-04.png')
                }
            }
        },
        83: {
            style: {
                width: 118.36,
                height: 57.61,
                left: 48.52,
                top: 70.5
            },
            image: {
                minted: require('assets/images/episode1/page17/2142-01-17-05.png'),
                unMinted: {
                    color: require('assets/images/episode1/page17/pixelized/2142-01-17-05.png'),
                    bw: require('assets/images/episode1/page17/pixelized-b-w/2142-01-17-05.png')
                }
            }
        },
        84: {
            style: {
                width: 160.45,
                height: 116.35,
                left: 7,
                top: 115.7
            },
            image: {
                minted: require('assets/images/episode1/page17/2142-01-17-06.png'),
                unMinted: {
                    color: require('assets/images/episode1/page17/pixelized/2142-01-17-06.png'),
                    bw: require('assets/images/episode1/page17/pixelized-b-w/2142-01-17-06.png')
                }
            }
        }
    },
    91: {
        86: {
            style: {
                height: 87.53,
                width: 47.67,
                left: 4,
                top: 6
            },
            image: {
                minted: require('assets/images/episode1/page18/2142-01-18-01.png'),
                unMinted: {
                    color: require('assets/images/episode1/page18/pixelized/2142-01-18-01.png'),
                    bw: require('assets/images/episode1/page18/pixelized-b-w/2142-01-18-01.png')
                }
            }
        },
        87: {
            style: {
                width: 70.12,
                height: 83,
                left: 27.16,
                top: 6
            },
            image: {
                minted: require('assets/images/episode1/page18/2142-01-18-02.png'),
                unMinted: {
                    color: require('assets/images/episode1/page18/pixelized/2142-01-18-02.png'),
                    bw: require('assets/images/episode1/page18/pixelized-b-w/2142-01-18-02.png')
                }
            }
        },
        88: {
            style: {
                width: 96.5,
                height: 87.53,
                left: 72.68,
                top: 6
            },
            image: {
                minted: require('assets/images/episode1/page18/2142-01-18-03.png'),
                unMinted: {
                    color: require('assets/images/episode1/page18/pixelized/2142-01-18-03.png'),
                    bw: require('assets/images/episode1/page18/pixelized-b-w/2142-01-18-03.png')
                }
            }
        },
        89: {
            style: {
                width: 156.57,
                height: 37.17,
                left: 8.31,
                top: 88
            },
            image: {
                minted: require('assets/images/episode1/page18/2142-01-18-04.png'),
                unMinted: {
                    color: require('assets/images/episode1/page18/pixelized/2142-01-18-04.png'),
                    bw: require('assets/images/episode1/page18/pixelized-b-w/2142-01-18-04.png')
                }
            }
        },
        90: {
            style: {
                width: 165.64,
                height: 137.45,
                left: 4,
                bottom: 6
            },
            image: {
                minted: require('assets/images/episode1/page18/2142-01-18-05.png'),
                unMinted: {
                    color: require('assets/images/episode1/page18/pixelized/2142-01-18-05.png'),
                    bw: require('assets/images/episode1/page18/pixelized-b-w/2142-01-18-05.png')
                }
            }
        }
    },
    96: {
        92: {
            style: {
                width: 165.91,
                height: 92.66,
                left: 4,
                top: 6
            },
            image: {
                minted: require('assets/images/episode1/page19/2142-01-19-01.png'),
                unMinted: {
                    color: require('assets/images/episode1/page19/pixelized/2142-01-19-01.png'),
                    bw: require('assets/images/episode1/page19/pixelized-b-w/2142-01-19-01.png')
                }
            }
        },
        93: {
            style: {
                width: 89.34,
                height: 73.25,
                left: 4,
                top: 93
            },
            image: {
                minted: require('assets/images/episode1/page19/2142-01-19-02.png'),
                unMinted: {
                    color: require('assets/images/episode1/page19/pixelized/2142-01-19-02.png'),
                    bw: require('assets/images/episode1/page19/pixelized-b-w/2142-01-19-02.png')
                }
            }
        },
        94: {
            style: {
                width: 99,
                height: 74.6,
                left: 70.87,
                top: 78
            },
            image: {
                minted: require('assets/images/episode1/page19/2142-01-19-03.png'),
                unMinted: {
                    color: require('assets/images/episode1/page19/pixelized/2142-01-19-03.png'),
                    bw: require('assets/images/episode1/page19/pixelized-b-w/2142-01-19-03.png')
                }
            }
        },
        95: {
            style: {
                width: 166,
                height: 89.34,
                left: 4,
                bottom: 5
            },
            image: {
                minted: require('assets/images/episode1/page19/2142-01-19-04.png'),
                unMinted: {
                    color: require('assets/images/episode1/page19/pixelized/2142-01-19-04.png'),
                    bw: require('assets/images/episode1/page19/pixelized-b-w/2142-01-19-04.png')
                }
            }
        }
    },
    101: {
        97: {
            style: {
                width: 74.51,
                height: 67.5,
                left: 4,
                top: 5
            },
            image: {
                minted: require('assets/images/episode1/page20/2142-01-20-01.png'),
                unMinted: {
                    color: require('assets/images/episode1/page20/pixelized/2142-01-20-01.png'),
                    bw: require('assets/images/episode1/page20/pixelized-b-w/2142-01-20-01.png')
                }
            }
        },
        98: {
            style: {
                width: 109.65,
                height: 67.5,
                left: 60.35,
                top: 5
            },
            image: {
                minted: require('assets/images/episode1/page20/2142-01-20-02.png'),
                unMinted: {
                    color: require('assets/images/episode1/page20/pixelized/2142-01-20-02.png'),
                    bw: require('assets/images/episode1/page20/pixelized-b-w/2142-01-20-02.png')
                }
            }
        },
        99: {
            style: {
                width: 166,
                height: 86.73,
                left: 4,
                top: 77
            },
            image: {
                minted: require('assets/images/episode1/page20/2142-01-20-03.png'),
                unMinted: {
                    color: require('assets/images/episode1/page20/pixelized/2142-01-20-03.png'),
                    bw: require('assets/images/episode1/page20/pixelized-b-w/2142-01-20-03.png')
                }
            }
        },
        100: {
            style: {
                width: 165.91,
                height: 67.68,
                left: 4,
                bottom: 5
            },
            image: {
                minted: require('assets/images/episode1/page20/2142-01-20-04.png'),
                unMinted: {
                    color: require('assets/images/episode1/page20/pixelized/2142-01-20-04.png'),
                    bw: require('assets/images/episode1/page20/pixelized-b-w/2142-01-20-04.png')
                }
            }
        }
    },
    109: {
        102: {
            style: {
                width: 102,
                height: 48,
                left: 4,
                top: 5,
                zIndex: 7
            },
            image: {
                minted: require('assets/images/episode1/page21/2142-01-21-01.png'),
                unMinted: {
                    color: require('assets/images/episode1/page21/pixelized/2142-01-21-01.png'),
                    bw: require('assets/images/episode1/page21/pixelized-b-w/2142-01-21-01.png')
                }
            }
        },
        103: {
            style: {
                width: 68,
                height: 52,
                right: 4,
                top: 5
            },
            image: {
                minted: require('assets/images/episode1/page21/2142-01-21-02.png'),
                unMinted: {
                    color: require('assets/images/episode1/page21/pixelized/2142-01-21-02.png'),
                    bw: require('assets/images/episode1/page21/pixelized-b-w/2142-01-21-02.png')
                }
            }
        },
        104: {
            style: {
                width: 58,
                height: 60,
                left: 4,
                top: 49,
                zIndex: 3
            },
            image: {
                minted: require('assets/images/episode1/page21/2142-01-21-03.png'),
                unMinted: {
                    color: require('assets/images/episode1/page21/pixelized/2142-01-21-03.png'),
                    bw: require('assets/images/episode1/page21/pixelized-b-w/2142-01-21-03.png')
                }
            }
        },
        105: {
            style: {
                width: 63,
                height: 65,
                left: 48,
                top: 53
            },
            image: {
                minted: require('assets/images/episode1/page21/2142-01-21-04.png'),
                unMinted: {
                    color: require('assets/images/episode1/page21/pixelized/2142-01-21-04.png'),
                    bw: require('assets/images/episode1/page21/pixelized-b-w/2142-01-21-04.png')
                }
            }
        },
        106: {
            style: {
                width: 67,
                height: 72,
                right: 4,
                top: 56
            },
            image: {
                minted: require('assets/images/episode1/page21/2142-01-21-05.png'),
                unMinted: {
                    color: require('assets/images/episode1/page21/pixelized/2142-01-21-05.png'),
                    bw: require('assets/images/episode1/page21/pixelized-b-w/2142-01-21-05.png')
                }
            }
        },
        107: {
            style: {
                width: 114,
                height: 129,
                left: 4,
                bottom: 6
            },
            image: {
                minted: require('assets/images/episode1/page21/2142-01-21-06.png'),
                unMinted: {
                    color: require('assets/images/episode1/page21/pixelized/2142-01-21-06.png'),
                    bw: require('assets/images/episode1/page21/pixelized-b-w/2142-01-21-06.png')
                }
            }
        },
        108: {
            style: {
                width: 77,
                height: 111,
                right: 4,
                bottom: 6
            },
            image: {
                minted: require('assets/images/episode1/page21/2142-01-21-07.png'),
                unMinted: {
                    color: require('assets/images/episode1/page21/pixelized/2142-01-21-07.png'),
                    bw: require('assets/images/episode1/page21/pixelized-b-w/2142-01-21-07.png')
                }
            }
        }
    },
    117: {
        110: {
            style: {
                width: 161,
                height: 33,
                left: 8,
                top: 9,
                zIndex: 7
            },
            image: {
                minted: require('assets/images/episode1/page22/2142-01-22-01.png'),
                unMinted: {
                    color: require('assets/images/episode1/page22/pixelized/2142-01-22-01.png'),
                    bw: require('assets/images/episode1/page22/pixelized-b-w/2142-01-22-01.png')
                }
            }
        },
        111: {
            style: {
                width: 164,
                height: 72,
                left: 4,
                top: 39,
                zIndex: 6
            },
            image: {
                minted: require('assets/images/episode1/page22/2142-01-22-02.png'),
                unMinted: {
                    color: require('assets/images/episode1/page22/pixelized/2142-01-22-02.png'),
                    bw: require('assets/images/episode1/page22/pixelized-b-w/2142-01-22-02.png')
                }
            }
        },
        112: {
            style: {
                width: 159,
                height: 28,
                left: 11,
                top: 107,
                zIndex: 5
            },
            image: {
                minted: require('assets/images/episode1/page22/2142-01-22-03.png'),
                unMinted: {
                    color: require('assets/images/episode1/page22/pixelized/2142-01-22-03.png'),
                    bw: require('assets/images/episode1/page22/pixelized-b-w/2142-01-22-03.png')
                }
            }
        },
        113: {
            style: {
                width: 36,
                height: 36,
                left: 7,
                top: 133,
                zIndex: 4
            },
            image: {
                minted: require('assets/images/episode1/page22/2142-01-22-04.png'),
                unMinted: {
                    color: require('assets/images/episode1/page22/pixelized/2142-01-22-04.png'),
                    bw: require('assets/images/episode1/page22/pixelized-b-w/2142-01-22-04.png')
                }
            }
        },
        114: {
            style: {
                width: 57,
                height: 35,
                left: 45,
                top: 129
            },
            image: {
                minted: require('assets/images/episode1/page22/2142-01-22-05.png'),
                unMinted: {
                    color: require('assets/images/episode1/page22/pixelized/2142-01-22-05.png'),
                    bw: require('assets/images/episode1/page22/pixelized-b-w/2142-01-22-05.png')
                }
            }
        },
        115: {
            style: {
                width: 65,
                height: 36,
                right: 5,
                top: 134
            },
            image: {
                minted: require('assets/images/episode1/page22/2142-01-22-06.png'),
                unMinted: {
                    color: require('assets/images/episode1/page22/pixelized/2142-01-22-06.png'),
                    bw: require('assets/images/episode1/page22/pixelized-b-w/2142-01-22-06.png')
                }
            }
        },
        116: {
            style: {
                width: 158,
                height: 78,
                left: 12,
                bottom: 9
            },
            image: {
                minted: require('assets/images/episode1/page22/2142-01-22-07.png'),
                unMinted: {
                    color: require('assets/images/episode1/page22/pixelized/2142-01-22-07.png'),
                    bw: require('assets/images/episode1/page22/pixelized-b-w/2142-01-22-07.png')
                }
            }
        }
    },
    126: {
        118: {
            style: {
                width: 79,
                height: 35,
                left: 5,
                top: 8
            },
            image: {
                minted: require('assets/images/episode1/page23/2142-01-23-01.png'),
                unMinted: {
                    color: require('assets/images/episode1/page23/pixelized/2142-01-23-01.png'),
                    bw: require('assets/images/episode1/page23/pixelized-b-w/2142-01-23-01.png')
                }
            }
        },
        119: {
            style: {
                width: 79,
                height: 35,
                right: 8,
                top: 8
            },
            image: {
                minted: require('assets/images/episode1/page23/2142-01-23-02.png'),
                unMinted: {
                    color: require('assets/images/episode1/page23/pixelized/2142-01-23-02.png'),
                    bw: require('assets/images/episode1/page23/pixelized-b-w/2142-01-23-02.png')
                }
            }
        },
        120: {
            style: {
                width: 157,
                height: 103,
                left: 7,
                top: 11
            },
            image: {
                minted: require('assets/images/episode1/page23/2142-01-23-03.png'),
                unMinted: {
                    color: require('assets/images/episode1/page23/pixelized/2142-01-23-03.png'),
                    bw: require('assets/images/episode1/page23/pixelized-b-w/2142-01-23-03.png')
                }
            }
        },
        121: {
            style: {
                width: 52,
                height: 54,
                left: 5,
                top: 64
            },
            image: {
                minted: require('assets/images/episode1/page23/2142-01-23-04.png'),
                unMinted: {
                    color: require('assets/images/episode1/page23/pixelized/2142-01-23-04.png'),
                    bw: require('assets/images/episode1/page23/pixelized-b-w/2142-01-23-04.png')
                }
            }
        },
        122: {
            style: {
                width: 132,
                height: 54,
                right: 1,
                top: 64
            },
            image: {
                minted: require('assets/images/episode1/page23/2142-01-23-05.png'),
                unMinted: {
                    color: require('assets/images/episode1/page23/pixelized/2142-01-23-05.png'),
                    bw: require('assets/images/episode1/page23/pixelized-b-w/2142-01-23-05.png')
                }
            }
        },
        123: {
            style: {
                width: 62,
                height: 109,
                left: 5,
                bottom: 9,
                zIndex: 1
            },
            image: {
                minted: require('assets/images/episode1/page23/2142-01-23-06.png'),
                unMinted: {
                    color: require('assets/images/episode1/page23/pixelized/2142-01-23-06.png'),
                    bw: require('assets/images/episode1/page23/pixelized-b-w/2142-01-23-06.png')
                }
            }
        },
        124: {
            style: {
                width: 130,
                height: 109,
                right: 8,
                bottom: 9
            },
            image: {
                minted: require('assets/images/episode1/page23/2142-01-23-07.png'),
                unMinted: {
                    color: require('assets/images/episode1/page23/pixelized/2142-01-23-07.png'),
                    bw: require('assets/images/episode1/page23/pixelized-b-w/2142-01-23-07.png')
                }
            }
        },
        125: {
            style: {
                width: 31,
                height: 57,
                right: 6,
                bottom: 12
            },
            image: {
                minted: require('assets/images/episode1/page23/2142-01-23-08.png'),
                unMinted: {
                    color: require('assets/images/episode1/page23/pixelized/2142-01-23-08.png'),
                    bw: require('assets/images/episode1/page23/pixelized-b-w/2142-01-23-08.png')
                }
            }
        }
    },
    136: {
        127: {
            style: {
                width: 94,
                height: 62,
                left: 5,
                top: 5
            },
            image: {
                minted: require('assets/images/episode1/page24/2142-01-24-01.png'),
                unMinted: {
                    color: require('assets/images/episode1/page24/pixelized/2142-01-24-01.png'),
                    bw: require('assets/images/episode1/page24/pixelized-b-w/2142-01-24-01.png')
                }
            }
        },
        128: {
            style: {
                width: 59,
                height: 40,
                left: 86,
                top: 5
            },
            image: {
                minted: require('assets/images/episode1/page24/2142-01-24-02.png'),
                unMinted: {
                    color: require('assets/images/episode1/page24/pixelized/2142-01-24-02.png'),
                    bw: require('assets/images/episode1/page24/pixelized-b-w/2142-01-24-02.png')
                }
            }
        },
        129: {
            style: {
                width: 36,
                height: 34,
                right: 5,
                top: 5
            },
            image: {
                minted: require('assets/images/episode1/page24/2142-01-24-03.png'),
                unMinted: {
                    color: require('assets/images/episode1/page24/pixelized/2142-01-24-03.png'),
                    bw: require('assets/images/episode1/page24/pixelized-b-w/2142-01-24-03.png')
                }
            }
        },
        130: {
            style: {
                width: 94,
                height: 35,
                right: 5,
                top: 37,
                zIndex: 1
            },
            image: {
                minted: require('assets/images/episode1/page24/2142-01-24-04.png'),
                unMinted: {
                    color: require('assets/images/episode1/page24/pixelized/2142-01-24-04.png'),
                    bw: require('assets/images/episode1/page24/pixelized-b-w/2142-01-24-04.png')
                }
            }
        },
        131: {
            style: {
                width: 56,
                height: 26,
                left: 5,
                zIndex: 1,
                top: 71
            },
            image: {
                minted: require('assets/images/episode1/page24/2142-01-24-05.png'),
                unMinted: {
                    color: require('assets/images/episode1/page24/pixelized/2142-01-24-05.png'),
                    bw: require('assets/images/episode1/page24/pixelized-b-w/2142-01-24-05.png')
                }
            }
        },
        132: {
            style: {
                width: 160,
                height: 170,
                left: 7,
                top: 63
            },
            image: {
                minted: require('assets/images/episode1/page24/2142-01-24-06.png'),
                unMinted: {
                    color: require('assets/images/episode1/page24/pixelized/2142-01-24-06.png'),
                    bw: require('assets/images/episode1/page24/pixelized-b-w/2142-01-24-06.png')
                }
            }
        },
        133: {
            style: {
                width: 48,
                height: 29,
                right: 5,
                top: 76
            },
            image: {
                minted: require('assets/images/episode1/page24/2142-01-24-07.png'),
                unMinted: {
                    color: require('assets/images/episode1/page24/pixelized/2142-01-24-07.png'),
                    bw: require('assets/images/episode1/page24/pixelized-b-w/2142-01-24-07.png')
                }
            }
        },
        134: {
            style: {
                width: 34,
                height: 36,
                left: 5,
                bottom: 17
            },
            image: {
                minted: require('assets/images/episode1/page24/2142-01-24-08.png'),
                unMinted: {
                    color: require('assets/images/episode1/page24/pixelized/2142-01-24-08.png'),
                    bw: require('assets/images/episode1/page24/pixelized-b-w/2142-01-24-08.png')
                }
            }
        },
        135: {
            style: {
                width: 37,
                height: 53,
                right: 5,
                bottom: 11
            },
            image: {
                minted: require('assets/images/episode1/page24/2142-01-24-09.png'),
                unMinted: {
                    color: require('assets/images/episode1/page24/pixelized/2142-01-24-09.png'),
                    bw: require('assets/images/episode1/page24/pixelized-b-w/2142-01-24-09.png')
                }
            }
        }
    },
    144: {
        137: {
            style: {
                width: 161,
                height: 96,
                left: 6,
                top: 9
            },
            image: {
                minted: require('assets/images/episode1/page25/2142-01-25-01.png'),
                unMinted: {
                    color: require('assets/images/episode1/page25/pixelized/2142-01-25-01.png'),
                    bw: require('assets/images/episode1/page25/pixelized-b-w/2142-01-25-01.png')
                }
            }
        },
        138: {
            style: {
                width: 86,
                height: 36,
                left: 3,
                top: 81
            },
            image: {
                minted: require('assets/images/episode1/page25/2142-01-25-02.png'),
                unMinted: {
                    color: require('assets/images/episode1/page25/pixelized/2142-01-25-02.png'),
                    bw: require('assets/images/episode1/page25/pixelized-b-w/2142-01-25-02.png')
                }
            }
        },
        139: {
            style: {
                width: 83,
                height: 29,
                right: 5,
                top: 83
            },
            image: {
                minted: require('assets/images/episode1/page25/2142-01-25-03.png'),
                unMinted: {
                    color: require('assets/images/episode1/page25/pixelized/2142-01-25-03.png'),
                    bw: require('assets/images/episode1/page25/pixelized-b-w/2142-01-25-03.png')
                }
            }
        },
        140: {
            style: {
                width: 57,
                height: 38,
                left: 7,
                top: 112
            },
            image: {
                minted: require('assets/images/episode1/page25/2142-01-25-04.png'),
                unMinted: {
                    color: require('assets/images/episode1/page25/pixelized/2142-01-25-04.png'),
                    bw: require('assets/images/episode1/page25/pixelized-b-w/2142-01-25-04.png')
                }
            }
        },
        141: {
            style: {
                width: 46,
                height: 39,
                left: 60,
                top: 108
            },
            image: {
                minted: require('assets/images/episode1/page25/2142-01-25-05.png'),
                unMinted: {
                    color: require('assets/images/episode1/page25/pixelized/2142-01-25-05.png'),
                    bw: require('assets/images/episode1/page25/pixelized-b-w/2142-01-25-05.png')
                }
            }
        },
        142: {
            style: {
                width: 63,
                height: 38,
                right: 8,
                top: 106
            },
            image: {
                minted: require('assets/images/episode1/page25/2142-01-25-06.png'),
                unMinted: {
                    color: require('assets/images/episode1/page25/pixelized/2142-01-25-06.png'),
                    bw: require('assets/images/episode1/page25/pixelized-b-w/2142-01-25-06.png')
                }
            }
        },
        143: {
            style: {
                width: 161,
                height: 91,
                left: 6,
                bottom: 9
            },
            image: {
                minted: require('assets/images/episode1/page25/2142-01-25-07.png'),
                unMinted: {
                    color: require('assets/images/episode1/page25/pixelized/2142-01-25-07.png'),
                    bw: require('assets/images/episode1/page25/pixelized-b-w/2142-01-25-07.png')
                }
            }
        }
    },
    153: {
        145: {
            style: {
                width: 166,
                height: 84,
                left: 4,
                top: 5
            },
            image: {
                minted: require('assets/images/episode1/page26/2142-01-26-01.png'),
                unMinted: {
                    color: require('assets/images/episode1/page26/pixelized/2142-01-26-01.png'),
                    bw: require('assets/images/episode1/page26/pixelized-b-w/2142-01-26-01.png')
                }
            }
        },
        146: {
            style: {
                width: 54,
                height: 28,
                left: 7,
                top: 70,
                zIndex: 1
            },
            image: {
                minted: require('assets/images/episode1/page26/2142-01-26-02.png'),
                unMinted: {
                    color: require('assets/images/episode1/page26/pixelized/2142-01-26-02.png'),
                    bw: require('assets/images/episode1/page26/pixelized-b-w/2142-01-26-02.png')
                }
            }
        },
        147: {
            style: {
                width: 48,
                height: 28,
                left: 63,
                top: 70,
                zIndex: 1
            },
            image: {
                minted: require('assets/images/episode1/page26/2142-01-26-03.png'),
                unMinted: {
                    color: require('assets/images/episode1/page26/pixelized/2142-01-26-03.png'),
                    bw: require('assets/images/episode1/page26/pixelized-b-w/2142-01-26-03.png')
                }
            }
        },
        148: {
            style: {
                width: 54,
                height: 30,
                top: 70,
                right: 6,
                zIndex: 1
            },
            image: {
                minted: require('assets/images/episode1/page26/2142-01-26-04.png'),
                unMinted: {
                    color: require('assets/images/episode1/page26/pixelized/2142-01-26-04.png'),
                    bw: require('assets/images/episode1/page26/pixelized-b-w/2142-01-26-04.png')
                }
            }
        },
        149: {
            style: {
                width: 166,
                height: 141,
                left: 4,
                bottom: 6
            },
            image: {
                minted: require('assets/images/episode1/page26/2142-01-26-05.png'),
                unMinted: {
                    color: require('assets/images/episode1/page26/pixelized/2142-01-26-05.png'),
                    bw: require('assets/images/episode1/page26/pixelized-b-w/2142-01-26-05.png')
                }
            }
        },
        150: {
            style: {
                width: 63,
                height: 30,
                left: 16,
                top: 149
            },
            image: {
                minted: require('assets/images/episode1/page26/2142-01-26-06.png'),
                unMinted: {
                    color: require('assets/images/episode1/page26/pixelized/2142-01-26-06.png'),
                    bw: require('assets/images/episode1/page26/pixelized-b-w/2142-01-26-06.png')
                }
            }
        },
        151: {
            style: {
                width: 65,
                height: 45,
                left: 8,
                bottom: 14
            },
            image: {
                minted: require('assets/images/episode1/page26/2142-01-26-07.png'),
                unMinted: {
                    color: require('assets/images/episode1/page26/pixelized/2142-01-26-07.png'),
                    bw: require('assets/images/episode1/page26/pixelized-b-w/2142-01-26-07.png')
                }
            }
        },
        152: {
            style: {
                width: 90,
                height: 78,
                right: 15,
                bottom: 10
            },
            image: {
                minted: require('assets/images/episode1/page26/2142-01-26-08.png'),
                unMinted: {
                    color: require('assets/images/episode1/page26/pixelized/2142-01-26-08.png'),
                    bw: require('assets/images/episode1/page26/pixelized-b-w/2142-01-26-08.png')
                }
            }
        }
    },
    157: {
        154: {
            style: {
                width: 164,
                height: 60,
                left: 5,
                top: 5
            },
            image: {
                minted: require('assets/images/episode1/page27/2142-01-27-01.png'),
                unMinted: {
                    color: require('assets/images/episode1/page27/pixelized/2142-01-27-01.png'),
                    bw: require('assets/images/episode1/page27/pixelized-b-w/2142-01-27-01.png')
                }
            }
        },
        155: {
            style: {
                width: 164,
                height: 48,
                left: 5,
                top: 68
            },
            image: {
                minted: require('assets/images/episode1/page27/2142-01-27-02.png'),
                unMinted: {
                    color: require('assets/images/episode1/page27/pixelized/2142-01-27-02.png'),
                    bw: require('assets/images/episode1/page27/pixelized-b-w/2142-01-27-02.png')
                }
            }
        },
        156: {
            style: {
                width: 164,
                height: 115,
                left: 5,
                bottom: 5
            },
            image: {
                minted: require('assets/images/episode1/page27/2142-01-27-03.png'),
                unMinted: {
                    color: require('assets/images/episode1/page27/pixelized/2142-01-27-03.png'),
                    bw: require('assets/images/episode1/page27/pixelized-b-w/2142-01-27-03.png')
                }
            }
        }
    },
    159: {
        158: {
            style: {
                width: 164,
                height: 230,
                left: 5,
                top: 5
            },
            image: {
                minted: require('assets/images/episode1/page28/2142-01-28-01.png'),
                unMinted: {
                    color: require('assets/images/episode1/page28/pixelized/2142-01-28-01.png'),
                    bw: require('assets/images/episode1/page28/pixelized-b-w/2142-01-28-01.png')
                }
            }
        }
    },
    167: {
        160: {
            style: {
                width: 121,
                height: 27,
                left: 7,
                top: 6
            },
            image: {
                minted: require('assets/images/episode1/page29/2142-01-29-01.png'),
                unMinted: {
                    color: require('assets/images/episode1/page29/pixelized/2142-01-29-01.png'),
                    bw: require('assets/images/episode1/page29/pixelized-b-w/2142-01-29-01.png')
                }
            }
        },
        161: {
            style: {
                width: 121,
                height: 48,
                left: 4,
                top: 31
            },
            image: {
                minted: require('assets/images/episode1/page29/2142-01-29-02.png'),
                unMinted: {
                    color: require('assets/images/episode1/page29/pixelized/2142-01-29-02.png'),
                    bw: require('assets/images/episode1/page29/pixelized-b-w/2142-01-29-02.png')
                }
            }
        },
        162: {
            style: {
                width: 41,
                height: 69,
                right: 5,
                top: 5
            },
            image: {
                minted: require('assets/images/episode1/page29/2142-01-29-03.png'),
                unMinted: {
                    color: require('assets/images/episode1/page29/pixelized/2142-01-29-03.png'),
                    bw: require('assets/images/episode1/page29/pixelized-b-w/2142-01-29-03.png')
                }
            }
        },
        163: {
            style: {
                width: 95,
                height: 52,
                left: 5,
                top: 82,
                zIndex: 1
            },
            image: {
                minted: require('assets/images/episode1/page29/2142-01-29-04.png'),
                unMinted: {
                    color: require('assets/images/episode1/page29/pixelized/2142-01-29-04.png'),
                    bw: require('assets/images/episode1/page29/pixelized-b-w/2142-01-29-04.png')
                }
            }
        },
        164: {
            style: {
                width: 69,
                height: 55,
                right: 3,
                top: 73,
                zIndex: 1
            },
            image: {
                minted: require('assets/images/episode1/page29/2142-01-29-05.png'),
                unMinted: {
                    color: require('assets/images/episode1/page29/pixelized/2142-01-29-05.png'),
                    bw: require('assets/images/episode1/page29/pixelized-b-w/2142-01-29-05.png')
                }
            }
        },
        165: {
            style: {
                width: 161,
                height: 113,
                left: 5,
                top: 85
            },
            image: {
                minted: require('assets/images/episode1/page29/2142-01-29-06.png'),
                unMinted: {
                    color: require('assets/images/episode1/page29/pixelized/2142-01-29-06.png'),
                    bw: require('assets/images/episode1/page29/pixelized-b-w/2142-01-29-06.png')
                }
            }
        },
        166: {
            style: {
                width: 166,
                height: 35,
                left: 4,
                bottom: 7
            },
            image: {
                minted: require('assets/images/episode1/page29/2142-01-29-07.png'),
                unMinted: {
                    color: require('assets/images/episode1/page29/pixelized/2142-01-29-07.png'),
                    bw: require('assets/images/episode1/page29/pixelized-b-w/2142-01-29-07.png')
                }
            }
        }
    },
    172: {
        168: {
            style: {
                width: 97.34,
                height: 85.83,
                left: 4,
                top: 5
            },
            image: {
                minted: require('assets/images/episode1/page30/2142-01-30-01.png'),
                unMinted: {
                    color: require('assets/images/episode1/page30/pixelized/2142-01-30-01.png'),
                    bw: require('assets/images/episode1/page30/pixelized-b-w/2142-01-30-01.png')
                }
            }
        },
        169: {
            style: {
                width: 79.45,
                height: 93.74,
                left: 90.55,
                top: 5
            },
            image: {
                minted: require('assets/images/episode1/page30/2142-01-30-02.png'),
                unMinted: {
                    color: require('assets/images/episode1/page30/pixelized/2142-01-30-02.png'),
                    bw: require('assets/images/episode1/page30/pixelized-b-w/2142-01-30-02.png')
                }
            }
        },
        170: {
            style: {
                width: 165.91,
                height: 54.82,
                left: 4,
                top: 83.1
            },
            image: {
                minted: require('assets/images/episode1/page30/2142-01-30-03.png'),
                unMinted: {
                    color: require('assets/images/episode1/page30/pixelized/2142-01-30-03.png'),
                    bw: require('assets/images/episode1/page30/pixelized-b-w/2142-01-30-03.png')
                }
            }
        },
        171: {
            style: {
                width: 165.91,
                height: 103.45,
                left: 4,
                bottom: 5
            },
            image: {
                minted: require('assets/images/episode1/page30/2142-01-30-04.png'),
                unMinted: {
                    color: require('assets/images/episode1/page30/pixelized/2142-01-30-04.png'),
                    bw: require('assets/images/episode1/page30/pixelized-b-w/2142-01-30-04.png')
                }
            }
        }
    },
    179: {
        173: {
            style: {
                width: 72.35,
                height: 59.32,
                left: 4,
                top: 6
            },
            image: {
                minted: require('assets/images/episode1/page31/2142-01-31-01.png'),
                unMinted: {
                    color: require('assets/images/episode1/page31/pixelized/2142-01-31-01.png'),
                    bw: require('assets/images/episode1/page31/pixelized-b-w/2142-01-31-01.png')
                }
            }
        },
        174: {
            style: {
                width: 94.73,
                height: 59.14,
                left: 75.27,
                top: 6
            },
            image: {
                minted: require('assets/images/episode1/page31/2142-01-31-02.png'),
                unMinted: {
                    color: require('assets/images/episode1/page31/pixelized/2142-01-31-02.png'),
                    bw: require('assets/images/episode1/page31/pixelized-b-w/2142-01-31-02.png')
                }
            }
        },
        175: {
            style: {
                width: 161.24,
                height: 223.34,
                left: 6,
                top: 8.52,
                zIndex: 1
            },
            image: {
                minted: require('assets/images/episode1/page31/2142-01-31-03.png'),
                unMinted: {
                    color: require('assets/images/episode1/page31/pixelized/2142-01-31-03.png'),
                    bw: require('assets/images/episode1/page31/pixelized-b-w/2142-01-31-03.png')
                }
            }
        },
        176: {
            style: {
                width: 58.15,
                height: 56.89,
                left: 4,
                bottom: 6
            },
            image: {
                minted: require('assets/images/episode1/page31/2142-01-31-04.png'),
                unMinted: {
                    color: require('assets/images/episode1/page31/pixelized/2142-01-31-04.png'),
                    bw: require('assets/images/episode1/page31/pixelized-b-w/2142-01-31-04.png')
                }
            }
        },
        177: {
            style: {
                width: 54.64,
                height: 46.38,
                left: 59,
                bottom: 6
            },
            image: {
                minted: require('assets/images/episode1/page31/2142-01-31-05.png'),
                unMinted: {
                    color: require('assets/images/episode1/page31/pixelized/2142-01-31-05.png'),
                    bw: require('assets/images/episode1/page31/pixelized-b-w/2142-01-31-05.png')
                }
            }
        },
        178: {
            style: {
                width: 62.82,
                height: 56.53,
                left: 107,
                bottom: 6
            },
            image: {
                minted: require('assets/images/episode1/page31/2142-01-31-06.png'),
                unMinted: {
                    color: require('assets/images/episode1/page31/pixelized/2142-01-31-06.png'),
                    bw: require('assets/images/episode1/page31/pixelized-b-w/2142-01-31-06.png')
                }
            }
        }
    },
    183: {
        180: {
            style: {
                width: 102.82,
                height: 87.9,
                left: 4,
                top: 6
            },
            image: {
                minted: require('assets/images/episode1/page32/2142-01-32-01.png'),
                unMinted: {
                    color: require('assets/images/episode1/page32/pixelized/2142-01-32-01.png'),
                    bw: require('assets/images/episode1/page32/pixelized-b-w/2142-01-32-01.png')
                }
            }
        },
        181: {
            style: {
                width: 87.72,
                height: 87.9,
                left: 82,
                top: 6
            },
            image: {
                minted: require('assets/images/episode1/page32/2142-01-32-02.png'),
                unMinted: {
                    color: require('assets/images/episode1/page32/pixelized/2142-01-32-02.png'),
                    bw: require('assets/images/episode1/page32/pixelized-b-w/2142-01-32-02.png')
                }
            }
        },
        182: {
            style: {
                width: 165.82,
                height: 136.97,
                left: 4,
                bottom: 6
            },
            image: {
                minted: require('assets/images/episode1/page32/2142-01-32-03.png'),
                unMinted: {
                    color: require('assets/images/episode1/page32/pixelized/2142-01-32-03.png'),
                    bw: require('assets/images/episode1/page32/pixelized-b-w/2142-01-32-03.png')
                }
            }
        }
    },
    188: {
        184: {
            style: {
                width: 166,
                height: 89.5,
                left: 4,
                top: 10
            },
            image: {
                minted: require('assets/images/episode1/page33/2142-01-33-01.png'),
                unMinted: {
                    color: require('assets/images/episode1/page33/pixelized/2142-01-33-01.png'),
                    bw: require('assets/images/episode1/page33/pixelized-b-w/2142-01-33-01.png')
                }
            }
        },
        185: {
            style: {
                width: 89.11,
                height: 61.8,
                left: 0,
                top: 84
            },
            image: {
                minted: require('assets/images/episode1/page33/2142-01-33-02.png'),
                unMinted: {
                    color: require('assets/images/episode1/page33/pixelized/2142-01-33-02.png'),
                    bw: require('assets/images/episode1/page33/pixelized-b-w/2142-01-33-02.png')
                }
            }
        },
        186: {
            style: {
                width: 87.77,
                height: 54.01,
                left: 83.97,
                top: 98
            },
            image: {
                minted: require('assets/images/episode1/page33/2142-01-33-03.png'),
                unMinted: {
                    color: require('assets/images/episode1/page33/pixelized/2142-01-33-03.png'),
                    bw: require('assets/images/episode1/page33/pixelized-b-w/2142-01-33-03.png')
                }
            }
        },
        187: {
            style: {
                width: 172,
                height: 88.37,
                left: 0,
                bottom: 9
            },
            image: {
                minted: require('assets/images/episode1/page33/2142-01-33-04.png'),
                unMinted: {
                    color: require('assets/images/episode1/page33/pixelized/2142-01-33-04.png'),
                    bw: require('assets/images/episode1/page33/pixelized-b-w/2142-01-33-04.png')
                }
            }
        }
    },
    194: {
        189: {
            style: {
                width: 94,
                height: 82,
                left: 4,
                top: 6.5,
                zIndex: 1
            },
            image: {
                minted: require('assets/images/episode1/page34/2142-01-34-01.png'),
                unMinted: {
                    color: require('assets/images/episode1/page34/pixelized/2142-01-34-01.png'),
                    bw: require('assets/images/episode1/page34/pixelized-b-w/2142-01-34-01.png')
                }
            }
        },
        190: {
            style: {
                width: 99.27,
                height: 45.31,
                left: 71,
                top: 6,
                zIndex: 2
            },
            image: {
                minted: require('assets/images/episode1/page34/2142-01-34-02.png'),
                unMinted: {
                    color: require('assets/images/episode1/page34/pixelized/2142-01-34-02.png'),
                    bw: require('assets/images/episode1/page34/pixelized-b-w/2142-01-34-02.png')
                }
            }
        },
        191: {
            style: {
                width: 70,
                height: 31.82,
                left: 103,
                top: 50,
                zIndex: 3
            },
            image: {
                minted: require('assets/images/episode1/page34/2142-01-34-03.png'),
                unMinted: {
                    color: require('assets/images/episode1/page34/pixelized/2142-01-34-03.png'),
                    bw: require('assets/images/episode1/page34/pixelized-b-w/2142-01-34-03.png')
                }
            }
        },
        192: {
            style: {
                width: 166,
                height: 51,
                left: 4,
                top: 86
            },
            image: {
                minted: require('assets/images/episode1/page34/2142-01-34-04.png'),
                unMinted: {
                    color: require('assets/images/episode1/page34/pixelized/2142-01-34-04.png'),
                    bw: require('assets/images/episode1/page34/pixelized-b-w/2142-01-34-04.png')
                }
            }
        },
        193: {
            style: {
                width: 166,
                height: 91.24,
                left: 4,
                bottom: 6
            },
            image: {
                minted: require('assets/images/episode1/page34/2142-01-34-05.png'),
                unMinted: {
                    color: require('assets/images/episode1/page34/pixelized/2142-01-34-05.png'),
                    bw: require('assets/images/episode1/page34/pixelized-b-w/2142-01-34-05.png')
                }
            }
        }
    }
};
