import styled, { keyframes } from 'styled-components';

const loading = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Loader = styled.div`
    display: inline-block;
    position: relative;
    width: 40px;
    height: 40px;
    flex: ${props => props.disableFlex ? '' : '1 !important'};
    div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 40px;
        height: 40px;
        margin: 8px;
        border: 3px solid #4effe0;
        border-color: #4effe0 transparent transparent transparent;
        border-radius: 50%;
        animation: ${loading} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    }
    div:nth-child(1) {
        animation-delay: -0.45s;
    }
    div:nth-child(2) {
        animation-delay: -0.3s;
    }
    div:nth-child(3) {
        animation-delay: -0.15s;
    }
`;
