import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Libs
import { useMarketplace, useCreateDirectListing, useAddress } from '@thirdweb-dev/react';
import { NATIVE_TOKEN_ADDRESS } from '@thirdweb-dev/sdk';
import { BigNumber } from 'ethers';
import * as yup from 'yup';
// Modal
import Modal from 'components/Common/Modal';
// Toast
import Toast from 'components/Common/Toast';
// Components
import Title from 'components/Common/Title';
import DescriptionText from 'components/Common/Description';
import Input from 'components/Common/Input';
import Button from 'components/Common/Button';
import Form from 'components/Common/Form';
import Card from 'components/Assets/sections/Card';
import Checkbox from 'components/Common/Checkbox';
import ConfirmListingsToast from 'components/Toast/ConfirmListingsToast';
import ErrorToast from 'components/Toast/ErrorToast';
import Loading from 'components/Common/Loading';
// Icons
import BackButtonIcon from 'assets/images/svg/arrow-back-blue.svg';
import CloseButtonIcon from 'assets/images/svg/close.svg';
import Check from 'assets/images/check.png';
// Style
import {
    ConfirmListingsWrapper,
    ContainerSellAsset,
    ContentSellAsset,
    SellAssetCheckbox,
    SellAssetWrapper
} from 'assets/styles/components/Modal/index.styled';
// Providers
import { useMoralisDapp } from 'providers/MoralisDappProvider/provider';

const ConfirmListings = (props) => {
    // States
    const [allQuantity, setAllQuantity] = useState(false);
    const [hiddenCheckbox, setHiddenCheckbox] = useState(true);
    const [assetContractAddress, setAssetContractAddress] = useState('');

    // Use the hooks
    const address = useAddress();
    const navigate = useNavigate();
    const { chainId } = useMoralisDapp();
    const marketplace = useMarketplace(process.env.REACT_APP_MARKETPLACE_CONTRACT_ADDRESS);
    const { mutate: createDirectListing, status, error: createListingError } = useCreateDirectListing(marketplace);

    useEffect(() => {
        if (props.asset.type.includes('panel') || props.asset.type.includes('page')) {
            setAssetContractAddress(
                chainId === '0x1' ? '0xe198ff500aceeea02f3ab1da993ad819c11c4397' : '0xA14E690904a13Dc5C1Fcfa2BBb42200EC98073a3'
            );
        } else if (props.asset.type.includes('cover')) {
            setAssetContractAddress(
                chainId === '0x1' ? '0x652EA88992D1e199A87cC3D00760b8B4B27ee173' : '0x26A9Bd79070421a7D83011d0CFaC2e4C13c43FF5'
            );
        }
    }, [props, chainId, address]);

    useEffect(() => {
        if (status === 'error' || status === 'success') {
            Modal.hide();
            navigate('/assets', { replace: true });
            if (status === 'success') {
                Toast.success(<ConfirmListingsToast success />);
            } else if (status === 'error') {
                Toast.error(<ErrorToast error message={createListingError?.message.split('\n')[0]} />);
            }
        }
    }, [status, navigate, createListingError]);

    //  Validation form
    const validationSchema = (quantity, amount) => {
        return yup.object().shape({
            allQuantity: quantity === amount ? yup.boolean().oneOf([true], '') : yup.boolean()
        });
    };

    return (
        <ConfirmListingsWrapper className="comic-modal-container">
            <div className="comic-modal-button">
                <Button className="comic-modal-back" small onClick={() => Modal.closeMessage()} outline>
                    <img src={BackButtonIcon} alt="Back button" />
                    BACK
                </Button>
                <Button small onClick={() => Modal.hide()} id="comic-modal-exit" className="comic-modal-exit" outline>
                    <img src={CloseButtonIcon} alt="Close button" />
                </Button>
            </div>
            <div className="comic-modal">
                <div className="comic-modal-header h2-caps">
                    <Title tag="h2" fontSize="display5" textAlign="left" title="CONFIRM LISTINGS" />
                </div>

                <div className="comic-modal-description">
                    <DescriptionText tag="p" textAlign="left" fontSize="16" gradient>
                        Submitting this form will list this asset for sale on the marketplace, visible to all members to purchase for a set
                        price
                    </DescriptionText>
                </div>

                <div className="comic-modal-content">
                    <Form
                        enableReinitialize={true}
                        initialValues={{
                            quantity: props.quantity,
                            price: props.price,
                            allQuantity: allQuantity
                        }}
                        onSubmit={(values, actions) => {
                            createDirectListing({
                                asset: {
                                    id: BigNumber.from(props.asset.id).toBigInt(),
                                    uri: `https://ipfs.io/ipfs/QmdtJrhhZUW3b8GBKNXDkTRhKxF1rQ4Z5YuGYG7UKaJPCW/${props.asset.id}`
                                },
                                assetContractAddress: assetContractAddress,
                                buyoutPricePerToken: values.price.toString(),
                                currencyContractAddress: NATIVE_TOKEN_ADDRESS,
                                quantity: values.quantity,
                                startTimestamp: new Date(),
                                listingDurationInSeconds: 60 * 60 * 24 * 30,
                                tokenId: props.asset.id
                            });
                        }}
                        validationSchema={validationSchema(props.values, props.amount)}>
                        {({ handleSubmit, values, errors, touched, setFieldValue }) => {
                            return (
                                <ContainerSellAsset>
                                    <SellAssetWrapper hiddenCheckbox={hiddenCheckbox}>
                                        <ContentSellAsset hiddenCheckbox={hiddenCheckbox}>
                                            <Card
                                                className="sell-asset-modal-card"
                                                type={props.asset.type}
                                                modal
                                                hideFromSale
                                                hideDuplicate
                                                asset={props.asset}
                                            />
                                            <div className="sell-asset-form">
                                                {props.amount > 1 ? (
                                                    <p className="sell-asset-form-title p-14">
                                                        Enter quantity below if you want to list for sale more than one duplicate of this
                                                        panel
                                                    </p>
                                                ) : (
                                                    <p className="sell-asset-form-title p-14">Enter the asking price below:</p>
                                                )}

                                                {props.amount > 1 && (
                                                    <Input
                                                        disabled={true}
                                                        error={false}
                                                        value={values.quantity}
                                                        name="quantity"
                                                        className="sell-asset-input"
                                                        id="quantity"
                                                        status={`${props.amount} available`}
                                                        type="text"
                                                        placeholder="Enter quantity"
                                                    />
                                                )}
                                                <Input
                                                    id="price"
                                                    disabled={true}
                                                    error={false}
                                                    value={values.price}
                                                    name="price"
                                                    status={props.assetPrice}
                                                    type="text"
                                                    placeholder="Enter price (Ξ)"
                                                />
                                            </div>
                                        </ContentSellAsset>
                                        <SellAssetCheckbox hiddenCheckbox={hiddenCheckbox}>
                                            {props.quantity === props.amount ? (
                                                props.amount > 1 ? (
                                                    <p className="p-16">
                                                        Are you sure you want to sell all copies of this NFT item? <br /> You won’t have any
                                                        copies left in your wallet anymore.
                                                    </p>
                                                ) : (
                                                    <p className="p-16">
                                                        This is the only copy of this NFT item in your wallet. <br /> Are you sure you want
                                                        to sell it?
                                                    </p>
                                                )
                                            ) : (
                                                <></>
                                            )}
                                            <Checkbox
                                                name="uncompleted"
                                                label="YES, I’M SURE"
                                                defaultChecked={allQuantity}
                                                onChange={() => {
                                                    setAllQuantity(!allQuantity);
                                                    setFieldValue('allQuantity', !allQuantity);
                                                }}
                                            />
                                        </SellAssetCheckbox>
                                    </SellAssetWrapper>

                                    {status === 'loading' && <Loading title="Listing creation is in progress" />}

                                    <Button
                                        disabled={status === 'loading'}
                                        onClick={() => {
                                            if (props.quantity === props.amount && !allQuantity) {
                                                setHiddenCheckbox(false);
                                            } else {
                                                handleSubmit();
                                            }
                                        }}
                                        type="submit">
                                        <img src={Check} alt="Icon Check" />
                                        <span>CONFIRM LISTING</span>
                                    </Button>
                                </ContainerSellAsset>
                            );
                        }}
                    </Form>
                </div>
            </div>
        </ConfirmListingsWrapper>
    );
};

export default ConfirmListings;
