import React from 'react';

// Libs
import { useNavigate } from 'react-router-dom';
// Providers
import { useDiscordTracking } from 'providers/DiscordTracking/provider';
// Images
import { ReactComponent as Logo } from 'assets/images/brand/logo.svg';
import { ReactComponent as Discord } from 'assets/images/svg/socials_media/discord-footer.svg';
import { ReactComponent as Instagram } from 'assets/images/svg/socials_media/instagram.svg';
import { ReactComponent as Linkedin } from 'assets/images/svg/socials_media/linkedin.svg';
import { ReactComponent as Twitter } from 'assets/images/svg/socials_media/twitter.svg';

const Footer = () => {
    // Hooks
    const discord = useDiscordTracking();
    const navigation = useNavigate();

    return (
        <footer className="footer text-center">
            <div className="footer-content">
                <div
                    className="cursor-pointer"
                    onClick={() => {
                        navigation('/', { replace: true });
                        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                    }}>
                    <Logo className="footer-logo" />
                </div>
                <p className="footer-join display4 font-weight-900">Join the community!</p>
                <div className="d-flex footer-social">
                    <a href={discord.link} target="_blank" rel="noopener noreferrer">
                        <Discord />
                    </a>
                    <a href="https://instagram.com/2142_nft?igshid=YmMyMTA2M2Y=" target="_blank" rel="noopener noreferrer">
                        <Instagram />
                    </a>
                    <a href="https://www.linkedin.com/company/2142ad/" target="_blank" rel="noopener noreferrer">
                        <Linkedin />
                    </a>
                    <a href="https://twitter.com/2142_ad" target="_blank" rel="noopener noreferrer">
                        <Twitter />
                    </a>
                </div>
                <div className="d-lg-flex footer-document">
                    <div className="d-lg-flex">
                        <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
                            <p className="p-14 mb-0 cursor-pointer">Privacy policy</p>
                        </a>
                        <a href="/terms-of-use" target="_blank" rel="noopener noreferrer">
                            <p className="p-14 mb-0 cursor-pointer">Terms of use</p>
                        </a>
                        {/* <span rel="noopener noreferrer">
                            <p className="p-14 mb-0 ">Help</p>
                        </span> */}
                    </div>
                    <div className="p-14">© {new Date().getFullYear()} | 2142 MEDIA LTD</div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
