import styled from 'styled-components';

import { COLORS } from 'assets/styles/common/Colors.styled';

const ShowcaseContainer = styled.div`
    margin-top: 65px;
`;

const ShowcaseFilter = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
    > p {
        color: #b6b9bc;
    }
`;

const ShowcaseContent = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 32px;
    padding-bottom: 150px;
`;

const ShowcaseItemContainer = styled.div`
    max-width: 176px;
    width: 100%;
    height: 297px;
    background-color: ${COLORS.showcaseItemContainer};
`;

const ShowcaseItemPage = styled.div`
    position: relative;
    width: 100%;
    height: 241px;
    border-left: 1px solid ${COLORS.showcaseItemBorder};
    border-right: 1px solid ${COLORS.showcaseItemBorder};
    border-top: 1px solid ${COLORS.showcaseItemBorder};
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-color: #1e2830;
    background-color: ${(props) => (props.havePage ? 'white' : 'transparent')};
    cursor: pointer;
`;

const ShowcaseItemImage = styled.div.attrs((props) => ({
    style: {
        background: `url(${props.image})`
    }
}))`
    position: absolute;
    background-size: cover !important;
    background-repeat: no-repeat;
`;

const ShowcaseItemDiv = styled.div.attrs((props) => ({
    style: {
        background: `url(${props.image})`
    }
}))`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background-size: cover !important;
    background-repeat: no-repeat;
    .buy-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: 20;
        svg {
            width: 13px;
            height: 16px;
            margin-right: 3px;
            position: relative;
            top: -2px;
        }
        .buy-label {
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 1;
            color: ${COLORS.primary};
        }
    }
`;

const ShowcaseItemStatus = styled.div`
    height: 56px;
    padding: 0 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-left: 1px solid ${COLORS.showcaseItemBorder};
    border-right: 1px solid ${COLORS.showcaseItemBorder};
    border-bottom: 1px solid ${COLORS.showcaseItemBorder};
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-color: ${COLORS.statusBackgroud};
    background-color: ${COLORS.priceButton};
    .showcase-item-status-page-number {
        color: ${COLORS.morningBlue};
        margin-bottom: 4px;
        line-height: 16px;
        span {
            color: ${COLORS.blueLight};
        }
    }
    .showcase-item-status-page-number-collected {
        color: ${COLORS.primary};
        span {
            color: ${COLORS.primary};
        }
    }
    .showcase-item-status-progress-container {
        display: flex;
        align-items: center;
        height: 12px;
        .showcase-item-status-collected {
            position: relative;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background: ${COLORS.colletedPanels};
            box-shadow: 0px 0px 8px ${COLORS.colletedPanelsShadow};
            &::before {
                content: '';
                position: absolute;
                top: 2.8px;
                left: 5px;
                width: 4px;
                height: 7px;
                border: solid ${COLORS.priceButton};
                border-width: 0 2px 2px 0;
                transform: rotate(34deg);
            }
        }
    }
    .showcase-item-status-progress-bar {
        background: ${COLORS.red};
        box-shadow: 0px 0px 10px ${COLORS.statusProgressBarShadow};
        border-radius: 32px;
    }
    .showcase-item-status-progress-bar-completed {
        background: ${COLORS.primary};
        box-shadow: 0px 0px 10px ${COLORS.statusProgressBarCompletedShadow};
    }
`;

export {
    ShowcaseContainer,
    ShowcaseFilter,
    ShowcaseContent,
    ShowcaseItemContainer,
    ShowcaseItemPage,
    ShowcaseItemStatus,
    ShowcaseItemImage,
    ShowcaseItemDiv
};
