// Images
import Linkedin from 'assets/images/socials/linkedin.svg';
import Artstation from 'assets/images/socials/artstation.svg';
import UpVote from 'assets/images/svg/up-vote.svg';
import DownVote from 'assets/images/svg/down-vote.svg';
import VoteImage from 'assets/images/comic/dao/vote-image.png';

import { ReactComponent as ComicIcon } from 'assets/images/svg/sidebar/inactive/ico-comic.svg';
import { ReactComponent as AssetsIcon } from 'assets/images/svg/sidebar/inactive/ico-assets.svg';
import { ReactComponent as MarketIcon } from 'assets/images/svg/sidebar/inactive/ico-market.svg';
import { ReactComponent as DAOIcon } from 'assets/images/svg/sidebar/inactive/ico-dao.svg';
import { ReactComponent as RewardsIcon } from 'assets/images/svg/sidebar/inactive/ico-rewards.svg';
import { ReactComponent as Icon1 } from 'assets/images/landing/how-to-mint/ico-1.svg';
import { ReactComponent as Icon2 } from 'assets/images/landing/how-to-mint/ico-2.svg';
import { ReactComponent as Icon3 } from 'assets/images/landing/how-to-mint/ico-3.svg';
import { ReactComponent as Icon4 } from 'assets/images/landing/how-to-mint/ico-4.svg';
import { ReactComponent as Icon5 } from 'assets/images/landing/how-to-mint/ico-5.svg';

// Roadmap icon
import { ReactComponent as Awakening } from 'assets/images/landing/roadmap/ico-awakening.svg';
import { ReactComponent as Summoning } from 'assets/images/landing/roadmap/ico-summoning.svg';
import { ReactComponent as Channeling } from 'assets/images/landing/roadmap/ico-channeling.svg';
import { ReactComponent as Transforming } from 'assets/images/landing/roadmap/ico-transforming.svg';
import { ReactComponent as Rising } from 'assets/images/landing/roadmap/ico-rising.svg';
import { ReactComponent as CheckmarkIcon } from 'assets/images/landing/roadmap/ico-checkmark.svg';

// Stories images
import Story1 from 'assets/images/stories/story1.png';
import Story2 from 'assets/images/stories/story2.png';
import StoryMob2 from 'assets/images/stories/storyMob2.png';
import Story3 from 'assets/images/stories/story3.png';
import StoryMob3 from 'assets/images/stories/storyMob3.png';
import Story4 from 'assets/images/stories/story4.png';
import StoryMob4 from 'assets/images/stories/storyMob4.png';
import Story5 from 'assets/images/stories/story5.png';
import StoryMob5 from 'assets/images/stories/storyMob5.png';
import Story6 from 'assets/images/stories/story6.png';
import StoryMob6 from 'assets/images/stories/storyMob6.png';
import Story7 from 'assets/images/stories/story7.png';
import StoryMob7 from 'assets/images/stories/storyMob7.png';
import Story8 from 'assets/images/stories/story8.png';
import StoryMob8 from 'assets/images/stories/storyMob8.png';
import Story9 from 'assets/images/stories/story9.png';
import StoryMob9 from 'assets/images/stories/storyMob9.png';
import Story10 from 'assets/images/stories/story10.png';
import StoryMob10 from 'assets/images/stories/storyMob10.png';
import Story11 from 'assets/images/stories/story11.png';
import StoryMob11 from 'assets/images/stories/storyMob11.png';
import Story12 from 'assets/images/stories/story12.png';
import StoryMob12 from 'assets/images/stories/storyMob12.png';

export const SLIDER_ITEMS = [
    {
        image: require('assets/images/landing/slider/slide-1.png'),
        imageWidth: 728,
        label: 'MINT PAGES',
        title: `MINT PAGES TO ASSEMBLE  STORY`,
        text: `Each page contains  empty panels.`
    },
    {
        image: require('assets/images/landing/slider/slide-2.png'),
        imageWidth: 761,
        label: 'MINT PANELS',
        title: `Mint panels to assemble pages`,
        text: `Fill all panels and follow the story.`
    },
    {
        image: require('assets/images/landing/slider/slide-3.png'),
        imageWidth: 729,
        label: 'MINT COVERS',
        title: `Mint cover to complete episode`,
        text: `Make the comic book issue absolutely unique by minting authentic covers.`
    },
    {
        image: require('assets/images/landing/slider/slide-4.png'),
        imageWidth: 590,
        label: 'BURN ASSETS',
        title: `Craft your NFT assets into new story`,
        text: `Get your personal spin-off webcomic based on your crafting choices.`
    }
];
export const PROGRESS_BARS = [
    {
        width: 493,
        percent: 40,
        shadowClassName: 'dao-voting-bg-up-voting',
        backgroundClassName: 'dao-voting-shadow-up-voting',
        label: 'Up-vote',
        textShadow: 'text-shadow-1',
        icon: UpVote
    },
    {
        width: 493,
        percent: 35,
        label: 'Down-vote',
        shadowClassName: 'dao-voting-bg-down-voting',
        backgroundClassName: 'dao-voting-shadow-down-voting',
        textShadow: 'text-shadow-2',
        icon: DownVote
    },
    {
        width: 493,
        percent: 25,
        label: 'Restrained',
        shadowClassName: 'dao-voting-bg-restrained',
        backgroundClassName: 'dao-voting-shadow-restrained',
        textShadow: 'text-shadow-3'
    }
];
export const VOTE_QUESTION = [
    {
        typeOption: true,
        active: true,
        title: 'How should the Colosseum look like in the Metaverse?',
        issueNumber: '1',
        options: [
            {
                image: VoteImage,
                title: 'Legensary Rome',
                optionTitle: 'Option 1',
                description:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad',
                buttonTitle: 'Vote for option 1'
            },
            {
                image: VoteImage,
                title: 'Legensary Rome',
                optionTitle: 'Option 2',
                description:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad',
                buttonTitle: 'Vote for option 2'
            }
        ]
    },
    {
        typeOption: false,
        active: true,
        title: 'How should the Colosseum look like in the Metaverse?',
        issueNumber: '',
        question:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
    },
    {
        typeOption: true,
        active: false,
        title: 'How should the Colosseum look like in the Metaverse?',
        issueNumber: '1',
        options: [
            {
                image: VoteImage,
                title: 'Legensary Rome',
                optionTitle: 'Option 1',
                description:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad',
                buttonTitle: '',
                percent: 56
            },
            {
                image: VoteImage,
                title: 'Legensary Rome',
                optionTitle: 'Option 2',
                description:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad',
                buttonTitle: '',
                percent: 44
            }
        ]
    },
    {
        typeOption: false,
        active: false,
        title: 'How should the Colosseum look like in the Metaverse?',
        issueNumber: '',
        question:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        results: {
            up: 56,
            down: 32,
            restrained: 12
        }
    }
];
export const TEAM_ITEMS = [
    [
        {
            image: require('assets/images/landing/team/dusan.png'),
            name: 'Dušan Žica',
            position: 'CEO & CCO',
            description:
                'Storyteller, creative director, and psychonaut, experienced in transmedia and video game development, absolutely mad about comics, mythology, and entheogen studies.',
            socials: [{ image: Linkedin, href: 'https://rs.linkedin.com/in/dusanzica' }]
        },
        {
            image: require('assets/images/landing/team/mladjen.png'),
            name: 'Mlađen Merdović',
            position: 'CSO',
            description:
                'Early Bitcoin investor, serial entrepreneur in crypto industry and blockchain technology, and the president of Bitcoin Association Serbia.',
            socials: [{ image: Linkedin, href: 'https://www.linkedin.com/in/mladjen-merdovic-a861b841/' }]
        }
    ],
    [
        {
            image: require('assets/images/landing/team/darjan.png'),
            name: 'Darjan Mikičić',
            position: 'Senior Concept Artist',
            description:
                'Senior Concept Artist, with 7+ years of experience in the Video Games Industry. Worked on the "Titan Quest" series and mobile MOBA game "Awakening of Heroes".',
            socials: [{ image: Artstation, href: 'https://www.artstation.com/darjanmikicic' }]
        },
        {
            image: require('assets/images/landing/team/vlada.png'),
            name: 'Vladimir Pajić',
            position: 'Senior Artist & Animator',
            description: 'Comic-book artist, illustrator and 2D animator with over a decade long experience in comics and cartoon.',
            socials: [{ image: Artstation, href: 'https://www.artstation.com/vladimirpajic' }]
        },
        {
            image: require('assets/images/landing/team/dragan.png'),
            name: 'Dragan Jovanović',
            position: 'Lead Art Director & Illustrator',
            description:
                'Illustrator and art director with extensive experience in comic books, video game concept art, environment, and character design.',
            socials: [{ image: Artstation, href: 'https://www.artstation.com/dragan_jovanovic' }]
        }
    ],
    [
        {
            image: require('assets/images/landing/team/nenad.png'),
            name: 'Nenad Krstić',
            position: 'COMMUNITY DIRECTOR',
            description:
                '10 years of building online communities, primarily on YouTube. Content creator with over 2500 made videos on topics ranging from gaming to cryptocurrency & NFTs.',
            socials: [{ image: Linkedin, href: 'https://www.linkedin.com/in/tony-tctn-759a61105u/ ' }]
        },
        {
            image: require('assets/images/landing/team/rade.png'),
            name: 'Rade Joksimović',
            position: 'ADVISORY CHAIR',
            description: '​​Spent the last 17 years building tech startups, mentoring entrepreneurs and leading a regulated hedge fund.',
            socials: [{ image: Linkedin, href: 'https://www.linkedin.com/in/radejoksimovic/ ' }]
        }
    ]
];

export const ROADMAP_ITEMS = [
    {
        icon: require('assets/images/landing/roadmap/ico-checkmark.svg'),
        color: '#7bdefc',
        subtitle: 'MID MAY',
        image: <Awakening />,
        title: 'Awakening',
        content: {
            mobile: (
                <ul>
                    <li>Discord & Social Media</li>
                    <li>Website</li>
                    <li>Teaser Trailer Reveal</li>
                </ul>
            ),
            desktop: (
                <div className="roadmap-text p-16">
                    Discord & <br /> Social Media
                    <br /> <br />
                    Website
                    <br /> <br />
                    Teaser Trailer <br /> Reveal
                </div>
            )
        }
    },
    {
        icon: require('assets/images/landing/roadmap/ico-checkmark.svg'),
        color: '#f8b127',
        subtitle: 'JULY',
        image: <Summoning />,
        title: 'Summoning',
        content: {
            mobile: (
                <ul>
                    <li>
                        “2142” webcomic <br /> Issue No.1 launch
                    </li>
                    <li>
                        DAO activation and <br /> voting system
                    </li>
                    <li>
                        Metamask integration <br /> & NFT minting
                    </li>
                </ul>
            ),
            desktop: (
                <>
                    <CheckmarkIcon className="roadmap-checkmark" />
                    <div className="roadmap-text p-16">
                        “2142” webcomic <br /> Issue No.1 launch
                        <br /> <br />
                        DAO activation and <br /> voting system
                        <br /> <br />
                        Metamask <br /> integration & NFT <br /> minting
                    </div>
                </>
            )
        }
    },
    {
        icon: require('assets/images/landing/roadmap/ico-checkmark.svg'),
        color: '#dc2fdd',
        subtitle: 'MID JULY',
        image: <Channeling />,
        title: 'Channeling',
        content: {
            mobile: (
                <ul>
                    <li>
                        Marketplace launch <br /> (Buy & Sell NFTs)
                    </li>
                </ul>
            ),
            desktop: (
                <>
                    <CheckmarkIcon className="roadmap-checkmark" />
                    <div className="roadmap-text p-16">
                        Marketplace launch <br /> (Buy & Sell NFTs)
                    </div>
                </>
            )
        }
    },
    {
        color: '#7fcd78',
        subtitle: 'IN PROGRESS',
        image: <Transforming />,
        title: 'Transforming',
        content: {
            mobile: (
                <ul style={{ minWidth: 208 }}>
                    <li>Mobile version</li>
                </ul>
            ),
            desktop: <div className="roadmap-text p-16">Mobile version</div>
        }
    },
    {
        color: '#ff694a',
        subtitle: 'JANUARY 2023',
        subtitleDesk: 'JANUARY 2023',
        image: <Rising />,
        title: 'Rising',
        content: {
            mobile: (
                <ul>
                    <li>
                        NFT character/item/ <br /> location drop
                    </li>
                    <li>
                        NFT story crafting <br /> mechanics
                    </li>
                    <li>
                        Video game <br /> announcement
                    </li>
                </ul>
            ),
            desktop: (
                <div className="roadmap-text p-16">
                    NFT character/ <br /> item/location drop
                    <br /> <br />
                    NFT story crafting <br /> mechanics
                    <br /> <br />
                    Video game <br /> announcement
                </div>
            )
        }
    }
];

export const HOW_TO_MINT_ITEMS = [
    {
        image: <Icon1 />,
        description:
            'Mint randomly generated NFT bundles with 21 NFT items each: comic book cover, comic book pages, and comic panels, to start assembling your 2142 webcomic issue.'
    },
    {
        image: <Icon2 />,
        description: 'Mint, collect and trade these NFT webcomic elements further to assemble the whole issue and reveal the storyline.'
    },
    {
        ellipse: true,
        image: <Icon3 />,
        description:
            'When you collect them all, burn selected cover, pages, and panels to merge them into one complete NFT webcomic item with unique value.'
    },
    {
        image: <Icon4 />,
        description: 'Get a free airdrop of a unique NFT character, location, or item featured in a previously assembled webcomic issue.'
    },
    {
        image: <Icon5 />,
        description:
            'Use these NFT characters, locations, and items to become a part of decentralized 2142 worldbuilding, craft a new story, and expand the 2142 universe with a specially created spin-off NFT webcomic issue based on your choices.'
    }
];

export const SIDEBAR_ITEMS = [
    {
        icon: ComicIcon,
        title: 'Comic',
        pathname: '/comic'
    },
    {
        icon: AssetsIcon,
        title: 'Assets',
        pathname: '/assets'
        // disabled: true
    },
    {
        icon: MarketIcon,
        title: 'Market',
        pathname: '/market'
    },
    {
        icon: DAOIcon,
        title: 'DAO',
        pathname: '/dao',
        disabled: true
        // notification: 2
    },
    {
        icon: RewardsIcon,
        title: 'Rewards',
        pathname: '/rewards',
        disabled: true
    }
];

export const DAO_HEADER_ITEMS = [
    {
        label: 'How 2142 DAO works',
        link: '/dao/how-dao-works'
    },
    {
        label: 'What is DAO',
        link: '/dao/what-is-dao'
    },
    {
        label: 'How to vote',
        link: '/dao/how-to-vote'
    },
    {
        label: 'Help',
        link: '/dao/help'
    }
];

export const ASSETS_DETAILS_PAGES = {
    MARKET: 'market',
    ASSETS: 'assets'
};

export const ASSETS_TYPE = {
    PANEL: 'panel',
    PAGE: 'page',
    COVER: 'cover'
};

export const MARKETPLACE_TABS = {
    FOR_SALE: 'For sale',
    MY_LISTINGS: 'My listings',
    HISTORY: 'History'
};

export const MARKETPLACE_SORT = {
    LOW: 'Lowest ask: Low to high',
    HIGH: 'Highest ask: High to low'
};

export const ISSUES_SELECT = [
    { value: 'issue1', label: 'Issue 1', pageNumber: 34 }
    // { value: 'issue2', label: 'Issue 2' }
];

export const ASSET_TYPES_SELECT = [
    { value: 'page', label: 'Page' },
    { value: 'panel', label: 'Panel' },
    { value: 'cover', label: 'Cover' }
];

export const FAKE_CHART_DATA = [
    {
        date: '2012-07-27',
        value: 13
    },
    {
        date: '2012-07-28',
        value: 11
    },
    {
        date: '2012-07-29',
        value: 15
    },
    {
        date: '2012-07-30',
        value: 16
    },
    {
        date: '2012-07-31',
        value: 18
    },
    {
        date: '2012-08-01',
        value: 13
    },
    {
        date: '2012-08-02',
        value: 22
    },
    {
        date: '2012-08-03',
        value: 23
    },
    {
        date: '2012-08-04',
        value: 20
    },
    {
        date: '2012-08-05',
        value: 17
    },
    {
        date: '2012-08-06',
        value: 16
    },
    {
        date: '2012-08-07',
        value: 18
    },
    {
        date: '2012-08-08',
        value: 21
    },
    {
        date: '2012-08-09',
        value: 26
    },
    {
        date: '2012-08-10',
        value: 24
    },
    {
        date: '2012-08-11',
        value: 29
    },
    {
        date: '2012-08-12',
        value: 32
    },
    {
        date: '2012-08-13',
        value: 18
    },
    {
        date: '2012-08-14',
        value: 24
    },
    {
        date: '2012-08-15',
        value: 22
    },
    {
        date: '2012-08-16',
        value: 18
    },
    {
        date: '2012-08-17',
        value: 19
    },
    {
        date: '2012-08-18',
        value: 14
    },
    {
        date: '2012-08-19',
        value: 15
    },
    {
        date: '2012-08-20',
        value: 12
    },
    {
        date: '2012-08-21',
        value: 8
    },
    {
        date: '2012-08-22',
        value: 9
    },
    {
        date: '2012-08-23',
        value: 8
    },
    {
        date: '2012-08-24',
        value: 7
    },
    {
        date: '2012-08-25',
        value: 5
    },
    {
        date: '2012-08-26',
        value: 11
    },
    {
        date: '2012-08-27',
        value: 13
    },
    {
        date: '2012-08-28',
        value: 18
    },
    {
        date: '2012-08-29',
        value: 20
    },
    {
        date: '2012-08-30',
        value: 29
    },
    {
        date: '2012-08-31',
        value: 33
    },
    {
        date: '2012-09-01',
        value: 42
    },
    {
        date: '2012-09-02',
        value: 35
    },
    {
        date: '2012-09-03',
        value: 31
    },
    {
        date: '2012-09-04',
        value: 47
    },
    {
        date: '2012-09-05',
        value: 52
    },
    {
        date: '2012-09-06',
        value: 46
    },
    {
        date: '2012-09-07',
        value: 41
    },
    {
        date: '2012-09-08',
        value: 43
    },
    {
        date: '2012-09-09',
        value: 40
    },
    {
        date: '2012-09-10',
        value: 39
    },
    {
        date: '2012-09-11',
        value: 34
    },
    {
        date: '2012-09-12',
        value: 29
    },
    {
        date: '2012-09-13',
        value: 34
    },
    {
        date: '2012-09-14',
        value: 37
    },
    {
        date: '2012-09-15',
        value: 42
    },
    {
        date: '2012-09-16',
        value: 49
    },
    {
        date: '2012-09-17',
        value: 46
    },
    {
        date: '2012-09-18',
        value: 47
    },
    {
        date: '2012-09-19',
        value: 55
    },
    {
        date: '2012-09-20',
        value: 59
    },
    {
        date: '2012-09-21',
        value: 58
    },
    {
        date: '2012-09-22',
        value: 57
    },
    {
        date: '2012-09-23',
        value: 61
    },
    {
        date: '2012-09-24',
        value: 59
    },
    {
        date: '2012-09-25',
        value: 67
    },
    {
        date: '2012-09-26',
        value: 65
    },
    {
        date: '2012-09-27',
        value: 61
    },
    {
        date: '2012-09-28',
        value: 66
    },
    {
        date: '2012-09-29',
        value: 69
    },
    {
        date: '2012-09-30',
        value: 71
    },
    {
        date: '2012-10-01',
        value: 67
    },
    {
        date: '2012-10-02',
        value: 63
    },
    {
        date: '2012-10-03',
        value: 46
    },
    {
        date: '2012-10-04',
        value: 32
    },
    {
        date: '2012-10-05',
        value: 21
    },
    {
        date: '2012-10-06',
        value: 18
    },
    {
        date: '2012-10-07',
        value: 21
    },
    {
        date: '2012-10-08',
        value: 28
    },
    {
        date: '2012-10-09',
        value: 27
    },
    {
        date: '2012-10-10',
        value: 36
    },
    {
        date: '2012-10-11',
        value: 33
    },
    {
        date: '2012-10-12',
        value: 31
    },
    {
        date: '2012-10-13',
        value: 30
    },
    {
        date: '2012-10-14',
        value: 34
    },
    {
        date: '2012-10-15',
        value: 38
    },
    {
        date: '2012-10-16',
        value: 37
    },
    {
        date: '2012-10-17',
        value: 44
    },
    {
        date: '2012-10-18',
        value: 49
    },
    {
        date: '2012-10-19',
        value: 53
    },
    {
        date: '2012-10-20',
        value: 57
    },
    {
        date: '2012-10-21',
        value: 60
    },
    {
        date: '2012-10-22',
        value: 61
    },
    {
        date: '2012-10-23',
        value: 69
    },
    {
        date: '2012-10-24',
        value: 67
    },
    {
        date: '2012-10-25',
        value: 72
    },
    {
        date: '2012-10-26',
        value: 77
    },
    {
        date: '2012-10-27',
        value: 75
    },
    {
        date: '2012-10-28',
        value: 70
    },
    {
        date: '2012-10-29',
        value: 72
    },
    {
        date: '2012-10-30',
        value: 70
    },
    {
        date: '2012-10-31',
        value: 72
    },
    {
        date: '2012-11-01',
        value: 73
    },
    {
        date: '2012-11-02',
        value: 67
    },
    {
        date: '2012-11-03',
        value: 68
    },
    {
        date: '2012-11-04',
        value: 65
    },
    {
        date: '2012-11-05',
        value: 71
    },
    {
        date: '2012-11-06',
        value: 75
    },
    {
        date: '2012-11-07',
        value: 74
    },
    {
        date: '2012-11-08',
        value: 71
    },
    {
        date: '2012-11-09',
        value: 76
    },
    {
        date: '2012-11-10',
        value: 77
    },
    {
        date: '2012-11-11',
        value: 81
    },
    {
        date: '2012-11-12',
        value: 83
    },
    {
        date: '2012-11-13',
        value: 80
    },
    {
        date: '2012-11-14',
        value: 81
    },
    {
        date: '2012-11-15',
        value: 87
    },
    {
        date: '2012-11-16',
        value: 82
    },
    {
        date: '2012-11-17',
        value: 86
    },
    {
        date: '2012-11-18',
        value: 80
    },
    {
        date: '2012-11-19',
        value: 87
    },
    {
        date: '2012-11-20',
        value: 83
    },
    {
        date: '2012-11-21',
        value: 85
    },
    {
        date: '2012-11-22',
        value: 84
    },
    {
        date: '2012-11-23',
        value: 82
    },
    {
        date: '2012-11-24',
        value: 73
    },
    {
        date: '2012-11-25',
        value: 71
    },
    {
        date: '2012-11-26',
        value: 75
    },
    {
        date: '2012-11-27',
        value: 79
    },
    {
        date: '2012-11-28',
        value: 70
    },
    {
        date: '2012-11-29',
        value: 73
    },
    {
        date: '2012-11-30',
        value: 61
    },
    {
        date: '2012-12-01',
        value: 62
    },
    {
        date: '2012-12-02',
        value: 66
    },
    {
        date: '2012-12-03',
        value: 65
    },
    {
        date: '2012-12-04',
        value: 73
    },
    {
        date: '2012-12-05',
        value: 79
    },
    {
        date: '2012-12-06',
        value: 78
    },
    {
        date: '2012-12-07',
        value: 78
    },
    {
        date: '2012-12-08',
        value: 78
    },
    {
        date: '2012-12-09',
        value: 74
    },
    {
        date: '2012-12-10',
        value: 73
    },
    {
        date: '2012-12-11',
        value: 75
    },
    {
        date: '2012-12-12',
        value: 70
    },
    {
        date: '2012-12-13',
        value: 77
    },
    {
        date: '2012-12-14',
        value: 67
    },
    {
        date: '2012-12-15',
        value: 62
    },
    {
        date: '2012-12-16',
        value: 64
    },
    {
        date: '2012-12-17',
        value: 61
    },
    {
        date: '2012-12-18',
        value: 59
    },
    {
        date: '2012-12-19',
        value: 53
    },
    {
        date: '2012-12-20',
        value: 54
    },
    {
        date: '2012-12-21',
        value: 56
    },
    {
        date: '2012-12-22',
        value: 59
    },
    {
        date: '2012-12-23',
        value: 58
    },
    {
        date: '2012-12-24',
        value: 55
    },
    {
        date: '2012-12-25',
        value: 52
    },
    {
        date: '2012-12-26',
        value: 54
    },
    {
        date: '2012-12-27',
        value: 50
    },
    {
        date: '2012-12-28',
        value: 50
    },
    {
        date: '2012-12-29',
        value: 51
    },
    {
        date: '2012-12-30',
        value: 52
    },
    {
        date: '2012-12-31',
        value: 58
    },
    {
        date: '2013-01-01',
        value: 60
    },
    {
        date: '2013-01-02',
        value: 67
    },
    {
        date: '2013-01-03',
        value: 64
    },
    {
        date: '2013-01-04',
        value: 66
    },
    {
        date: '2013-01-05',
        value: 60
    },
    {
        date: '2013-01-06',
        value: 63
    },
    {
        date: '2013-01-07',
        value: 61
    },
    {
        date: '2013-01-08',
        value: 60
    },
    {
        date: '2013-01-09',
        value: 65
    },
    {
        date: '2013-01-10',
        value: 75
    },
    {
        date: '2013-01-11',
        value: 77
    },
    {
        date: '2013-01-12',
        value: 78
    },
    {
        date: '2013-01-13',
        value: 70
    },
    {
        date: '2013-01-14',
        value: 70
    },
    {
        date: '2013-01-15',
        value: 73
    },
    {
        date: '2013-01-16',
        value: 71
    },
    {
        date: '2013-01-17',
        value: 74
    },
    {
        date: '2013-01-18',
        value: 78
    },
    {
        date: '2013-01-19',
        value: 85
    },
    {
        date: '2013-01-20',
        value: 82
    },
    {
        date: '2013-01-21',
        value: 83
    },
    {
        date: '2013-01-22',
        value: 88
    },
    {
        date: '2013-01-23',
        value: 85
    },
    {
        date: '2013-01-24',
        value: 85
    },
    {
        date: '2013-01-25',
        value: 80
    },
    {
        date: '2013-01-26',
        value: 87
    },
    {
        date: '2013-01-27',
        value: 84
    },
    {
        date: '2013-01-28',
        value: 83
    },
    {
        date: '2013-01-29',
        value: 84
    },
    {
        date: '2013-01-30',
        value: 81
    }
];

export const stories = [
    {
        header: ['Cryptocracy', 'Allen Taylor, Nov 25'],
        image: {
            desktop: Story1,
            mobile: Story1
        },
        link: 'https://cryptocracy.substack.com/p/2142-ad-the-non-fungible-comic-book',
        title: 'How Comic Book Minting Takes Place in 2142'
    },
    {
        header: ['cointelegraph', 'Gareth Jenkinson, Sep 14'],
        image: {
            desktop: Story2,
            mobile: StoryMob2
        },
        link: 'https://cointelegraph.com/news/sci-fi-nft-comic-book-project-lays-the-foundation-for-ccg-development',
        title: (
            <>
                Sci-fi NFT comic book project <br /> lays the foundation for CCG <br /> development
            </>
        )
    },
    {
        header: ['coinspector', 'Bartosz Juraszek'],
        image: {
            desktop: Story3,
            mobile: StoryMob3
        },
        link: 'https://coinspector.io/2142-community-driven-sci-fi-transmedia-inspired-by-satoshi/',
        title: (
            <>
                “2142” Community-Driven, <br /> Sci-Fi Transmedia Inspired <br /> by Satoshi
            </>
        )
    },
    {
        header: ['medium', 'Gideon, Sep 27'],
        image: {
            desktop: Story4,
            mobile: StoryMob4
        },
        link: 'https://medium.com/3-minutes/finally-an-nft-project-that-will-truly-change-the-world-825e7c7e1b21',
        title: (
            <>
                Finally, an NFT Project That <br /> Will Truly Change the <br /> World
            </>
        )
    },
    {
        header: ['publish0x', 'Keegan King, Oct 12'],
        image: {
            desktop: Story5,
            mobile: StoryMob5
        },
        link: 'https://www.bccn3.com/news/an-interview-with-dusan-zica-founder-of-2142-comics',
        title: (
            <>
                2142: The Comic Book That is <br /> a Non-Fungible Token
            </>
        )
    },
    {
        header: ['bccn3', 'Allen Taylor, Nov 25'],
        image: {
            desktop: Story6,
            mobile: StoryMob6,
            tablet: StoryMob6
        },
        link: 'https://www.publish0x.com/cryptocracy/2142-the-comic-book-that-is-a-non-fungible-token-xkprpqn',
        title: (
            <>
                An interview with Dusan <br /> Zica, Founder of 2142 Comics
            </>
        )
    },
    {
        header: ['buidlbee', 'Viktoriia Pushkina, Nov 1'],
        image: {
            desktop: Story7,
            mobile: StoryMob7
        },
        link: 'https://buidlbee.com/crypto-dystopia-a-story-of-the-first-nft-comic-book-and-a-blockchain-game-for-gamers-not-traders/',
        title: (
            <>
                Crypto dystopia: a story of the first NFT comic book <br /> and a blockchain game for gamers, not traders
            </>
        )
    },
    {
        header: ['analyticsdrift', 'Gareema Lakra, Nov 5'],
        image: {
            desktop: Story8,
            mobile: StoryMob8
        },
        link: 'https://analyticsdrift.com/how-is-2142-taking-the-leap-with-an-nft-comic-book/',
        title: 'How is 2142 taking the leap with an NFT comic book?'
    },
    {
        header: ['buidlbee', 'Robyn Abrahams, Dec 5'],
        image: {
            desktop: Story9,
            mobile: StoryMob9
        },
        link: 'https://buidlbee.com/2142-the-transmedia-plan-for-2023-and-beyond/',
        title: (
            <>
                2142 – the transmedia plan <br /> for 2023 and beyond
            </>
        )
    },
    {
        header: ['breakingcryptonews', 'Dec 15'],
        image: {
            desktop: Story10,
            mobile: StoryMob10
        },
        link: 'https://buidlbee.com/2142-the-transmedia-plan-for-2023-and-beyond/',
        title: (
            <>
                The World’s First <br /> Community Driven NFT <br /> Comic Book
            </>
        )
    },
    {
        header: ['dailycoin', 'Inshaullah Zia, DEC 20'],
        image: {
            desktop: Story11,
            mobile: StoryMob11
        },
        link: 'https://dailycoin.com/7-nft-projects-to-explore-this-christmas/',
        title: (
            <>
                7 NFT Projects to Explore <br /> This Christmas
            </>
        )
    },
    {
        header: ['NFT World News', '29 Dec'],
        image: {
            desktop: Story12,
            mobile: StoryMob12
        },
        link: 'https://www.nftworldnews.tech/2142-community-driven-sci-fi-transmedia/',
        title: (
            <>
                “2142” Community-Driven, <br /> Sci-Fi Transmedia Inspired <br /> by Satoshi Nakamoto
            </>
        )
    }
];

export const menuItems = [
    {
        hash: '/#story',
        title: 'The story'
    },
    {
        hash: '/#dao',
        title: 'DAO'
    },
    {
        hash: '/#mechanics',
        title: 'Mechanics'
    },
    {
        hash: '/#roadmap',
        title: 'Roadmap'
    },
    {
        hash: '/#team',
        title: 'About'
    },
    {
        pathname: '/press',
        title: 'Press'
    }
];
