import React, { Component } from 'react';

// Helpers
import { withRouter } from 'helpers/common';
// Components
import Press from 'components/Press';

class PressPage extends Component {
    render() {
        return <Press />;
    }
}

export default withRouter(PressPage);
