import React, { Component } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export class Toast extends Component {
    constructor(props) {
        super(props);
        Toast.error = this.error.bind(this);
        Toast.success = this.success.bind(this);
        Toast.info = this.info.bind(this);
        Toast.warning = this.warning.bind(this);
        Toast.default = this.default.bind(this);
        Toast.clear = this.clear.bind(this);
        Toast.instance = this;
    }
    error(message, id = 'error') {
        toast.error(message, { toastId: id });
    }
    success(message, id = 'success') {
        toast.success(message, { toastId: id });
    }
    info(message) {
        toast.info(message);
    }
    warning(message) {
        toast.warning(message);
    }
    default(message) {
        toast(message);
    }
    clear() {
        toast.dismiss();
    }
    render() {
        return <ToastContainer {...this.props} />;
    }
}

export default Toast;
