import React from 'react';

// Components
import Loader from 'components/Common/Loader';
// Styled Components
import { LoadingContainer, LoadingWrapper } from 'assets/styles/components/Loading/index.styled';

/**
 * LoadingCreateListing
 * * Loading component when user start create listing assets
 * @param {*} props
 */
const Loading = (props) => {
    return (
        <LoadingContainer>
            <LoadingWrapper style={{ background: props.backgroundColor ? props.backgroundColor : 'rgba(7, 12, 15, 0.3)' }}>
                <Loader />
                <p className="loading-create-listing-title p-16 font-weight-600">{props.title}</p>
                {props.description && <p className="loading-create-listing-description p-14">{props.description}</p>}
            </LoadingWrapper>
        </LoadingContainer>
    );
};

export default Loading;
