import React, { useState } from 'react';

// Libs
import MailchimpSubscribe from 'react-mailchimp-subscribe';
// Components
import Title from 'components/Common/Title';
import Description from 'components/Common/Description';
import Button from '../../../Common/Button';
// Images
import { ReactComponent as ArrowNext } from 'assets/images/svg/arrow-next.svg';

// End Point
const url = 'https://2142ad.us10.list-manage.com/subscribe/post?u=b2df2a4e31a4fcd317a887037&id=6598d3edb5';
// Regex
const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const Newsletters = () => {
    // States
    const [email, setEmail] = useState('');
    const [notEmail, setNotEmail] = useState(false);

    return (
        <section className="newsletters-container">
            <div className="newsletters-content">
                <Title title="GET ON THE LIST" tag="h2" fontSize={'display2'} className="newsletters-title" />
                <Description tag="p" fontSize={24} className="newsletters-description" gradient>
                    Be at the forefront, don’t miss a thing
                </Description>
                <MailchimpSubscribe
                    url={url}
                    render={({ subscribe, status }) => {
                        return (
                            <div>
                                <form className={`position-relative`}>
                                    <div className={`newsletters-input-wrapper`}>
                                        <input
                                            type="text"
                                            placeholder="Email address"
                                            className="newsletters-input"
                                            onChange={(e) => {
                                                setEmail(e.target.value);
                                                setNotEmail(false);
                                            }}
                                            value={email}
                                        />
                                    </div>
                                    <Button
                                        className="newsletters-button"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (email.match(emailRegex)) {
                                                subscribe({ EMAIL: email });
                                                setEmail('');
                                            } else {
                                                setNotEmail(true);
                                            }
                                        }}>
                                        <ArrowNext />
                                    </Button>
                                </form>
                                {notEmail && (
                                    <div className="newsletters-not-valid-email font-weight-400">
                                        Incorrect email format. Please correct
                                    </div>
                                )}
                                {status === 'success' && (
                                    <div className="newsletters-success-subscribed font-weight-400">
                                        Well done! You are subscribed to 2142 News and Updates.
                                    </div>
                                )}
                            </div>
                        );
                    }}
                />
                <Description tag="p" fontSize={14} className="newsletters-note">
                    Your email address is safe with us. Read our{' '}
                    <a className="newsletters-privacy-policy" href="/privacy-policy">
                        Privacy policy
                    </a>
                </Description>
            </div>
        </section>
    );
};

export default Newsletters;
