// Libs
import { useLocation, useNavigate, useMatch, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
// abi
import ERC1155 from 'assets/contracts/ERC1155.json';
import Factory from 'assets/contracts/Factory.json';
import FactoryV2 from 'assets/contracts/FactoryV2.json';

/**
 * scrollTo
 * * This method scroll from top through screen to section
 * @param to ID of section
 **/
export const scrollTo = (to) => {
    setTimeout(() => {
        if (to) {
            const y = document.getElementById(to).getBoundingClientRect().top + window.pageYOffset;
            window.scrollTo({ left: 0, top: y - 100, behavior: 'smooth' });
        }
    }, 500);
};

/**
 * withRouter HOC
 * * This method use hooks from react-router-dom
 * @param Component child component
 **/
export const withRouter = (Component) => (props) => {
    const location = useLocation();
    const history = useNavigate();
    const [cookies, setCookie] = useCookies(['inviteCode']);
    const match = useMatch('join/:id');
    const params = useParams();
    return (
        <Component
            {...props}
            id={match?.params?.id}
            setCookie={setCookie}
            cookies={cookies}
            location={location}
            history={history}
            params={params}
        />
    );
};

/**
 * getHeroBackground
 * * This method return background image of current page
 * @param pathname Current pathname
 * @returns
 */
export const getHeroBackground = (pathname, isShowcase = false) => {
    if (isShowcase) {
        return require('assets/images/comic/background/bg-comic-03.png');
    }

    if (pathname.includes('/assets') || pathname.includes('/market')) {
        return require('assets/images/assets/background/bg-comic-01.png');
    }

    switch (pathname) {
        case '/comic':
            return require('assets/images/comic/background/bg-comic.jpg');
        case '/dao':
            return require('assets/images/dao/background/bg-dao.jpg');
        case '/dao/how-dao-works':
        case '/dao/what-is-dao':
        case '/dao/how-to-vote':
        case '/dao/help':
            return;
        case '/assets':
        case '/market':
            return require('assets/images/assets/background/bg-comic-01.png');
        default:
            return require('assets/images/coming_soon/background/bg-coming-soon.jpg');
    }
};

/**
 * formatNumberWithCommas
 * * This method convert number to string and add commas on thousands
 * @param number Number to be formated
 * @returns {string}
 */
export const formatNumberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

/**
 * getMethods
 * * This method get methods from smart contract
 * @param web3 Ethereum JavaScript API
 * @returns object with daoToken_contract, factory_contract
 */
export const getMethods = (web3) => {
    const chainId = window.ethereum?.networkVersion;

    try {
        if (web3?.currentProvider?.selectedAddress) {
            let daoToken_contract;
            let factory_contract;
            let factory_contractv2;

            switch (chainId) {
                case '1':
                    daoToken_contract = new web3.eth.Contract(ERC1155.abi, '0x2eE1934DFb56080962Ad86f60821120219bBd5d4');
                    factory_contract = new web3.eth.Contract(Factory.abi, '0x359496E65435d13C50C4ac50947Db4bA685B76C7');
                    factory_contractv2 = new web3.eth.Contract(FactoryV2.abi, '0xA0628101Be35299c036E8fE061098DA451209672');
                    break;
                case '5':
                    daoToken_contract = new web3.eth.Contract(ERC1155.abi, '0xcB85e6946E41B3E96620cbdb869CC0F0f6Aab569');
                    factory_contract = new web3.eth.Contract(Factory.abi, '0x163e54F3e8898298225207816B92E9e9a9e9360C');
                    factory_contractv2 = new web3.eth.Contract(FactoryV2.abi, '0xe3978B5d63B69DD3Ba9fbb383cC6b1103b4F3618');
                    break;
                default:
                    break;
            }

            return {
                daoToken_contract,
                factory_contract,
                factory_contractv2
            };
        }
    } catch (error) {
        console.error(error);
    }
};

/**
 * getPureWidthAndHeight
 * * This method will return element width and height including their scale
 * @param element DOM Element
 * @returns object with it's width and height
 */
export const getPureWidthAndHeight = (element) => {
    const el = element.getBoundingClientRect();

    return { width: el.width, height: el.height };
};

export const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};

export const getCoverMetadata = (chainId, token_id) => {
    return new Promise((resolve, reject) => {
        switch (chainId) {
            case '0x1':
                if (token_id) {
                    fetch(`${process.env.REACT_APP_COVER_METADATA}/${token_id}`)
                        .then((response) => response.json())
                        .then((data) => {
                            resolve(data);
                        })
                        .catch((err) => reject(err));
                } else {
                    resolve({});
                }
                break;
            case '0x5':
                if (token_id) {
                    fetch(`${process.env.REACT_APP_COVER_METADATA}/${token_id}?chain=${process.env.REACT_APP_CHAIN}`)
                        .then((response) => response.json())
                        .then((data) => {
                            resolve(data);
                        })
                        .catch((err) => reject(err));
                } else {
                    resolve({});
                }

                break;
            default:
                break;
        }
    });
};
