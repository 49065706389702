import styled from 'styled-components';

import { COLORS } from 'assets/styles/common/Colors.styled';
import { Flex } from 'assets/styles/common/Flex.styled';

const LoadingMintingContainer = styled(Flex)`
    position: relative;
    width: 100%;
    height: 100%;
    flex: 1 !important;
`;
const LoadingMintingWrapper = styled(Flex)`
    max-width: 26.4375em;
    width: 100%;
    max-height: 203px;
    height: 100%;
    flex: 1 !important;
    flex-direction: column;
    padding: 32px;
    border-radius: 20px;
    margin: 0 auto;
    background: rgba(7, 12, 15, 0.5);
    .loading-minting-title {
        color: ${COLORS.white};
        margin: 17px 0 8px;
    }
    .loading-minting-description {
        color: ${COLORS.blueLight};
        text-align: center;
        margin: 0;
        line-height: 1.5em;
    }
`;
const LoadingMintingQuotesWrapper = styled.div`
    position: absolute;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 2em;
    color: ${COLORS.blueLight};
    p {
        max-width: 806px;
        width: 100%;
        font-family: 'Courier New', Courier, monospace;
        line-height: 1.5em;
        margin: 0;
        @media screen and (min-width: 1920px) {
            max-width: 993px;
        }
    }
`;

export { LoadingMintingContainer, LoadingMintingWrapper, LoadingMintingQuotesWrapper };
