import React from 'react';
// Icons
import ErrorIcon from 'assets/images/toast/error.svg';

const MintToast = (props) => {
    return (
        <div className="toast-message-container">
            <div className="toast-message">{props.error && <div className="message-error-mint">{props.message}</div>}</div>
            <div className={`toast-icon${props.success ? ' t-success' : props.error ? ' t-error' : ''}`}>
                {props.error && <img src={ErrorIcon} alt="Error Icon" />}
            </div>
        </div>
    );
};

export default MintToast;
