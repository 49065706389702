import React, { useState, useEffect } from 'react';

// Helpers
import { ASSETS_TYPE } from 'helpers/data';
import { formatNumberWithCommas, getCoverMetadata } from 'helpers/common';
// Provider
import { useMoralisDapp } from 'providers/MoralisDappProvider/provider';
// Styled components
import {
    AssetCardContainer,
    AssetCard,
    AssetCardTitleDescription,
    AssetCardListForSale,
    AssetCardDuplicates,
    AssetCardDuplicatesNumberContainer,
    AssetCardDuplicatesNumber
} from 'assets/styles/components/Assets/index.styled';
// Images
import PageIcon from 'assets/images/assets/assets-page.png';
import ListForSaleIcon from 'assets/images/svg/list-for-sale.svg';
import DuplicatesIcon from 'assets/images/svg/duplicates.svg';

/**
 * Card
 * * Card component for assets e.g. panels, pages or covers
 * @param {string} type Type of card component
 * @param {object} assets Asset informations
 * @param {boolean} hideFromSale Hide LIST FOR SALE button
 * @param {boolean} hideDuplicate Hide amount of duplicates
 * @param {string} className Custom classes
 * @returns {}
 */
const Card = (props) => {
    return (
        <AssetCardContainer className={props.className}>
            <AssetCard addSpace={[ASSETS_TYPE.PAGE, ASSETS_TYPE.COVER].includes(props.type) || parseInt(props.asset?.amount) > 1}>
                <CardImage asset={props.asset} type={props.type} />
                <CardDescription asset={props.asset} type={props.type} />
                {!props.hideFromSale && (
                    <AssetCardListForSale
                        onClick={() => {
                            props.history(`/assets/details/${props.type}/${props.asset?.id}`, { replace: true });
                        }}>
                        <img src={ListForSaleIcon} alt="List for sale icon" />
                        <span className="p-12 font-weight-800">List for sale</span>
                    </AssetCardListForSale>
                )}
                {!props.hideDuplicate && props.asset?.amount && parseInt(props.asset?.amount) > 1 ? (
                    <AssetCardDuplicates>
                        <div className="duplicate duplicate-one"></div>
                        <div className="duplicate duplicate-two"></div>
                        <AssetCardDuplicatesNumberContainer>
                            <AssetCardDuplicatesNumber>
                                <img src={DuplicatesIcon} alt="Duplicate icon" />
                                <span className="p-16 font-weight-800">{props.asset?.amount}</span>
                            </AssetCardDuplicatesNumber>
                        </AssetCardDuplicatesNumberContainer>
                    </AssetCardDuplicates>
                ) : (
                    <></>
                )}
            </AssetCard>
        </AssetCardContainer>
    );
};

export default Card;

/**
 * CardImage
 * * Internal component for card cover
 * @param {string} type Type of assets
 * @param {object} asset Asset informations
 * @returns
 */
const CardImage = (props) => {
    const [video, setVideo] = useState('');
    const { chainId } = useMoralisDapp();

    useEffect(() => {
        (async () => {
            if (props.type === ASSETS_TYPE.COVER) {
                try {
                    const data = await getCoverMetadata(chainId, props.asset?.id);
                    setVideo(data);
                } catch (err) {
                    console.log(err);
                }
            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chainId, props.asset.id]);

    switch (props.type) {
        case ASSETS_TYPE.COVER: {
            return (
                <div className="asset-card-page asset-card-page-cover">
                    <video
                        type="video/mp4"
                        width={'100%'}
                        height={'100%'}
                        src={video.animation_url}
                        autoPlay={true}
                        controls={false}
                        alt={video.name}
                        loop={true}
                        muted={true}
                    />
                </div>
            );
        }
        case ASSETS_TYPE.PAGE: {
            return (
                <div className="asset-card-page">
                    <img src={PageIcon} alt={`ID of page ${props.asset?.id}`} className="asset-card-image" />
                    <div className="asset-card-page-id p-28 font-weight-800">{`#${props.asset?.pageID}`}</div>
                    <div className="asset-card-page-episode p-16 font-weight-800">{`${props.asset?.episode}`}</div>
                </div>
            );
        }
        default: {
            return <img src={props.asset?.image} alt={`ID of assets ${props.asset?.id}`} className="asset-card-image" />;
        }
    }
};

/**
 * CardDescription
 * * Internal component for title and description of asset
 * @param {string} type Type of asset
 * @param {object} asset Asset information
 * @returns
 */
const CardDescription = (props) => {
    switch (props.type) {
        case ASSETS_TYPE.COVER: {
            return (
                <AssetCardTitleDescription>
                    <p className="assets-card assets-card-title p-16 font-weight-800">Cover</p>
                    <p className="assets-card-cover p-14">
                        #{formatNumberWithCommas(props.asset?.id)} / <span>{formatNumberWithCommas(21000)}</span>
                    </p>
                </AssetCardTitleDescription>
            );
        }
        default: {
            return (
                <AssetCardTitleDescription>
                    {props.asset?.title && <p className="assets-card assets-card-title p-16 font-weight-800">{props.asset?.title}</p>}
                    {props.asset?.description && <p className="assets-card assets-card-description p-14">{props.asset?.description}</p>}
                </AssetCardTitleDescription>
            );
        }
    }
};
