import React, { useState, useEffect } from 'react';

// Libs
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
// Provider
import { useMoralisDapp } from 'providers/MoralisDappProvider/provider';
// Helpers
import { ASSETS_TYPE, MARKETPLACE_TABS } from 'helpers/data';
import { formatNumberWithCommas, getCoverMetadata } from 'helpers/common';
// Styled components
import { AssetCardContainer, AssetCard, AssetCardTitleDescription } from 'assets/styles/components/Assets/index.styled';
import { AssetMarketSection } from 'assets/styles/pages/Market/index.styled';
// Images
import PageIcon from 'assets/images/assets/assets-page.png';

/**
 * Card
 * * Card component for assets e.g. panels, pages or covers
 * @param {string} type Type of card component
 * @param {object} assets Asset informations
 * @param {string} className Custom classes
 * @param {string} tab Current active tab on marketplace
 * @returns {}
 */
const Card = (props) => {
    const navigate = useNavigate();
    return (
        <AssetCardContainer
            className={`cursor-pointer${props.className ? ` ${props.className}` : ''}`}
            onClick={() => {
                if (props.onClick) {
                    props.onClick();
                } else {
                    navigate(`/market/details/${props.type}/${props.asset?.id}`, { replace: true, state: props.navigateState || {} });
                }
            }}>
            <AssetCard>
                <CardImage asset={props.asset} type={props.type} tab={props.tab} />
                <CardDescription asset={props.asset} type={props.type} />
                <CardPrice asset={props.asset} tab={props.tab} />
            </AssetCard>
        </AssetCardContainer>
    );
};

export default Card;

/**
 * CardImage
 * * Internal component for card cover
 * @param {string} type Type of assets
 * @param {object} asset Asset informations
 * @returns
 */
const CardImage = (props) => {
    const [video, setVideo] = useState('');
    const { chainId } = useMoralisDapp();

    useEffect(() => {
        (async () => {
            if (props.type === ASSETS_TYPE.COVER) {
                try {
                    const data = await getCoverMetadata(chainId, props.asset?.id);
                    setVideo(data);
                } catch (err) {
                    console.log(err);
                }
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chainId, props.asset.id]);

    switch (props.type) {
        case ASSETS_TYPE.COVER: {
            return (
                <div className="asset-card-page asset-card-page-cover">
                    <video
                        type="video/mp4"
                        width={'100%'}
                        height={'100%'}
                        src={video.animation_url}
                        autoPlay={true}
                        controls={false}
                        alt={video.name}
                        loop={true}
                        muted={true}
                    />
                </div>
            );
        }
        case ASSETS_TYPE.PAGE: {
            return (
                <div className="asset-card-page">
                    <img src={PageIcon} alt={`ID of page ${props.asset?.id}`} className="asset-card-image" />
                    <div className="asset-card-page-id p-28 font-weight-800">{`#${props.asset?.pageID}`}</div>
                    <div className="asset-card-page-episode p-16 font-weight-800">{`${props.asset?.episode}`}</div>
                </div>
            );
        }
        default: {
            let image = props.asset?.imagePixelized;
            if ([MARKETPLACE_TABS.MY_LISTINGS, MARKETPLACE_TABS.HISTORY].includes(props.tab)) {
                image = props.asset?.imageBig;
            }
            return <img src={image} alt={`ID of assets ${props.asset?.id}`} className="asset-card-image" />;
        }
    }
};

/**
 * CardDescription
 * * Internal component for title and description of asset
 * @param {string} type Type of asset
 * @param {object} asset Asset information
 * @returns
 */
const CardDescription = (props) => {
    switch (props.type) {
        case ASSETS_TYPE.COVER: {
            return (
                <AssetCardTitleDescription>
                    <p className="assets-card assets-card-title p-16 font-weight-800">Cover</p>
                    <p className="assets-card-cover p-14">
                        #{formatNumberWithCommas(props.asset?.id)} / <span>{formatNumberWithCommas(21000)}</span>
                    </p>
                </AssetCardTitleDescription>
            );
        }
        default: {
            return (
                <AssetCardTitleDescription>
                    {props.asset?.title && <p className="assets-card assets-card-title p-16 font-weight-800">{props.asset?.title}</p>}
                    {props.asset?.description && <p className="assets-card assets-card-description p-14">{props.asset?.description}</p>}
                </AssetCardTitleDescription>
            );
        }
    }
};

const CardPrice = (props) => {
    switch (props.tab) {
        case MARKETPLACE_TABS.FOR_SALE: {
            return (
                <AssetMarketSection>
                    <div className="lowest-ask-wrapper info-price-wrapper">
                        <p className="lowest-ask-wrapper__label p-12 font-weight-500">Lowest ask</p>
                        <p className="lowest-ask-wrapper__value p-14 font-weight-400">{`Ξ ${props.asset?.price?.displayValue}`}</p>
                    </div>
                    <div className="avg-sale-wrapper info-price-wrapper">
                        <p className="avg-sale-wrapper__label p-12 font-weight-500">Avg sale</p>
                        <p className="avg-sale-wrapper__value p-14 font-weight-400">{`Ξ ${props.asset?.avgPrice}`}</p>
                    </div>
                </AssetMarketSection>
            );
        }
        case MARKETPLACE_TABS.MY_LISTINGS: {
            return (
                <AssetMarketSection>
                    <div className="lowest-ask-wrapper info-price-wrapper">
                        <p className="lowest-ask-wrapper__label p-12 font-weight-500">Sell price</p>
                        <p className="lowest-ask-wrapper__value p-14 font-weight-400">{`Ξ ${props.asset?.price?.displayValue}`}</p>
                    </div>
                </AssetMarketSection>
            );
        }
        case MARKETPLACE_TABS.HISTORY: {
            return (
                <AssetMarketSection>
                    <div className="lowest-ask-wrapper info-price-wrapper">
                        <p className="lowest-ask-wrapper__label p-12 font-weight-500">Sold for</p>
                        <p className="lowest-ask-wrapper__value p-14 font-weight-400">Ξ 0.005</p>
                    </div>
                    <p className="sold-date p-12 font-weight-500">{format(new Date(), 'MMM do, yyyy')}</p>
                </AssetMarketSection>
            );
        }
        default: {
            return <></>;
        }
    }
};
