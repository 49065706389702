import styled from 'styled-components';

import { COLORS } from 'assets/styles/common/Colors.styled';

const DescriptionSellAsset = styled.div`
    max-width: 504px;
    width: 100%;
`;
const ContainerSellAsset = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .comic-button {
        margin: 0;
    }
    .comic-button-content {
        img {
            top: -3px;
        }
    }
    .sell-asset-modal-card {
        position: relative;
        max-width: unset;
        width: 176px;
        > div {
            margin-bottom: 0;
            .asset-card-image {
                max-width: unset;
                width: 144px;
            }
            > div {
                margin-bottom: 0;
            }
        }
    }
    .edit-listing-modal-btn {
        width: 100%;
        display: flex;
        justify-content: center;
        > * + * {
            margin-left: 30px;
        }
        button {
            &:last-child {
                &.comic-button.outline {
                    color: ${COLORS.red};
                    border-color: ${COLORS.red};
                    &.button-disabled {
                        color: ${COLORS.blueLight};
                        border: 2px solid ${COLORS.blueLight};
                    }
                }
            }
        }
    }
`;
const SellAssetWrapper = styled.div`
    margin-bottom: ${(props) => (props.hiddenCheckbox ? '0px' : '40px')};
    border: ${(props) => (props.hiddenCheckbox ? '1px solid transparent' : `1px solid ${COLORS.red}`)};
    border-radius: 16px;
`;

const ContentSellAsset = styled.div`
    background: rgba(6, 15, 23, 0.5);
    border-radius: 16px;
    padding: 24px 56px 24px 24px;
    margin-bottom: ${(props) => (props.hiddenCheckbox ? '48px' : '0px')};
    border-bottom: ${(props) => (props.hiddenCheckbox ? '1px solid transparent' : `1px solid ${COLORS.red}`)};
    display: flex;
    .sell-asset-form {
        margin-left: 35px;
        .sell-asset-form-title {
            margin-bottom: 24px;
            color: ${COLORS.morningBlue};
            width: 429px;
        }
        .sell-asset-input {
            margin-bottom: 15px;
        }
    }
`;
const ConfirmListingsWrapper = styled.div`
    .comic-modal-header {
        margin-bottom: 16px;
    }
    .comic-modal-description {
        margin-bottom: 32px;
        max-width: 504px;
        width: 100%;
    }
    .comic-modal {
        width: 816px;
    }
`;

const SellAssetCheckbox = styled.div`
    width: 100%;
    padding: 20px 0 30px 24px;
    background: rgba(86, 37, 27, 0.3);
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
    display: ${(props) => (props.hiddenCheckbox ? 'none' : 'block')};
    > p {
        color: ${COLORS.red};
    }
    .checkbox-field {
        > p {
            color: ${COLORS.white};
        }
    }
    .checkmark {
        background: #070c0f;
        &.checked {
            background: ${COLORS.primary};
            &::after {
                display: block;
            }
        }
    }
`;

const LimitContainer = styled.div`
    max-width: 525px;
    width: 100%;
    .title {
        margin: 30px 0 24px 0 !important;
    }
`;

export {
    DescriptionSellAsset,
    ContentSellAsset,
    ContainerSellAsset,
    ConfirmListingsWrapper,
    SellAssetCheckbox,
    SellAssetWrapper,
    LimitContainer
};
