import { COLORS } from 'assets/styles/common/Colors.styled';
import styled from 'styled-components';

const RewardWrapperStyled = styled.div`
    display: flex;
    flex-direction: column;
    width: 45%;
`;
const RewardDescriptionStyled = styled.div`
    display: flex;
    width: 100%;
    height: 40px;
    margin-bottom: 27px;
    .description-wrapper {
        margin-left: 15px;
        p {
            margin-bottom: 0;
        }
        .gray-text {
            color: ${COLORS.rewardGray};
            img {
                margin-left: 5px;
            }
        }
    }
`;
const AssetsWrapperStyled = styled.div`
    display: flex;
    .assets-group {
        display: flex;
        background-color: ${(p) => (p.completed ? COLORS.assetsDarkBlueBackground : COLORS.assetsGroupBackground)};
        border-radius: 12px;
        &.assets-group-right {
            width: 100%;
        }
        .or-break {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            p {
                color: ${(p) => (p.completed ? COLORS.blueLight : COLORS.assetsBrown)};
                margin-bottom: 0;
            }
            .top-line {
                margin: 0 auto;
                height: 68px;
                width: 1px;
                background: linear-gradient(0deg, rgba(122, 90, 84, 0.35), rgba(122, 90, 84, 0));
            }
            .bottom-line {
                margin: 0 auto;
                height: 68px;
                width: 1px;
                background: linear-gradient(180deg, rgba(122, 90, 84, 0.35), rgba(122, 90, 84, 0));
            }
        }
    }
    .plus-icon {
        margin: auto 14px;
    }
`;

export { RewardWrapperStyled, RewardDescriptionStyled, AssetsWrapperStyled };
