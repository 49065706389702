import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="legal-documents-wrapper">
            <h1>Privacy Policy for 2142</h1>
            <br />
            <br />
            <div className="p-16">
                At 2142, accessible from www.2142ad.com, one of our main priorities is the privacy of our visitors. This Privacy Policy
                document contains types of information that is collected and recorded by 2142 and how we use it.
                <br /> If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.
                <br /> This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to
                the information that they shared and/or collected in 2142. This policy is not applicable to any information collected
                offline or via channels other than this website.
            </div>
            <br />
            <h5>Consent</h5>
            <div className="p-16">By using our website, you hereby consent to our Privacy Policy and agree to its terms.</div>
            <br />
            <h5>Information we collect</h5>
            <div className="p-16">
                The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear
                to you at the point we ask you to provide your personal information.
                <br /> If you contact us directly, we may receive additional information about you, such as your name, email address, phone
                number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.
                <br /> When you register for an Account, we may ask for your contact information, including items such as name, company
                name, address, email address, and telephone number.
            </div>
            <br />
            <h5>How we use your information</h5>
            <div className="p-16">
                We use the information we collect in various ways, including:
                <ul>
                    <li>Provide, operate, and maintain our website</li>
                    <li>Improve, personalize, and expand our website</li>
                    <li>Understand and analyze how you use our website</li>
                    <li>Develop new products, services, features, and functionality</li>
                    <li>
                        Communicate with you, either directly or through one of our partners, including for customer service, to provide you
                        with updates and other information relating to the website and for marketing and promotional purposes
                    </li>
                    <li>Send you emails</li>
                    <li>Find and prevent fraud</li>
                </ul>
            </div>
            <br />
            <h5>Log Files</h5>
            <div className="p-16">
                2142 follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting
                companies do this and are a part of hosting services' analytics. The information collected by log files includes internet
                protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and
                possibly the number of clicks. These are not linked to any personally identifiable information. The purpose of the
                information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering
                demographic information.
            </div>
            <br />
            <h5>Cookies and Web Beacons</h5>
            <div className="p-16">
                Like any other website, 2142 uses 'cookies.' These cookies are used to store information, including visitors' preferences
                and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience
                by customizing our web page content based on visitors' browser type and/or other information.
            </div>
            <br />
            <h5>Newsletter</h5>
            <div className="p-16">
                If you subscribe to our newsletter, We add your name and email address to the 2142 newsletter mailing list. Our newsletters
                use tracking, which means that we can identify how many or which users have opened mailouts or followed links.
                <br />
                Subscribers receive one general 2142 newsletter monthly. Rarely do we send an extra mailout to impart news or announce
                events.
                <br />
                If you want to unsubscribe, every newsletter contains a quick and easy unsubscribe link in its footer.
            </div>
            <br />
            <h5>Advertising Partners Privacy Policies</h5>
            <div className="p-16">
                You may consult this list to find the Privacy Policy for each of the advertising partners of 2142.
                <br />
                Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their
                respective advertisements and links that appear on 2142, which are sent directly to users' browser. They automatically
                receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising
                campaigns and/or to personalize the advertising content that you see on websites that you visit. Note that 2142 has no
                access to or control over these cookies that third-party advertisers use.
            </div>
            <br />
            <h5>Third-Party Privacy Policies</h5>
            <div className="P-16">
                2142's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective
                Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and
                instructions about how to opt out of certain options.
                <br />
                You can choose to disable cookies through your individual browser options. To know more detailed information about cookie
                management with specific web browsers, it can be found on the browsers' respective websites.
            </div>
            <br />
            <h5>CCPA Privacy Rights (Do Not Sell My Personal Information)</h5>
            <div className="p-16">
                Under the CCPA, among other rights, California consumers have the right to: Request that a business that collects a
                consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about
                consumers.
                <br />
                Request that a business deletes any personal data about the consumer that a business has collected. Request that a business
                that sells a consumer's personal data, not sell the consumer's personal data.
                <br />
                If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please
            </div>
            <br />
            <h5>GDPR Data Protection Rights</h5>
            <div className="p-16">
                We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the
                following:
                <ul>
                    <li>
                        The right to access – You have the right to request copies of your personal data. We may charge you a small fee for
                        this service.
                    </li>
                    <li>
                        The right to rectification – You have the right to request that we correct any information you believe is
                        inaccurate. You also have the right to request that we complete the information you believe is incomplete.
                    </li>
                    <li>The right to erasure – You have the right to request that we erase your personal data under certain conditions.</li>
                    <li>
                        The right to restrict processing – You have the right to request that we restrict the processing of your personal
                        data under certain conditions.
                    </li>
                    <li>
                        The right to object to processing – You have the right to object to our processing of your personal data, under
                        certain conditions.
                    </li>
                    <li>
                        personal data, under certain conditions. The right to data portability – You have the right to request that we
                        transfer the data that we have collected to another organization, or directly to you, under certain conditions .
                    </li>
                </ul>
                If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please
                contact us.
            </div>
            <br />
            <h5>Children's Information</h5>
            <div className="p-16">
                Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians
                to observe, participate in, and/or monitor and guide their online activity.
                <br />
                2142 does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that
                your child provided this kind of information on our website, we strongly encourage you to contact us immediately, and we
                will make our best efforts to remove such information from our records promptly.
            </div>
        </div>
    );
};

export default PrivacyPolicy;
