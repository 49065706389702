import styled from 'styled-components';

// Colors
import { COLORS } from 'assets/styles/common/Colors.styled';

const AssetCardContainer = styled.div`
    position: absolute;
    max-width: 176px;
    width: 100%;
    min-height: 185px;
`;

const AssetCard = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 16px 0;
    border: 1px solid ${COLORS.cardBorderColor};
    border-radius: 12px;
    margin-bottom: ${(props) => (props.addSpace ? '64px' : '32px')};
    & > * {
        max-width: 144px;
        margin-right: 16px;
        margin-left: 16px;
        width: 100%;
    }
    .asset-card-page {
        position: relative;
        max-width: 144px;
        width: 100%;
        height: auto;
        .asset-card-page-id {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            color: ${COLORS.priceButton};
            opacity: 0.5;
            text-transform: uppercase;
        }
        .asset-card-page-episode {
            position: absolute;
            bottom: 16px;
            left: 16px;
            color: ${COLORS.priceButton};
            opacity: 0.3;
            text-transform: uppercase;
        }
    }
    .asset-card-page-cover {
        min-height: 192px;
    }
    .asset-card-image {
        max-width: 144px;
        width: 100%;
        height: auto;
    }
`;

const AssetsSectionContainer = styled.section`
    display: flex;
    flex-direction: column;
    min-height: 250px;
    margin-bottom: 80px;
    .assets-header {
        margin-right: ${(props) => (props.marginHeader ? '0px' : '14px')};
        margin-bottom: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .assets-title {
            font-size: 24px;
            line-height: 28px;
            color: #dde1e4;
            span {
                &:last-child {
                    color: ${COLORS.MintPriceLabel};
                }
            }
        }
    }
    .assets-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 60px;
        span {
            color: ${COLORS.morningBlue};
            padding: 10px 38px;
            text-transform: uppercase;
            background-color: #0e1e2a;
            display: inline-block;
            border-radius: 9px;
            cursor: pointer;
            line-height: 20px;
        }
    }
    .assets-loading-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        flex: 1 1 auto;
        .assets-loading {
            display: flex;
            justify-content: center;
        }
    }
    .assets-no-minted {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        flex: 1 1 auto;
        text-transform: uppercase;
        text-align: center;
        opacity: 0.4;
        margin: 0;
    }
    .disabled-show-assets {
        opacity: 0.5;
        span {
            cursor: not-allowed;
        }
    }
    .assets-masonry {
        position: relative;
        width: 100%;
        max-height: 800px;
    }
`;

const AssetsSectionMasonry = styled.div`
    max-height: 800px;
    overflow: ${(props) => (props.checkOverflow ? 'auto' : 'hidden')};
    &.scrollBar {
        &::-webkit-scrollbar {
            background-color: rgba(255, 255, 255, 0.05);
        }
    }
`;

const AssetCardTitleDescription = styled.div`
    margin-top: 16px;
    margin-bottom: 26px;
    .assets-card {
        &:not(:last-child) {
            margin-bottom: 4px;
        }
    }
    .assets-card-cover {
        color: ${COLORS.white};
        line-height: 18px;
        span {
            color: ${COLORS.blueLight};
        }
    }
    .assets-card-title {
        color: ${COLORS.white};
        line-height: 18px;
    }
    .assets-card-description {
        color: ${COLORS.blueLight};
        line-height: 18px;
        margin: 0;
    }
`;

const AssetCardListForSale = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    cursor: pointer;
    img {
        width: 16px;
        height: 16px;
        margin-right: 4px;
    }
    span {
        line-height: 12px;
        text-transform: uppercase;
        color: ${COLORS.textButtonOutline};
    }
`;

const AssetCardDuplicates = styled.div`
    position: absolute;
    top: 100%;
    max-width: 148px;
    width: 100%;
    .duplicate {
        height: 13px;
        border: 1px solid ${COLORS.cardBorderColor};
        border-radius: 0 0 12px 12px;
    }
    .duplicate-one {
        max-width: 148px;
        width: 100%;
    }
    .duplicate-two {
        max-width: 116px;
        width: 100%;
        margin: 0 auto;
    }
`;

const AssetCardDuplicatesNumberContainer = styled.div`
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
`;

const AssetCardDuplicatesNumber = styled.div`
    min-width: 48px;
    width: fit-content;
    height: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    color: ${COLORS.secondary};
    background-color: ${COLORS.cardDuplicateNumberBackground};
    span {
        margin-left: 5px;
        line-height: 10px;
    }
`;

export {
    AssetCardContainer,
    AssetCard,
    AssetsSectionContainer,
    AssetsSectionMasonry,
    AssetCardTitleDescription,
    AssetCardListForSale,
    AssetCardDuplicates,
    AssetCardDuplicatesNumberContainer,
    AssetCardDuplicatesNumber
};
