import React, { useRef } from 'react';

// Libs
import { Controller, Scene } from 'react-scrollmagic';
// Helpers
import { SLIDER_ITEMS } from 'helpers/data';
// Components
import Title from 'components/Common/Title';
import Description from 'components/Common/Description';

const SliderDesktop = (props) => {
    const controllerRef = useRef(null);

    const handleSliderClicked = (key) => {
        // console.log(controllerRef.current);
        // controllerRef.current.triggerPosition(key);
    };
    return (
        <div className="slider-wrapper">
            <Controller ref={controllerRef}>
                <Scene duration={300} triggerHook={0} pin={true}>
                    {(progress) => (
                        <div className="w-100" style={{ height: '100vh' }}>
                            <div className="slider-items-list">
                                {SLIDER_ITEMS?.map((item, key) => (
                                    <div
                                        className="slider-item-wrapper"
                                        key={key}
                                        onClick={() => {
                                            handleSliderClicked(key);
                                        }}>
                                        <div
                                            className={`slider-item-label${
                                                Math.round(progress * 1 * (SLIDER_ITEMS?.length - 1)) === key ? ' opacity-100' : ''
                                            }`}>
                                            {item?.label}
                                        </div>
                                        <div
                                            className={`slider-item-indicator${
                                                Math.round(progress * 1 * (SLIDER_ITEMS?.length - 1)) === key ? ' active' : ''
                                            }`}></div>
                                    </div>
                                ))}
                            </div>
                            <div className="slider-content">
                                {SLIDER_ITEMS?.map((item, key) => (
                                    <div
                                        className="slider-content-item"
                                        style={{
                                            opacity: key !== Math.round(progress * 1 * (SLIDER_ITEMS?.length - 1)) ? 0 : 1
                                        }}
                                        key={key}>
                                        <img src={item?.image} alt={item?.title} />
                                        <div className="slider-title-description">
                                            <Title
                                                tag="h2"
                                                title={item?.title}
                                                fontSize={'display3'}
                                                textAlign={'left'}
                                                width={315}
                                                className="m-0"
                                            />
                                            <Description tag="p" fontSize={24} fontWeight={600} textAlign={'left'}>
                                                {item?.text}
                                            </Description>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </Scene>
            </Controller>
        </div>
    );
};

export default SliderDesktop;

/* */
