import React from 'react';

// Helpers
import { HOW_TO_MINT_ITEMS } from 'helpers/data';
// Components
import Title from 'components/Common/Title';
import { Item } from './item';

const HowToMint = () => {
    return (
        <section id="how-to-mint" className="how-to-mint-container">
            <div className="how-to-mint-content">
                <div className="how-to-mint-ellipse-1"></div>
                <div className="how-to-mint-text">
                    <Title tag="h2" title="How to mint, craft, and get valuable NFTs?" fontSize="display2" className="how-to-mint-title" />
                    <div className="how-to-mint-item-wrapper">
                        {HOW_TO_MINT_ITEMS.map((item, key) => (
                            <Item image={item.image} description={item.description} ellipse={item.ellipse} key={key} />
                        ))}
                    </div>
                </div>
                <div className="how-to-mint-ellipse-2"></div>
            </div>
        </section>
    );
};

export default HowToMint;
