import React from 'react';

// Components
import Title from 'components/Common/Title';
import Description from 'components/Common/Description';
import Button from 'components/Common/Button';
// Styled Components
import { StartMintingContainer } from 'assets/styles/components/Mint/index.styled';
// Images
import Plus from 'assets/images/svg/ico-plus.svg';

/**
 * FirtsMinting
 * * Component for first display only
 * @param {*} props
 * @param onClick On click triggered method for minting assets
 */
const FirstMinting = (props) => {
    return (
        <StartMintingContainer>
            <Title tag={'h1'} title={'GRAB YOUR FREE BUNDLE OF NFTS!'} />

            <Description tag={'p'} fontSize={'20'} className="start-minting-description">
                It's so awesome you joined our DAO! To welcome you to the 2142 universe, you are entitled to mint your DAO token plus seven
                NFT comic book assets (one empty page and six panels) to begin assembling the 2142 issue #1 "As Above, So Below." You only
                have to pay the gas fee.
            </Description>
            <Button onClick={props.action}>
                <img src={Plus} alt="Plus icon" />
                MINT 7 FREE NFTS
            </Button>
        </StartMintingContainer>
    );
};

export default FirstMinting;
