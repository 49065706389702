import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
// Libs
import Media from 'react-media';
import ReactGA from 'react-ga4';
import { useAddress, useConnect } from '@thirdweb-dev/react';
import Loader from 'components/Common/Loader';
import { useMoralis } from 'react-moralis';
import * as am5 from '@amcharts/amcharts5';
// Import Routes
import { publicRoutes, protectedRoutes } from 'routes';
// Components
import ScrollToTop from 'components/Common/ScrollToTop';
import FreeMintMobile from 'pages/FreeMintMobile';
import LandingPage from 'pages/LandingPage';
// Layouts
import NonConnectLayout from 'components/Layout/Landing';
import Layout from 'components/Layout';
// Provider
import { useDaoToken } from 'providers/DaoToken/provider';

const App = () => {
    ReactGA.initialize('G-5G4EEPL32J');
    // Use the hooks thirdweb give us.
    const address = useAddress();
    const connect = useConnect();
    //  Use the hooks react-moralis give us.
    const { isAuthenticated, enableWeb3 } = useMoralis();
    //  Get DaoToken
    const { daoToken } = useDaoToken();

    useEffect(() => {
        am5.addLicense('AM5C348032852');
    }, []);

    useEffect(() => {
        try {
            if (window.ethereum) {
                (async () => {
                    enableWeb3();
                    ReactGA.send('pageview');
                })();
            } else {
                console.log('No Metamask installation found!');
            }
        } catch (error) {
            console.error(error);
        }
    }, [enableWeb3, address]);

    return (
        <React.Fragment>
            <ScrollToTop />
            <Media queries={{ xs: '(max-width: 1200px)' }}>
                {(matches) => {
                    return connect[0].loading === true ? (
                        <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Loader disableFlex={true} />
                        </div>
                    ) : (
                        <Routes>
                            {!address || !isAuthenticated ? (
                                publicRoutes.map((route, key) => {
                                    return (
                                        <Route
                                            key={key}
                                            route={route.exact}
                                            path={route.path}
                                            element={<NonConnectLayout>{route.component}</NonConnectLayout>}
                                        />
                                    );
                                })
                            ) : matches.xs && daoToken === '0' ? (
                                <Route exact path="/*" element={<FreeMintMobile />} />
                            ) : matches.xs && daoToken === '1' ? (
                                <Route
                                    exact
                                    path="/*"
                                    element={
                                        <NonConnectLayout>
                                            <LandingPage />
                                        </NonConnectLayout>
                                    }
                                />
                            ) : (
                                !matches.xs &&
                                protectedRoutes.map((route, key) => {
                                    if (daoToken === '1') {
                                        return (
                                            <Route
                                                key={key}
                                                route={route.exact}
                                                path={route.path}
                                                element={<Layout>{route.component}</Layout>}
                                            />
                                        );
                                    } else if (daoToken === '0' && daoToken !== '') {
                                        return (
                                            <Route
                                                key={key}
                                                route={route.exact}
                                                path={route.path}
                                                element={
                                                    route.path !== '/comic' ? (
                                                        <Navigate replace to="/comic" />
                                                    ) : (
                                                        <Layout>{route.component}</Layout>
                                                    )
                                                }
                                            />
                                        );
                                    } else {
                                        return (
                                            <Route
                                                key={key}
                                                route={route.exact}
                                                path={route.path}
                                                element={<Layout>{route.component}</Layout>}
                                            />
                                        );
                                    }
                                })
                            )}
                        </Routes>
                    );
                }}
            </Media>
        </React.Fragment>
    );
};
export default App;
