import React from 'react';

const ProgressBar = (props) => {
    // Value for progress bar
    const percent = props.percent ?? (props.value / props.max) * 100;

    return (
        <div className="progress-bar-wrapper">
            <div className="progress-bar" style={{ width: props.width }}>
                <div
                    className={`progress-bar-current ${props.backgroundClassName ?? ''} ${props.shadowClassName ?? ''} ${
                        props.className ?? ''
                    }`}
                    style={{ width: `${percent + 7}%` }}>
                    <div className={`progress-bar-label ${props.textShadow ?? ''}`}>
                        {props.icon ? <img src={props.icon} alt={`Progress bar ${props.label}`} /> : <></>}
                        {props.label ?? ''}
                    </div>
                </div>
            </div>
            <div className={`progress-bar-percent`}>{`${props.percent}%`}</div>
        </div>
    );
};

export default ProgressBar;
