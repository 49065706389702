import React from 'react';

// Libs
import { NavLink } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
// Helpers
import { withRouter } from 'helpers/common';
import { menuItems } from 'helpers/data';

const MenuDesktop = (props) => {
    const hash = props.location.hash;
    const pathname = props.location.pathname;

    return (
        <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto navmenu-wrapper">
                {menuItems.map((item, key) => (
                    <NavLink
                        end
                        key={key}
                        to={item.hash ? item.hash : item.pathname}
                        className={({ isActive }) =>
                            `navmenu-item ${
                                isActive && item.hash
                                    ? item.hash.substring(1) === hash
                                        ? ' active'
                                        : ''
                                    : isActive && item.pathname === pathname
                                    ? ' active'
                                    : ''
                            }`
                        }
                        onClick={() => {
                            if (item.pathname) {
                                props.history(item.pathname, { replace: true });
                                props.setExpanded(!props.expanded);
                            } else {
                                props.checkPathname(item.hash.substring(2));
                            }
                        }}>
                        {item.title}
                    </NavLink>
                ))}
            </Nav>
        </Navbar.Collapse>
    );
};

export default withRouter(MenuDesktop);
