import styled from 'styled-components';

import { COLORS } from 'assets/styles/common/Colors.styled';

const AssetsDetailsContainer = styled.div`
    padding-top: 137px;
    padding-bottom: 50px;
`;

const AssetsDetailsBackContainer = styled.div`
    width: fit-content;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 40px;
    img {
        margin-right: 8px;
    }
    span {
        color: ${COLORS.assetsDetailsBackText};
        text-transform: uppercase;
    }
`;

const AssetsDetailsItemContainer = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 32px;
`;

const AssetsDetailsItemImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 696px;
    width: 100%;
    min-height: 585px;
    background: linear-gradient(141.74deg, rgba(108, 93, 150, 0.14) 0%, rgba(142, 96, 90, 0.14) 98.22%);
    border-radius: 8px;
    padding: 20px;
`;

const AssetsDetailsItemImage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    img {
        filter: drop-shadow(0px 4px 116px rgba(253, 152, 121, 0.2));
    }
    .horizontal-asset {
        max-width: 424px;
        width: 100%;
        height: auto;
    }
    .vertical-asset {
        max-height: 465px;
        height: 100%;
        width: auto;
    }
    .asset-card-page {
        position: relative;
        max-width: 144px;
        width: 100%;
        height: auto;
        .asset-card-page-id {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            color: ${COLORS.priceButton};
            opacity: 0.5;
            text-transform: uppercase;
        }
        .asset-card-page-episode {
            position: absolute;
            bottom: 16px;
            left: 16px;
            color: ${COLORS.priceButton};
            opacity: 0.3;
            text-transform: uppercase;
        }
    }
    .asset-card-video-page {
        max-width: 424px;
        width: 100%;
    }
    .asset-details-image-loader {
        width: fit-content;
    }
`;

const AssetsDetailsItemDataContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 488px;
    width: 100%;
    min-height: 586px;
    border: 1px solid #1b3548;
    border-radius: 8px;
    overflow: hidden;
    .assets-details-item-data-position {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px 24px 29px;
        .assets-details-item-data-position-title {
            color: ${COLORS.white};
        }
        .assets-details-item-data-position-description {
            color: ${COLORS.blueLight};
        }
    }
    .assets-details-item-data-sell {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 0 24px 24px;
        .assets-details-item-data-sold-by {
            .assets-details-item-data-sold-by-label {
                color: ${COLORS.blueLight};
                margin: 0;
            }
            .assets-details-item-data-sold-by-value {
                color: ${COLORS.white};
                margin: 0;
            }
        }
        .assets-details-item-data-for-sale {
            text-align: right;
            p {
                margin: 0;
            }
            .assets-details-item-data-for-sale-label {
                color: ${COLORS.blueLight};
            }
        }
    }
`;

const AssetsDetailsItemGraphContainer = styled.div`
    position: relative;
    width: 100%;
    height: 219px;
    background: linear-gradient(180deg, #091620 0%, rgba(9, 22, 32, 0) 100%);
    .asset-details-select {
        position: absolute;
        top: 30px;
        left: 24px;
        z-index: 5;
    }
`;

const AssetsDetailsItemListForSaleContainer = styled.div`
    .assets-details-item-statistics-price-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 33px 33px 0;
        margin-bottom: 40px;
        .assets-details-item-statistics-price-item {
            min-width: 88px;
            .assets-details-item-statistics-price-item-value {
                color: ${COLORS.white};
                img {
                    margin-right: 5px;
                }
            }
            .assets-details-item-statistics-price-item-label {
                color: ${COLORS.blueLight};
            }
        }
    }
`;

const AssetsDetailsItemSaleButton = styled.div`
    padding: 0 33px 33px;
    .assets-details-item-list-for-sale-button {
        width: 100%;
        margin: 0;
        text-transform: uppercase;
        height: 56px;
    }
`;

const AssetsDetailsPriceInfosContainer = styled.div`
    flex: 1;
    padding: 24px 23px 29px 25px;
    background: ${COLORS.assetsDetailsPriceBackground};
    .assets-details-price-infos-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 17px;
        span {
            color: ${COLORS.morningBlue};
        }
        .assets-on-sale-sort-container {
            display: flex;
            align-items: center;
            cursor: pointer;
            img {
                margin-right: 6px;
            }
            p {
                margin-bottom: 0;
                color: ${COLORS.morningBlue};
            }
        }
    }
    .assets-details-price-info-labels-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 52px;
        margin-bottom: 24px;
        span {
            color: ${COLORS.blueLight};
            text-transform: uppercase;
            &:nth-child(2) {
                text-align: right;
            }
        }
    }
    .assets-details-price-info-select-container {
        height: 270px;
        margin-bottom: 50px;
        overflow: auto;
        .price-radio-button {
            padding-left: 52px;
            margin-bottom: 0;
            p {
                font-size: 1em;
                font-weight: 500;
            }
        }
        .assets-details-price-info-radio-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-right: 5px;
            position: relative;

            &:not(:last-child) {
                margin-bottom: 24px;
            }
            .address-long {
                position: absolute;
                background-color: ${COLORS.layout};
                width: 300px;
                padding: 15px;
                right: 0;
                top: 30px;
                word-break: break-all;
                border-radius: 8px;
                text-align: center;
                z-index: 20;
                span {
                    font-size: 14px;
                    background: ${COLORS.title};
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
            .address-short {
                cursor: pointer;
                color: ${COLORS.blueLight};
                margin-bottom: 0;
            }
        }
    }
    .assets-details-price-info-buttons-container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;
        .assets-details-price-info-button-buy {
            max-width: 290px;
            width: 100%;
            margin: 0;
        }
    }
`;

export {
    AssetsDetailsContainer,
    AssetsDetailsBackContainer,
    AssetsDetailsItemContainer,
    AssetsDetailsItemImageContainer,
    AssetsDetailsItemImage,
    AssetsDetailsItemDataContainer,
    AssetsDetailsItemGraphContainer,
    AssetsDetailsItemListForSaleContainer,
    AssetsDetailsItemSaleButton,
    AssetsDetailsPriceInfosContainer
};
