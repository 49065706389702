import React from 'react';

export const Item = (props) => {
    return (
        <div className="text-center">
            {props.image && props.image}
            {props.title && <div className="p-20 roadmap-title font-weight-700">{props.title}</div>}
            {props.subtitle && <div className="roadmap-subtitle p-14 font-weight-800">{props.subtitle}</div>}
            {props.content}
        </div>
    );
};
