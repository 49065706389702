import React from 'react';

// Components
import { Cover } from './Cover';
import { Rewards } from './Rewards';
import Pages from './Pages';
// Styled Components
import { ShowcaseWrapper, CoverRewardsContainer } from 'assets/styles/pages/ComicBook/ShowCase/index.styled';

const ShowCase = (props) => {
    return (
        <ShowcaseWrapper>
            <CoverRewardsContainer>
                <Cover mintedCover={props.mintedCover} />
                <Rewards />
            </CoverRewardsContainer>
            <Pages />
        </ShowcaseWrapper>
    );
};

export default ShowCase;
