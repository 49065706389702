import React from 'react';
import { useNavigate } from 'react-router-dom';
// Icons
import SuccessIcon from 'assets/images/toast/success.svg';

const ConfirmListingsToast = (props) => {
    let navigate = useNavigate();

    return (
        <div className="toast-message-container">
            <div className="toast-message">
                {props.success && (
                    <div className="message-success-sell">
                        <span>All good!</span> <span>Your asset is now listed on</span>{' '}
                        <span
                            onClick={() => {
                                navigate('/market', { replace: true });
                            }}>
                            Marketplace
                        </span>
                    </div>
                )}
            </div>
            <div className="toast-icon t-success">
                <img src={SuccessIcon} alt="Success Icon" />
            </div>
        </div>
    );
};

export default ConfirmListingsToast;
