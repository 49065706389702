import styled from 'styled-components';

const StyledSidebar = styled.div`
    position: fixed;
    top: 120px;
    bottom: 0;
    left: 32px;
    z-index: 1001;
    max-width: 80px;
    width: 100%;
    min-height: 494px;
    height: fit-content;
    padding: 24px 0px;
    border-radius: 20px;
    background: rgba(7, 12, 15, 0.5);
    .sidebar-items {
        display: flex;
        flex-direction: column;
        justify-content: center;
        row-gap: 32px;
        .sidebar-item {
            color: #8aa7b7;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 0.04em;
            padding: 0 8px;
            cursor: pointer;
            .sidebar-item-image-badge {
                position: relative;
                margin-bottom: 8px;
                .sidebar-item-badge {
                    position: absolute;
                    top: -7px;
                    right: 7px;
                    width: 16px;
                    height: 16px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    color: #000;
                    background-color: #ff7052;
                }
            }
            p {
                margin: 0;
            }
        }
        .disabled-item {
            pointer-events: none;
            opacity: 0.3;
            cursor: unset;
        }
        .active {
            position: relative;
            &::after {
                content: '';
                position: absolute;
                right: 1px;
                top: 5%;
                width: 2px;
                height: 41px;
                background-color: #25fff3;
            }
            p {
                color: #25fff3;
            }
            .sidebar-item-image-badge {
                filter: drop-shadow(0px 0px 8px rgba(0, 255, 199, 0.5));
                svg {
                    path,
                    mask {
                        fill: rgba(37, 255, 243, 1);
                        stroke: rgba(37, 255, 243, 1);
                    }
                }
            }
        }
    }
    .logout-item {
        text-align: center;
        text-transform: uppercase;
        margin-top: 24px;
        padding-top: 24px;
        border-top: 1px solid #112534;
        color: #8aa7b7;
        letter-spacing: 0.04em;
        cursor: pointer;
        img {
            margin-bottom: 8px;
        }
        p {
            margin: 0;
        }
    }
`;

export { StyledSidebar };
