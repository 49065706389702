import React from 'react';

// Helpers
import { data, panels, pages } from 'helpers/episode1/showcase';
// Styled Components
import { MintedAssetsItem } from 'assets/styles/components/Mint/index.styled';
// Images
import PageAssets from 'assets/images/comic/assets/assets-page.png';

export const Item = (props) => {
    const panel = props.tokenId && panels[props.tokenId]?.panel;
    const page = props.tokenId && panels[props.tokenId]?.page;
    const image = panel ? data[pages[page]][props.tokenId].image.minted : PageAssets;

    return (
        <MintedAssetsItem className="item">
            <div className="minted-assets-item-title p-12 font-weight-800">
                {panel ? (
                    <React.Fragment>
                        Panel {panel} <span> - Page {page}</span>
                    </React.Fragment>
                ) : (
                    <React.Fragment>Page {page}</React.Fragment>
                )}
            </div>
            <div className="minted-assets-item-asset">
                <img src={image} alt={`Minted assets ${props.tokenId}`} style={{ width: !panel && 'auto' }} />
            </div>
        </MintedAssetsItem>
    );
};
