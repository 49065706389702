import React, { useEffect } from 'react';

// Libs
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useAddress } from '@thirdweb-dev/react';
// Styled Components
import { AssetsStyled } from 'assets/styles/pages/Assets/index.styled';
// Actions
import { setAssetsLoadingPanels, setAssetsLoadingPages, setAssetsLoadingCovers } from 'store/actions';
// Common Components
import Title from 'components/Common/Title';
// Components
import Panels from 'components/Assets/sections/Panels';
import Pages from 'components/Assets/sections/Pages';
import Covers from 'components/Assets/sections/Covers';
// Helpers
import { scrollTo } from 'helpers/common';

const Assets = () => {
    const address = useAddress();
    const location = useLocation();
    const assets = useSelector((state) => state.Assets);
    const dispatch = useDispatch();
    const assetsPaginationStart = process.env.REACT_APP_ASSETS_PAGINATION_START || 10;

    useEffect(() => {
        if (!assets?.loadingPanels || !assets?.loadingPages || !assets?.loadingCovers) {
            if (location?.state?.scrollTo) {
                scrollTo(location?.state?.scrollTo);
            }
        }
    }, [location.state, assets]);

    useEffect(() => {
        if (assets?.allPanel === null || assets?.allPage === null || assets?.allCover === null) {
            dispatch(setAssetsLoadingPanels());
            dispatch(setAssetsLoadingPages());
            dispatch(setAssetsLoadingCovers());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address]);

    return (
        <AssetsStyled id="assets-content">
            <Title tag="h2" title="ASSETS" textAlign="left" className="font-weight-800" fontSize="display3" />
            <Panels assets={assets?.allPanel} loading={assets?.loadingPanels} pagination={assetsPaginationStart} />
            <Pages assets={assets?.allPage} loading={assets?.loadingPages} pagination={assetsPaginationStart} />
            <Covers assets={assets?.allCover} loading={assets?.loadingCovers} pagination={assetsPaginationStart} />
        </AssetsStyled>
    );
};

export default Assets;
