import React, { useState, useEffect } from 'react';

// Libs
import { useAddress } from '@thirdweb-dev/react';
import Web3 from 'web3';
// Helpers
import { getMethods } from 'helpers/common';
// Provider
import { useMint } from 'providers/MintProvider/provider';
// Common
import Toast from 'components/Common/Toast';
import Loader from 'components/Common/Loader';
// Components
import Mint from './Mint';
import ShowCase from './Showcase';
import MintToast from 'components/Toast/MintToast';
// Styled Components
import { ComicBook } from 'assets/styles/pages/ComicBook/index.styled';

export const Comic = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isMinting, setIsMinting] = useState(false);
    const { isMinted, setIsMinted, minting, mintType, setMinting, setFirstMint } = useMint();
    const [mintedAssets, setMintedAssets] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [mintedCover, setMintedCover] = useState([]);
    // Use the hooks thirdweb give us.
    const address = useAddress();

    useEffect(() => {
        if (address) {
            setMinting(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address]);

    useEffect(() => {
        (async () => {
            try {
                const web3 = new Web3(window.ethereum);
                const { daoToken_contract } = getMethods(web3);

                const daoTokens = await daoToken_contract.methods.balanceOf(web3.currentProvider?.selectedAddress, 0).call();
                if (daoTokens === '0') {
                    setMinting('free');
                }

                setFirstMint(daoTokens === '1');
                setIsLoading(false);
            } catch (error) {
                console.error(error);
            }
        })();
    }, [setIsMinted, setFirstMint, address, setMinting]);

    const mint = async () => {
        const web3 = new Web3(window.ethereum);
        const { factory_contractv2 } = getMethods(web3);

        const tx = factory_contractv2.methods.batchMint(0);
        const estimation = await tx
            .estimateGas({ from: web3.currentProvider?.selectedAddress, value: Web3.utils.toWei('0.02') })
            .catch((error) => {
                Toast.error(<MintToast error message="Insufficient funds" />);
                return error.message.split('\n')[0];
            });

        tx.send({ from: web3.currentProvider?.selectedAddress, gas: estimation + 2e5, value: Web3.utils.toWei('0.02') })
            .on('receipt', function (receipt) {
                if (receipt.status === true) {
                    setIsMinting(false);
                    setIsMinted(true);
                    setMintedAssets(
                        // eslint-disable-next-line array-callback-return
                        receipt.events.Minted.returnValues.items.map((x) => {
                            if (x.startsWith('2')) {
                                const temp = parseInt(x) - 200000000;
                                return temp;
                            }
                            if (x.startsWith('1')) {
                                const temp = parseInt(x) - 100000000;
                                return temp;
                            }
                        })
                    );
                    setMintedCover(
                        // eslint-disable-next-line array-callback-return
                        receipt.events.Minted.returnValues.items.map((x) => {
                            if (x.startsWith('3')) {
                                const temp = parseInt(x) - 300000000;
                                return temp;
                            }
                        })
                    );
                }
            })
            .on('transactionHash', function (transactionHash) {
                setIsMinting(true);
            });
    };
    const freeMint = async () => {
        const web3 = new Web3(window.ethereum);
        const { daoToken_contract, factory_contract } = getMethods(web3);
        const daoTokens = await daoToken_contract.methods.balanceOf(web3.currentProvider?.selectedAddress, 0).call();
        if (daoTokens === '0') {
            const tx = factory_contract.methods.freeMint(0);
            const estimation = await tx.estimateGas({ from: web3.currentProvider?.selectedAddress }).catch((error) => {
                Toast.error(<MintToast error message="Insufficient funds" />);
                return error.message.split('\n')[0];
            });

            tx.send({ from: web3.currentProvider.selectedAddress, gas: estimation })
                .on('receipt', function (receipt) {
                    if (receipt.status === true) {
                        setIsMinting(false);
                        setIsMinted(true);
                        setFirstMint(true);
                        setMintedAssets(receipt.events.FreeMint.returnValues.items.map((x) => parseInt(x[0])));
                    }
                })
                .on('transactionHash', function (transactionHash) {
                    setIsMinting(true);
                });
        }
    };

    return (
        <ComicBook id="comic-content">
            {isLoading === false ? (
                minting === true ? (
                    <Mint
                        isLoading={isLoading}
                        isMinting={isMinting}
                        isMinted={isMinted}
                        action={mintType === 'free' ? freeMint : mint}
                        type={mintType}
                        mintedAssets={mintedAssets}
                    />
                ) : (
                    <ShowCase />
                )
            ) : (
                <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Loader disableFlex={true} />
                </div>
            )}
        </ComicBook>
    );
};
