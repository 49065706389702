import React from 'react';

export const Item = (props) => {
    return (
        <div className="d-flex align-items-baseline roadmap-item">
            <div className={`roadmap-icon ${props.roadMapIconStyle ? props.roadMapIconStyle : ''}`}>
                {props.subtitle && <div className="roadmap-subtitle p-14 font-weight-800">{props.subtitle}</div>}
                {props.icon && <img src={props.icon?.default} alt="Checkmark" />}
            </div>
            <div className="roadmap-image-wrapper">
                {props.image && props.image}
                {props.title && (
                    <div className="p-20 roadmap-title font-weight-700" style={{ color: props.color }}>
                        {props.title}
                    </div>
                )}
                {props.content}
            </div>
        </div>
    );
};
