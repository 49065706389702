import styled from 'styled-components';

import { COLORS } from 'assets/styles/common/Colors.styled';

const HeroBackgroundImage = styled.div`
    width: 100%;
    height: 900px;
    background-image: linear-gradient(180deg, rgba(12, 26, 37, 0) 90.5%, ${COLORS.layout} 100%), url(${(props) => props.image});
    background-repeat: no-repeat;
    background-size: cover;
`;

export { HeroBackgroundImage };
