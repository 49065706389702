import React from 'react';

// Libs
import { Formik } from 'formik';

const Form = ({ initialValues, onSubmit, validationSchema, children }) => {
    return (
        <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
            {children}
        </Formik>
    );
};

export default Form;
