import React from 'react';

export const Item = (props) => {
    return (
        <div className="team-item text-center">
            <img src={props.image} alt={props.name} />
            <div className="team-item-name p-20 font-weight-700">{props.name}</div>
            <div className="team-item-position p-14 font-weight-800">{props.position}</div>
            <div className="team-item-description p-14 font-weight-400">{props.description}</div>
            {props.socials && (
                <div className="team-item-socials">
                    {props.socials.map((social, key) => {
                        return (
                            <a key={key} href={social.href} target="_blank" rel="noreferrer">
                                <img src={social.image} alt="social" className="team-item-social-image" />
                            </a>
                        );
                    })}
                </div>
            )}
        </div>
    );
};
