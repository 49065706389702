import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';

// Components
import Modal from 'components/Common/Modal';
import Toast from 'components/Common/Toast';
import App from './App';
// Styles
import './assets/scss/app.scss';
import 'react-toastify/dist/ReactToastify.css';
// Libs
import { MoralisProvider } from 'react-moralis';
import { ThirdwebProvider } from '@thirdweb-dev/react';
import { Provider } from 'react-redux';
// Provider
import { MoralisDappProvider } from './providers/MoralisDappProvider/provider';
import { MintProvider } from './providers/MintProvider/provider';
import EthPriceContext from 'providers/EthPrice/context';
import { MarketplaceProvider } from 'providers/MarketplaceProvider/provider';
import { DaoTokenProvider } from 'providers/DaoToken/provider';
// Store
import store from 'store';

// priceFetching as singleton
let priceInterval = null;

export const Root = () => {
    let APP_ID;
    let SERVER_URL;

    const [chainId, setChainId] = useState(process.env.REACT_APP_CHAINID);
    const [ethPrice, setEthPrice] = useState(0);

    useEffect(() => {
        async function fetchData() {
            try {
                fetch('https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD').then((response) => {
                    response.json().then((data) => {
                        if (data.Response === 'Error') {
                            setEthPrice(0);
                            return;
                        }
                        setEthPrice(data.USD);
                    });
                });
            } catch (err) {
                console.error('error', err);
                setEthPrice(0);
            }
        }
        fetchData();
        if (priceInterval === null) {
            priceInterval = setInterval(() => {
                fetchData();
            }, 20000);
        }

        return () => {
            clearInterval(priceInterval);
            priceInterval = null;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    window.ethereum?.on('chainChanged', (chainId) => {
        setChainId(chainId);
        window.location.reload();
    });

    switch (chainId) {
        case '0x1':
            APP_ID = 'piAGvwKo23Sa5cLUiQSiGzXlVBaUBlokL7X2kwMQ';
            SERVER_URL = 'https://xytzzk2qsgxv.usemoralis.com:2053/server';
            break;
        case '0x5':
            APP_ID = 'Pms2LOY4zFfwplpkvHZmItkxNXCsL4rX4B7rxs5h';
            SERVER_URL = 'https://xhlhxcazl1kl.grandmoralis.com:2053/server';
            break;
        default:
            break;
    }

    return (
        <React.StrictMode>
            <Provider store={store}>
                <BrowserRouter>
                    <ThirdwebProvider desiredChainId={chainId === '0x1' ? 1 : 5}>
                        <MoralisProvider appId={APP_ID} serverUrl={SERVER_URL}>
                            <MoralisDappProvider>
                                <EthPriceContext.Provider value={ethPrice}>
                                    <MarketplaceProvider>
                                        <MintProvider>
                                            <DaoTokenProvider>
                                                <Toast
                                                    newestOnTop
                                                    theme="colored"
                                                    toastStyle={{ backgroundColor: 'transparent' }}
                                                    closeButton={false}
                                                    position="top-right"
                                                    icon={false}
                                                    hideProgressBar={true}
                                                />
                                                <Modal ethPrice={ethPrice} />
                                                <App />
                                            </DaoTokenProvider>
                                        </MintProvider>
                                    </MarketplaceProvider>
                                </EthPriceContext.Provider>
                            </MoralisDappProvider>
                        </MoralisProvider>
                    </ThirdwebProvider>
                </BrowserRouter>
            </Provider>
        </React.StrictMode>
    );
};

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<Root />);
