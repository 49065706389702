import { SET_FOR_SALE_LISTINGS, SET_MY_LISTINGS, GET_LISTING } from './actionTypes';
import { panels, pages, data } from 'helpers/episode1/showcase';
import { data as dataViewer } from 'helpers/episode1/viewer';
import { ASSETS_TYPE } from 'helpers/data';
import { BigNumber } from 'ethers';

const initialState = {
    forSale: undefined,
    myListings: undefined,
    history: undefined,
    singleListing: undefined,
    loading: false,
    error: undefined
};

const Marketplace = (state = initialState, action) => {
    switch (action.type) {
        case SET_FOR_SALE_LISTINGS: {
            let forSale = [];
            // TODO Optimize data
            action.payload?.forEach((asset) => {
                let avgPrice = 0,
                    lowestAsk = 0,
                    topSale = 0,
                    priceList = [];
                let allAssetsByID = action.payload?.filter(
                    (payload) => BigNumber.from(payload?.tokenId).toNumber() === BigNumber.from(asset?.tokenId).toNumber()
                );

                let reduce = Object.keys(allAssetsByID).reduce(function (previous, key) {
                    return previous + parseFloat(allAssetsByID[key]?.buyoutCurrencyValuePerToken?.displayValue);
                }, 0);

                avgPrice = reduce / allAssetsByID?.length;
                lowestAsk = allAssetsByID?.reduce(function (prev, curr) {
                    return parseFloat(prev?.buyoutCurrencyValuePerToken?.displayValue) <
                        parseFloat(curr?.buyoutCurrencyValuePerToken?.displayValue)
                        ? prev
                        : curr;
                });

                priceList = allAssetsByID?.map((value) => {
                    return {
                        price: value?.buyoutCurrencyValuePerToken?.displayValue,
                        address: value?.sellerAddress,
                        listingID: value?.id
                    };
                });

                if (asset?.asset?.attributes && asset?.asset?.attributes[0]?.value === 'Cover') {
                    forSale.push({
                        sc: asset,
                        id: asset.tokenId,
                        price: asset.buyoutCurrencyValuePerToken,
                        avgPrice,
                        lowestAsk: lowestAsk?.buyoutCurrencyValuePerToken?.displayValue,
                        topSale,
                        priceList,
                        forSale: asset.quantity.toString() || 0,
                        type: ASSETS_TYPE.COVER
                    });
                } else if (!Object.values(pages)?.includes(parseInt(asset['tokenId']))) {
                    forSale.push({
                        sc: asset,
                        id: asset.tokenId,
                        price: asset.buyoutCurrencyValuePerToken,
                        avgPrice,
                        lowestAsk: lowestAsk?.buyoutCurrencyValuePerToken?.displayValue,
                        topSale,
                        forSale: asset.quantity.toString() || 0,
                        priceList,
                        title: `Panel ${panels[asset.tokenId].panel}`,
                        description: `Page ${panels[asset.tokenId].page}`,
                        pageID: panels[asset.tokenId].page,
                        image: data[pages[panels[asset.tokenId].page]][asset.tokenId].image.minted,
                        imageBig: dataViewer[pages[panels[asset.tokenId].page]][asset.tokenId].image.minted,
                        imagePixelized: dataViewer[pages[panels[asset.tokenId].page]][asset.tokenId].image.unMinted.color,
                        type: ASSETS_TYPE.PANEL
                    });
                } else {
                    forSale.push({
                        sc: asset,
                        id: asset.tokenId,
                        price: asset.buyoutCurrencyValuePerToken,
                        lowestAsk: lowestAsk?.buyoutCurrencyValuePerToken?.displayValue,
                        avgPrice,
                        topSale,
                        forSale: asset.quantity.toString() || 0,
                        priceList,
                        title: `Page ${panels[asset.tokenId].page}`,
                        pageID: panels[asset.tokenId].page,
                        episode: 'ISSUE #1',
                        type: ASSETS_TYPE.PAGE
                    });
                }
            });

            state = {
                ...state,
                forSale
            };
            break;
        }
        case SET_MY_LISTINGS: {
            let myListings = [];
            let history = action.payload || [];

            action.payload?.forEach((asset) => {
                if (asset?.asset?.attributes && asset?.asset?.attributes[0]?.value === 'Cover') {
                    myListings.push({
                        sc: asset,
                        id: asset.tokenId,
                        price: asset.buyoutCurrencyValuePerToken,
                        type: ASSETS_TYPE.COVER
                    });
                } else if (!Object.values(pages)?.includes(parseInt(asset['tokenId']))) {
                    myListings.push({
                        sc: asset,
                        id: asset.tokenId,
                        price: asset.buyoutCurrencyValuePerToken,
                        title: `Panel ${panels[asset.tokenId].panel}`,
                        description: `Page ${panels[asset.tokenId].page}`,
                        image: data[pages[panels[asset.tokenId].page]][asset.tokenId].image.minted,
                        imageBig: dataViewer[pages[panels[asset.tokenId].page]][asset.tokenId].image.minted,
                        imagePixelized: dataViewer[pages[panels[asset.tokenId].page]][asset.tokenId].image.unMinted.color,
                        type: ASSETS_TYPE.PANEL
                    });
                } else {
                    myListings.push({
                        sc: asset,
                        id: asset.tokenId,
                        price: asset.buyoutCurrencyValuePerToken,
                        title: `Page ${panels[asset.tokenId].page}`,
                        pageID: panels[asset.tokenId].page,
                        episode: 'ISSUE #1',
                        type: ASSETS_TYPE.PAGE
                    });
                }
            });

            state = {
                ...state,
                myListings,
                history
            };
            break;
        }

        case GET_LISTING: {
            let singleListing = state.forSale.find((listing) => {
                return listing.id === parseInt(action.payload);
            });

            state = {
                ...state,
                singleListing
            };
            break;
        }
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default Marketplace;
