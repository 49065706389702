import React, { useState, useEffect } from 'react';

const Radio = (props) => {
    const [isChecked, setIsChecked] = useState(props.defaultChecked);

    const onChange = (e) => {
        if (props.onChange) {
            props.onChange(e.target.checked);
        }
        setIsChecked(e.target.checked);
    };

    useEffect(() => {
        setIsChecked(props.defaultChecked);
    }, [props.defaultChecked]);

    return (
        <label className={`radio-field${props.className ? ` ${props.className}` : ''}`} style={props.style}>
            <p className="mb-0 font-weight-800 p-12"> {props.label}</p>
            <input
                name={props.name}
                type="radio"
                disabled={props.disabled}
                onChange={onChange}
                defaultChecked={isChecked}
                value={isChecked}
                placeholder={props.placeholder}
            />
            <span className={`checkmark-radio${isChecked ? ' checked' : ''}`}></span>
        </label>
    );
};

export default Radio;
