import React from 'react';
import { useNavigate } from 'react-router-dom';

// Icons
import SuccessIcon from 'assets/images/toast/success.svg';

const BuyAssetToast = (props) => {
    let navigate = useNavigate();
    //  const message = 'Panel 3 from Page 6';
    //  const message = 'Page 6';
    //  const message = 'Cover #1;

    return (
        <div className="toast-message-container">
            <div className="toast-message">
                {props.success && (
                    <div className="message-success-sell">
                        <span>Well done!</span> <span>{`${props.message} has been purchased and transferred to`}</span>{' '}
                        <span
                            onClick={() => {
                                navigate('/assets', { replace: true });
                            }}>
                            your assets
                        </span>
                        .
                    </div>
                )}
            </div>
            <div className="toast-icon t-success">
                <img src={SuccessIcon} alt="Success Icon" />
            </div>
        </div>
    );
};

export default BuyAssetToast;
