import React from 'react';

// Libs
import Media from 'react-media';
// Components
import Description from 'components/Common/Description';
import Title from 'components/Common/Title';

const Rewards = () => {
    return (
        <Media queries={{ xs: '(max-width: 768px)' }}>
            {(matches) => {
                return (
                    <section className="rewards-container">
                        <div className="rewards-content">
                            <Title
                                title={
                                    matches.xs ? (
                                        <>
                                            Interactive <br /> world-
                                            <br />
                                            building
                                            <br /> with RPG-
                                            <br />
                                            lite <br /> mechanics
                                        </>
                                    ) : (
                                        'Interactive worldbuilding with RPG-lite mechanics'
                                    )
                                }
                                fontSize="display2"
                                tag="h2"
                            />
                            <Description gradient tag="p" fontSize="24" className="dao-description">
                                After each successfully assembled comic book issue, you get an airdrop with NFT character, location, and
                                item, which you can craft and merge into a plot of a new and unique spin-off webcomic, specially created for
                                you.
                            </Description>
                        </div>
                    </section>
                );
            }}
        </Media>
    );
};

export default Rewards;
