import React from 'react';

// Libs
import Media from 'react-media';
// Components
import { RoadmapDesktop } from './Roadmap';
import { RoadmapMobile } from './Mobile';

const Roadmap = () => {
    return (
        <Media queries={{ sm: '(max-width: 991px)' }}>
            {(matches) => {
                if (matches.sm) {
                    return <RoadmapMobile />;
                } else {
                    return <RoadmapDesktop />;
                }
            }}
        </Media>
    );
};

export default Roadmap;
