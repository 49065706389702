import React, { useEffect, useState } from 'react';
import { useAddress } from '@thirdweb-dev/react';
import Web3 from 'web3';

// Helpers
import { getMethods } from 'helpers/common';
// Providers
import DaoTokenContext from './context';
import { useMint } from 'providers/MintProvider/provider';

const DaoTokenProvider = ({ children }) => {
    const [daoToken, _setDaoToken] = useState('');
    const address = useAddress();
    const { firstMint } = useMint();

    useEffect(() => {
        if (window.ethereum) {
            const web3 = new Web3(window.ethereum);
            (async () => {
                if (web3.currentProvider?.selectedAddress) {
                    const { daoToken_contract } = getMethods(web3);
                    const dao = await daoToken_contract.methods.balanceOf(web3.currentProvider?.selectedAddress, 0).call();
                    _setDaoToken(dao);
                }
            })();
        }
    }, [address, daoToken, firstMint]);

    return <DaoTokenContext.Provider value={{ daoToken }}>{children}</DaoTokenContext.Provider>;
};

const useDaoToken = () => {
    const context = React.useContext(DaoTokenContext);

    if (context === undefined) {
        throw new Error('useDaoToken must be used within a DaoTokenProvider');
    }

    return context;
};

export { DaoTokenProvider, useDaoToken };
