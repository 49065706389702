import styled from 'styled-components';

import { COLORS } from 'assets/styles/common/Colors.styled';

export const InputContainer = styled.div`
    position: relative;
    width: calc(100% - 10px);
    border-radius: 12px;
    transform: skewX(-20deg);
    background-color: ${COLORS.input};
    padding: 15px 110px 15px 22px;
    border: ${(props) => (props.error ? `1px solid ${COLORS.red}` : '1px solid transparent')};
    label {
        position: absolute;
        font-size: 16px;
        line-height: 20px;
        left: 22px;
        top: 18px;
        color: ${COLORS.blueLight};
        cursor: text;
        transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms ease-in;
        transform: skewX(20deg);
    }

    .input:focus ~ .label,
    .input:not(:placeholder-shown).input:not(:focus) ~ .label {
        font-size: 10px;
        line-height: 10px;
        font-weight: 600;
        top: 8px;
        left: 15px;
        transform: skewX(20deg);
    }

    .input:focus,
    .input:not(:placeholder-shown).input:not(:focus) {
        top: 5px;
        position: relative;
    }
    input {
        width: 100%;
        background-color: transparent;
        color: ${COLORS.white};
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
        border: none;
        transform: skewX(20deg);
        &:focus {
            caret-color: ${COLORS.neonGreen};
            color: ${COLORS.assetsBorderColor};
        }
    }
    .input-status {
        color: ${COLORS.MintPriceLabel};
        position: absolute;
        right: 27px;
        top: 18px;
        transform: skewX(20deg);
    }
`;
