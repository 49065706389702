import { SET_FOR_SALE_LISTINGS, SET_MY_LISTINGS, GET_LISTING } from './actionTypes';

export const setForSaleListings = (payload) => {
    return {
        type: SET_FOR_SALE_LISTINGS,
        payload
    };
};

export const setMyListings = (payload) => {
    return {
        type: SET_MY_LISTINGS,
        payload
    };
};

export const getListing = (payload) => {
    return {
        type: GET_LISTING,
        payload
    };
};
