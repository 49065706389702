import React, { Component } from 'react';

// Helpers
import { withRouter } from 'helpers/common';
// Providers
import DiscordContext from 'providers/DiscordTracking/context';
// Components
import Header from 'components/Layout/Landing/Header';
import Footer from 'components/Layout/Landing/Footer';

// Influencers Map
const invites = {
    PpPU9Vdn: 'https://discord.gg/invite/PpPU9Vdn',
    '3URGHuu25c': 'https://discord.gg/3URGHuu25c'
};
const getInvite = (invite) => {
    return invite && invites[invite] ? invites[invite] : 'https://discord.gg/4FFWBaDvSJ';
};

class NonConnectLayout extends Component {
    constructor(props) {
        super(props);
        this.state = { link: getInvite(props?.id) };
    }
    componentDidMount() {
        if (this.props.cookies && !this.props.cookies['inviteCode']) {
            if (this.props.id && invites[this.props.id]) {
                this.props.setCookie('inviteCode', this.props.id, { maxAge: 60 * 24 * 60 * 60 });
            }
        }
    }
    render() {
        return (
            <DiscordContext.Provider value={this.state}>
                <Header />
                {this.props.children}
                <Footer />
            </DiscordContext.Provider>
        );
    }
}

export default withRouter(NonConnectLayout);
