import React from 'react';
import DiscordContext from './context';

function useDiscordTracking() {
    const context = React.useContext(DiscordContext);
    if (context === undefined) {
        throw new Error('useDiscordTracking must be used within a DiscordContext');
    }
    return context;
}

export { useDiscordTracking };
