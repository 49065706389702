import React from 'react';
//styled
import { AssetRewardCardStyled } from 'assets/styles/components/AssetRewardCard/index.styled';

/**
 * Asset Reward Card
 * * Component for single asset reward
 * * Icons and text color depends of completed status (true/false)
 * @param img Display asses Icon
 * @param title Display title
 * @param subtitle Display subtitle
 * @param lock Display lock icon on top-right corner
 */

export const AssetRewardCard = (props) => {
    return (
        <AssetRewardCardStyled {...props}>
            <img className="asset-icon" src={props.img} alt="Assets Icon" />
            <p className="p-16 font-weight-700">{props.title}</p>
            <p className="p-12 font-weight-800 asset-subtitle">{props.subtitle}</p>
            <img className="asset-lock" src={props.lock} alt="Lock Icon" />
        </AssetRewardCardStyled>
    );
};
