import React from 'react';

// Styled components
import { AssetWrapperStyle, AssetStyle, AssetImageWrapper } from 'assets/styles/components/Header/DropDown/Asset.styled';
// Images
import MissingElement from 'assets/images/comic/header/assets/missing-cover.svg';

/**
 * Asset
 * * Component for display single asset in header dropdown
 * @param {imageURL, title} props
 */

const Asset = (props) => {
    return (
        <AssetWrapperStyle
            onClick={(e) => {
                if (props.disabled) {
                    e.stopPropagation();
                    e.preventDefault();
                }
            }}
            disabled={props.disabled}>
            <AssetStyle disabled={props.disabled}>
                {props.coverDetails && Object.keys(props.coverDetails).length ? (
                    <video
                        type="video/mp4"
                        width={'100%'}
                        height={'100%'}
                        src={props.coverDetails?.animation_url}
                        autoPlay={true}
                        controls={false}
                        alt={props.coverDetails?.name}
                        loop={true}
                        muted={true}
                    />
                ) : (
                    <AssetImageWrapper className="asset-image-wrapper" imageURL={props.imageURL || MissingElement} />
                )}
            </AssetStyle>
            <div className="asset-title">{props.title}</div>
            {props.title === 'Issue 2' && <div className="asset-title-coming">coming in oct</div>}
        </AssetWrapperStyle>
    );
};

export default Asset;
