import React, { Component } from 'react';
// Libs
import Media from 'react-media';
// Components
import Spinner from 'components/Common/Spinner';
import Button from 'components/Common/Button';
import Loader from 'components/Common/Loader';
// Icons
import BackButtonIcon from 'assets/images/svg/arrow-back-blue.svg';
import CloseButtonIcon from 'assets/images/svg/close.svg';

class Modal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            loading: this.props.loading || false,
            options: {}
        };
        Modal.show = this.show.bind(this);
        Modal.hide = this.hide.bind(this);

        Modal.message = this.message.bind(this);
        Modal.closeMessage = this.closeMessage.bind(this);
        Modal.options = this.options.bind(this);

        Modal.header = this.header.bind(this);
        Modal.description = this.description.bind(this);
        Modal.content = this.content.bind(this);
        Modal.footer = this.footer.bind(this);

        Modal.modalStyle = this.modalStyle.bind(this);
        Modal.headerStyle = this.headerStyle.bind(this);
        Modal.descriptionStyle = this.descriptionStyle.bind(this);
        Modal.contentStyle = this.contentStyle.bind(this);
        Modal.footerStyle = this.footerStyle.bind(this);
        Modal.setLoadingStyle = this.setLoadingStyle.bind(this);
        Modal.overlayStyle = this.overlayStyle.bind(this);

        Modal.setLoading = this.setLoading.bind(this);
        Modal.instance = this;

        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this._hide = this._hide.bind(this);
    }
    header(title) {
        this.setState({
            title
        });
    }
    description(description) {
        this.setState({
            description
        });
    }
    content(content) {
        this.setState({
            content
        });
    }
    footer(footer) {
        this.setState({
            footer
        });
    }
    modalStyle(style) {
        this.setState({
            modalStyle: style
        });
    }
    headerStyle(style) {
        this.setState({
            headerStyle: style
        });
    }
    descriptionStyle(style) {
        this.setState({
            descriptionStyle: style
        });
    }
    contentStyle(style) {
        this.setState({
            contentStyle: style
        });
    }
    footerStyle(style) {
        this.setState({
            footerStyle: style
        });
    }
    setLoadingStyle(style) {
        this.setState({
            setLoadingStyle: style
        });
    }
    overlayStyle(style) {
        this.setState({
            overlayStyle: style
        });
    }
    message(content) {
        this.setState({ messageContent: content });
    }
    closeMessage(e) {
        this.setState({ messageContent: undefined });
    }
    options(o) {
        this.setState({ options: o });
    }
    show(title = undefined, desription = undefined, content = undefined, footer = undefined, options = {}) {
        this.setState({
            visible: true,
            content,
            title,
            desription,
            footer,
            modalStyle: undefined,
            headerStyle: undefined,
            descriptionStyle: undefined,
            contentStyle: undefined,
            footerStyle: undefined,
            setLoadingStyle: undefined,
            messageContent: undefined,
            options: options
        });
    }
    _hide(e) {
        if (this.state.options.disableHideOnBlur) {
            return;
        }
        if (!this.state.options.forced && e.target.id === 'comic-modal-wrapper') {
            if (this.state.options.stopHide) {
                this.closeMessage();
            } else {
                this.hide(e);
            }
        }
        if (e.target.id === 'comic-modal-message-wrapper') {
            this.closeMessage();
        }
    }
    hide(e) {
        if (e) {
            if (e.target) {
                if (e.target.id === 'comic-modal-wrapper' || e.target.id === 'comic-modal-exit') {
                    if (this.state.forced) {
                        return;
                    }
                    document.body.style.overflow = 'auto';

                    this.setState({
                        visible: false,
                        content: undefined,
                        title: undefined,
                        desription: undefined,
                        footer: undefined,
                        loading: false,
                        modalStyle: undefined,
                        headerStyle: undefined,
                        descriptionStyle: undefined,
                        contentStyle: undefined,
                        footerStyle: undefined,
                        setLoadingStyle: undefined,
                        messageContent: undefined,
                        options: {}
                    });
                }
            }
        } else {
            document.body.style.overflow = 'auto';
            this.setState({
                visible: false,
                content: undefined,
                title: undefined,
                desription: undefined,
                footer: undefined,
                loading: false,
                modalStyle: undefined,
                headerStyle: undefined,
                descriptionStyle: undefined,
                contentStyle: undefined,
                footerStyle: undefined,
                setLoadingStyle: undefined,
                messageContent: undefined,
                options: {}
            });
        }
    }
    setLoading(loading) {
        this.setState({ loading });
    }
    render() {
        return (
            <Media queries={{ small: '(max-width: 1px)' }}>
                {(matches) => {
                    const mobile = this.state.options.mobile;
                    if (this.state.visible) {
                        document.body.style.overflow = 'hidden';
                    }
                    return (
                        <div
                            onClick={this._hide}
                            id={`${this.state.messageContent ? 'comic-modal-message-wrapper' : 'comic-modal-wrapper'}`}
                            className={`comic-modal-wrapper scrollBar ${this.state.visible ? 'visible' : ''}`}
                            style={this.state.overlayStyle}>
                            <div
                                className={`comic-modal-container ${mobile ? 'mobile' : ''}`}
                                style={{ display: this.state.messageContent ? 'none' : 'block' }}>
                                {!this.state.options.forced && !this.state.options.hideClose && (
                                    <div className={`comic-modal-button ${mobile ? 'mobile' : ''}`}>
                                        {this.state.options.hasBack && (
                                            <Button className="comic-modal-back" small onClick={this.hide} outline>
                                                <img src={BackButtonIcon} alt="Back button" />
                                                BACK
                                            </Button>
                                        )}
                                        {this.state.options.hasClose && (
                                            <Button
                                                small
                                                onClick={() => this.hide()}
                                                id="comic-modal-exit"
                                                className="comic-modal-exit"
                                                outline>
                                                <img src={CloseButtonIcon} alt="Close button" />
                                            </Button>
                                        )}
                                    </div>
                                )}
                                <div
                                    className={`comic-modal${this.state.options.bgColor ? ' bg-color' : ''} ${
                                        this.state.options.bgImage ? ' bg-image' : ''
                                    } ${mobile ? 'mobile' : ''}`}
                                    style={this.state.modalStyle}>
                                    {this.state.loading && (
                                        <div className="comic-modal-loading-screen" style={this.state.setLoadingStyle}>
                                            <Spinner show={this.state.loading}>
                                                <Loader />
                                            </Spinner>
                                        </div>
                                    )}

                                    {this.state.title && (
                                        <div className={`comic-modal-header h2-caps`} style={this.state.headerStyle}>
                                            {this.state.title}
                                        </div>
                                    )}
                                    {this.state.desription && (
                                        <div className={`comic-modal-description`} style={this.state.descriptionStyle}>
                                            {this.state.desription}
                                        </div>
                                    )}
                                    <div
                                        className={`comic-modal-content ${this.state.footer ? 'hasFooter' : ''}`}
                                        style={this.state.contentStyle}>
                                        {this.state.content}
                                    </div>
                                    {this.state.footer && (
                                        <div className={`comic-modal-footer`} style={this.state.footerStyle}>
                                            {this.state.footer}
                                        </div>
                                    )}
                                </div>
                            </div>
                            {this.state.messageContent && this.state.messageContent}
                        </div>
                    );
                }}
            </Media>
        );
    }
}

export default Modal;
