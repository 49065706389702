import styled from 'styled-components';

import { COLORS } from 'assets/styles/common/Colors.styled';

const AssetWrapperStyle = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 51px;
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
    .asset-title {
        margin-top: 10px;
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
        padding-left: 5px;
        color: ${(props) => (props.disabled ? COLORS.blueLight : COLORS.white)};
    }
    .asset-title-coming {
        text-transform: uppercase;
        color: ${COLORS.red};
        margin-top: 3px;
        font-size: 10px;
        font-weight: 800;
        margin-left: 6px;
    }
`;

const AssetStyle = styled.div`
    width: 88px;
    height: 118px;
    padding: 3px;
    border: 1px solid ${(props) => (props.disabled ? COLORS.progressBarBackground : COLORS.assetsBorderColor)};
    box-shadow: 0px 0px 10px ${(props) => (props.disabled ? 'transparent' : 'rgb(0 255 199 / 30%)')};
    border-radius: 8px;
    background-color: ${(props) => (props.disabled ? 'transparent' : COLORS.assetsBackground)};
`;

const AssetImageWrapper = styled.div`
    height: 100%;
    border-radius: 8px;
    background-image: url(${(p) => p.imageURL});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
`;

export { AssetStyle, AssetWrapperStyle, AssetImageWrapper };
