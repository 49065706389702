import React from 'react';
// Icons
import SuccessIcon from 'assets/images/toast/success.svg';

const UpdateListingToast = (props) => {
    return (
        <div className="toast-message-container">
            <div className="toast-message">
                {props.success && (
                    <div className="message-success-sell">
                        <span>All good!</span> <span>Your asset price is now updated on</span> <span>My listings</span>
                    </div>
                )}
            </div>
            <div className="toast-icon t-success">
                <img src={SuccessIcon} alt="Success Icon" />
            </div>
        </div>
    );
};

export default UpdateListingToast;
