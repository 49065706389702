import React from 'react';

// Components
import Title from 'components/Common/Title';
import { Item } from './item';

// Images
import { ReactComponent as CheckmarkIcon } from 'assets/images/landing/roadmap/ico-checkmark.svg';

// Helpers
import { ROADMAP_ITEMS } from 'helpers/data';

export const RoadmapDesktop = () => {
    return (
        <section className="roadmap-container" id="roadmap">
            <Title title="Roadmap" fontSize="display2" tag="h2" />
            <div className="roadmap-wrapper">
                <div className="d-flex roadmap-content">
                    {ROADMAP_ITEMS.map((item, key) => (
                        <Item
                            key={key}
                            title={item.title}
                            subtitle={item.subtitleDesk || item.subtitle}
                            image={item.image}
                            content={item.content['desktop']}
                        />
                    ))}
                </div>
                <div className="roadmap-border-wrapper">
                    <div>
                        <CheckmarkIcon />
                        <div className="roadmap-border"></div>
                    </div>
                </div>
            </div>
        </section>
    );
};
