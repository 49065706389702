import React, { useState } from 'react';

import MintContext from './context';

function MintProvider({ children }) {
    const [isMinted, setIsMinted] = useState(false);
    const [firstMint, setFirstMint] = useState(false);
    const [mintType, setMintType] = useState('');
    const [minting, _setMinting] = useState(false);
    const [showcase, _setShowcase] = useState(false);

    const setShowcase = (props) => {
        _setShowcase(props);
    };

    const setMinting = (props, page) => {
        if (typeof props === 'boolean') {
            setMintType('');
            setIsMinted(false);
            _setMinting(props);
        } else {
            setMintType(props);
            setIsMinted(false);
            _setMinting(true);
        }
    };
    return (
        <MintContext.Provider
            value={{
                showcase,
                setShowcase,
                isMinted,
                setIsMinted,
                minting,
                mintType,
                setMinting,
                firstMint,
                setFirstMint
            }}>
            {children}
        </MintContext.Provider>
    );
}

function useMint() {
    const context = React.useContext(MintContext);

    if (context === undefined) {
        throw new Error('useMint must be used within a MintContext');
    }

    return context;
}

export { MintProvider, useMint };
