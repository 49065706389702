import React, { useEffect, useState } from 'react';

// Libs
import { Link, NavLink } from 'react-router-dom';
import { useAddress } from '@thirdweb-dev/react';
import { useDispatch, useSelector } from 'react-redux';
import Web3 from 'web3';
// Helpers
import { getCoverMetadata, withRouter } from 'helpers/common';
import { DAO_HEADER_ITEMS } from 'helpers/data';
// Styled Components
import { StyledHeader, MiddleHeaderContent, AssetsStatus, Nav } from 'assets/styles/components/Header/index.styled';
import { DropDownStyled, DropDownStyledWrapper } from 'assets/styles/components/Header/DropDown/index.style';
// Components
import Button from 'components/Common/Button';
import { ProgressBar } from 'components/Layout/Header/ProgressBar';
import Asset from 'components/Layout/Header/DropDown/Asset';
// Actions
import { setAssetsPages, setAssetsPanels, setAssetsCovers } from 'store/actions';
// Images
import Logo from 'assets/images/brand/logo.svg';
import PagesIcon from 'assets/images/comic/header/pages.svg';
import PanelsIcon from 'assets/images/comic/header/panels.svg';
import CoverIcon from 'assets/images/comic/header/cover.svg';
import DropDownIcon from 'assets/images/comic/header/ico-dropdown.svg';
import DropDownIconActive from 'assets/images/comic/header/ico-dropdown-active.svg';
import PlusIcon from 'assets/images/comic/header/ico-plus.svg';
import PlusIconDisabled from 'assets/images/comic/header/ico-plus-disabled.svg';
// Providers
import { useMint } from 'providers/MintProvider/provider';
import { useMoralisDapp } from 'providers/MoralisDappProvider/provider';
import { useDaoToken } from 'providers/DaoToken/provider';
// abi
import ERC1155 from 'assets/contracts/ERC1155.json';
import ERC721 from 'assets/contracts/ERC721.json';

/**
 * Header
 * * Header Component when wallet is connected
 * @param {*} props
 */
const Header = (props) => {
    const address = useAddress();
    const { setMinting, isMinted } = useMint();
    const { chainId } = useMoralisDapp();
    const dispatch = useDispatch();

    const [isExpand, setIsExpand] = useState(false);
    const [assetsAmount, setAssetsAmount] = useState([]);
    const [data, setData] = useState({});
    const [covers, setCovers] = useState([]);

    //  Get DaoToken
    const { daoToken } = useDaoToken();

    const web3 = new Web3(
        new Web3.providers.HttpProvider(`https://${process.env.REACT_APP_CHAIN}.infura.io/v3/${process.env.REACT_APP_INFURA_API_KEY}`)
    );

    // store
    const assets = useSelector((state) => state.Assets);
    // Assets numbers
    const collectedUniqueAssets = assetsAmount.length;
    const numberOfAssets = 195;

    useEffect(() => {
        (async () => {
            const token_addresses_nfts =
                chainId === '0x1' ? '0xe198ff500aceeea02f3ab1da993ad819c11c4397' : '0xA14E690904a13Dc5C1Fcfa2BBb42200EC98073a3';
            const token_addresses_cover =
                chainId === '0x1' ? '0x652EA88992D1e199A87cC3D00760b8B4B27ee173' : '0x26A9Bd79070421a7D83011d0CFaC2e4C13c43FF5';

            const myContractNfts = new web3.eth.Contract(ERC1155.abi, token_addresses_nfts);
            const myContractCovers = new web3.eth.Contract(ERC721.abi, token_addresses_cover);

            // Get NFTs
            myContractNfts.getPastEvents(
                'TransferSingle',
                {
                    filter: { to: address },
                    fromBlock: 0,
                    toBlock: 'latest'
                },
                function (error, nfts) {
                    const data = nfts.reduce((acc, nft) => {
                        let found = acc.findIndex((x) => x.token_id === nft.returnValues.id);
                        if (found < 0) {
                            acc.push({ token_id: nft.returnValues.id, amount: parseFloat(nft.returnValues.value) });
                        } else {
                            acc[found].amount += parseFloat(nft.returnValues.value);
                        }
                        return acc;
                    }, []);

                    setAssetsAmount(data);
                }
            );

            // Get Covers
            myContractCovers.getPastEvents(
                'Transfer',
                {
                    filter: { to: address },
                    fromBlock: 0,
                    toBlock: 'latest'
                },
                function (error, covers) {
                    const data = covers.reduce((acc, cover) => {
                        acc.push({ token_id: cover.returnValues.tokenId, amount: '1' });
                        return acc;
                    }, []);
                    setCovers(data);
                }
            );
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address, chainId, isMinted]);

    useEffect(() => {
        const completed = covers.length ? covers[covers.length - 1] : false;

        (async () => {
            try {
                const data = await getCoverMetadata(chainId, completed.token_id);
                setData(data);
            } catch (err) {
                console.log(err);
            }
        })();
    }, [address, covers, chainId]);

    const ButtonClickHandler = () => {
        setTimeout(() => {
            setMinting('21');
        }, 10);
        if (props.location !== '/comic') {
            props.history('/comic');
        }
    };

    useEffect(() => {
        dispatch(setAssetsPages(assetsAmount));
        dispatch(setAssetsPanels(assetsAmount));
    }, [assetsAmount, dispatch, address]);

    useEffect(() => {
        dispatch(setAssetsCovers(covers));
    }, [covers, dispatch, address]);

    return (
        <>
            <StyledHeader isExpand={isExpand}>
                <Link
                    to="/comic"
                    onClick={() => {
                        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                    }}>
                    <img src={Logo} alt="logo" />
                </Link>
                {props.location?.pathname?.includes('/dao') ? (
                    <Nav>
                        {DAO_HEADER_ITEMS?.map((item, key) => (
                            <NavLink className="dao-navigation-link font-weight-800" to={item?.link} key={key}>
                                {item?.label}
                            </NavLink>
                        ))}
                    </Nav>
                ) : (
                    <MiddleHeaderContent isExpand>
                        <div
                            onClick={() => setIsExpand(!isExpand)}
                            id="dropdown"
                            className={`header-dropdown-wrapper${isExpand ? ' active' : ''}`}>
                            <p className="p-14 font-weight-700">2142 - Issue 1</p>
                            <img
                                className={`p-12${isExpand ? ' active-icon' : ''}`}
                                src={isExpand ? DropDownIconActive : DropDownIcon}
                                alt="dropdown icon"
                            />
                        </div>
                        <div className="progress-bar-wrapper">
                            <p className="progress-bar-label p-12 font-weight-800">NFT ASSEMBLY</p>
                            <ProgressBar
                                className="progress-bar-header"
                                collected={collectedUniqueAssets}
                                maxAssets={numberOfAssets}></ProgressBar>
                        </div>
                        <div className="number-of-assets-wrapper">
                            <AssetsStatus
                                className="cursor-pointer"
                                onClick={() =>
                                    props.history('/assets', {
                                        replace: true,
                                        state: { scrollTo: 'pages' }
                                    })
                                }>
                                <img src={PagesIcon} alt="Pages icon" />
                                <div className="number-of-assets">
                                    <p className="number p-12 font-weight-800">
                                        {assets?.allPage?.length}{' '}
                                        <span
                                            style={{
                                                color: assets?.allPage?.length === 34 ? '#fff' : '#68899A'
                                            }}>
                                            / 34
                                        </span>
                                    </p>
                                    <p className="desc p-10 font-weight-600">PAGES</p>
                                </div>
                            </AssetsStatus>
                            <AssetsStatus
                                className="cursor-pointer"
                                onClick={() =>
                                    props.history('/assets', {
                                        replace: true,
                                        state: { scrollTo: 'panels' }
                                    })
                                }>
                                <img src={PanelsIcon} alt="Panels icon" />
                                <div className="number-of-assets">
                                    <p className="number p-12 font-weight-800">
                                        {assets?.allPanel?.length}{' '}
                                        <span
                                            style={{
                                                color: assets?.allPanel?.length === 161 ? '#fff' : '#68899A'
                                            }}>
                                            / 161
                                        </span>
                                    </p>
                                    <p className="desc p-10 font-weight-600">PANELS</p>
                                </div>
                            </AssetsStatus>
                            <AssetsStatus
                                className="cursor-pointer"
                                onClick={() =>
                                    props.history('/assets', {
                                        replace: true,
                                        state: { scrollTo: 'covers' }
                                    })
                                }>
                                <img src={CoverIcon} alt="Cover icon" />
                                <div className="number-of-assets">
                                    <p className="number p-12 font-weight-800">{assets?.allCover?.length}</p>
                                    <p className="desc p-10 font-weight-600">COVERS</p>
                                </div>
                            </AssetsStatus>
                        </div>
                    </MiddleHeaderContent>
                )}

                <Button onClick={ButtonClickHandler} disabled={daoToken === '0'} className="header-minting-button" outline small>
                    <img src={daoToken === '0' ? PlusIconDisabled : PlusIcon} alt="Mint assets" />
                    <span>MINT ASSETS</span>
                </Button>
            </StyledHeader>
            {isExpand && (
                <DropDownStyledWrapper
                    onClick={() => {
                        setIsExpand(false);
                    }}
                />
            )}
            <DropDownStyled
                onClick={() => {
                    setIsExpand(false);
                }}
                className={isExpand ? 'classAdd' : ''}
                isExpand>
                {[
                    { title: 'Issue 1', coverDetails: data },
                    { title: 'Issue 2', disabled: true }
                ]?.map((asset, key) => (
                    <Asset {...asset} key={key}></Asset>
                ))}
            </DropDownStyled>
        </>
    );
};

export default withRouter(Header);
