import React from 'react';

/**
 * Description
 * * Component for description
 * @param tag HTML tag
 * @param title Display text
 * @param textAlign Text alignment
 * @param fontSize Class for font-size
 * @param className Custom classes
 * @param style Inline style
 * @param gradient Property to set text color gradient
 * @param children Content
 */
const Description = (props) => {
    return (
        <props.tag
            className={`description-main font-weight-400 text-${props.textAlign ? props.textAlign : 'center'} p-${
                props.fontSize ? props.fontSize : '20'
            }${props.gradient ? ' gradient' : ''}${props.className ? ` ${props.className}` : ''}`}
            style={{ ...props.style }}>
            {props.children}
        </props.tag>
    );
};

export default Description;
