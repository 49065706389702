import React, { useState } from 'react';

// Libs
import Masonry from 'react-masonry-component';
// Helpers
import { ASSETS_TYPE } from 'helpers/data';
import { withRouter } from 'helpers/common';
// Components
import Checkbox from 'components/Common/Checkbox';
import Loader from 'components/Common/Loader';
import Card from './Card';
// Styled Components
import { AssetsSectionContainer, AssetsSectionMasonry } from 'assets/styles/components/Assets/index.styled';

const Panels = (props) => {
    // States
    const [showDuplicates, setShowDuplicates] = useState(false);
    const [numberOfShowenAssets, setNumberOfShowenAssets] = useState(props.pagination);
    const [disableShowAssets, setDisableShowAssets] = useState(false);

    return (
        <AssetsSectionContainer id="panels" marginHeader={disableShowAssets}>
            <div className="assets-header">
                <div className="assets-title font-weight-600">
                    <span>Panels</span> <span>({props.assets?.length ? props.assets?.length : 0})</span>
                </div>
                <Checkbox
                    name="duplicates-panels"
                    label="Show only duplicates"
                    defaultChecked={showDuplicates}
                    onChange={() => {
                        setShowDuplicates(!showDuplicates);
                        setNumberOfShowenAssets(props.pagination);
                        setDisableShowAssets(false);
                    }}
                />
            </div>
            {props.loading ? (
                <div className="assets-loading-wrapper">
                    <Loader className={'assets-loading'} />
                </div>
            ) : props.assets?.length ? (
                <AssetsSectionMasonry checkOverflow={disableShowAssets} className="scrollBar">
                    <Masonry
                        className="assets-masonry"
                        options={{
                            columnWidth: 176,
                            transitionDuration: 0,
                            gutter: 32,
                            resize: true
                        }}>
                        {props.assets?.slice(0, numberOfShowenAssets).map((asset, key) => {
                            if (showDuplicates && parseInt(asset.amount) <= 1) {
                                return <React.Fragment key={key}></React.Fragment>;
                            }

                            return <Card history={props.history} type={ASSETS_TYPE.PANEL} asset={asset} key={key} />;
                        })}
                    </Masonry>
                </AssetsSectionMasonry>
            ) : (
                <div className="assets-no-minted p-16 font-weight-600">No panels</div>
            )}

            {props.assets && props.assets?.length && props.assets?.length > 10 && !props.loading ? (
                <div
                    className={`assets-btn p-12 font-weight-800${disableShowAssets ? ' disabled-show-assets' : ''}`}
                    onClick={() => {
                        setNumberOfShowenAssets(props.assets?.length);
                        setDisableShowAssets(true);
                    }}>
                    <span>SHOW ALL PANELS</span>
                </div>
            ) : (
                <></>
            )}
        </AssetsSectionContainer>
    );
};

export default withRouter(Panels);
