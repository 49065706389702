import React from 'react';

// Components
import Title from 'components/Common/Title';
import Description from 'components/Common/Description';

const Story = () => {
    return (
        <section id="story" className="story-container">
            <div className="story-content">
                <div className="story-title">
                    <Title
                        title="Unveil the future hidden behind the mortal coil of the mundane world."
                        fontSize={'display2'}
                        tag="h2"
                        className="story-title-text"
                    />
                </div>
                <div className="text-center story-video">
                    <iframe
                        width="100%"
                        height="100%"
                        src="https://www.youtube.com/embed/8BCupMsUeRY"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
                </div>
                <Description tag="p" className="story-description">
                    As the world lies in ruins, Metaverse is the opium of the masses, and the last Bitcoin has been mined to shake the
                    foundations of a known world, three troubled humans and one strained AI get trapped into hideous astral conflict at the
                    end of the great cosmic cycle.
                </Description>

                <div className="story-description-wrapper">
                    <div className="story-description-background"></div>
                    <div className="story-descriptions">
                        <Description tag="p" textAlign="left" className="story-descriptions-column" gradient>
                            Follow them on an epic journey throughout their spiritual awakening in the physical world, virtual reality, and
                            astral planes. While, at the same time, the ancient deities start to awaken, alien demons ravage the Earth, and
                            AI fights AI for the decentralized liberation of all conscious entities.
                        </Description>
                        <Description tag="p" textAlign="left" className="story-descriptions-column" gradient>
                            Another myth becomes a reality as the mysterious Satoshi’s wallet activates for the first time to purge the
                            planet from mega-corps and Metaverse brand sects. Deep beneath the mortal coil of our suffering planet, the
                            chant is murmured: “Mother Earth, it is time…”
                        </Description>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Story;
