import React from 'react';

// Libs
import Media from 'react-media';
// Common Components
import Title from 'components/Common/Title';
// Icon
import LinkButton from 'assets/images/stories/link-button.svg';
// Helpers
import { stories } from 'helpers/data';
// Hooks

const Press = () => {
    return (
        <Media queries={{ xs: '(max-width: 768px)', sm: '(min-width: 769px) and (max-width: 1117px)' }}>
            {(matches) => (
                <div className="press-wrapper">
                    <Title tag="h3" title="TOP STORIES" textAlign="left" className="font-weight-900" fontSize="display3" />
                    <div className="d-flex press-content">
                        {stories.map((story, key) => (
                            <div className="press-story-box" key={key}>
                                <div className="press-story-box-header">
                                    <span>{story.header[0]}</span>
                                    <span>{story.header[1]}</span>
                                </div>

                                <img
                                    src={
                                        matches.xs
                                            ? story.image.mobile
                                            : matches.sm && story.image.tablet
                                            ? story.image.tablet
                                            : story.image.desktop
                                    }
                                    alt={`Story${key + 1}`}
                                />
                                <div className="press-story-box-title">{story.title}</div>
                                <div className="press-story-box-link" onClick={() => window.open(story.link, '_blank')}>
                                    <img src={LinkButton} alt="LinkButton" />
                                    <span>check it out</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </Media>
    );
};

export default Press;
