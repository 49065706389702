import React, { useState } from 'react';

// Libs
import Media from 'react-media';
import { Navbar } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useMetamask } from '@thirdweb-dev/react';
import { useMoralis } from 'react-moralis';

// Components
import Button from 'components/Common/Button';
import Menu from 'components/Layout/Landing/Menu';
import MenuToggleIcon from 'components/Common/MenuToggleIcon';
// Images
import Logo from 'assets/images/brand/logo.svg';
import { ReactComponent as MetamaskIcon } from 'assets/images/svg/metamask-logo-small.svg';
// Modal stuff
import NoMetamaskModal from 'components/Modals/NoMetamask';

const Header = () => {
    // States
    const [expanded, setExpanded] = useState(false);
    // Hooks
    let navigate = useNavigate();
    const { authenticate } = useMoralis();
    const connectWithMetamask = useMetamask();

    const connect = async () => {
        try {
            if (window.ethereum) {
                await connectWithMetamask();
                await authenticate();
            } else {
                NoMetamaskModal.Show();
                console.log('No Metamask installation found! 222');
            }
        } catch (err) {
            console.log('error', err);
        }
    };

    return (
        <Media queries={{ xs: '(max-width: 991px)' }}>
            {(matches) => {
                return (
                    <header id="page-topbar">
                        <Navbar expand="lg" className="navbar-header">
                            <Navbar.Brand
                                className="cursor-pointer"
                                onClick={() => {
                                    navigate('/', { replace: true });
                                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                                }}>
                                <img src={Logo} alt="logo" />
                            </Navbar.Brand>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)}>
                                <MenuToggleIcon expanded={expanded} />
                            </Navbar.Toggle>

                            <Menu expanded={expanded} setExpanded={setExpanded} />

                            {!matches.xs ? (
                                <Button outline small onClick={connect}>
                                    <MetamaskIcon />
                                    CONNECT METAMASK
                                </Button>
                            ) : (
                                <></>
                            )}
                        </Navbar>
                    </header>
                );
            }}
        </Media>
    );
};

export default Header;
