import {
    SET_ASSETS_PANELS,
    SET_ASSETS_PAGES,
    SET_ASSETS_COVERS,
    SET_LOADING_PANELS,
    SET_LOADING_PAGES,
    SET_LOADING_COVERS
} from './actionTypes';

export const setAssetsPanels = (panels) => {
    return {
        type: SET_ASSETS_PANELS,
        payload: panels
    };
};

export const setAssetsLoadingPanels = () => {
    return {
        type: SET_LOADING_PANELS
    };
};
export const setAssetsPages = (pages) => {
    return {
        type: SET_ASSETS_PAGES,
        payload: pages
    };
};

export const setAssetsLoadingPages = () => {
    return {
        type: SET_LOADING_PAGES
    };
};
export const setAssetsCovers = (covers) => {
    return {
        type: SET_ASSETS_COVERS,
        payload: covers
    };
};

export const setAssetsLoadingCovers = () => {
    return {
        type: SET_LOADING_COVERS
    };
};
