import React from 'react';

// Components
import Loader from 'components/Common/Loader';
// Styled Components
import {
    LoadingMintingContainer,
    LoadingMintingWrapper,
    LoadingMintingQuotesWrapper
} from 'assets/styles/components/LoadingMinting/index.styled';
// Images
import QuotesIcon from 'assets/images/svg/ico-quotes.svg';

/**
 * LoadingMinting
 * * Loading component when user start minting assets
 * @param {*} props
 */
const LoadingMinting = (props) => {
    return (
        <LoadingMintingContainer>
            <LoadingMintingWrapper>
                <Loader />
                <p className="loading-minting-title p-18 font-weight-700">Minting in progress</p>
                <p className="loading-minting-description p-16">
                    Patience, our DAO friend. Minting usually takes a couple of minutes to complete.
                </p>
            </LoadingMintingWrapper>
            <LoadingMintingQuotesWrapper>
                <img src={QuotesIcon} alt="Quotes icon" />
                <p>
                    Meanwhile, did you know that the first digitally created comic published online was "Witches and Stitches" by Eric
                    Millikin, published on CompuServe in 1985?
                </p>
            </LoadingMintingQuotesWrapper>
        </LoadingMintingContainer>
    );
};

export default LoadingMinting;
