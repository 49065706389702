export const COLORS = {
    MintPriceButton: '#112534',
    MintPriceLabel: '#7fa1b2',
    black: 'black',
    white: 'white',

    layout: '#060f17',
    secondary: '#ff694a',
    primary: '#21ffef',

    paleBlue: '#7d898e',
    midnightBlue: '#244657',
    yellow: '#ffe58a',
    red: '#ff694a',

    morningBlue: '#d8e8ef',
    counterLabel: '#b69d98',
    navItem: '#ddeaef',
    navLabel: '#646695',

    blueLight: '#68899A',
    risingBlue: '#21fff0',

    mintButton: ' #4effdf',

    // Button
    textButton: '#08131a',
    textButtonOutline: '#47f9db',
    button: 'linear-gradient(108.9deg, #4effdf 26.34%, #19d0ba 54.25%)',
    buttonShadow: '0px 0px 25px rgba(78, 255, 224, 0.5)',
    buttonShadowOutline: '0px 0px 4px rgba(78, 255, 224, 0.4)',

    // Title and paragraph
    title: 'linear-gradient(94.11deg, #d2fffa 12.69%, #ffffff 47.54%, #ffd4cb 80.97%)',
    paragraph: 'linear-gradient(94.11deg, #e2fffc 12.69%, #ffffff 47.54%, #ffe0da 80.97%)',

    // DAO and progress bar
    progressBar: '#213c51',
    progressBarText: '#8aa7b7',
    daoVotingColors: {
        'up-voting': 'linear-gradient(104.75deg, #4effdf 21.6%, #19d0ba 46.78%)',
        'down-voting': 'linear-gradient(108.83deg, #ff9e82 26.36%, #ff7151 57.08%)',
        restrained: 'linear-gradient(116.88deg, #dad3d1 27.69%, #9b928f 68.34%)'
    },
    daoVotingShadows: {
        'up-voting': 'rgba(78, 255, 224, 0.1)',
        'down-voting': 'rgba(255, 100, 69, 0.5)',
        restrained: 'rgba(142, 142, 142, 0.5)'
    },
    textShadows: {
        1: '#9dffed',
        2: '#ffb7a2',
        3: '#c6c6c6'
    },

    // Mint
    priceButton: '#112534',

    //Header
    progressBarBackground: '#1b3548',
    progressBarShadow: '0px 0px 10px rgba(0, 255, 199, 0.5)',
    assetsBackground: '#1b1f21',
    assetsBorderColor: '#25fff3',

    //DAO
    daoWrapperBlue: '#0D1C28',
    darkBlueOpacity: 'rgba(17, 37, 52, 0.6)',
    neonGreen: '#4EFFE0',

    //SHOW CASE
    borderGray: '#515D62',
    rewardGray: '#8EAAB8',
    assetsGroupBackground: 'rgba(255, 105, 74, 0.06)',
    assetsDarkBlueBackground: 'rgba(17, 37, 52, 0.7)',
    assetsBrown: '#7A5A54',
    statusBackgroud: '#1e2830',
    statusProgressBarShadow: 'rgba(255, 172, 74, 0.5)',
    statusProgressBarCompletedShadow: 'rgba(0, 255, 199, 0.5)',
    colletedPanels: '#24FFF2',
    colletedPanelsShadow: 'rgba(0, 255, 199, 0.4)',
    // Market
    filterSelectBlack: '#060F18',
    // Assets
    cardBorderColor: '#1B3548',
    cardDuplicateNumberBackground: '#0B151B',

    // Assets Details
    assetsDetailsBackText: '#FFE1DA',
    assetsDetailsPriceBackground: '#070C0F',
    // Input
    input: '#182D3D'
};
