import React, { useState, useEffect } from 'react';

//Libs
import { useAddress } from '@thirdweb-dev/react';
// Helpers
import { data } from 'helpers/episode1/showcase';
// Components
import Checkbox from 'components/Common/Checkbox';
import { ProgressBar } from 'components/Layout/Header/ProgressBar';
import Loader from 'components/Common/Loader';
import ViewerModal from 'components/Modals/ViewerModal';
// Styled Component
import {
    ShowcaseContainer,
    ShowcaseContent,
    ShowcaseFilter,
    ShowcaseItemContainer,
    ShowcaseItemPage,
    ShowcaseItemImage,
    ShowcaseItemStatus
} from 'assets/styles/components/Showcase/index.styled';
// Provider
import { useMint } from 'providers/MintProvider/provider';
import { useSelector } from 'react-redux';

const Showcase = () => {
    const address = useAddress();
    // store
    const { allPanel, allPage } = useSelector((state) => state.Assets);
    //  Provider
    const { setShowcase } = useMint();

    // States
    const [showUncompleted, setShowUncompleted] = useState(false);
    const [NFTS, setNFTS] = useState([]);
    const arr = NFTS.map((nft) => parseInt(nft.id));

    useEffect(() => {
        setShowcase(true);
        if (address) {
            setNFTS([...allPanel, ...allPage]);
        }
        return () => {
            setShowcase(false);
        };
    }, [allPanel, allPage, address, setShowcase]);

    /**
     * getPanelImage
     * * Get minted or unminted image
     * @param {*} havePage
     * @param {*} haveElement
     * @param {*} value
     */
    const getPanelImage = (havePage, haveElement, value) => {
        if (haveElement) return value.image?.minted;

        if (havePage) {
            return value.image?.unMinted?.color;
        }

        return value.image?.unMinted?.bw;
    };

    /**
     * getStatusColor
     * * Get color of status
     * @param {*} collected
     * @param {*} total
     */
    const getStatusColor = (collected, total) => {
        if (collected === 0) {
            return '#68899A';
        }

        if (collected < total) {
            return '#FF694A';
        }

        return '';
    };

    return (
        <ShowcaseContainer>
            <ShowcaseFilter>
                <p className="mb-0 p-20 font-weight-700">Pages</p>
                <Checkbox name="uncompleted" label="Show uncompleted only" defaultChecked={showUncompleted} onChange={setShowUncompleted} />
            </ShowcaseFilter>
            <ShowcaseContent>
                {NFTS.length > 0 ? (
                    Object.entries(data)?.map(([pageKey, value], index) => {
                        let havePage = arr.includes(parseInt(pageKey));
                        let currentCollected = havePage ? 1 : 0;
                        let total = Object.keys(value)?.length ? Object.keys(value)?.length + 1 : 1;
                        let pageCompleted =
                            Object.entries(value).filter(([key, value]) => {
                                return arr.includes(parseInt(key));
                            }).length ===
                                total - 1 && havePage;
                        if (showUncompleted && pageCompleted) {
                            return <React.Fragment key={pageKey + index}></React.Fragment>;
                        }
                        return (
                            <ShowcaseItemContainer key={index}>
                                <ShowcaseItemPage
                                    havePage={havePage}
                                    onClick={() => {
                                        ViewerModal.Show(index, arr);
                                    }}>
                                    {Object.keys(value)?.length &&
                                        Object.entries(value)?.map(([panelKey, value]) => {
                                            let haveElement = arr.includes(parseInt(panelKey));
                                            currentCollected = haveElement ? currentCollected + 1 : currentCollected;
                                            let image = getPanelImage(havePage, haveElement, value);

                                            return (
                                                <ShowcaseItemImage
                                                    style={{
                                                        ...value?.style
                                                    }}
                                                    image={image}
                                                    havePage={havePage}
                                                    key={panelKey}
                                                />
                                            );
                                        })}
                                </ShowcaseItemPage>

                                <ShowcaseItemStatus>
                                    <p
                                        className={`showcase-item-status-page-number${
                                            currentCollected === total ? ' showcase-item-status-page-number-collected' : ''
                                        } font-weight-800 p-14`}>
                                        <span>#</span>
                                        {index + 1}
                                    </p>
                                    <div className="showcase-item-status-progress-container">
                                        <ProgressBar
                                            className={`showcase-item-status-progress-bar${
                                                currentCollected === total ? ' showcase-item-status-progress-bar-completed' : ''
                                            }`}
                                            collected={currentCollected}
                                            maxAssets={total}
                                            hideLabel={true}
                                        />
                                        <div
                                            className="font-weight-800 p-12"
                                            style={{
                                                color: getStatusColor(currentCollected, total)
                                            }}>
                                            {currentCollected < total ? (
                                                `${currentCollected}/${total}`
                                            ) : (
                                                <div className="showcase-item-status-collected"></div>
                                            )}
                                        </div>
                                    </div>
                                </ShowcaseItemStatus>
                            </ShowcaseItemContainer>
                        );
                    })
                ) : (
                    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Loader disableFlex={true} />
                    </div>
                )}
            </ShowcaseContent>
        </ShowcaseContainer>
    );
};

export default Showcase;
