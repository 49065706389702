import styled from 'styled-components';
// import colors
import { COLORS } from 'assets/styles/common/Colors.styled';

const ProgressBarWrapperStyled = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    > p {
        color: ${COLORS.blueLight};
        margin-bottom: 0;
    }
`;

const ProgressBarStyled = styled.div`
    width: 100%;
    height: ${(p) => (p.height ? `${p.height}px` : '4px')};
    position: relative;
    background-color: ${COLORS.progressBarBackground};
    border-radius: 32px;
    margin-right: 8px;
    .current-progress {
        position: absolute;
        top: ${(p) => (p.padding ? '3px' : '0')};
        bottom: ${(p) => (p.padding ? '3px' : '0')};
        right: ${(p) => (p.padding ? '5px' : '0')};
        left: ${(p) => (p.padding ? '5px' : '0')};
        height: ${(p) => (p.padding ? '83%' : '100%')};
        width: 1%;
        background-color: ${(p) => (p.percent < 50 ? COLORS.progressBarText : COLORS.neonGreen)};
        box-shadow: ${(p) => (p.percent < 50 ? '' : COLORS.progressBarShadow)};
        border-radius: inherit;
    }
`;

export { ProgressBarStyled, ProgressBarWrapperStyled };
