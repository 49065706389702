import styled from 'styled-components';

export const Container = styled.div`
    max-width: 1216px;
    width: calc(100% - 217px);
    height: 100vh;
    margin: 0 56px 0 168px;
    @media screen and (min-width: 1500px) {
        margin: 0 auto;
    }
    &.assets-layout {
        width: calc(100% - 204px);
        max-width: 1230px;
    }
`;
