import React from 'react';

/**
 * Button
 * * Button component
 * @param tag HTML tag - button or link tag
 * @param href Link if tag is 'a'
 * @param outline
 * @param small Property for small button
 * @param className Custom classes
 * @param children Content
 * @param onClick On click triggered method - only when property tag is not 'a'
 */
const Button = (props) => {
    if (props.tag === 'a') {
        return (
            <a
                href={props.href}
                target="_blank"
                rel="noopener noreferrer"
                className={`comic-button${props.outline ? ' outline' : ''}${props.small ? ' small' : ''}${props.comic ? ' comic' : ''}${
                    props.className ? ` ${props.className}` : ''
                }`}>
                <div className="comic-button-content">{props.children}</div>
            </a>
        );
    }

    return (
        <button
            type={props.type}
            id={props.id}
            className={`comic-button${props.outline ? ' outline' : ''}${props.outlineColored ? ` outline-${props.outlineColored}` : ''}${
                props.small ? ' small' : ''
            }${props.comic ? ' comic' : ''}${props.className ? ` ${props.className}` : ''} ${
                props.disabled && props.outline ? 'button-disabled' : props.disabled ? 'disabled-full' : ''
            }${props.disabled && props.disabledCustom ? ` ${props.disabledCustom}` : ''}`}
            onClick={(e) => props.onClick(e)}>
            <div className="comic-button-content">{props.children}</div>
        </button>
    );
};

export default Button;
