import { Navigate } from 'react-router-dom';

// Components public
import LandingPage from 'pages/LandingPage';
import PrivacyPolicy from 'components/LegalDocuments/PrivacyPolicy';
import TermsOfUse from 'components/LegalDocuments/TermsOfUse';
// Components private
import ComicPage from 'pages/ComicPage';
// import DaoPage from 'pages/DaoPage';
// import WhatIsDaoPage from 'pages/WhatIsDaoPage';
// import HowDaoWorksPage from 'pages/HowDaoWorksPage';
// import HowToVotePage from 'pages/HowToVotePage';
// import DaoHelpPage from 'pages/DaoHelpPage';
// import ComingSoonPage from 'pages/ComingSoonPage';
// import RewardsPage from 'pages/RewardsPage';
import MarketPage from 'pages/MarketPage';
import AssetsPage from 'pages/AssetsPage';
import AssetsDetailsPage from 'pages/AssetsDetailsPage';
import PressPage from 'pages/PressPage';

const protectedRoutes = [
    { path: '/comic', exact: true, component: <ComicPage /> },
    { path: '/assets', exact: true, component: <AssetsPage /> },
    { path: '/market', exact: true, component: <MarketPage /> },
    { path: '/:page/details/:type/:id', exact: true, component: <AssetsDetailsPage /> },
    // { path: '/market', exact: true, component: <ComingSoonPage /> },
    //  { path: '/dao', exact: true, component: <DaoPage /> },
    //  { path: '/dao/how-dao-works', exact: true, component: <HowDaoWorksPage /> },
    //  { path: '/dao/what-is-dao', exact: true, component: <WhatIsDaoPage /> },
    //  { path: '/dao/how-to-vote', exact: true, component: <HowToVotePage /> },
    //  { path: '/dao/help', exact: true, component: <DaoHelpPage /> },
    // { path: '/rewards', exact: true, component: <ComingSoonPage /> },
    { path: '*', exact: true, component: <Navigate replace to="/comic" /> }

    // TODO Create not found page
    //  { path: '/:404', exact: true, component: Page404 },
];

const publicRoutes = [
    { path: '/', exact: true, component: <LandingPage /> },
    { path: '/press', exact: true, component: <PressPage /> },
    { path: '/join/:id', exact: true, component: <LandingPage /> },
    { path: '/privacy-policy', exact: true, component: <PrivacyPolicy /> },
    { path: '/terms-of-use', exact: true, component: <TermsOfUse /> },
    { path: '*', exact: true, component: <Navigate replace to="/" /> }
];

export { protectedRoutes, publicRoutes };
