import React from 'react';

// Libs
import { Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
// Helpers
import { SLIDER_ITEMS } from 'helpers/data';

const SliderMobile = (props) => {
    return (
        <Swiper
            spaceBetween={50}
            slidesPerView={1}
            pagination={{
                clickable: true,
                // Custom bullets for pagination
                renderBullet: (index, className) => {
                    return `<div class="swiper-mobile-wrapper ${className}"><div class="swiper-mobile-bullet"></div><div class="swiper-mobile-label">${SLIDER_ITEMS[index]?.label}</div></div>`;
                },
                // Custom class for bullets
                bulletClass: 'swiper-mobile',
                // Custom class only for active bullet
                bulletActiveClass: 'swiper-mobile-bullet-active'
            }}
            modules={[Pagination, A11y]}>
            {SLIDER_ITEMS?.map((item, key) => (
                <SwiperSlide key={key}>
                    <img src={item?.image} className="w-100 slider-swiper-image" alt={`Slider mobile ${key + 1}`} />
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default SliderMobile;
