import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Helpers
import { scrollTo } from 'helpers/common';
// Components
import ComicBook from './sections/ComicBook';
import Story from './sections/Story';
import Mechanics from './sections/Mechanics';
import DAO from './sections/DAO';
import Rewards from './sections/Rewards';
import Mint from './sections/Mint';
import HowToMint from './sections/HowToMint';
import Roadmap from './sections/Roadmap';
import Team from './sections/Team';
import Newsletters from './sections/Newsletters';

export const Landing = () => {
    const location = useLocation();

    useEffect(() => {
        if (location?.state?.scrollTo) {
            scrollTo(location?.state?.scrollTo);
        }
    }, [location.state]);

    return (
        <div className="landing-page-wrapper">
            <ComicBook />
            <Story />
            <Mechanics />
            <DAO />
            <Rewards />
            <Mint />
            <HowToMint />
            <Team />
            <Roadmap />
            <Newsletters />
        </div>
    );
};
