import styled from 'styled-components';

import { COLORS } from 'assets/styles/common/Colors.styled';

const HeaderStyled = styled.div`
    display: flex;
    opacity: ${(props) => (props.hide ? 0 : 1)};
    transition: opacity 1s ease-in-out;
    align-items: center;
    justify-content: space-between;
    height: 88px;
    width: 100%;
    padding: 0 32px;
    position: relative;
    background: linear-gradient(180deg, #0b151b 16.6%, rgba(11, 21, 27, 0) 100%);
    z-index: 2;
    .comic-button {
        border-color: ${COLORS.secondary};
        padding: 0.625em 1.3em;
        img {
            margin-right: 0;
        }
    }
    .viewer-page-progress-container {
        display: flex;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        .page-assembly {
            white-space: nowrap;
            margin-bottom: 0;
            margin-right: 8px;
        }
    }
    .viewer-page-progress-container {
        width: 195px;
        display: flex;
    }
    .showcase-item-status-progress-bar {
        background: ${COLORS.red};
        box-shadow: 0px 0px 10px rgb(255 172 74 / 50%);
        border-radius: 32px;
    }
    .showcase-item-status-collected {
        position: relative;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background: ${COLORS.colletedPanels};
        box-shadow: 0px 0px 8px ${COLORS.colletedPanelsShadow};
        &::before {
            content: '';
            position: absolute;
            top: 2.8px;
            left: 5px;
            width: 4px;
            height: 7px;
            border: solid ${COLORS.priceButton};
            border-width: 0 2px 2px 0;
            transform: rotate(34deg);
        }
    }
`;

const ContentStyled = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    height: 100%;
    .viewer-slider-wrapper {
        position: relative;
        width: 100%;
        margin: auto 0;
        height: calc(100% - 88px);
        .swiper-zoom-container {
            position: relative;
            width: 488px;
        }
        .swiper-slide-zoomed {
            z-index: 11;
        }
        .viewer-slider-pagination-item {
            position: relative;
            font-size: 12px;
            .viewer-slider-pagination-item-thumbail {
                position: absolute;
                left: -26px;
                bottom: 14px;
                display: none;
                width: 72px;
                height: 99px;
                background-color: ${COLORS.black};
                .viewer-slider-pagination-thumbail-image {
                    position: absolute;
                    background-size: cover !important;
                    background-repeat: no-repeat;
                }
            }
            .viewer-slider-pagination-item-number {
                position: absolute;
                top: 10px;
                width: 100%;
                opacity: 0;
            }
        }
        .swiper {
            overflow: unset;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }
    .viewer-footer-wrapper {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 208px;
        z-index: 999;
    }
    .swiper-button-prev,
    .swiper-button-next {
        opacity: ${(props) => (props.hide ? 0 : 1)};
        transition: opacity 1s ease-in-out;
        color: ${COLORS.primary};
        bottom: 33px;
        top: unset;
        z-index: 999;
        &::after {
            font-size: 20px;
            font-weight: 900;
        }
    }
    .swiper-button-prev {
        left: 55px;
    }
    .swiper-button-next {
        right: unset;
        left: 115px;
    }
    div.swiper-pagination-bullet {
        background: ${COLORS.progressBarBackground};
        opacity: 1;
        &.swiper-pagination-bullet-active {
            background: ${COLORS.primary};
            .viewer-slider-pagination-item-number {
                opacity: 1;
                color: ${COLORS.primary};
            }
        }
        &:hover {
            background: ${COLORS.secondary};
            .viewer-slider-pagination-item-thumbail {
                display: block;
            }
            .viewer-slider-pagination-item-number {
                opacity: 1;
                color: ${COLORS.secondary};
            }
        }
    }
    .swiper-pagination {
        opacity: ${(props) => (props.hide ? 0 : 1)};
        transition: opacity 1s ease-in-out;
        align-items: center;
        gap: 13px;
        background: linear-gradient(180deg, rgba(13, 30, 39, 0) 0%, rgba(5, 9, 12, 0.9) 82.69%);
        .swiper-pagination-bullet {
            width: 19px;
            height: 8px;
            border-radius: 24px;
            margin: 0;
        }
    }
`;

const SwiperZoomTargetWrapper = styled.div`
    overflow: scroll;
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    z-index: 9999;
    padding-bottom: 100px;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
`;

const SlideContainerStyled = styled.div`
    position: relative;
    width: 488px;
    height: 668px;
    background-color: ${(props) => (props.havePage ? 'white' : '#18262D')};
    filter: ${(p) => (p.current ? '' : 'grayscale(100%) blur(5px) brightness(35%) saturate(6%) contrast(105%)')};
`;
const SlideZoomContainer = styled.div`
    z-index: ${(props) => (props.isActive ? 2 : 1)};
`;

const ZoomStyled = styled.div`
    opacity: ${(props) => (props.hide ? 0 : 1)};
    transition: opacity 1s ease-in-out;
    position: absolute;
    right: 43px;
    bottom: 42px;
    z-index: 1004;
    > * + * {
        margin-left: 16px;
    }
`;

export { HeaderStyled, ContentStyled, SlideContainerStyled, ZoomStyled, SlideZoomContainer, SwiperZoomTargetWrapper };
