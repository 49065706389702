import React, { useEffect } from 'react';

// Libs
import { Nav } from 'react-bootstrap';
import { useSpring, animated } from 'react-spring';
import { NavLink } from 'react-router-dom';
// Providers
import { useDiscordTracking } from 'providers/DiscordTracking/provider';
// Components
import MenuToggleIcon from 'components/Common/MenuToggleIcon';
// Helpers
import { withRouter } from 'helpers/common';
import { menuItems } from 'helpers/data';
// Images
import Discord from 'assets/images/svg/socials_media/discord-footer.svg';
import Instagram from 'assets/images/svg/socials_media/instagram.svg';
import Linkedin from 'assets/images/svg/socials_media/linkedin.svg';
import Twitter from 'assets/images/svg/socials_media/twitter.svg';

const MenuMobile = (props) => {
    // Animation for sidebar
    const { right } = useSpring({
        from: { right: '-102%' },
        right: props.expanded ? '0' : '-102%'
    });
    // Hooks
    const discord = useDiscordTracking();
    //  withRouter hooks
    const hash = props.location.hash;
    const pathname = props.location.pathname;

    // When the props.expanded changes, hide scroll of body tag
    useEffect(() => {
        if (props.expanded) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [props.expanded]);

    return (
        <animated.div className="nav-sidebar" style={{ right: right }}>
            <div className="d-flex align-items-center justify-content-between w-100">
                <span className="navmenu-label p-24 font-weight-900">MAIN MENU</span>
                <MenuToggleIcon expanded={props.expanded} onClick={() => props.setExpanded(!props.expanded)} />
            </div>

            <div className="h-100 d-flex flex-column justify-content-around">
                <Nav className="mx-auto navmenu-wrapper">
                    {menuItems.map((item, key) => (
                        <NavLink
                            end
                            key={key}
                            to={item.hash ? item.hash : item.pathname}
                            className={({ isActive }) =>
                                `navmenu-item ${
                                    isActive && item.hash
                                        ? item.hash.substring(1) === hash
                                            ? ' active'
                                            : ''
                                        : isActive && item.pathname === pathname
                                        ? ' active'
                                        : ''
                                }`
                            }
                            onClick={() => {
                                if (item.pathname) {
                                    props.history(item.pathname, { replace: true });
                                    props.setExpanded(!props.expanded);
                                } else {
                                    props.checkPathname(item.hash.substring(2), props.matches);
                                }
                            }}>
                            {item.title}
                        </NavLink>
                    ))}
                </Nav>
                <div className="navmenu-sidebar-footer">
                    <p className="p-24 font-weight-600">Join the community!</p>
                    <div className="navmenu-sidebar-footer-socials">
                        <a href={discord.link} target="_blank" rel="noopener noreferrer">
                            <img src={Discord} alt="Discord icon" />
                        </a>
                        <a href="https://instagram.com/2142_nft?igshid=YmMyMTA2M2Y=" target="_blank" rel="noopener noreferrer">
                            <img src={Instagram} alt="Instagram icon" />
                        </a>
                        <a href="https://www.linkedin.com/company/2142ad/" target="_blank" rel="noopener noreferrer">
                            <img src={Linkedin} alt="Linkedin icon" />
                        </a>
                        <a href="https://twitter.com/2142_ad" target="_blank" rel="noopener noreferrer">
                            <img src={Twitter} alt="Twitter icon" />
                        </a>
                    </div>
                </div>
            </div>
        </animated.div>
    );
};

export default withRouter(MenuMobile);
