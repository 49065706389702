import React from 'react';

// Libs
import Masonry from 'react-masonry-component';
// Components
import { Item } from './item';
import Title from 'components/Common/Title';
import Description from 'components/Common/Description';
import Button from 'components/Common/Button';
// Styled Components
import { MintedAssetsContainer, MintedAssetsMasonry, ShowComicContainer } from 'assets/styles/components/Mint/index.styled';
// Images
import ArrowNext from 'assets/images/svg/arrow-next.svg';
import { useMint } from 'providers/MintProvider/provider';

const MintedAssets = (props) => {
    const { setMinting, setShowcase } = useMint();

    let titleContent = 'FREE NFT ASSETS MINTED!';
    let buttonContent = 'SHOW ME THE COMIC';

    if (props.type === '21') {
        titleContent = '21 NFT ASSETS MINTED!';
        buttonContent = (
            <>
                Go to issue 1 <img src={ArrowNext} alt="Go to new issue" />
            </>
        );
    }
    return (
        <MintedAssetsContainer>
            <Title tag={'h5'} title={titleContent} fontSize={'display5'} />
            {props.type === 'free' && (
                <Description tag={'p'} className="minted-assets-description" gradient>
                    Now you can start assembling the first comic book issue. Welcome to the 2142 universe!
                </Description>
            )}
            <MintedAssetsMasonry>
                <Masonry
                    className="minted-assets-masonry"
                    options={{
                        gutter: 32,
                        columnWidth: 176,
                        transitionDuration: 0,
                        resize: true
                    }}>
                    {props.assets.map((tokenId, key) => {
                        return <Item tokenId={tokenId} key={key} />;
                    })}
                </Masonry>
            </MintedAssetsMasonry>
            <ShowComicContainer>
                <Button
                    onClick={() => {
                        setMinting(false);
                        setShowcase(true);
                    }}>
                    {buttonContent}
                </Button>
            </ShowComicContainer>
        </MintedAssetsContainer>
    );
};

export default MintedAssets;
