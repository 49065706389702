import React from 'react';

// Components
import Title from 'components/Common/Title';
import { Item } from './item';
// Helpers
import { ROADMAP_ITEMS } from 'helpers/data';

export const RoadmapMobile = () => {
    return (
        <section className="roadmap-mobile-container" id="roadmap">
            <Title title="Roadmap" fontSize="display2" tag="h2" textAlign="left" />
            {ROADMAP_ITEMS.map((item, key) => (
                <Item
                    key={key}
                    icon={item.icon}
                    color={item.color}
                    subtitle={item.subtitle}
                    image={item.image}
                    title={item.title}
                    content={item.content['mobile']}
                    roadMapIconStyle={`roadmap-item-mobile-${key + 1}`}
                />
            ))}
        </section>
    );
};
