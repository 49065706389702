import React, { Component } from 'react';
// Helpers
import { withRouter } from 'helpers/common';
// Components
import { Comic } from 'components/Comic/Comic';
// Styled Components
import { Container } from 'assets/styles/common/Container.styled.js';

class ComicPage extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Container>
                <Comic {...this.props} />
            </Container>
        );
    }
}

export default withRouter(ComicPage);
