import React from 'react';

//import style
import { ProgressBarStyled, ProgressBarWrapperStyled } from 'assets/styles/components/Header/ProgressBar/index.style';

/**
 * Progress Bar
 * * Component for Header progress bar which calculate current percent of minted assets
 * @param {collected, maxAssets} props
 * @param onClick On click triggered method for minting assets
 */

const ProgressBar = (props) => {
    // Value for progress bar
    const percent = (props.collected / props.maxAssets) * 100;

    return (
        <ProgressBarWrapperStyled>
            <ProgressBarStyled>
                <div style={{ width: `${percent}%` }} className={`current-progress${props.className ? ` ${props.className}` : ''}`}></div>
            </ProgressBarStyled>
            {!props.hideLabel && <p className="p-12">{Math.floor(percent)}%</p>}
        </ProgressBarWrapperStyled>
    );
};

export { ProgressBar };
