import styled from 'styled-components';

// Colors
import { COLORS } from 'assets/styles/common/Colors.styled';

const AssetRewardCardStyled = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    position: relative;
    width: ${(p) => (p.single ? (p.medium ? '110px' : '136px') : '33.33%')};
    height: 160px;
    padding: 42px 8px 28px 8px;
    .asset-icon {
        height: 40px;
        margin-bottom: 20px;
    }
    .p-16 {
        font-size: ${(p) => (p.medium ? '13px' : '1em')};
    }
    .asset-subtitle {
        color: ${(p) => (p.completed ? COLORS.blueLight : COLORS.assetsBrown)};
    }
    .asset-lock {
        position: absolute;
        top: 8px;
        right: 8px;
    }
`;

export { AssetRewardCardStyled };
