import React from 'react';
import Media from 'react-media';

// Libs
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
// Components
import { AssetRewardCard } from './AssetRewardCard';
// Styled Components
import {
    RewardWrapperStyled,
    RewardDescriptionStyled,
    AssetsWrapperStyled
} from 'assets/styles/pages/ComicBook/ShowCase/Rewards/index.styled';
// Images
import TrophyIcon from 'assets/images/comic/show_case/ico-trophy.svg';
import TrophyCompletedIcon from 'assets/images/comic/show_case/ico-trophy_completed.svg';
import InfoIcon from 'assets/images/comic/show_case/ico-info.svg';
import LockIcon from 'assets/images/comic/show_case/ico-lock.svg';
import UnlockIcon from 'assets/images/comic/show_case/ico-unlock.svg';
import PlusIcon from 'assets/images/comic/show_case/ico-plus.svg';
import PlusCompletedIcon from 'assets/images/comic/show_case/ico-plus_completed.svg';
import ComicBookIcon from 'assets/images/comic/show_case/ico-comic_book.svg';
import ComicBookCompletedIcon from 'assets/images/comic/show_case/ico-comic_book_completed.svg';
import CharacterIcon from 'assets/images/comic/show_case/ico-character.svg';
import CharacterCompletedIcon from 'assets/images/comic/show_case/ico-character_completed.svg';
import LocationIcon from 'assets/images/comic/show_case/ico-location.svg';
import LocationCompletedIcon from 'assets/images/comic/show_case/ico-location_completed.svg';
import ItemIcon from 'assets/images/comic/show_case/ico-item.svg';
import ItemCompletedIcon from 'assets/images/comic/show_case/ico-item_completed.svg';

export const Rewards = () => {
    const completed = false;

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            <p>
                You will get a free drop of one unique NFT character, location, or item to craft a one-of-a-kind spin-off comic book issue
                specially created for you.
            </p>
        </Tooltip>
    );

    return (
        <RewardWrapperStyled>
            <RewardDescriptionStyled>
                <img src={completed ? TrophyCompletedIcon : TrophyIcon} alt="Trophy icon" />
                <div className="description-wrapper">
                    <p className="p-18 font-weight-800"> Issue completion rewards</p>
                    <p className="p-12 gray-text">
                        Assemble complete episode to unlock rewards.
                        <OverlayTrigger placement="bottom" overlay={renderTooltip}>
                            <img className="cursor-pointer" src={InfoIcon} alt="info icon" />
                        </OverlayTrigger>
                    </p>
                </div>
            </RewardDescriptionStyled>
            <Media
                queries={{
                    medium: '(min-width: 800px) and (max-width: 1280px)'
                }}>
                {(matches) => (
                    <AssetsWrapperStyled completed={completed}>
                        <div className="assets-group">
                            <AssetRewardCard
                                medium={matches.medium}
                                single
                                completed={completed}
                                lock={completed ? UnlockIcon : LockIcon}
                                img={completed ? ComicBookCompletedIcon : ComicBookIcon}
                                title="Comic Book"
                                subtitle="AS NFT"></AssetRewardCard>
                        </div>
                        <img className="plus-icon" src={completed ? PlusCompletedIcon : PlusIcon} alt="plus icon" />
                        <div className="assets-group assets-group-right">
                            <AssetRewardCard
                                medium={matches.medium}
                                completed={completed}
                                lock={completed ? UnlockIcon : LockIcon}
                                img={completed ? CharacterCompletedIcon : CharacterIcon}
                                title="Character"
                                subtitle="UNIQUE 1/1"
                            />
                            <div className="or-break">
                                <div className="top-line"></div>
                                <p className="p-10 font-weight-500">OR</p>
                                <div className="bottom-line"></div>
                            </div>
                            <AssetRewardCard
                                medium={matches.medium}
                                completed={completed}
                                lock={completed ? UnlockIcon : LockIcon}
                                img={completed ? LocationCompletedIcon : LocationIcon}
                                title="Location"
                                subtitle="UNIQUE 1/1"
                            />
                            <div className="or-break">
                                <div className="top-line"></div>
                                <p className="p-10 font-weight-500">OR</p>
                                <div className="bottom-line"></div>
                            </div>
                            <AssetRewardCard
                                medium={matches.medium}
                                completed={completed}
                                lock={completed ? UnlockIcon : LockIcon}
                                img={completed ? ItemCompletedIcon : ItemIcon}
                                title="Item"
                                subtitle="UNIQUE 1/1"
                            />
                        </div>
                    </AssetsWrapperStyled>
                )}
            </Media>
        </RewardWrapperStyled>
    );
};
