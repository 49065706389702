import React from 'react';

// Libs
import { useNavigate, useLocation } from 'react-router-dom';
import Media from 'react-media';
// Helpers
import { scrollTo } from 'helpers/common';
// Components
import MenuMobile from './Mobile';
import MenuDesktop from './Desktop';

const Menu = (props) => {
    let navigate = useNavigate();
    const location = useLocation();
    const paths = ['/privacy-policy', '/terms-of-use', '/press'];

    const checkPathname = (toPage, matches = null) => {
        if (matches?.xs) {
            props.setExpanded(!props.expanded);
        }
        if (paths.includes(location.pathname)) {
            setTimeout(() => {
                navigate(`/#${toPage}`, {
                    replace: true,
                    state: { scrollTo: toPage }
                });
            }, 1);
        } else {
            scrollTo(toPage);
        }
    };

    return (
        <Media queries={{ xs: '(max-width: 991px)' }}>
            {(matches) => {
                if (matches.xs) return <MenuMobile {...props} checkPathname={checkPathname} matches={matches} />;

                return <MenuDesktop {...props} checkPathname={checkPathname} />;
            }}
        </Media>
    );
};

export default Menu;
