import React, { useEffect, useState } from 'react';

// Libs
import Masonry from 'react-masonry-component';
import { useSelector } from 'react-redux';
// Helpers
// import { ASSETS_TYPE } from 'helpers/data';
// Components
import Card from './Card';
import EditListingModal from 'components/Modals/EditListingModal';
import Loader from 'components/Common/Loader';
// Styled Components
import { MyListingsContainer } from 'assets/styles/components/Market/index.styled';

export const MyListings = (props) => {
    // Redux
    const data = useSelector((state) => state.Marketplace?.myListings);
    const [modal, setModal] = useState(false);

    useEffect(() => {
        if (modal) {
        }
    }, [modal]);

    if (props.isLoading) {
        return <Loader className="marketplace-loading-container" />;
    }

    return (
        <MyListingsContainer className={`${data && data?.length ? '' : 'my-listings-no-assets'}`}>
            {data && data?.length ? (
                <Masonry
                    className="assets-on-sale-masonry"
                    key={data.length}
                    options={{
                        stamp: '.stamp',
                        columnWidth: 176,
                        transitionDuration: 0,
                        gutter: 26,
                        resize: true
                    }}>
                    {data?.map((asset, key) => {
                        return (
                            <Card
                                type={asset.type}
                                asset={asset}
                                tab={props.tab}
                                onClick={() => EditListingModal.Show(asset, setModal)}
                                key={key}
                                parent={props}
                            />
                        );
                    })}
                </Masonry>
            ) : (
                <div>You don't have active listings</div>
            )}
        </MyListingsContainer>
    );
};
