import styled from 'styled-components';

import { COLORS } from 'assets/styles/common/Colors.styled';
import { Flex } from 'assets/styles/common/Flex.styled';

const StartMintingContainer = styled(Flex)`
    max-width: 50em;
    width: 100%;
    height: 100%;
    flex-direction: column;
    margin: 0 auto;
    .start-minting-description {
        margin: 24px 0;
    }
    .not-first-minting-description {
        max-width: 693px;
        width: 100%;
    }
    .not-first-minting-mint-button {
        max-width: 512px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 6px;
        border-radius: 1em;
        background: ${COLORS.MintPriceButton};
        transform: skewX(-20deg);
        .not-first-minting-price {
            padding-left: 16px;
            transform: skewX(20deg);
            p {
                margin: 0;
            }
            .not-first-minting-price-label {
                text-transform: uppercase;
                color: ${COLORS.MintPriceLabel};
                margin-bottom: 3px;
            }
        }
        button {
            transform: skewX(0);
            margin: 0;
        }
    }
`;

const MintedAssetsContainer = styled.div`
    margin-top: 32px;
    .minted-assets-description {
        max-width: 33em;
        width: 100%;
        margin: 16px auto 13px;
    }
    .minted-assets-masonry {
        position: relative;
        width: 100%;
    }
`;

const MintedAssetsMasonry = styled.div`
    padding-bottom: 160px;
    margin-top: 40px;
`;

const MintedAssetsItem = styled.div`
    position: absolute;
    max-width: 176px;
    width: 100%;
    min-height: 100px;
    padding: 16px;
    border-radius: 16px;
    margin-bottom: 32px;
    background: rgba(7, 12, 15, 0.5);
    .minted-assets-item-title {
        color: rgb(184, 190, 193);
        span {
            color: rgb(104, 137, 154);
        }
    }
    .minted-assets-item-asset {
        img{
            width: 100%;
        }
        text-align: center;
        margin: 12px 0 0 0;
    }
    .minted-assets-item-id-count {
        color: rgba(184, 190, 193, 0.63);
        text-align: right;
    }
`;

const ShowComicContainer = styled.div`
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    height: 256px;
    padding-bottom: 63px;
    background: linear-gradient(180deg, rgba(13, 30, 39, 0) 0%, rgba(5, 9, 12, 0.9) 62.2%);
    button {
        margin: 0;
    }
`;

export { StartMintingContainer, MintedAssetsContainer, MintedAssetsItem, MintedAssetsMasonry, ShowComicContainer };
