import React, { useState } from 'react';

// Libs
import { useSelector } from 'react-redux';
// Components
import { ForSale } from './sections/ForSale';
import { MyListings } from './sections/MyListings';
// import { History } from './sections/History';
import Title from 'components/Common/Title';
// Helpers
import { MARKETPLACE_TABS } from 'helpers/data';
// Providers
import { useMP } from 'providers/MarketplaceProvider/provider';
// Styled Components
import { MarketplaceContainer } from 'assets/styles/pages/Market/index.styled';
import { MarketTab, MarketTabList, MarketTabPanel, MarketTabs } from 'assets/styles/pages/Market/Tabs/index.styled';

export const Market = (props) => {
    // Hooks
    const { isLoadingMyListings, isLoadingForSale } = useMP();
    // States
    const [currentTab, setCurrentTab] = useState(MARKETPLACE_TABS.FOR_SALE);
    // Redux
    const myListingsData = useSelector((state) => state.Marketplace?.myListings);

    return (
        <MarketplaceContainer id="market-content">
            <Title notMargin textTransform textAlign title="Marketplace" fontSize={'display2'} className="font-weight-800" tag="h1" />

            <MarketTabs selectedTabClassName="is-selected" selectedTabPanelClassName="is-selected">
                <MarketTabList>
                    <MarketTab onClick={() => setCurrentTab(MARKETPLACE_TABS.FOR_SALE)}>
                        <p className="p-18 font-weight-500">For sale</p>
                    </MarketTab>
                    <MarketTab className={'my-listings-tab'} onClick={() => setCurrentTab(MARKETPLACE_TABS.MY_LISTINGS)}>
                        <p className="p-18 font-weight-500">My listings</p>
                        <div className="my-listings-badge p-12 font-weight-700">{myListingsData?.length || 0}</div>
                    </MarketTab>
                    {/* <MarketTab onClick={() => setCurrentTab(MARKETPLACE_TABS.HISTORY)}>
                        <p className="p-18 font-weight-500">History</p>
                    </MarketTab> */}
                </MarketTabList>
                <div className="tabFilterWrapper">
                    <div className="tabWrapper">
                        <MarketTabPanel className={`${isLoadingForSale ? 'align-items-center' : ''}`}>
                            <ForSale tab={currentTab} isLoading={isLoadingForSale} navigationFilters={props.location?.state} />
                        </MarketTabPanel>
                        <MarketTabPanel className={`${isLoadingMyListings ? 'align-items-center' : ''}`}>
                            <MyListings tab={currentTab} isLoading={isLoadingMyListings} />
                        </MarketTabPanel>
                        {/* <MarketTabPanel className={`${isLoading?.myListings ? 'align-items-center' : ''}`}>
                            <History tab={currentTab} isLoading={isLoading?.myListings} />
                        </MarketTabPanel> */}
                    </div>
                </div>
            </MarketTabs>
        </MarketplaceContainer>
    );
};
