import React from 'react';

//Libs
import Media from 'react-media';
import { useMetamask } from '@thirdweb-dev/react';
import { useMoralis } from 'react-moralis';

// Providers
import { useDiscordTracking } from 'providers/DiscordTracking/provider';
import { useDaoToken } from 'providers/DaoToken/provider';
// Components
import Title from 'components/Common/Title';
import Description from 'components/Common/Description';
import Button from 'components/Common/Button';
import ResponsiveModal from 'components/Modals/ResponsiveModal';
// Images
import DiscordGreen from 'assets/images/svg/socials_media/discord-green.svg';
import { ReactComponent as MetamaskIcon } from 'assets/images/svg/metamask-logo.svg';

// Modal stuff
import NoMetamaskModal from 'components/Modals/NoMetamask';

const ComicBook = () => {
    // Hooks
    const discord = useDiscordTracking();
    const { authenticate } = useMoralis();
    const connectWithMetamask = useMetamask();
    //  Get DaoToken
    const { daoToken } = useDaoToken();

    const connect = async () => {
        try {
            if (window.ethereum) {
                await connectWithMetamask();
                await authenticate();
            } else {
                NoMetamaskModal.Show();
                console.log('No Metamask installation found!');
            }
        } catch (err) {
            console.log('Error', err);
        }
    };

    return (
        <Media queries={{ xs: '(max-width: 1200px)' }}>
            {(matches) => {
                return (
                    <section className="comic-book-container">
                        <div className="comic-book-content">
                            <Title title="The world's first community-driven NFT comic book" textAlign="left" tag="h1" />
                            <div className="d-flex justify-content-center comic-book-description w-100">
                                <div className="comic-book-description-line"></div>
                                <div className="w-100">
                                    <Description gradient tag="p" textAlign="left" fontSize={24}>
                                        Join us in creating a “2142”, one-of-a-kind NFT sci-fi comic book where YOU decide what will happen
                                        and how it will end by voting, collecting, and crafting.
                                    </Description>
                                    <div className="comic-book-button">
                                        <Button onClick={() => (matches.xs && daoToken === '1' ? ResponsiveModal.Show(true) : connect())}>
                                            <MetamaskIcon />
                                            CONNECT METAMASK
                                        </Button>
                                        <Button tag={'a'} href={discord.link} className="comic-book-discord-button" outline>
                                            <img src={DiscordGreen} alt="Discord" /> JOIN DISCORD
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                );
            }}
        </Media>
    );
};

export default ComicBook;
