import styled from 'styled-components';

// Colors
import { COLORS } from 'assets/styles/common/Colors.styled';

const FilterContainer = styled.div`
    max-width: 176px;
    width: 100%;
    height: 100%;

    .filter-input {
        color: ${COLORS.blueLight};
        margin-bottom: 0;
        margin-top: 21px;
    }
    .checkbox-field {
        margin-bottom: 16px;
    }
`;

const FilterTitle = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    p {
        margin-left: 4px;
        margin-bottom: 0;
        text-transform: uppercase;
    }
`;

const FilterAssetType = styled.div`
    margin-bottom: 35px;
    p {
        color: ${COLORS.blueLight};
        margin-bottom: 8px;
    }
    .filter-assets-select {
        &:not(:last-child) {
            margin-bottom: 8px;
        }
    }
    .filter-asset-input {
        background-color: transparent;
        border-bottom: 1px solid #fff;
        border-radius: 0;
        transform: skewX(0);
        padding-left: 4px;
        padding-right: 4px;
        padding-bottom: 5px;
        margin-top: 21px;
        label {
            left: 4px;
            transform: skewX(0);
        }
        input {
            transform: skewX(0);
        }
        .input:focus ~ .label,
        .input:not(:placeholder-shown).input:not(:focus) ~ .label {
            left: 4px;
            transform: skewX(0);
        }
    }
`;

const FilterRewardsType = styled.div`
    p {
        color: ${COLORS.blueLight};
        margin-bottom: 14px;
    }
    .filter-rewards-checkbox {
        p {
            font-weight: 400;
            font-size: 0.875em;
            color: ${COLORS.white};
            text-transform: capitalize;
        }
    }
    .filter-rewards-checkbox-disabled {
        opacity: 0.3;
        cursor: not-allowed;
    }
`;

export { FilterContainer, FilterTitle, FilterAssetType, FilterRewardsType };
