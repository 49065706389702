import React from 'react';

/**
 * Title
 * * Component for title
 * @param tag HTML tag
 * @param title Display text
 * @param textAlign Text alignment - default center
 * @param textTransform Text transform - default uppercase
 * @param fontSize Class for font-size - default .display1
 * @param className Custom classes
 * @param style Inline style
 * @param notGradient Title with no gradient
 */
const Title = (props) => {
    return (
        <props.tag
            className={`title mb-0 ${props.notMargin ? 'title-margin-0' : ''} ${props.notGradient ? '' : ' title-gradient'} text-${
                props.textAlign ? props.textAlign : 'center'
            } text-${props.textTransform ? props.textTransform : 'uppercase'}${props.fontSize ? ` ${props.fontSize}` : ' display1'}${
                props.className ? ` ${props.className}` : ''
            }`}
            style={{ ...props.style }}>
            {props.title}
        </props.tag>
    );
};

export default Title;
