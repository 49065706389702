import React, { Component } from 'react';

// Helpers
import { withRouter } from 'helpers/common';
// Components
import AssetsDetails from 'components/AssetsDetails/AssetsDetails';
// Styled Components
import { Container } from 'assets/styles/common/Container.styled.js';

class AssetsDetailsPage extends Component {
    render() {
        return (
            <Container className="assets-layout">
                <AssetsDetails {...this.props} />
            </Container>
        );
    }
}

export default withRouter(AssetsDetailsPage);
