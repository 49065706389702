import React, { useState, useMemo } from 'react';

// Libs
import Masonry from 'react-masonry-component';
import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// Helpers
import { ASSETS_TYPE, MARKETPLACE_SORT, ISSUES_SELECT, ASSET_TYPES_SELECT } from 'helpers/data';
// Components
import Checkbox from 'components/Common/Checkbox';
import Input from 'components/Common/Input';
import Card from 'components/Market/sections/Card';
import { SelectComponent as Select } from 'components/Common/Select';
import Loader from 'components/Common/Loader';
// Styled Components
import { FilterContainer, FilterTitle, FilterAssetType, FilterRewardsType } from 'assets/styles/pages/Market/Filter/index.styled';
import { AssetsOnSaleContainer, AssetsOnSaleHeader, AssetsOnSaleMasonryContainer } from 'assets/styles/pages/Market/Tabs/index.styled.js';
// Images
import FilterIcon from 'assets/images/comic/market/ico-filter.svg';
import OrderIcon from 'assets/images/comic/market/ico-order.svg';

export const ForSale = (props) => {
    // States
    const [selectedIssueOption, setSelectedIssueOption] = useState(props.navigationFilters?.issue || ISSUES_SELECT[0]);
    const [selectedAssetOption, setSelectedAssetOption] = useState(props.navigationFilters?.type || '');
    const [inputAssetOption, setInputAssetOption] = useState(props.navigationFilters?.pageID || '');
    const [sortPrice, setSortPrice] = useState(MARKETPLACE_SORT.LOW);
    // const navigate = useNavigate();

    // Redux
    const data = useSelector((state) => state.Marketplace?.forSale);

    const searchText = useMemo(() => {
        if (!selectedAssetOption) return `All listings on Marketplace`;
        if (!inputAssetOption || inputAssetOption === '') {
            return `Search results for all ${selectedAssetOption.label}s`;
        }
        if (selectedAssetOption.value === 'cover') {
            return `Search for all Covers`;
        }
        return `Search for ${selectedAssetOption.label} ${
            selectedAssetOption.value === 'panel' ? 'with page' : ''
        } number ${inputAssetOption}`;
    }, [selectedAssetOption, inputAssetOption]);

    return (
        <>
            <FilterContainer>
                <FilterTitle>
                    <img src={FilterIcon} alt="Filter" />
                    <p className="p-18 font-weight-800">FILTER</p>
                </FilterTitle>
                <FilterAssetType>
                    <p className="p-14 font-weight-600">Assets</p>
                    <Select
                        isDisabled={props.isLoading}
                        placeholder="Choose issue"
                        className={'filter-assets-select'}
                        options={ISSUES_SELECT}
                        defaultValue={selectedIssueOption}
                        onChange={(value) => {
                            setSelectedIssueOption(value);
                            setInputAssetOption('');
                        }}
                        customStyle={customStyles}
                    />
                    <Select
                        isDisabled={props.isLoading}
                        placeholder="Choose asset"
                        className={'filter-assets-select'}
                        options={ASSET_TYPES_SELECT}
                        defaultValue={selectedAssetOption}
                        onChange={(value) => {
                            setSelectedAssetOption(value);
                            setInputAssetOption('');
                        }}
                        customStyle={customStyles}
                    />
                    {[ASSETS_TYPE.PAGE, ASSETS_TYPE.PANEL].includes(selectedAssetOption?.value) ? (
                        <Input
                            id="filter-asset-input"
                            type="number"
                            min="0"
                            max={`${selectedIssueOption?.pageNumber}`}
                            placeholder={`Page number`}
                            className="filter-asset-input"
                            value={inputAssetOption}
                            onChange={(e) => {
                                try {
                                    let value = isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value);

                                    if (value < parseInt(selectedIssueOption?.pageNumber)) {
                                        setInputAssetOption(e.target.value);
                                    }
                                } catch (error) {
                                    console.error(error);
                                }
                            }}
                            disabled={props.isLoading}
                        />
                    ) : (
                        <></>
                    )}
                </FilterAssetType>

                <FilterRewardsType>
                    <p className="p-14 font-weight-600">Rewards</p>
                    <Checkbox
                        name="issue"
                        label="Issues"
                        className="filter-rewards-checkbox filter-rewards-checkbox-disabled"
                        disabled={true}
                    />
                    <Checkbox
                        name="characters"
                        label="Characters"
                        className="filter-rewards-checkbox filter-rewards-checkbox-disabled"
                        disabled={true}
                    />
                    <Checkbox
                        name="locations"
                        label="Locations"
                        className="filter-rewards-checkbox filter-rewards-checkbox-disabled"
                        disabled={true}
                    />
                    <Checkbox
                        name="items"
                        label="Items"
                        className="filter-rewards-checkbox filter-rewards-checkbox-disabled"
                        disabled={true}
                    />
                </FilterRewardsType>
            </FilterContainer>
            <AssetsOnSaleContainer>
                {props.isLoading ? (
                    <Loader className="marketplace-loading-container" />
                ) : (
                    <>
                        <AssetsOnSaleHeader>
                            <p className="assets-on-sale-search p-18 font-weight-500">{searchText}</p>
                            <div
                                className="assets-on-sale-sort-container"
                                onClick={() => {
                                    if (sortPrice === MARKETPLACE_SORT.LOW) {
                                        setSortPrice(MARKETPLACE_SORT.HIGH);
                                    } else {
                                        setSortPrice(MARKETPLACE_SORT.LOW);
                                    }
                                }}>
                                <img src={OrderIcon} alt="order icon" />
                                <p className="p-14 font-weight-500">{sortPrice}</p>
                            </div>
                        </AssetsOnSaleHeader>
                        <AssetsOnSaleMasonryContainer className={`${data && data?.length ? '' : 'on-sale-listings-no-assets'}`}>
                            {data && data?.length ? (
                                <Masonry
                                    className="assets-on-sale-masonry"
                                    key={data.length}
                                    options={{
                                        stamp: '.stamp',
                                        columnWidth: 176,
                                        transitionDuration: 0,
                                        gutter: 26,
                                        resize: true
                                    }}>
                                    {data
                                        ?.filter((asset, key) => {
                                            // First we filter if its page, panel, etc.
                                            // Skip if filter is not selected
                                            if (!selectedAssetOption) return true;
                                            return selectedAssetOption.value === asset.type;
                                        })
                                        ?.filter((asset, key) => {
                                            // Then we filter by id
                                            // Skip if filter is not selected
                                            if (!selectedAssetOption) return true;
                                            if (!inputAssetOption || inputAssetOption === '') return true;
                                            if (selectedAssetOption.value === 'cover') return true;
                                            return asset.pageID?.toString() === inputAssetOption?.toString();
                                        })
                                        ?.sort((a, b) => {
                                            if (parseFloat(a.price?.displayValue) < parseFloat(b.price?.displayValue)) {
                                                return sortPrice === MARKETPLACE_SORT.LOW ? -1 : 1;
                                            }
                                            if (parseFloat(a.price?.displayValue) > parseFloat(b.price?.displayValue)) {
                                                return sortPrice === MARKETPLACE_SORT.LOW ? 1 : -1;
                                            }
                                            return 0;
                                        })
                                        ?.map((asset, key) => {
                                            return (
                                                <Card
                                                    type={asset.type}
                                                    navigateState={{
                                                        type: selectedAssetOption,
                                                        issue: selectedIssueOption,
                                                        pageID: inputAssetOption
                                                    }}
                                                    asset={asset}
                                                    key={key}
                                                    tab={props.tab}
                                                />
                                            );
                                        })}
                                </Masonry>
                            ) : (
                                <div className="text-center">No active items on Marketplace</div>
                            )}
                        </AssetsOnSaleMasonryContainer>
                    </>
                )}
            </AssetsOnSaleContainer>
        </>
    );
};

const customStyles = {
    control: (provided) => ({
        ...provided,
        background: 'transparent',
        cursor: 'pointer',
        borderTop: 0,
        borderLeft: 0,
        borderRight: 0,
        borderRadius: 0,
        boxShadow: 'unset'
    })
};
