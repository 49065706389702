import React from 'react';
import EthPriceContext from './context';

export function useEthPrice() {
    const context = React.useContext(EthPriceContext);
    if (context === undefined) {
        throw new Error('useEthPrice must be used within a EthPriceContext');
    }
    return context;
}
