import React from 'react';

// Libs
import Select from 'react-select';
// Styled Components
import { COLORS } from 'assets/styles/common/Colors.styled';

export const SelectComponent = (props) => {
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            fontSize: '0.875em',
            backgroundColor: state.isSelected ? COLORS.mintButton : COLORS.layout,
            color: state.isSelected ? COLORS.layout : COLORS.mintButton,
            cursor: 'pointer',
            '&:hover, &:focus': {
                backgroundColor: state.isSelected ? COLORS.mintButton : COLORS.layout,
                color: state.isSelected ? COLORS.layout : COLORS.mintButton
            }
        }),
        placeholder: (provided, state) => ({
            ...provided,
            fontSize: '0.875em',
            color: COLORS.white,
            cursor: 'pointer'
        }),
        menu: (provided) => ({
            ...provided,
            background: 'transparent'
        }),
        control: (provided) => ({
            ...provided,
            background: 'transparent',
            border: 0,
            boxShadow: 'unset',
            padding: 0,
            cursor: 'pointer'
        }),
        indicatorSeparator: () => ({
            opacity: 0
        }),
        dropdownIndicator: (base, state) => ({
            ...base,
            transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0)',
            transition: '300ms',
            cursor: 'pointer'
        }),
        singleValue: (provided, state) => ({
            ...provided,
            opacity: state.isDisabled ? 0.5 : 1,
            transition: 'opacity 300ms',
            color: COLORS.white,
            fontSize: '0.875em',
            fontWeight: 600
        }),
        valueContainer: (provided) => ({
            ...provided,
            padding: 0,
            cursor: 'pointer'
        })
    };

    return <Select {...props} styles={{ ...customStyles, ...props.customStyle }} />;
};
