import React from 'react';

// Helpers
import { withRouter } from 'helpers/common';
// Components
import FirstMinting from './FirstMinting';
import LoadingMinting from './LoadingMinting';
import MintedAssets from './MintedAssets/index';
import Loader from 'components/Common/Loader';
import Title from 'components/Common/Title';
import Description from 'components/Common/Description';
import Button from 'components/Common/Button';
// Styled Components
import { StartMintingContainer } from 'assets/styles/components/Mint/index.styled';
// Images
import Plus from 'assets/images/svg/ico-plus.svg';
// Provider
import { useEthPrice } from 'providers/EthPrice/provider';

const Mint = (props) => {
    const ethPrice = useEthPrice();

    if (props.isMinting) {
        return <LoadingMinting />;
    }
    if (props.isMinted === true) {
        return <MintedAssets assets={props.mintedAssets} type={props.type} />;
    } else if (props.isMinted === false && props.type === 'free') {
        return <FirstMinting action={props.action} />;
    } else if (props.isMinted === false && props.type === '21') {
        return (
            <StartMintingContainer>
                <Title tag={'h1'} title={'MINT UNIQUE BUNDLE WITH 21 NFTS'} />
                <Description tag={'p'} fontSize={'20'} className="start-minting-description not-first-minting-description">
                    Get a bundle of elements for assembling the Issue #1. The drop consists of 21 random NFT assets, including comic book
                    pages, panels, and unique covers.
                </Description>
                <div className="not-first-minting-mint-button">
                    <div className="not-first-minting-price">
                        <p className="not-first-minting-price-label p-12 font-weight-800">Price</p>
                        <p className="not-first-minting-price-number p-14 font-weight-500">
                            <span className="font-weight-800">0.02 ETH</span> {`(~$${parseInt(0.02 * ethPrice)} USD)`}
                        </p>
                    </div>
                    <Button onClick={props.action}>
                        <img src={Plus} alt="Plus icon" />
                        MINT YOUR NFT BUNDLE
                    </Button>
                </div>
            </StartMintingContainer>
        );
    } else {
        return (
            <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Loader disableFlex={true} />
            </div>
        );
    }
};

export default withRouter(Mint);
