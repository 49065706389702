import React, { useEffect, useState } from 'react';

// Libs
import Media from 'react-media';
// Helpers
import { withRouter, getHeroBackground } from 'helpers/common';
// Providers
import DiscordContext from 'providers/DiscordTracking/context';
import { useMint } from 'providers/MintProvider/provider';
// Components
import Header from 'components/Layout/Header';
import SideBar from 'components/Layout/Sidebar';
// Styled Components
import { HeroBackgroundImage } from 'assets/styles/components/Hero/index.styled';

// Influencers Map
const invites = {
    PpPU9Vdn: 'https://discord.gg/invite/PpPU9Vdn',
    '3URGHuu25c': 'https://discord.gg/3URGHuu25c'
};
const getInvite = (invite) => {
    return invite && invites[invite] ? invites[invite] : 'https://discord.gg/4FFWBaDvSJ';
};

const Layout = (props) => {
    // States
    const [link] = useState(getInvite(props?.id));
    // Hooks
    const { showcase } = useMint();

    useEffect(() => {
        if (props.cookies && !props.cookies['inviteCode']) {
            if (props.id && invites[props.id]) {
                props.setCookie('inviteCode', props.id, { maxAge: 60 * 24 * 60 * 60 });
            }
        }
    }, [props]);

    return (
        <Media queries={{ xs: '(max-width: 1200px)' }}>
            {(matches) => {
                return (
                    <DiscordContext.Provider value={{ link: link }}>
                        <HeroBackgroundImage image={getHeroBackground(props.location?.pathname, showcase)} id="layout-wrapper">
                            <Header />
                            <SideBar />
                            {props.children}
                        </HeroBackgroundImage>
                    </DiscordContext.Provider>
                );
            }}
        </Media>
    );
};

export default withRouter(Layout);
