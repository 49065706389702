import styled from 'styled-components';

// Colors
import { COLORS } from 'assets/styles/common/Colors.styled';

const MarketplaceContainer = styled.div`
    height: 100%;
    padding: 100px 0 50px 0;
    .tabFilterWrapper {
        width: 100%;
        display: flex;
    }
    .filterWrapper {
        width: 176px;
    }
    .tabWrapper {
        width: 100%;
    }
`;

const AssetMarketSection = styled.div`
    display: flex;
    flex-direction: column;
    max-width: unset;
    width: 100%;
    padding: 16px 16px 0 16px;
    margin-left: 0px;
    margin-right: 0px;
    border-top: 1px solid ${COLORS.progressBarBackground};
    .info-price-wrapper {
        margin-bottom: 5px;
    }
    .lowest-ask-wrapper,
    .avg-sale-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .lowest-ask-wrapper__label,
    .avg-sale-wrapper__label {
        margin-bottom: 0;
        color: ${COLORS.blueLight};
    }
    .avg-sale-wrapper__value,
    .lowest-ask-wrapper__value {
        margin-bottom: 0;
    }
    .avg-sale-wrapper__value {
        color: ${COLORS.blueLight};
    }
    .sold-date {
        margin-bottom: 0;
        color: ${COLORS.blueLight};
        text-align: right;
    }
`;

export { MarketplaceContainer, AssetMarketSection };
