import React, { useState } from 'react';
// Libs
import * as yup from 'yup';
import { BigNumber, utils } from 'ethers';
import { useMarketplace } from '@thirdweb-dev/react';
// Common
import Modal from 'components/Common/Modal';
// Toast
import Toast from 'components/Common/Toast';
// Components
import Title from 'components/Common/Title';
import Input from 'components/Common/Input';
import Button from 'components/Common/Button';
import Form from 'components/Common/Form';
import Card from 'components/Assets/sections/Card';
import ErrorToast from 'components/Toast/ErrorToast';
import Loading from 'components/Common/Loading';
import UpdateListingToast from 'components/Toast/UpdateListingToast';
import CancelListingToast from 'components/Toast/CancelListingToast';
// Style
import { ContentSellAsset, ContainerSellAsset } from 'assets/styles/components/Modal/index.styled';
// Provider
import { useEthPrice } from 'providers/EthPrice/provider';

const Header = () => {
    return <Title tag="h2" fontSize="display5" textAlign="left" title="Edit listing" />;
};

const Content = (props) => {
    // States
    const [assetPrice, setAssetPrice] = useState('0$');
    const [values, setValues] = useState({ price: '' });
    const [loading, setLoading] = useState({ loading: false, loadingText: '' });
    // Use hook
    const ethPrice = useEthPrice();
    const marketplace = useMarketplace(process.env.REACT_APP_MARKETPLACE_CONTRACT_ADDRESS);

    // Form validation
    const validationSchema = (amount) => {
        return yup.object().shape({
            price: yup.number().positive().min(0).required()
        });
    };

    const onCancelListing = async () => {
        setLoading({ loading: true, loadingText: 'Listing de-list is in progress' });
        try {
            const transactionResult = await marketplace?.direct.cancelListing(props.asset.sc.id);
            if (transactionResult?.receipt.status === 1) {
                Modal.hide();
                Toast.success(<CancelListingToast success />);
                props.setModal(true);
            }
        } catch (error) {
            Modal.hide();
            Toast.error(<ErrorToast error message={error?.message.split('\n')[0]} />);
            console.error(error);
        }
    };

    const onUpdateListing = async (asset) => {
        setLoading({ loading: true, loadingText: 'Listing update is in progress' });
        try {
            const transactionResult = await marketplace?.direct.updateListing(asset);
            if (transactionResult?.receipt.status === 1) {
                Modal.hide();
                Toast.success(<UpdateListingToast success />);
                props.setModal(true);
            }
        } catch (error) {
            Modal.hide();
            Toast.error(<ErrorToast error message={error?.message.split('\n')[0]} />);
            console.error(error);
        }
    };

    return (
        <Form
            enableReinitialize={true}
            initialValues={{
                price: values.price
            }}
            onSubmit={(values, actions) => {
                const parsedEth = utils.parseEther(values.price);

                const asset = {
                    id: props.asset.sc.id,
                    assetContractAddress: props.asset.sc.assetContractAddress,
                    tokenId: props.asset.sc.tokenId,
                    quantity: props.asset.sc.quantity,
                    startTimeInSeconds: BigNumber.from(Date.now()),
                    currencyContractAddress: props.asset.sc.currencyContractAddress,
                    buyoutPrice: parsedEth,
                    secondsUntilEnd: props.asset.sc.secondsUntilEnd,
                    sellerAddress: props.asset.sc.sellerAddress,
                    type: props.asset.sc.type
                };
                onUpdateListing(asset);
            }}
            validationSchema={validationSchema(props.amount)}>
            {({ handleSubmit, values, errors, touched, setFieldValue }) => {
                return (
                    <ContainerSellAsset>
                        <ContentSellAsset hiddenCheckbox>
                            <Card
                                className="sell-asset-modal-card"
                                type={props.asset?.type}
                                hideFromSale
                                hideDuplicate
                                asset={props.asset}
                            />
                            <div className="sell-asset-form">
                                <p className="sell-asset-form-title p-14">
                                    To update the price, please insert a new amount, lower than the previous one:
                                </p>
                                <Input
                                    min="0"
                                    type="number"
                                    error={errors['price'] && touched['price']}
                                    id="price"
                                    onChange={(e) => {
                                        setAssetPrice(`~${parseInt(e.target.value * ethPrice)}$`);
                                        setFieldValue('price', e.target.value);
                                        setValues({
                                            ...values,
                                            price: e.target.value
                                        });
                                    }}
                                    value={values.price}
                                    name="price"
                                    status={assetPrice}
                                    placeholder="Enter price (Ξ)"
                                />
                            </div>
                        </ContentSellAsset>

                        {loading.loadingText && <Loading title={loading.loadingText} />}

                        <div className="edit-listing-modal-btn">
                            <Button disabled={loading.loading} onClick={handleSubmit} type="submit">
                                <span>UPDATE PRICE</span>
                            </Button>
                            <Button disabled={loading.loading} outline onClick={() => onCancelListing()}>
                                <span>DE-LIST ASSET</span>
                            </Button>
                        </div>
                    </ContainerSellAsset>
                );
            }}
        </Form>
    );
};

const EditListingModal = () => {};
EditListingModal.Show = (asset, setModal) => {
    Modal.show(<Header />, undefined, <Content asset={asset} amount={parseInt(asset?.amount)} setModal={setModal} />, undefined, {
        hasClose: true
    });
    Modal.headerStyle({ marginBottom: 16 });
    Modal.modalStyle({ width: 816 });
};
export default EditListingModal;
