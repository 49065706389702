import React from 'react';

// Libs
import { NavLink } from 'react-router-dom';
import { useDisconnect } from '@thirdweb-dev/react';
// Helpers
import { SIDEBAR_ITEMS } from 'helpers/data';
// Styled Components
import { StyledSidebar } from 'assets/styles/components/Sidebar/index.styled';
// Images
import LogoutIcon from 'assets/images/svg/sidebar/inactive/ico-logout.svg';
// Provider
import { useMint } from 'providers/MintProvider/provider';
import { useDaoToken } from 'providers/DaoToken/provider';

const Sidebar = () => {
    const disconnectFromMetamask = useDisconnect();
    const mintProvider = useMint();

    //  Get DaoToken
    const { daoToken } = useDaoToken();

    return (
        <StyledSidebar>
            <div className="sidebar-items">
                {SIDEBAR_ITEMS?.map((item, key) => {
                    return (
                        <NavLink
                            className={`sidebar-item font-weight-800 p-10${
                                item?.disabled || (item?.pathname !== '/comic' && daoToken === '0') ? ' disabled-item' : ''
                            }`}
                            to={item?.pathname}
                            onClick={() => {
                                if (item?.pathname === '/comic') {
                                    mintProvider.setMinting(false);
                                }
                            }}
                            key={key}>
                            <div>
                                <div className="sidebar-item-image-badge">
                                    {item?.icon && <item.icon />}
                                    {item?.notification && <div className="sidebar-item-badge">{item?.notification}</div>}
                                </div>
                                <p>{item?.title}</p>
                            </div>
                        </NavLink>
                    );
                })}
            </div>
            <div onClick={disconnectFromMetamask} className="logout-item font-weight-800 p-10">
                <img src={LogoutIcon} alt={`Log out icon`} />
                <p>Log out</p>
            </div>
        </StyledSidebar>
    );
};

export default Sidebar;
