export const data = {
    4: {
        0: {
            style: {
                width: 469,
                height: 127,
                left: 10,
                top: 13
            },
            image: {
                minted: require('assets/images/viewer/page1/2142-01-01-01.png'),
                unMinted: {
                    color: require('assets/images/viewer/page1/pixelized/2142-01-01-01.webp'),
                    bw: require('assets/images/viewer/page1/pixelized-b-w/2142-01-01-01.webp')
                }
            }
        },
        1: {
            style: {
                width: 469,
                height: 126,
                left: 10,
                top: 149
            },
            image: {
                minted: require('assets/images/viewer/page1/2142-01-01-02.png'),
                unMinted: {
                    color: require('assets/images/viewer/page1/pixelized/2142-01-01-02.webp'),
                    bw: require('assets/images/viewer/page1/pixelized-b-w/2142-01-01-01.webp')
                }
            }
        },
        2: {
            style: {
                width: 469,
                height: 127,
                left: 10,
                top: 285
            },
            image: {
                minted: require('assets/images/viewer/page1/2142-01-01-03.png'),
                unMinted: {
                    color: require('assets/images/viewer/page1/pixelized/2142-01-01-03.webp'),
                    bw: require('assets/images/viewer/page1/pixelized-b-w/2142-01-01-03.webp')
                }
            }
        },
        3: {
            style: {
                width: 469,
                height: 233,
                left: 10,
                bottom: 13
            },
            image: {
                minted: require('assets/images/viewer/page1/2142-01-01-04.png'),
                unMinted: {
                    color: require('assets/images/viewer/page1/pixelized/2142-01-01-04.webp'),
                    bw: require('assets/images/viewer/page1/pixelized-b-w/2142-01-01-04.webp')
                }
            }
        }
    },
    8: {
        5: {
            style: {
                width: 468,
                height: 630,
                left: 10,
                top: 11
            },
            image: {
                minted: require('assets/images/viewer/page2/2142-01-02-01.png'),
                unMinted: {
                    color: require('assets/images/viewer/page2/pixelized/2142-01-02-01.webp'),
                    bw: require('assets/images/viewer/page2/pixelized-b-w/2142-01-02-01.webp')
                }
            }
        },
        6: {
            style: {
                width: 446,
                height: 119,
                left: 21,
                top: 460
            },
            image: {
                minted: require('assets/images/viewer/page2/2142-01-02-02.png'),
                unMinted: {
                    color: require('assets/images/viewer/page2/pixelized/2142-01-02-02.webp'),
                    bw: require('assets/images/viewer/page2/pixelized-b-w/2142-01-02-02.webp')
                }
            }
        },
        7: {
            style: {
                width: 446,
                height: 119,
                left: 21,
                bottom: 11
            },
            image: {
                minted: require('assets/images/viewer/page2/2142-01-02-03.png'),
                unMinted: {
                    color: require('assets/images/viewer/page2/pixelized/2142-01-02-03.webp'),
                    bw: require('assets/images/viewer/page2/pixelized-b-w/2142-01-02-03.webp')
                }
            }
        }
    },
    14: {
        9: {
            style: {
                width: 230,
                height: 201,
                left: 10,
                top: 15
            },
            image: {
                minted: require('assets/images/viewer/page3/2142-01-03-01.png'),
                unMinted: {
                    color: require('assets/images/viewer/page3/pixelized/2142-01-03-01.webp'),
                    bw: require('assets/images/viewer/page3/pixelized-b-w/2142-01-03-01.webp')
                }
            }
        },
        10: {
            style: {
                width: 229,
                height: 111,
                left: 10,
                top: 227
            },
            image: {
                minted: require('assets/images/viewer/page3/2142-01-03-02.png'),
                unMinted: {
                    color: require('assets/images/viewer/page3/pixelized/2142-01-03-02.webp'),
                    bw: require('assets/images/viewer/page3/pixelized-b-w/2142-01-03-02.webp')
                }
            }
        },
        11: {
            style: {
                width: 229,
                height: 322,
                top: 15,
                right: 10
            },
            image: {
                minted: require('assets/images/viewer/page3/2142-01-03-03.png'),
                unMinted: {
                    color: require('assets/images/viewer/page3/pixelized/2142-01-03-03.webp'),
                    bw: require('assets/images/viewer/page3/pixelized-b-w/2142-01-03-03.webp')
                }
            }
        },
        12: {
            style: {
                width: 466,
                height: 113,
                left: 11,
                top: 344
            },
            image: {
                minted: require('assets/images/viewer/page3/2142-01-03-04.png'),
                unMinted: {
                    color: require('assets/images/viewer/page3/pixelized/2142-01-03-04.webp'),
                    bw: require('assets/images/viewer/page3/pixelized-b-w/2142-01-03-04.webp')
                }
            }
        },
        13: {
            style: {
                width: 468,
                height: 188,
                bottom: 15,
                left: 10
            },
            image: {
                minted: require('assets/images/viewer/page3/2142-01-03-05.png'),
                unMinted: {
                    color: require('assets/images/viewer/page3/pixelized/2142-01-03-05.webp'),
                    bw: require('assets/images/viewer/page3/pixelized-b-w/2142-01-03-05.webp')
                }
            }
        }
    },
    19: {
        15: {
            style: {
                width: 469,
                height: 108,
                left: 10,
                top: 10
            },
            image: {
                minted: require('assets/images/viewer/page4/2142-01-04-02.png'),
                unMinted: {
                    color: require('assets/images/viewer/page4/pixelized/2142-01-04-02.webp'),
                    bw: require('assets/images/viewer/page4/pixelized-b-w/2142-01-04-02.webp')
                }
            }
        },
        16: {
            style: {
                width: 469,
                height: 86,
                left: 10,
                top: 128
            },
            image: {
                minted: require('assets/images/viewer/page4/2142-01-04-01.png'),
                unMinted: {
                    color: require('assets/images/viewer/page4/pixelized/2142-01-04-01.webp'),
                    bw: require('assets/images/viewer/page4/pixelized-b-w/2142-01-04-01.webp')
                }
            }
        },
        17: {
            style: {
                width: 469,
                height: 145,
                left: 10,
                top: 224
            },
            image: {
                minted: require('assets/images/viewer/page4/2142-01-04-03.png'),
                unMinted: {
                    color: require('assets/images/viewer/page4/pixelized/2142-01-04-03.webp'),
                    bw: require('assets/images/viewer/page4/pixelized-b-w/2142-01-04-03.webp')
                }
            }
        },
        18: {
            style: {
                width: 469,
                height: 278,
                left: 10,
                bottom: 10
            },
            image: {
                minted: require('assets/images/viewer/page4/2142-01-04-04.png'),
                unMinted: {
                    color: require('assets/images/viewer/page4/pixelized/2142-01-04-04.webp'),
                    bw: require('assets/images/viewer/page4/pixelized-b-w/2142-01-04-04.webp')
                }
            }
        }
    },
    21: {
        20: {
            style: {
                width: 469,
                height: 644,
                left: 10,
                top: 12
            },
            image: {
                minted: require('assets/images/viewer/page5/2142-01-05-01.png'),
                unMinted: {
                    color: require('assets/images/viewer/page5/pixelized/2142-01-05-01.webp'),
                    bw: require('assets/images/viewer/page5/pixelized-b-w/2142-01-05-01.webp')
                }
            }
        }
    },
    29: {
        22: {
            style: {
                width: 293,
                height: 277,
                left: 10,
                top: 14
            },
            image: {
                minted: require('assets/images/viewer/page6/2142-01-06-01.png'),
                unMinted: {
                    color: require('assets/images/viewer/page6/pixelized/2142-01-06-01.webp'),
                    bw: require('assets/images/viewer/page6/pixelized-b-w/2142-01-06-01.webp')
                }
            }
        },
        23: {
            style: {
                width: 176,
                height: 71,
                left: 302,
                top: 14
            },
            image: {
                minted: require('assets/images/viewer/page6/2142-01-06-02.png'),
                unMinted: {
                    color: require('assets/images/viewer/page6/pixelized/2142-01-06-02.webp'),
                    bw: require('assets/images/viewer/page6/pixelized-b-w/2142-01-06-02.webp')
                }
            }
        },
        24: {
            style: {
                width: 191,
                height: 131,
                left: 287,
                top: 48
            },
            image: {
                minted: require('assets/images/viewer/page6/2142-01-06-03.png'),
                unMinted: {
                    color: require('assets/images/viewer/page6/pixelized/2142-01-06-03.webp'),
                    bw: require('assets/images/viewer/page6/pixelized-b-w/2142-01-06-03.webp')
                }
            }
        },
        25: {
            style: {
                width: 179,
                height: 356,
                left: 10,
                bottom: 14
            },
            image: {
                minted: require('assets/images/viewer/page6/2142-01-06-05.png'),
                unMinted: {
                    color: require('assets/images/viewer/page6/pixelized/2142-01-06-05.webp'),
                    bw: require('assets/images/viewer/page6/pixelized-b-w/2142-01-06-05.webp')
                }
            }
        },
        26: {
            style: {
                width: 207,
                height: 151,
                left: 270,
                top: 141
            },
            image: {
                minted: require('assets/images/viewer/page6/2142-01-06-04.png'),
                unMinted: {
                    color: require('assets/images/viewer/page6/pixelized/2142-01-06-04.webp'),
                    bw: require('assets/images/viewer/page6/pixelized-b-w/2142-01-06-04.webp')
                }
            }
        },
        27: {
            style: {
                width: 202,
                height: 356,
                left: 143,
                bottom: 14
            },
            image: {
                minted: require('assets/images/viewer/page6/2142-01-06-06.png'),
                unMinted: {
                    color: require('assets/images/viewer/page6/pixelized/2142-01-06-06.webp'),
                    bw: require('assets/images/viewer/page6/pixelized-b-w/2142-01-06-06.webp')
                }
            }
        },
        28: {
            style: {
                width: 179,
                height: 356,
                left: 300,
                bottom: 14
            },
            image: {
                minted: require('assets/images/viewer/page6/2142-01-06-07.png'),
                unMinted: {
                    color: require('assets/images/viewer/page6/pixelized/2142-01-06-07.webp'),
                    bw: require('assets/images/viewer/page6/pixelized-b-w/2142-01-06-07.webp')
                }
            }
        }
    },
    33: {
        30: {
            style: {
                width: 469,
                height: 389,
                left: 9,
                top: 11
            },
            image: {
                minted: require('assets/images/viewer/page7/2142-01-07-01.png'),
                unMinted: {
                    color: require('assets/images/viewer/page7/pixelized/2142-01-07-01.webp'),
                    bw: require('assets/images/viewer/page7/pixelized-b-w/2142-01-07-01.webp')
                }
            }
        },
        31: {
            style: {
                width: 468,
                height: 185,
                left: 9,
                top: 410
            },
            image: {
                minted: require('assets/images/viewer/page7/2142-01-07-02.png'),
                unMinted: {
                    color: require('assets/images/viewer/page7/pixelized/2142-01-07-02.webp'),
                    bw: require('assets/images/viewer/page7/pixelized-b-w/2142-01-07-02.webp')
                }
            }
        },
        32: {
            style: {
                width: 468,
                height: 204,
                left: 9,
                bottom: 11
            },
            image: {
                minted: require('assets/images/viewer/page7/2142-01-07-03.png'),
                unMinted: {
                    color: require('assets/images/viewer/page7/pixelized/2142-01-07-03.webp'),
                    bw: require('assets/images/viewer/page7/pixelized-b-w/2142-01-07-03.webp')
                }
            }
        }
    },
    37: {
        34: {
            style: {
                width: 447,
                height: 230,
                left: 19,
                top: 25,
                zIndex: 1
            },
            image: {
                minted: require('assets/images/viewer/page8/2142-01-08-01.png'),
                unMinted: {
                    color: require('assets/images/viewer/page8/pixelized/2142-01-08-01.webp'),
                    bw: require('assets/images/viewer/page8/pixelized-b-w/2142-01-08-01.webp')
                }
            }
        },
        35: {
            style: {
                width: 466,
                height: 322,
                left: 0,
                top: 131
            },
            image: {
                minted: require('assets/images/viewer/page8/2142-01-08-02.png'),
                unMinted: {
                    color: require('assets/images/viewer/page8/pixelized/2142-01-08-02.webp'),
                    bw: require('assets/images/viewer/page8/pixelized-b-w/2142-01-08-02.webp')
                }
            }
        },
        36: {
            style: {
                width: 447,
                height: 253,
                left: 19,
                bottom: 25
            },
            image: {
                minted: require('assets/images/viewer/page8/2142-01-08-03.png'),
                unMinted: {
                    color: require('assets/images/viewer/page8/pixelized/2142-01-08-03.webp'),
                    bw: require('assets/images/viewer/page8/pixelized-b-w/2142-01-08-03.webp')
                }
            }
        }
    },
    44: {
        38: {
            style: {
                width: 153,
                height: 216,
                left: 10,
                top: 13
            },
            image: {
                minted: require('assets/images/viewer/page9/2142-01-09-01.png'),
                unMinted: {
                    color: require('assets/images/viewer/page9/pixelized/2142-01-09-01.webp'),
                    bw: require('assets/images/viewer/page9/pixelized-b-w/2142-01-09-01.webp')
                }
            }
        },
        39: {
            style: {
                width: 149,
                height: 215,
                left: 170,
                top: 13
            },
            image: {
                minted: require('assets/images/viewer/page9/2142-01-09-02.png'),
                unMinted: {
                    color: require('assets/images/viewer/page9/pixelized/2142-01-09-02.webp'),
                    bw: require('assets/images/viewer/page9/pixelized-b-w/2142-01-09-02.webp')
                }
            }
        },
        40: {
            style: {
                width: 152,
                height: 215,
                left: 327,
                top: 13
            },
            image: {
                minted: require('assets/images/viewer/page9/2142-01-09-03.png'),
                unMinted: {
                    color: require('assets/images/viewer/page9/pixelized/2142-01-09-03.webp'),
                    bw: require('assets/images/viewer/page9/pixelized-b-w/2142-01-09-03.webp')
                }
            }
        },
        41: {
            style: {
                width: 229,
                height: 200,
                left: 10,
                top: 236
            },
            image: {
                minted: require('assets/images/viewer/page9/2142-01-09-05.png'),
                unMinted: {
                    color: require('assets/images/viewer/page9/pixelized/2142-01-09-05.webp'),
                    bw: require('assets/images/viewer/page9/pixelized-b-w/2142-01-09-05.webp')
                }
            }
        },
        42: {
            style: {
                width: 231,
                height: 200,
                left: 247,
                top: 236
            },
            image: {
                minted: require('assets/images/viewer/page9/2142-01-09-04.png'),
                unMinted: {
                    color: require('assets/images/viewer/page9/pixelized/2142-01-09-04.webp'),
                    bw: require('assets/images/viewer/page9/pixelized-b-w/2142-01-09-04.webp')
                }
            }
        },
        43: {
            style: {
                width: 467,
                height: 211,
                left: 11,
                bottom: 13
            },
            image: {
                minted: require('assets/images/viewer/page9/2142-01-09-06.png'),
                unMinted: {
                    color: require('assets/images/viewer/page9/pixelized/2142-01-09-06.webp'),
                    bw: require('assets/images/viewer/page9/pixelized-b-w/2142-01-09-06.webp')
                }
            }
        }
    },
    49: {
        45: {
            style: {
                width: 461,
                height: 197,
                left: 13,
                top: 17
            },
            image: {
                minted: require('assets/images/viewer/page10/2142-01-10-01.png'),
                unMinted: {
                    color: require('assets/images/viewer/page10/pixelized/2142-01-10-01.webp'),
                    bw: require('assets/images/viewer/page10/pixelized-b-w/2142-01-10-01.webp')
                }
            }
        },
        46: {
            style: {
                width: 189,
                height: 163,
                left: 13,
                top: 223
            },
            buyStyle: {
                width: 189,
                height: 163,
                left: 13,
                top: 223,
                zIndex: 2
            },
            image: {
                minted: require('assets/images/viewer/page10/2142-01-10-02.png'),
                unMinted: {
                    color: require('assets/images/viewer/page10/pixelized/2142-01-10-02.webp'),
                    bw: require('assets/images/viewer/page10/pixelized-b-w/2142-01-10-02.webp')
                }
            }
        },
        47: {
            style: {
                width: 464,
                height: 242,
                left: 17,
                top: 202,
                zIndex: 1
            },
            buyStyle: {
                width: 464,
                height: 242,
                left: 17,
                top: 202,
                zIndex: 1,
                justifyContent: 'flex-end',
                paddingRight: '23%'
            },
            image: {
                minted: require('assets/images/viewer/page10/2142-01-10-03.png'),
                unMinted: {
                    color: require('assets/images/viewer/page10/pixelized/2142-01-10-03.webp'),
                    bw: require('assets/images/viewer/page10/pixelized-b-w/2142-01-10-03.webp')
                }
            }
        },
        48: {
            style: {
                width: 461,
                height: 257,
                left: 13,
                top: 394,
                bottom: 17
            },
            image: {
                minted: require('assets/images/viewer/page10/2142-01-10-04.png'),
                unMinted: {
                    color: require('assets/images/viewer/page10/pixelized/2142-01-10-04.webp'),
                    bw: require('assets/images/viewer/page10/pixelized-b-w/2142-01-10-04.webp')
                }
            }
        }
    },
    53: {
        50: {
            style: {
                width: 468.98,
                height: 201.1,
                left: 9.51,
                top: 10.7
            },
            image: {
                minted: require('assets/images/viewer/page11/2142-01-11-01.png'),
                unMinted: {
                    color: require('assets/images/viewer/page11/pixelized/2142-01-11-01.webp'),
                    bw: require('assets/images/viewer/page11/pixelized-b-w/2142-01-11-01.webp')
                }
            }
        },
        51: {
            style: {
                width: 468.98,
                height: 146.43,
                left: 9.51,
                top: 221.55
            },
            image: {
                minted: require('assets/images/viewer/page11/2142-01-11-02.png'),
                unMinted: {
                    color: require('assets/images/viewer/page11/pixelized/2142-01-11-02.webp'),
                    bw: require('assets/images/viewer/page11/pixelized-b-w/2142-01-11-02.webp')
                }
            }
        },
        52: {
            style: {
                width: 468.98,
                height: 279.81,
                left: 9.51,
                top: 377.7
            },
            image: {
                minted: require('assets/images/viewer/page11/2142-01-11-03.png'),
                unMinted: {
                    color: require('assets/images/viewer/page11/pixelized/2142-01-11-03.webp'),
                    bw: require('assets/images/viewer/page11/pixelized-b-w/2142-01-11-03.webp')
                }
            }
        }
    },
    58: {
        54: {
            style: {
                width: 459.45,
                height: 232.21,
                left: 14.4,
                top: 18.49
            },
            image: {
                minted: require('assets/images/viewer/page12/2142-01-12-01.png'),
                unMinted: {
                    color: require('assets/images/viewer/page12/pixelized/2142-01-12-01.webp'),
                    bw: require('assets/images/viewer/page12/pixelized-b-w/2142-01-12-01.webp')
                }
            }
        },

        55: {
            style: {
                width: 459,
                height: 391,
                left: 14,
                top: 259
            },
            buyStyle: {
                width: 459,
                height: 391,
                left: 14,
                top: 259,
                alignItems: 'flex-end',
                paddingBottom: '15%'
            },
            image: {
                minted: require('assets/images/viewer/page12/2142-01-12-04.png'),
                unMinted: {
                    color: require('assets/images/viewer/page12/pixelized/2142-01-12-04.webp'),
                    bw: require('assets/images/viewer/page12/pixelized-b-w/2142-01-12-04.webp')
                }
            }
        },
        56: {
            style: {
                width: 151,
                height: 167,
                left: 6,
                top: 228
            },
            image: {
                minted: require('assets/images/viewer/page12/2142-01-12-02.png'),
                unMinted: {
                    color: require('assets/images/viewer/page12/pixelized/2142-01-12-02.webp'),
                    bw: require('assets/images/viewer/page12/pixelized-b-w/2142-01-12-02.webp')
                }
            }
        },
        57: {
            style: {
                width: 316,
                height: 189,
                left: 167,
                top: 219
            },
            image: {
                minted: require('assets/images/viewer/page12/2142-01-12-03.png'),
                unMinted: {
                    color: require('assets/images/viewer/page12/pixelized/2142-01-12-03.webp'),
                    bw: require('assets/images/viewer/page12/pixelized-b-w/2142-01-12-03.webp')
                }
            }
        }
    },
    62: {
        59: {
            style: {
                width: 468.75,
                height: 280.18,
                left: 9.62,
                top: 8.5
            },
            image: {
                minted: require('assets/images/viewer/page13/2142-01-13-01.png'),
                unMinted: {
                    color: require('assets/images/viewer/page13/pixelized/2142-01-13-01.webp'),
                    bw: require('assets/images/viewer/page13/pixelized-b-w/2142-01-13-01.webp')
                }
            }
        },
        60: {
            style: {
                width: 468.5,
                height: 338.3,
                top: 95.29,
                left: 9.88
            },
            image: {
                minted: require('assets/images/viewer/page13/2142-01-13-02.png'),
                unMinted: {
                    color: require('assets/images/viewer/page13/pixelized/2142-01-13-02.webp'),
                    bw: require('assets/images/viewer/page13/pixelized-b-w/2142-01-13-02.webp')
                }
            }
        },
        61: {
            style: {
                width: 468.75,
                height: 302.77,
                left: 9.62,
                top: 356.95
            },
            image: {
                minted: require('assets/images/viewer/page13/2142-01-13-03.png'),
                unMinted: {
                    color: require('assets/images/viewer/page13/pixelized/2142-01-13-03.webp'),
                    bw: require('assets/images/viewer/page13/pixelized-b-w/2142-01-13-03.webp')
                }
            }
        }
    },
    68: {
        63: {
            style: {
                width: 455.18,
                height: 394.49,
                left: 16.41,
                top: 20.79
            },
            image: {
                minted: require('assets/images/viewer/page14/2142-01-14-01.png'),
                unMinted: {
                    color: require('assets/images/viewer/page14/pixelized/2142-01-14-01.webp'),
                    bw: require('assets/images/viewer/page14/pixelized-b-w/2142-01-14-01.webp')
                }
            }
        },
        64: {
            style: {
                width: 303.46,
                height: 169.74,
                left: 9.99,
                top: 250.98
            },
            image: {
                minted: require('assets/images/viewer/page14/2142-01-14-02.png'),
                unMinted: {
                    color: require('assets/images/viewer/page14/pixelized/2142-01-14-02.webp'),
                    bw: require('assets/images/viewer/page14/pixelized-b-w/2142-01-14-02.webp')
                }
            }
        },
        65: {
            style: {
                width: 161.84,
                height: 169.74,
                left: 316.16,
                top: 276.38,
                zIndex: 2
            },
            image: {
                minted: require('assets/images/viewer/page14/2142-01-14-03.png'),
                unMinted: {
                    color: require('assets/images/viewer/page14/pixelized/2142-01-14-03.webp'),
                    bw: require('assets/images/viewer/page14/pixelized-b-w/2142-01-14-03.webp')
                }
            }
        },
        66: {
            style: {
                width: 276.32,
                height: 220.56,
                left: 19.47,
                top: 426.38,
                zIndex: 1
            },
            image: {
                minted: require('assets/images/viewer/page14/2142-01-14-04.png'),
                unMinted: {
                    color: require('assets/images/viewer/page14/pixelized/2142-01-14-04.webp'),
                    bw: require('assets/images/viewer/page14/pixelized-b-w/2142-01-14-04.webp')
                }
            }
        },
        67: {
            style: {
                width: 254.85,
                height: 221.05,
                left: 216.74,
                top: 426.38
            },
            image: {
                minted: require('assets/images/viewer/page14/2142-01-14-05.png'),
                unMinted: {
                    color: require('assets/images/viewer/page14/pixelized/2142-01-14-05.webp'),
                    bw: require('assets/images/viewer/page14/pixelized-b-w/2142-01-14-05.webp')
                }
            }
        }
    },
    74: {
        69: {
            style: {
                width: 449,
                height: 219,
                left: 20,
                top: 27
            },
            image: {
                minted: require('assets/images/viewer/page15/2142-01-15-01.png'),
                unMinted: {
                    color: require('assets/images/viewer/page15/pixelized/2142-01-15-01.webp'),
                    bw: require('assets/images/viewer/page15/pixelized-b-w/2142-01-15-01.webp')
                }
            }
        },
        70: {
            style: {
                width: 486,
                height: 655,
                left: 1,
                top: 7,
                zIndex: 10
            },
            buyStyle: {
                width: 486,
                height: 655,
                left: 1,
                top: 7,
                zIndex: 10,
                alignItems: 'flex-end',
                paddingBottom: '30%'
            },
            image: {
                minted: require('assets/images/viewer/page15/2142-01-15-05.png'),
                unMinted: {
                    color: require('assets/images/viewer/page15/pixelized/2142-01-15-05.webp'),
                    bw: require('assets/images/viewer/page15/pixelized-b-w/2142-01-15-05.webp')
                }
            }
        },
        71: {
            style: {
                width: 170,
                height: 161,
                left: 13,
                top: 217,
                zIndex: 10
            },
            image: {
                minted: require('assets/images/viewer/page15/2142-01-15-02.png'),
                unMinted: {
                    color: require('assets/images/viewer/page15/pixelized/2142-01-15-02.webp'),
                    bw: require('assets/images/viewer/page15/pixelized-b-w/2142-01-15-02.webp')
                }
            }
        },
        72: {
            style: {
                width: 135,
                height: 161,
                left: 147,
                top: 234,
                zIndex: 10
            },
            image: {
                minted: require('assets/images/viewer/page15/2142-01-15-03.png'),
                unMinted: {
                    color: require('assets/images/viewer/page15/pixelized/2142-01-15-03.webp'),
                    bw: require('assets/images/viewer/page15/pixelized-b-w/2142-01-15-03.webp')
                }
            }
        },
        73: {
            style: {
                width: 217,
                height: 152,
                left: 254,
                top: 217
            },
            buStyle: {
                width: 217,
                height: 152,
                left: 254,
                top: 217
                // zIndex: 11
            },
            image: {
                minted: require('assets/images/viewer/page15/2142-01-15-04.png'),
                unMinted: {
                    color: require('assets/images/viewer/page15/pixelized/2142-01-15-04.webp'),
                    bw: require('assets/images/viewer/page15/pixelized-b-w/2142-01-15-04.webp')
                }
            }
        }
    },
    78: {
        75: {
            style: {
                width: 467.74,
                height: 476.61,
                left: 10.13,
                top: 10.93
            },
            image: {
                minted: require('assets/images/viewer/page16/2142-01-16-01.png'),
                unMinted: {
                    color: require('assets/images/viewer/page16/pixelized/2142-01-16-01.webp'),
                    bw: require('assets/images/viewer/page16/pixelized-b-w/2142-01-16-01.webp')
                }
            }
        },
        76: {
            style: {
                width: 309.8,
                height: 166.31,
                left: 157.68,
                top: 311.34
            },
            image: {
                minted: require('assets/images/viewer/page16/2142-01-16-02.png'),
                unMinted: {
                    color: require('assets/images/viewer/page16/pixelized/2142-01-16-02.webp'),
                    bw: require('assets/images/viewer/page16/pixelized-b-w/2142-01-16-02.webp')
                }
            }
        },
        77: {
            style: {
                width: 468.75,
                height: 159.97,
                left: 9.63,
                top: 497.32
            },
            image: {
                minted: require('assets/images/viewer/page16/2142-01-16-03.png'),
                unMinted: {
                    color: require('assets/images/viewer/page16/pixelized/2142-01-16-03.webp'),
                    bw: require('assets/images/viewer/page16/pixelized-b-w/2142-01-16-03.webp')
                }
            }
        }
    },
    85: {
        79: {
            style: {
                width: 160.69,
                height: 163.16,
                left: 18.29,
                top: 21.74
            },
            image: {
                minted: require('assets/images/viewer/page17/2142-01-17-01.png'),
                unMinted: {
                    color: require('assets/images/viewer/page17/pixelized/2142-01-17-01.webp'),
                    bw: require('assets/images/viewer/page17/pixelized-b-w/2142-01-17-01.webp')
                }
            }
        },
        80: {
            style: {
                width: 185.62,
                height: 176,
                left: 128.63,
                top: 21.74
            },
            image: {
                minted: require('assets/images/viewer/page17/2142-01-17-02.png'),
                unMinted: {
                    color: require('assets/images/viewer/page17/pixelized/2142-01-17-02.webp'),
                    bw: require('assets/images/viewer/page17/pixelized-b-w/2142-01-17-02.webp')
                }
            }
        },
        81: {
            style: {
                width: 226.6,
                height: 215.24,
                left: 260.44,
                top: 3.23
            },
            image: {
                minted: require('assets/images/viewer/page17/2142-01-17-03.png'),
                unMinted: {
                    color: require('assets/images/viewer/page17/pixelized/2142-01-17-03.webp'),
                    bw: require('assets/images/viewer/page17/pixelized-b-w/2142-01-17-03.webp')
                }
            }
        },
        82: {
            style: {
                width: 157.98,
                height: 145.64,
                left: 18.29,
                top: 181.7
            },
            image: {
                minted: require('assets/images/viewer/page17/2142-01-17-04.png'),
                unMinted: {
                    color: require('assets/images/viewer/page17/pixelized/2142-01-17-04.webp'),
                    bw: require('assets/images/viewer/page17/pixelized-b-w/2142-01-17-04.webp')
                }
            }
        },
        83: {
            style: {
                width: 334.22,
                height: 162.67,
                left: 135.54,
                top: 197.74
            },
            image: {
                minted: require('assets/images/viewer/page17/2142-01-17-05.png'),
                unMinted: {
                    color: require('assets/images/viewer/page17/pixelized/2142-01-17-05.webp'),
                    bw: require('assets/images/viewer/page17/pixelized-b-w/2142-01-17-05.webp')
                }
            }
        },
        84: {
            style: {
                width: 451.48,
                height: 325.35,
                left: 18.29,
                top: 328.19
            },
            image: {
                minted: require('assets/images/viewer/page17/2142-01-17-06.png'),
                unMinted: {
                    color: require('assets/images/viewer/page17/pixelized/2142-01-17-06.webp'),
                    bw: require('assets/images/viewer/page17/pixelized-b-w/2142-01-17-06.webp')
                }
            }
        }
    },
    91: {
        86: {
            style: {
                height: 247.18,
                width: 134.62,
                left: 9.63,
                top: 11.64
            },
            buyStyle: {
                height: 247.18,
                width: 134.62,
                left: 9.63,
                top: 11.64,
                justifyContent: 'flex-start',
                paddingLeft: '4%'
            },
            image: {
                minted: require('assets/images/viewer/page18/2142-01-18-01.png'),
                unMinted: {
                    color: require('assets/images/viewer/page18/pixelized/2142-01-18-01.webp'),
                    bw: require('assets/images/viewer/page18/pixelized-b-w/2142-01-18-01.webp')
                }
            }
        },
        87: {
            style: {
                width: 198,
                height: 234.5,
                left: 75.04,
                top: 11.64
            },
            image: {
                minted: require('assets/images/viewer/page18/2142-01-18-02.png'),
                unMinted: {
                    color: require('assets/images/viewer/page18/pixelized/2142-01-18-02.webp'),
                    bw: require('assets/images/viewer/page18/pixelized-b-w/2142-01-18-02.webp')
                }
            }
        },
        88: {
            style: {
                width: 274.81,
                height: 247.18,
                left: 203.56,
                top: 11.64
            },
            image: {
                minted: require('assets/images/viewer/page18/2142-01-18-03.png'),
                unMinted: {
                    color: require('assets/images/viewer/page18/pixelized/2142-01-18-03.webp'),
                    bw: require('assets/images/viewer/page18/pixelized-b-w/2142-01-18-03.webp')
                }
            }
        },
        89: {
            style: {
                width: 442.13,
                height: 104.96,
                left: 21.79,
                top: 244.37
            },
            buyStyle: {
                width: 442.13,
                height: 104.96,
                left: 21.79,
                top: 244.37,
                zIndex: 2
            },
            image: {
                minted: require('assets/images/viewer/page18/2142-01-18-04.png'),
                unMinted: {
                    color: require('assets/images/viewer/page18/pixelized/2142-01-18-04.webp'),
                    bw: require('assets/images/viewer/page18/pixelized-b-w/2142-01-18-04.webp')
                }
            }
        },
        90: {
            style: {
                width: 467.74,
                height: 388.13,
                left: 10.13,
                top: 268.45
            },
            image: {
                minted: require('assets/images/viewer/page18/2142-01-18-05.png'),
                unMinted: {
                    color: require('assets/images/viewer/page18/pixelized/2142-01-18-05.webp'),
                    bw: require('assets/images/viewer/page18/pixelized-b-w/2142-01-18-05.webp')
                }
            }
        }
    },
    96: {
        92: {
            style: {
                width: 468.5,
                height: 261.66,
                left: 9.63,
                top: 12.56
            },
            image: {
                minted: require('assets/images/viewer/page19/2142-01-19-01.png'),
                unMinted: {
                    color: require('assets/images/viewer/page19/pixelized/2142-01-19-01.webp'),
                    bw: require('assets/images/viewer/page19/pixelized-b-w/2142-01-19-01.webp')
                }
            }
        },
        93: {
            style: {
                width: 252.27,
                height: 206.84,
                left: 9.63,
                top: 255.44
            },
            image: {
                minted: require('assets/images/viewer/page19/2142-01-19-02.png'),
                unMinted: {
                    color: require('assets/images/viewer/page19/pixelized/2142-01-19-02.webp'),
                    bw: require('assets/images/viewer/page19/pixelized-b-w/2142-01-19-02.webp')
                }
            }
        },
        94: {
            style: {
                width: 279.68,
                height: 210.65,
                left: 198.45,
                top: 214.07
            },
            image: {
                minted: require('assets/images/viewer/page19/2142-01-19-03.png'),
                unMinted: {
                    color: require('assets/images/viewer/page19/pixelized/2142-01-19-03.webp'),
                    bw: require('assets/images/viewer/page19/pixelized-b-w/2142-01-19-03.webp')
                }
            }
        },
        95: {
            style: {
                width: 468.75,
                height: 252.27,
                left: 9.63,
                top: 403.39
            },
            image: {
                minted: require('assets/images/viewer/page19/2142-01-19-04.png'),
                unMinted: {
                    color: require('assets/images/viewer/page19/pixelized/2142-01-19-04.webp'),
                    bw: require('assets/images/viewer/page19/pixelized-b-w/2142-01-19-04.webp')
                }
            }
        }
    },
    101: {
        97: {
            style: {
                width: 210.39,
                height: 190.6,
                left: 9.63,
                top: 11
            },
            image: {
                minted: require('assets/images/viewer/page20/2142-01-20-01.png'),
                unMinted: {
                    color: require('assets/images/viewer/page20/pixelized/2142-01-20-01.webp'),
                    bw: require('assets/images/viewer/page20/pixelized-b-w/2142-01-20-01.webp')
                }
            }
        },
        98: {
            style: {
                width: 309.62,
                height: 190.6,
                left: 168.75,
                top: 11
            },
            image: {
                minted: require('assets/images/viewer/page20/2142-01-20-02.png'),
                unMinted: {
                    color: require('assets/images/viewer/page20/pixelized/2142-01-20-02.webp'),
                    bw: require('assets/images/viewer/page20/pixelized-b-w/2142-01-20-02.webp')
                }
            }
        },
        99: {
            style: {
                width: 468.75,
                height: 244.91,
                left: 9.63,
                top: 211.4
            },
            image: {
                minted: require('assets/images/viewer/page20/2142-01-20-03.png'),
                unMinted: {
                    color: require('assets/images/viewer/page20/pixelized/2142-01-20-03.webp'),
                    bw: require('assets/images/viewer/page20/pixelized-b-w/2142-01-20-03.webp')
                }
            }
        },
        100: {
            style: {
                width: 468.5,
                height: 191.1,
                left: 9.88,
                top: 466.11
            },
            image: {
                minted: require('assets/images/viewer/page20/2142-01-20-04.png'),
                unMinted: {
                    color: require('assets/images/viewer/page20/pixelized/2142-01-20-04.webp'),
                    bw: require('assets/images/viewer/page20/pixelized-b-w/2142-01-20-04.webp')
                }
            }
        }
    },
    109: {
        102: {
            style: {
                width: 289,
                height: 135,
                left: 10,
                top: 11,
                zIndex: 7
            },
            image: {
                minted: require('assets/images/viewer/page21/2142-01-21-01.png'),
                unMinted: {
                    color: require('assets/images/viewer/page21/pixelized/2142-01-21-01.webp'),
                    bw: require('assets/images/viewer/page21/pixelized-b-w/2142-01-21-01.webp')
                }
            }
        },
        103: {
            style: {
                width: 199,
                height: 147,
                right: 9,
                top: 11
            },
            image: {
                minted: require('assets/images/viewer/page21/2142-01-21-02.png'),
                unMinted: {
                    color: require('assets/images/viewer/page21/pixelized/2142-01-21-02.webp'),
                    bw: require('assets/images/viewer/page21/pixelized-b-w/2142-01-21-02.webp')
                }
            }
        },
        104: {
            style: {
                width: 163,
                height: 169,
                left: 10,
                top: 133,
                zIndex: 3
            },
            image: {
                minted: require('assets/images/viewer/page21/2142-01-21-03.png'),
                unMinted: {
                    color: require('assets/images/viewer/page21/pixelized/2142-01-21-03.webp'),
                    bw: require('assets/images/viewer/page21/pixelized-b-w/2142-01-21-03.webp')
                }
            }
        },
        105: {
            style: {
                width: 177,
                height: 182,
                left: 131,
                top: 144
            },
            image: {
                minted: require('assets/images/viewer/page21/2142-01-21-04.png'),
                unMinted: {
                    color: require('assets/images/viewer/page21/pixelized/2142-01-21-04.webp'),
                    bw: require('assets/images/viewer/page21/pixelized-b-w/2142-01-21-04.webp')
                }
            }
        },
        106: {
            style: {
                width: 196,
                height: 202,
                right: 9,
                top: 156
            },
            image: {
                minted: require('assets/images/viewer/page21/2142-01-21-05.png'),
                unMinted: {
                    color: require('assets/images/viewer/page21/pixelized/2142-01-21-05.webp'),
                    bw: require('assets/images/viewer/page21/pixelized-b-w/2142-01-21-05.webp')
                }
            }
        },
        107: {
            style: {
                width: 322,
                height: 363,
                left: 10,
                bottom: 11
            },
            image: {
                minted: require('assets/images/viewer/page21/2142-01-21-06.png'),
                unMinted: {
                    color: require('assets/images/viewer/page21/pixelized/2142-01-21-06.webp'),
                    bw: require('assets/images/viewer/page21/pixelized-b-w/2142-01-21-06.webp')
                }
            }
        },
        108: {
            style: {
                width: 225,
                height: 312,
                right: 9,
                bottom: 11
            },
            image: {
                minted: require('assets/images/viewer/page21/2142-01-21-07.png'),
                unMinted: {
                    color: require('assets/images/viewer/page21/pixelized/2142-01-21-07.webp'),
                    bw: require('assets/images/viewer/page21/pixelized-b-w/2142-01-21-07.webp')
                }
            }
        }
    },
    117: {
        110: {
            style: {
                width: 464,
                height: 95,
                left: 11,
                top: 20,
                zIndex: 7
            },
            image: {
                minted: require('assets/images/viewer/page22/2142-01-22-01.png'),
                unMinted: {
                    color: require('assets/images/viewer/page22/pixelized/2142-01-22-01.webp'),
                    bw: require('assets/images/viewer/page22/pixelized-b-w/2142-01-22-01.webp')
                }
            }
        },
        111: {
            style: {
                width: 473,
                height: 208,
                left: 2,
                top: 104,
                zIndex: 6
            },
            image: {
                minted: require('assets/images/viewer/page22/2142-01-22-02.png'),
                unMinted: {
                    color: require('assets/images/viewer/page22/pixelized/2142-01-22-02.webp'),
                    bw: require('assets/images/viewer/page22/pixelized-b-w/2142-01-22-02.webp')
                }
            }
        },
        112: {
            style: {
                width: 454,
                height: 80,
                left: 24,
                top: 300,
                zIndex: 5
            },
            image: {
                minted: require('assets/images/viewer/page22/2142-01-22-03.png'),
                unMinted: {
                    color: require('assets/images/viewer/page22/pixelized/2142-01-22-03.webp'),
                    bw: require('assets/images/viewer/page22/pixelized-b-w/2142-01-22-03.webp')
                }
            }
        },
        113: {
            style: {
                width: 102,
                height: 102,
                left: 11,
                top: 376,
                zIndex: 4
            },
            image: {
                minted: require('assets/images/viewer/page22/2142-01-22-04.png'),
                unMinted: {
                    color: require('assets/images/viewer/page22/pixelized/2142-01-22-04.webp'),
                    bw: require('assets/images/viewer/page22/pixelized-b-w/2142-01-22-04.webp')
                }
            }
        },
        114: {
            style: {
                width: 164,
                height: 100,
                left: 119,
                top: 364
            },
            image: {
                minted: require('assets/images/viewer/page22/2142-01-22-05.png'),
                unMinted: {
                    color: require('assets/images/viewer/page22/pixelized/2142-01-22-05.webp'),
                    bw: require('assets/images/viewer/page22/pixelized-b-w/2142-01-22-05.webp')
                }
            }
        },
        115: {
            style: {
                width: 186,
                height: 105,
                right: 12,
                top: 376
            },
            image: {
                minted: require('assets/images/viewer/page22/2142-01-22-06.png'),
                unMinted: {
                    color: require('assets/images/viewer/page22/pixelized/2142-01-22-06.webp'),
                    bw: require('assets/images/viewer/page22/pixelized-b-w/2142-01-22-06.webp')
                }
            }
        },
        116: {
            style: {
                width: 456,
                height: 224,
                left: 25,
                bottom: 11
            },
            image: {
                minted: require('assets/images/viewer/page22/2142-01-22-07.png'),
                unMinted: {
                    color: require('assets/images/viewer/page22/pixelized/2142-01-22-07.webp'),
                    bw: require('assets/images/viewer/page22/pixelized-b-w/2142-01-22-07.webp')
                }
            }
        }
    },
    126: {
        118: {
            style: {
                width: 227,
                height: 101,
                left: 13,
                top: 15
            },
            image: {
                minted: require('assets/images/viewer/page23/2142-01-23-01.png'),
                unMinted: {
                    color: require('assets/images/viewer/page23/pixelized/2142-01-23-01.webp'),
                    bw: require('assets/images/viewer/page23/pixelized-b-w/2142-01-23-01.webp')
                }
            }
        },
        119: {
            style: {
                width: 227,
                height: 102,
                right: 12,
                top: 15
            },
            image: {
                minted: require('assets/images/viewer/page23/2142-01-23-02.png'),
                unMinted: {
                    color: require('assets/images/viewer/page23/pixelized/2142-01-23-02.webp'),
                    bw: require('assets/images/viewer/page23/pixelized-b-w/2142-01-23-02.webp')
                }
            }
        },
        120: {
            style: {
                width: 450,
                height: 295,
                left: 19,
                top: 25
            },
            image: {
                minted: require('assets/images/viewer/page23/2142-01-23-03.png'),
                unMinted: {
                    color: require('assets/images/viewer/page23/pixelized/2142-01-23-03.webp'),
                    bw: require('assets/images/viewer/page23/pixelized-b-w/2142-01-23-03.webp')
                }
            }
        },
        121: {
            style: {
                width: 148,
                height: 155,
                left: 13,
                top: 176
            },
            image: {
                minted: require('assets/images/viewer/page23/2142-01-23-04.png'),
                unMinted: {
                    color: require('assets/images/viewer/page23/pixelized/2142-01-23-04.webp'),
                    bw: require('assets/images/viewer/page23/pixelized-b-w/2142-01-23-04.webp')
                }
            }
        },
        122: {
            style: {
                width: 378,
                height: 155,
                right: 1,
                top: 176
            },
            image: {
                minted: require('assets/images/viewer/page23/2142-01-23-05.png'),
                unMinted: {
                    color: require('assets/images/viewer/page23/pixelized/2142-01-23-05.webp'),
                    bw: require('assets/images/viewer/page23/pixelized-b-w/2142-01-23-05.webp')
                }
            }
        },
        123: {
            style: {
                width: 177,
                height: 312,
                left: 12,
                bottom: 15,
                zIndex: 1
            },
            image: {
                minted: require('assets/images/viewer/page23/2142-01-23-06.png'),
                unMinted: {
                    color: require('assets/images/viewer/page23/pixelized/2142-01-23-06.webp'),
                    bw: require('assets/images/viewer/page23/pixelized-b-w/2142-01-23-06.webp')
                }
            }
        },
        124: {
            style: {
                width: 371,
                height: 312,
                right: 17,
                bottom: 15
            },
            image: {
                minted: require('assets/images/viewer/page23/2142-01-23-07.png'),
                unMinted: {
                    color: require('assets/images/viewer/page23/pixelized/2142-01-23-07.webp'),
                    bw: require('assets/images/viewer/page23/pixelized-b-w/2142-01-23-07.webp')
                }
            }
        },
        125: {
            style: {
                width: 87,
                height: 163,
                right: 11,
                bottom: 24
            },
            image: {
                minted: require('assets/images/viewer/page23/2142-01-23-08.png'),
                unMinted: {
                    color: require('assets/images/viewer/page23/pixelized/2142-01-23-08.webp'),
                    bw: require('assets/images/viewer/page23/pixelized-b-w/2142-01-23-08.webp')
                }
            }
        }
    },
    136: {
        127: {
            style: {
                width: 266,
                height: 175,
                left: 10,
                top: 13
            },
            image: {
                minted: require('assets/images/viewer/page24/2142-01-24-01.png'),
                unMinted: {
                    color: require('assets/images/viewer/page24/pixelized/2142-01-24-01.webp'),
                    bw: require('assets/images/viewer/page24/pixelized-b-w/2142-01-24-01.webp')
                }
            }
        },
        128: {
            style: {
                width: 164,
                height: 112,
                left: 244,
                top: 13
            },
            image: {
                minted: require('assets/images/viewer/page24/2142-01-24-02.png'),
                unMinted: {
                    color: require('assets/images/viewer/page24/pixelized/2142-01-24-02.webp'),
                    bw: require('assets/images/viewer/page24/pixelized-b-w/2142-01-24-02.webp')
                }
            }
        },
        129: {
            style: {
                width: 102,
                height: 95,
                right: 10,
                top: 13
            },
            image: {
                minted: require('assets/images/viewer/page24/2142-01-24-03.png'),
                unMinted: {
                    color: require('assets/images/viewer/page24/pixelized/2142-01-24-03.webp'),
                    bw: require('assets/images/viewer/page24/pixelized-b-w/2142-01-24-03.webp')
                }
            }
        },
        130: {
            style: {
                width: 266,
                height: 105,
                right: 10,
                top: 99,
                zIndex: 1
            },
            image: {
                minted: require('assets/images/viewer/page24/2142-01-24-04.png'),
                unMinted: {
                    color: require('assets/images/viewer/page24/pixelized/2142-01-24-04.webp'),
                    bw: require('assets/images/viewer/page24/pixelized-b-w/2142-01-24-04.webp')
                }
            }
        },
        131: {
            style: {
                width: 158,
                height: 72,
                left: 11,
                zIndex: 1,
                top: 200
            },
            image: {
                minted: require('assets/images/viewer/page24/2142-01-24-05.png'),
                unMinted: {
                    color: require('assets/images/viewer/page24/pixelized/2142-01-24-05.webp'),
                    bw: require('assets/images/viewer/page24/pixelized-b-w/2142-01-24-05.webp')
                }
            }
        },
        132: {
            style: {
                width: 457,
                height: 479,
                left: 16,
                top: 176
            },
            image: {
                minted: require('assets/images/viewer/page24/2142-01-24-06.png'),
                unMinted: {
                    color: require('assets/images/viewer/page24/pixelized/2142-01-24-06.webp'),
                    bw: require('assets/images/viewer/page24/pixelized-b-w/2142-01-24-06.webp')
                }
            }
        },
        133: {
            style: {
                width: 135,
                height: 83,
                right: 8,
                top: 213
            },
            image: {
                minted: require('assets/images/viewer/page24/2142-01-24-07.png'),
                unMinted: {
                    color: require('assets/images/viewer/page24/pixelized/2142-01-24-07.webp'),
                    bw: require('assets/images/viewer/page24/pixelized-b-w/2142-01-24-07.webp')
                }
            }
        },
        134: {
            style: {
                width: 95,
                height: 100,
                left: 11,
                bottom: 40
            },
            image: {
                minted: require('assets/images/viewer/page24/2142-01-24-08.png'),
                unMinted: {
                    color: require('assets/images/viewer/page24/pixelized/2142-01-24-08.webp'),
                    bw: require('assets/images/viewer/page24/pixelized-b-w/2142-01-24-08.webp')
                }
            }
        },
        135: {
            style: {
                width: 105,
                height: 150,
                right: 10,
                bottom: 24
            },
            image: {
                minted: require('assets/images/viewer/page24/2142-01-24-09.png'),
                unMinted: {
                    color: require('assets/images/viewer/page24/pixelized/2142-01-24-09.webp'),
                    bw: require('assets/images/viewer/page24/pixelized-b-w/2142-01-24-09.webp')
                }
            }
        }
    },
    144: {
        137: {
            style: {
                width: 467,
                height: 279,
                left: 9,
                top: 14
            },
            image: {
                minted: require('assets/images/viewer/page25/2142-01-25-01.png'),
                unMinted: {
                    color: require('assets/images/viewer/page25/pixelized/2142-01-25-01.webp'),
                    bw: require('assets/images/viewer/page25/pixelized-b-w/2142-01-25-01.webp')
                }
            }
        },
        138: {
            style: {
                width: 251,
                height: 105,
                left: 2,
                top: 218
            },
            image: {
                minted: require('assets/images/viewer/page25/2142-01-25-02.png'),
                unMinted: {
                    color: require('assets/images/viewer/page25/pixelized/2142-01-25-02.webp'),
                    bw: require('assets/images/viewer/page25/pixelized-b-w/2142-01-25-02.webp')
                }
            }
        },
        139: {
            style: {
                width: 244,
                height: 83,
                right: 1,
                top: 226
            },
            image: {
                minted: require('assets/images/viewer/page25/2142-01-25-03.png'),
                unMinted: {
                    color: require('assets/images/viewer/page25/pixelized/2142-01-25-03.webp'),
                    bw: require('assets/images/viewer/page25/pixelized-b-w/2142-01-25-03.webp')
                }
            }
        },
        140: {
            style: {
                width: 165,
                height: 110,
                left: 16,
                top: 309
            },
            image: {
                minted: require('assets/images/viewer/page25/2142-01-25-04.png'),
                unMinted: {
                    color: require('assets/images/viewer/page25/pixelized/2142-01-25-04.webp'),
                    bw: require('assets/images/viewer/page25/pixelized-b-w/2142-01-25-04.webp')
                }
            }
        },
        141: {
            style: {
                width: 135,
                height: 114,
                left: 168,
                top: 296
            },
            image: {
                minted: require('assets/images/viewer/page25/2142-01-25-05.png'),
                unMinted: {
                    color: require('assets/images/viewer/page25/pixelized/2142-01-25-05.webp'),
                    bw: require('assets/images/viewer/page25/pixelized-b-w/2142-01-25-05.webp')
                }
            }
        },
        142: {
            style: {
                width: 183,
                height: 111,
                right: 11,
                top: 292
            },
            image: {
                minted: require('assets/images/viewer/page25/2142-01-25-06.png'),
                unMinted: {
                    color: require('assets/images/viewer/page25/pixelized/2142-01-25-06.webp'),
                    bw: require('assets/images/viewer/page25/pixelized-b-w/2142-01-25-06.webp')
                }
            }
        },
        143: {
            style: {
                width: 467,
                height: 264,
                left: 10,
                bottom: 14
            },
            image: {
                minted: require('assets/images/viewer/page25/2142-01-25-07.png'),
                unMinted: {
                    color: require('assets/images/viewer/page25/pixelized/2142-01-25-07.webp'),
                    bw: require('assets/images/viewer/page25/pixelized-b-w/2142-01-25-07.webp')
                }
            }
        }
    },
    153: {
        145: {
            style: {
                width: 469,
                height: 238,
                left: 10,
                top: 12
            },
            image: {
                minted: require('assets/images/viewer/page26/2142-01-26-01.png'),
                unMinted: {
                    color: require('assets/images/viewer/page26/pixelized/2142-01-26-01.webp'),
                    bw: require('assets/images/viewer/page26/pixelized-b-w/2142-01-26-01.webp')
                }
            }
        },
        146: {
            style: {
                width: 150,
                height: 79,
                left: 19,
                top: 194,
                zIndex: 1
            },
            image: {
                minted: require('assets/images/viewer/page26/2142-01-26-02.png'),
                unMinted: {
                    color: require('assets/images/viewer/page26/pixelized/2142-01-26-02.webp'),
                    bw: require('assets/images/viewer/page26/pixelized-b-w/2142-01-26-02.webp')
                }
            }
        },
        147: {
            style: {
                width: 136,
                height: 79,
                left: 176,
                top: 194,
                zIndex: 1
            },
            image: {
                minted: require('assets/images/viewer/page26/2142-01-26-03.png'),
                unMinted: {
                    color: require('assets/images/viewer/page26/pixelized/2142-01-26-03.webp'),
                    bw: require('assets/images/viewer/page26/pixelized-b-w/2142-01-26-03.webp')
                }
            }
        },
        148: {
            style: {
                width: 150,
                height: 85,
                top: 194,
                right: 18,
                zIndex: 1
            },
            image: {
                minted: require('assets/images/viewer/page26/2142-01-26-04.png'),
                unMinted: {
                    color: require('assets/images/viewer/page26/pixelized/2142-01-26-04.webp'),
                    bw: require('assets/images/viewer/page26/pixelized-b-w/2142-01-26-04.webp')
                }
            }
        },
        149: {
            style: {
                width: 468,
                height: 398,
                left: 10,
                top: 258
            },
            buyStyle: {
                width: 468,
                height: 398,
                left: 10,
                top: 258,
                alignItems: 'flex-start',
                paddingTop: '16%'
            },
            image: {
                minted: require('assets/images/viewer/page26/2142-01-26-05.png'),
                unMinted: {
                    color: require('assets/images/viewer/page26/pixelized/2142-01-26-05.webp'),
                    bw: require('assets/images/viewer/page26/pixelized-b-w/2142-01-26-05.webp')
                }
            }
        },
        150: {
            style: {
                width: 178,
                height: 84,
                left: 42,
                top: 417
            },
            image: {
                minted: require('assets/images/viewer/page26/2142-01-26-06.png'),
                unMinted: {
                    color: require('assets/images/viewer/page26/pixelized/2142-01-26-06.webp'),
                    bw: require('assets/images/viewer/page26/pixelized-b-w/2142-01-26-06.webp')
                }
            }
        },
        151: {
            style: {
                width: 183,
                height: 126,
                left: 21,
                bottom: 32
            },
            image: {
                minted: require('assets/images/viewer/page26/2142-01-26-07.png'),
                unMinted: {
                    color: require('assets/images/viewer/page26/pixelized/2142-01-26-07.webp'),
                    bw: require('assets/images/viewer/page26/pixelized-b-w/2142-01-26-07.webp')
                }
            }
        },
        152: {
            style: {
                width: 253,
                height: 219,
                right: 42,
                bottom: 22
            },
            image: {
                minted: require('assets/images/viewer/page26/2142-01-26-08.png'),
                unMinted: {
                    color: require('assets/images/viewer/page26/pixelized/2142-01-26-08.webp'),
                    bw: require('assets/images/viewer/page26/pixelized-b-w/2142-01-26-08.webp')
                }
            }
        }
    },
    157: {
        154: {
            style: {
                width: 468,
                height: 167,
                left: 10,
                top: 11
            },
            image: {
                minted: require('assets/images/viewer/page27/2142-01-27-01.png'),
                unMinted: {
                    color: require('assets/images/viewer/page27/pixelized/2142-01-27-01.webp'),
                    bw: require('assets/images/viewer/page27/pixelized-b-w/2142-01-27-01.webp')
                }
            }
        },
        155: {
            style: {
                width: 469,
                height: 134,
                left: 10,
                top: 188
            },
            image: {
                minted: require('assets/images/viewer/page27/2142-01-27-02.png'),
                unMinted: {
                    color: require('assets/images/viewer/page27/pixelized/2142-01-27-02.webp'),
                    bw: require('assets/images/viewer/page27/pixelized-b-w/2142-01-27-02.webp')
                }
            }
        },
        156: {
            style: {
                width: 468,
                height: 325,
                left: 10,
                bottom: 11
            },
            image: {
                minted: require('assets/images/viewer/page27/2142-01-27-03.png'),
                unMinted: {
                    color: require('assets/images/viewer/page27/pixelized/2142-01-27-03.webp'),
                    bw: require('assets/images/viewer/page27/pixelized-b-w/2142-01-27-03.webp')
                }
            }
        }
    },
    159: {
        158: {
            style: {
                width: 469,
                height: 644,
                left: 10,
                top: 12
            },
            image: {
                minted: require('assets/images/viewer/page28/2142-01-28-01.png'),
                unMinted: {
                    color: require('assets/images/viewer/page28/pixelized/2142-01-28-01.webp'),
                    bw: require('assets/images/viewer/page28/pixelized-b-w/2142-01-28-01.webp')
                }
            }
        }
    },
    167: {
        160: {
            style: {
                width: 349,
                height: 78,
                left: 10,
                top: 11
            },
            image: {
                minted: require('assets/images/viewer/page29/2142-01-29-01.png'),
                unMinted: {
                    color: require('assets/images/viewer/page29/pixelized/2142-01-29-01.webp'),
                    bw: require('assets/images/viewer/page29/pixelized-b-w/2142-01-29-01.webp')
                }
            }
        },
        161: {
            style: {
                width: 348,
                height: 138,
                left: 4,
                top: 82
            },
            image: {
                minted: require('assets/images/viewer/page29/2142-01-29-02.png'),
                unMinted: {
                    color: require('assets/images/viewer/page29/pixelized/2142-01-29-02.webp'),
                    bw: require('assets/images/viewer/page29/pixelized-b-w/2142-01-29-02.webp')
                }
            }
        },
        162: {
            style: {
                width: 118,
                height: 200,
                right: 12,
                top: 5
            },
            image: {
                minted: require('assets/images/viewer/page29/2142-01-29-03.png'),
                unMinted: {
                    color: require('assets/images/viewer/page29/pixelized/2142-01-29-03.webp'),
                    bw: require('assets/images/viewer/page29/pixelized-b-w/2142-01-29-03.webp')
                }
            }
        },
        163: {
            style: {
                width: 275,
                height: 149,
                left: 4,
                top: 232,
                zIndex: 1
            },
            image: {
                minted: require('assets/images/viewer/page29/2142-01-29-04.png'),
                unMinted: {
                    color: require('assets/images/viewer/page29/pixelized/2142-01-29-04.webp'),
                    bw: require('assets/images/viewer/page29/pixelized-b-w/2142-01-29-04.webp')
                }
            }
        },
        164: {
            style: {
                width: 199,
                height: 158,
                right: 5,
                top: 203,
                zIndex: 1
            },
            image: {
                minted: require('assets/images/viewer/page29/2142-01-29-05.png'),
                unMinted: {
                    color: require('assets/images/viewer/page29/pixelized/2142-01-29-05.webp'),
                    bw: require('assets/images/viewer/page29/pixelized-b-w/2142-01-29-05.webp')
                }
            }
        },
        165: {
            style: {
                width: 466,
                height: 326,
                left: 11,
                top: 237
            },
            image: {
                minted: require('assets/images/viewer/page29/2142-01-29-06.png'),
                unMinted: {
                    color: require('assets/images/viewer/page29/pixelized/2142-01-29-06.webp'),
                    bw: require('assets/images/viewer/page29/pixelized-b-w/2142-01-29-06.webp')
                }
            }
        },
        166: {
            style: {
                width: 479,
                height: 102,
                left: 4,
                bottom: 5
            },
            image: {
                minted: require('assets/images/viewer/page29/2142-01-29-07.png'),
                unMinted: {
                    color: require('assets/images/viewer/page29/pixelized/2142-01-29-07.webp'),
                    bw: require('assets/images/viewer/page29/pixelized-b-w/2142-01-29-07.webp')
                }
            }
        }
    },
    172: {
        168: {
            style: {
                width: 275,
                height: 242,
                left: 10,
                top: 10
            },
            image: {
                minted: require('assets/images/viewer/page30/2142-01-30-01.png'),
                unMinted: {
                    color: require('assets/images/viewer/page30/pixelized/2142-01-30-01.webp'),
                    bw: require('assets/images/viewer/page30/pixelized-b-w/2142-01-30-01.webp')
                }
            }
        },
        169: {
            style: {
                width: 224,
                height: 265,
                right: 10,
                top: 10
            },
            image: {
                minted: require('assets/images/viewer/page30/2142-01-30-02.png'),
                unMinted: {
                    color: require('assets/images/viewer/page30/pixelized/2142-01-30-02.webp'),
                    bw: require('assets/images/viewer/page30/pixelized-b-w/2142-01-30-02.webp')
                }
            }
        },
        170: {
            style: {
                width: 468,
                height: 155,
                left: 10,
                top: 231
            },
            image: {
                minted: require('assets/images/viewer/page30/2142-01-30-03.png'),
                unMinted: {
                    color: require('assets/images/viewer/page30/pixelized/2142-01-30-03.webp'),
                    bw: require('assets/images/viewer/page30/pixelized-b-w/2142-01-30-03.webp')
                }
            }
        },
        171: {
            style: {
                width: 468,
                height: 292,
                left: 10,
                bottom: 10
            },
            image: {
                minted: require('assets/images/viewer/page30/2142-01-30-04.png'),
                unMinted: {
                    color: require('assets/images/viewer/page30/pixelized/2142-01-30-04.webp'),
                    bw: require('assets/images/viewer/page30/pixelized-b-w/2142-01-30-04.webp')
                }
            }
        }
    },
    179: {
        173: {
            style: {
                width: 204,
                height: 168,
                left: 10,
                top: 12
            },
            buyStyle: {
                width: 204,
                height: 168,
                left: 10,
                top: 12,
                zIndex: 2
            },
            image: {
                minted: require('assets/images/viewer/page31/2142-01-31-01.png'),
                unMinted: {
                    color: require('assets/images/viewer/page31/pixelized/2142-01-31-01.webp'),
                    bw: require('assets/images/viewer/page31/pixelized-b-w/2142-01-31-01.webp')
                }
            }
        },
        174: {
            style: {
                width: 267,
                height: 167,
                left: 211,
                top: 12
            },
            image: {
                minted: require('assets/images/viewer/page31/2142-01-31-02.png'),
                unMinted: {
                    color: require('assets/images/viewer/page31/pixelized/2142-01-31-02.webp'),
                    bw: require('assets/images/viewer/page31/pixelized-b-w/2142-01-31-02.webp')
                }
            }
        },
        175: {
            style: {
                width: 455,
                height: 631,
                left: 16,
                top: 19,
                zIndex: 1
            },
            image: {
                minted: require('assets/images/viewer/page31/2142-01-31-03.png'),
                unMinted: {
                    color: require('assets/images/viewer/page31/pixelized/2142-01-31-03.webp'),
                    bw: require('assets/images/viewer/page31/pixelized-b-w/2142-01-31-03.webp')
                }
            }
        },
        176: {
            style: {
                width: 164,
                height: 161,
                left: 10,
                bottom: 12
            },
            buyStyle: {
                width: 164,
                height: 161,
                left: 10,
                bottom: 12,
                zIndex: 3
            },
            image: {
                minted: require('assets/images/viewer/page31/2142-01-31-04.png'),
                unMinted: {
                    color: require('assets/images/viewer/page31/pixelized/2142-01-31-04.webp'),
                    bw: require('assets/images/viewer/page31/pixelized-b-w/2142-01-31-04.webp')
                }
            }
        },
        177: {
            style: {
                width: 154,
                height: 131,
                left: 165,
                bottom: 12
            },
            image: {
                minted: require('assets/images/viewer/page31/2142-01-31-05.png'),
                unMinted: {
                    color: require('assets/images/viewer/page31/pixelized/2142-01-31-05.webp'),
                    bw: require('assets/images/viewer/page31/pixelized-b-w/2142-01-31-05.webp')
                }
            }
        },
        178: {
            style: {
                width: 177,
                height: 160,
                left: 301,
                bottom: 12
            },
            image: {
                minted: require('assets/images/viewer/page31/2142-01-31-06.png'),
                unMinted: {
                    color: require('assets/images/viewer/page31/pixelized/2142-01-31-06.webp'),
                    bw: require('assets/images/viewer/page31/pixelized-b-w/2142-01-31-06.webp')
                }
            }
        }
    },
    183: {
        180: {
            style: {
                width: 290,
                height: 248,
                left: 10,
                top: 12
            },
            image: {
                minted: require('assets/images/viewer/page32/2142-01-32-01.png'),
                unMinted: {
                    color: require('assets/images/viewer/page32/pixelized/2142-01-32-01.webp'),
                    bw: require('assets/images/viewer/page32/pixelized-b-w/2142-01-32-01.webp')
                }
            }
        },
        181: {
            style: {
                width: 248,
                height: 248,
                left: 231,
                top: 12
            },
            image: {
                minted: require('assets/images/viewer/page32/2142-01-32-02.png'),
                unMinted: {
                    color: require('assets/images/viewer/page32/pixelized/2142-01-32-02.webp'),
                    bw: require('assets/images/viewer/page32/pixelized-b-w/2142-01-32-02.webp')
                }
            }
        },
        182: {
            style: {
                width: 468,
                height: 387,
                left: 10,
                bottom: 12
            },
            image: {
                minted: require('assets/images/viewer/page32/2142-01-32-03.png'),
                unMinted: {
                    color: require('assets/images/viewer/page32/pixelized/2142-01-32-03.webp'),
                    bw: require('assets/images/viewer/page32/pixelized-b-w/2142-01-32-03.webp')
                }
            }
        }
    },
    188: {
        184: {
            style: {
                width: 460,
                height: 258,
                left: 15,
                top: 19
            },
            image: {
                minted: require('assets/images/viewer/page33/2142-01-33-01.png'),
                unMinted: {
                    color: require('assets/images/viewer/page33/pixelized/2142-01-33-01.webp'),
                    bw: require('assets/images/viewer/page33/pixelized-b-w/2142-01-33-01.webp')
                }
            }
        },
        185: {
            style: {
                width: 254,
                height: 178,
                left: 4,
                top: 230
            },
            image: {
                minted: require('assets/images/viewer/page33/2142-01-33-02.png'),
                unMinted: {
                    color: require('assets/images/viewer/page33/pixelized/2142-01-33-02.webp'),
                    bw: require('assets/images/viewer/page33/pixelized-b-w/2142-01-33-02.webp')
                }
            }
        },
        186: {
            style: {
                width: 247,
                height: 156,
                left: 234,
                top: 269
            },
            image: {
                minted: require('assets/images/viewer/page33/2142-01-33-03.png'),
                unMinted: {
                    color: require('assets/images/viewer/page33/pixelized/2142-01-33-03.webp'),
                    bw: require('assets/images/viewer/page33/pixelized-b-w/2142-01-33-03.webp')
                }
            }
        },
        187: {
            style: {
                width: 478,
                height: 255,
                left: 4,
                bottom: 19
            },
            image: {
                minted: require('assets/images/viewer/page33/2142-01-33-04.png'),
                unMinted: {
                    color: require('assets/images/viewer/page33/pixelized/2142-01-33-04.webp'),
                    bw: require('assets/images/viewer/page33/pixelized-b-w/2142-01-33-04.webp')
                }
            }
        }
    },
    194: {
        189: {
            style: {
                width: 271,
                height: 235,
                left: 10,
                top: 12,
                zIndex: 1
            },
            image: {
                minted: require('assets/images/viewer/page34/2142-01-34-01.png'),
                unMinted: {
                    color: require('assets/images/viewer/page34/pixelized/2142-01-34-01.webp'),
                    bw: require('assets/images/viewer/page34/pixelized-b-w/2142-01-34-01.webp')
                }
            }
        },
        190: {
            style: {
                width: 276,
                height: 130,
                left: 201,
                top: 11,
                zIndex: 2
            },
            image: {
                minted: require('assets/images/viewer/page34/2142-01-34-02.png'),
                unMinted: {
                    color: require('assets/images/viewer/page34/pixelized/2142-01-34-02.webp'),
                    bw: require('assets/images/viewer/page34/pixelized-b-w/2142-01-34-02.webp')
                }
            }
        },
        191: {
            style: {
                width: 196,
                height: 91,
                left: 290,
                top: 137,
                zIndex: 3
            },
            image: {
                minted: require('assets/images/viewer/page34/2142-01-34-03.png'),
                unMinted: {
                    color: require('assets/images/viewer/page34/pixelized/2142-01-34-03.webp'),
                    bw: require('assets/images/viewer/page34/pixelized-b-w/2142-01-34-03.webp')
                }
            }
        },
        192: {
            style: {
                width: 468,
                height: 147,
                left: 10,
                top: 238
            },
            image: {
                minted: require('assets/images/viewer/page34/2142-01-34-04.png'),
                unMinted: {
                    color: require('assets/images/viewer/page34/pixelized/2142-01-34-04.webp'),
                    bw: require('assets/images/viewer/page34/pixelized-b-w/2142-01-34-04.webp')
                }
            }
        },
        193: {
            style: {
                width: 467,
                height: 262,
                left: 10,
                bottom: 12
            },
            image: {
                minted: require('assets/images/viewer/page34/2142-01-34-05.png'),
                unMinted: {
                    color: require('assets/images/viewer/page34/pixelized/2142-01-34-05.webp'),
                    bw: require('assets/images/viewer/page34/pixelized-b-w/2142-01-34-05.webp')
                }
            }
        }
    }
};
