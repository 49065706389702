import styled from 'styled-components';

const AssetsStyled = styled.div`
    height: 100%;
    padding-top: 137px;
    padding-bottom: 50px;
    .title {
        max-width: fit-content;
        margin: 0 0 48px !important;
    }
`;

export { AssetsStyled };
