import React, { Component } from 'react';
// Helpers
import { withRouter } from 'helpers/common';
// Components
import { Market } from 'components/Market/Market';
// Styled Components
import { Container } from 'assets/styles/common/Container.styled.js';

class MarketPage extends Component {
    render() {
        return (
            <Container>
                <Market {...this.props} />
            </Container>
        );
    }
}

export default withRouter(MarketPage);
