import {
    SET_ASSETS_PANELS,
    SET_ASSETS_PAGES,
    SET_ASSETS_COVERS,
    SET_LOADING_PANELS,
    SET_LOADING_PAGES,
    SET_LOADING_COVERS
} from './actionTypes';

// Helpers
import { panels, pages, data } from 'helpers/episode1/showcase';
import { data as dataViewer } from 'helpers/episode1/viewer';
import { ASSETS_TYPE } from 'helpers/data';

const initialState = {
    allPage: null,
    allPanel: null,
    allCover: null,
    loadingPanels: false,
    loadingPages: false,
    loadingCovers: false
};

const Assets = (state = initialState, action) => {
    switch (action.type) {
        case SET_LOADING_PANELS: {
            state = {
                ...state,
                loadingPanels: true
            };
            break;
        }
        case SET_ASSETS_PANELS: {
            let arrPanels = [];
            action.payload?.forEach((panel) => {
                if (!Object.values(pages)?.includes(parseInt(panel['token_id']))) {
                    arrPanels.push({
                        id: panel.token_id,
                        amount: String(panel.amount),
                        title: `Panel ${panels[panel.token_id].panel}`,
                        description: `Page ${panels[panel.token_id].page}`,
                        image: data[pages[panels[panel.token_id].page]][panel.token_id].image.minted,
                        imageBig: dataViewer[pages[panels[panel.token_id].page]][panel.token_id].image.minted,
                        imagePixelized: dataViewer[pages[panels[panel.token_id].page]][panel.token_id].image.unMinted.color,
                        type: ASSETS_TYPE.PANEL
                    });
                }
            });

            state = {
                ...state,
                allPanel: [...arrPanels],
                loadingPanels: false
            };
            break;
        }
        case SET_LOADING_PAGES: {
            state = {
                ...state,
                loadingPages: true
            };
            break;
        }
        case SET_ASSETS_PAGES: {
            let arrPages = [];
            action.payload?.forEach((page) => {
                if (Object.values(pages)?.includes(parseInt(page['token_id']))) {
                    arrPages.push({
                        id: page.token_id,
                        amount: String(page.amount),
                        title: `Page ${panels[page.token_id].page}`,
                        pageID: panels[page.token_id].page,
                        episode: 'ISSUE #1',
                        type: ASSETS_TYPE.PAGE
                    });
                }
            });

            state = {
                ...state,
                allPage: [...arrPages],
                loadingPages: false
            };
            break;
        }
        case SET_LOADING_COVERS: {
            state = {
                ...state,
                loadingCovers: true
            };
            break;
        }
        case SET_ASSETS_COVERS: {
            const newCoversArr = action.payload?.map((cover) => {
                return {
                    id: cover.token_id,
                    amount: cover.amount,
                    type: ASSETS_TYPE.COVER
                };
            });

            state = {
                ...state,
                allCover: [...newCoversArr],
                loadingCovers: false
            };
            break;
        }

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default Assets;
