import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Modal
import Modal from '../Common/Modal';
// Helpers
import { getPureWidthAndHeight } from 'helpers/common';
import { data } from 'helpers/episode1/viewer';
import { data as thumnailData } from 'helpers/episode1/thumbnail';
// Components
import { ProgressBar } from 'components/Layout/Header/ProgressBar';
import Button from 'components/Common/Button';
// Images
import CloseButtonIcon from 'assets/images/svg/close.svg';
//import style
import {
    HeaderStyled,
    ContentStyled,
    SlideContainerStyled,
    ZoomStyled,
    SlideZoomContainer,
    SwiperZoomTargetWrapper
} from 'assets/styles/components/Viewer/index.styled';
import { ShowcaseItemImage, ShowcaseItemDiv } from 'assets/styles/components/Showcase/index.styled';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { Navigation, Pagination, Keyboard } from 'swiper';
//import image
import { ReactComponent as IconBuy } from 'assets/images/svg/buy.svg';
import ZoomOut from 'assets/images/zoom/1.svg';
import ZoomIn from 'assets/images/zoom/2.svg';
import Fit from 'assets/images/zoom/fit.svg';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

const Content = (props) => {
    // Constants
    const zoomConstant = 0.0006944444;
    const heightZoomConstant = 0.0008944444;
    const tallZoomConstant = 0.0011;
    const zoomLevels = [1, 1.5, 2, 2.5];
    // Refs
    const swiperRef = useRef(null);
    // States
    const [progressCollected, setProgressCollected] = useState(0);
    const [progressTotal, setProgressTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [hide, setHide] = useState(null);
    const [zoomLevel, setZoomLevel] = useState(1);
    const [zoomStep, setZoomStep] = useState(0);
    const [transform, setTransformZoom] = useState(0);
    const [topOffset, setTopOffset] = useState(0);
    const [childHeight, setChildHeight] = useState('100%');
    const [childWidth, setChildWidth] = useState('100%');
    const [ourChild, setOurChild] = useState();

    const navigate = useNavigate();

    useEffect(() => {
        handleWindowResize();
        resizeChildWrapper();

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        resizeChildWrapper();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [zoomLevel, zoomStep]);

    function resizeChildWrapper() {
        if (ourChild) {
            const dim = getPureWidthAndHeight(ourChild);
            const windowSizes = getWindowSize();
            const currentZoom = zoomStep === 0 ? zoomLevel : zoomLevels[zoomStep];

            setChildHeight(`${(windowSizes.innerHeight - 100) / currentZoom}px`);
            setChildWidth(`${dim.width / currentZoom}px`);
        }
    }

    function handleWindowResize() {
        const windowSizes = getWindowSize();
        if (windowSizes.innerHeight < 850) {
            setZoomLevel(windowSizes.innerHeight * heightZoomConstant);
            setTopOffset(-(850 - windowSizes.innerHeight) / 10);
        } else {
            if (windowSizes.innerWidth < 1440) {
                setZoomLevel(windowSizes.innerWidth * zoomConstant);
                setTopOffset(-(850 - windowSizes.innerHeight) / 23);
            } else {
                setZoomLevel(windowSizes.innerHeight * tallZoomConstant);
                setTopOffset(-(850 - windowSizes.innerHeight) / 13);
            }
        }
    }

    /**
     * getPanelImage
     * * Get minted or unminted image
     * @param {*} havePage
     * @param {*} haveElement
     * @param {*} value
     */
    const getPanelImage = (havePage, haveElement, value) => {
        if (haveElement) return { isBuy: false, image: value.image?.minted };

        if (havePage) {
            return { isBuy: true, image: value.image?.unMinted?.color };
        }

        return { isBuy: true, image: value.image?.unMinted?.bw };
    };

    /**
     * getThumnailPanelImage
     * * Get minted or unminted image
     * @param {*} havePage
     * @param {*} haveElement
     * @param {*} value
     */
    const getThumnailPanelImage = (havePage, haveElement, currentElement, currentIndex) => {
        const image = Object.entries(thumnailData)[currentIndex][1][currentElement];
        if (haveElement) return { style: image?.style, image: image?.image?.minted };

        if (havePage) {
            return { style: image?.style, image: image?.image?.unMinted?.color };
        }

        return { style: image?.style, image: image?.image?.unMinted?.bw };
    };

    var timeout;
    document.onmousemove = function () {
        clearTimeout(timeout);
        timeout = setTimeout(function () {
            setHide(true);
        }, 7000);
        setHide(false);
    };
    document.onclick = function () {
        setHide(false);
    };

    /**
     * getStatusColor
     * * Get color of status
     * @param {*} collected
     * @param {*} total
     */
    const getStatusColor = (collected = 2, total = 3) => {
        if (collected === 0) {
            return '#68899A';
        }
        if (collected < total) {
            return '#FF694A';
        }
        return '';
    };

    /**
     * objectToStringStyle
     * * Get css inline style from object style
     * @param {*} object
     */
    const objectToStringStyle = (object) => {
        var element = new Option();
        Object.entries(object).forEach(([key, value]) => {
            element.style[key] = value;
            if (!['zIndex', 'alignItems', 'justifyContent'].includes(key) || typeof value === 'number') {
                element.style[key] = value + 'px';
            }
        });
        return element.getAttribute('style');
    };

    /**
     * getWindowSize
     * * Get viewport width and height
     * @returns { innerWidth, innerHeight }
     */
    const getWindowSize = () => {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    };

    /**
     * zoomIn
     * * Set zoomStep to greater step
     */
    const zoomIn = () => {
        if (zoomLevels.length > zoomStep + 1) {
            setZoomStep(zoomStep + 1);
            setTransformZoom((zoomStep + 1) * 30);
        }
    };

    /**
     * resetZoom
     * * Set zoomStep to initial value
     */
    const resetZoom = () => {
        setZoomStep(0);
        setTransformZoom(0);
    };

    /**
     * zoomOut
     * * Set zoomStep to lower step
     */
    const zoomOut = () => {
        if (zoomStep > 0) {
            setZoomStep(zoomStep - 1);
            setTransformZoom((zoomStep - 1) * 30);
        }
    };

    return (
        <ContentStyled hide={hide}>
            <HeaderStyled hide={hide}>
                <div className="issue-number">
                    <p className="mb-0 page p-16 font-weight-700">
                        Page {currentPage} - <span className="issue p-16 font-weight-700">Issue 1</span>
                    </p>
                </div>
                <div className="viewer-page-progress-container">
                    <p className="mb-0 page-assembly p-12 font-weight-800">PAGE ASSEMBLY</p>
                    <ProgressBar
                        className={`showcase-item-status-progress-bar${
                            progressCollected === progressTotal ? 'showcase-item-status-progress-bar-completed' : ''
                        }`}
                        collected={progressCollected}
                        maxAssets={progressTotal}
                        hideLabel={true}
                    />
                    <div
                        className="font-weight-800 p-12"
                        style={{
                            color: getStatusColor(progressCollected, progressTotal)
                        }}>
                        {progressCollected < progressTotal ? (
                            `${progressCollected}/${progressTotal}`
                        ) : (
                            <div className="showcase-item-status-collected"></div>
                        )}
                    </div>
                </div>
                <Button small onClick={() => Modal.hide()} outline>
                    <img src={CloseButtonIcon} alt="Close button" />
                </Button>
            </HeaderStyled>
            <div className="viewer-slider-wrapper">
                <Swiper
                    ref={swiperRef}
                    observer={true}
                    observeParents={true}
                    spaceBetween={21}
                    slidesPerView={2.9}
                    centeredSlides={true}
                    breakpoints={{
                        1280: {
                            slidesPerView: 2.8
                        },
                        1500: {
                            slidesPerView: 3
                        },
                        1620: {
                            slidesPerView: 3.2
                        },
                        1800: {
                            slidesPerView: 3.5
                        },
                        1920: {
                            slidesPerView: 3.7
                        },
                        2100: {
                            slidesPerView: 3.8
                        },
                        2300: {
                            slidesPerView: 4.3
                        },
                        2500: {
                            slidesPerView: 4.6
                        },
                        2700: {
                            slidesPerView: 4.8
                        }
                    }}
                    navigation={true}
                    pagination={{
                        clickable: true,
                        type: 'bullets',
                        renderBullet: function (index, className) {
                            const currentPage = Object.entries(data)[index][0];
                            const currentPageEl = Object.keys(Object.entries(data)[index][1]);
                            let havePage = props.arr.includes(parseInt(currentPage));
                            return `<div class="viewer-slider-pagination-item ${className}">
                                    <div class="viewer-slider-pagination-item-thumbail" ${
                                        havePage ? 'style="background-color: white"' : ''
                                    }>
                                        ${currentPageEl?.map((currentElement) => {
                                            let haveEl = props.arr.includes(parseInt(currentElement));
                                            let { style, image } = getThumnailPanelImage(havePage, haveEl, currentElement, index);
                                            return `<div style="${objectToStringStyle(
                                                style
                                            )}; background-image: url('${image}')" class="viewer-slider-pagination-thumbail-image"></div>`;
                                        })}
                                    </div>
                                    <div class="viewer-slider-pagination-item-number font-weight-800 p-12">${index + 1}</div>
                                </div>`;
                        }
                    }}
                    keyboard={true}
                    loop={true}
                    modules={[Navigation, Keyboard, Pagination]}
                    onSlideChange={(swiper) => {
                        const currentPage = Object.entries(data)[swiper.realIndex][0];
                        const currentPageEl = Object.keys(Object.entries(data)[swiper.realIndex][1]);
                        setProgressTotal(currentPageEl.length + 1);
                        const countHandler = () => {
                            let i = 0;
                            props.arr.includes(parseInt(currentPage)) && i++;
                            currentPageEl.map((current) => {
                                let haveEl = props.arr.includes(parseInt(current));
                                return haveEl && i++;
                            });
                            return i;
                        };
                        setOurChild(swiper.slides[swiper.activeIndex].getElementsByClassName('swiper-zoom-container')[0]);

                        setProgressCollected(countHandler);
                        setCurrentPage(swiper.realIndex + 1);
                        resetZoom();
                    }}
                    onInit={() => {
                        Modal.setLoading(false);
                    }}
                    onSwiper={(swiper) => {
                        swiper.slideToLoop(props.slideId, 200, false);
                    }}>
                    {Object.entries(data)?.map(([pageKey, value], index) => {
                        let havePage = props.arr.includes(parseInt(pageKey));
                        return (
                            <SwiperSlide key={index}>
                                {({ isActive }) => (
                                    <SlideZoomContainer
                                        className="swiper-zoom-container"
                                        isActive={isActive}
                                        style={{
                                            transform: `translate3d(0px, ${
                                                isActive ? (zoomStep === 0 ? topOffset : transform) : topOffset
                                            }%, 0px) scale(${isActive ? (zoomStep === 0 ? zoomLevel : zoomLevels[zoomStep]) : zoomLevel})`
                                        }}>
                                        <SwiperZoomTargetWrapper
                                            width={isActive ? (zoomStep === 0 ? '100%' : childWidth) : '100%'}
                                            height={isActive ? (zoomStep === 0 ? '100%' : childHeight) : '100%'}>
                                            <SlideContainerStyled className="swiper-zoom-target" havePage={havePage} current={isActive}>
                                                {Object.keys(value)?.length &&
                                                    Object.entries(value)?.map(([panelKey, value]) => {
                                                        let haveElement = props.arr.includes(parseInt(panelKey));
                                                        let { isBuy, image } = getPanelImage(havePage, haveElement, value);

                                                        if (!isBuy) {
                                                            return (
                                                                <ShowcaseItemImage
                                                                    style={{
                                                                        ...value?.style
                                                                    }}
                                                                    image={image}
                                                                    havePage={havePage}
                                                                    key={panelKey}
                                                                />
                                                            );
                                                        } else {
                                                            return (
                                                                <ShowcaseItemDiv
                                                                    textColor={havePage}
                                                                    key={panelKey}
                                                                    style={{
                                                                        ...(value?.buyStyle || value?.style)
                                                                    }}
                                                                    image={image}>
                                                                    <div
                                                                        className="buy-wrapper"
                                                                        onClick={() => {
                                                                            if (isActive) {
                                                                                Modal.hide();
                                                                                navigate(`market/details/panel/${panelKey}`, {
                                                                                    replace: true
                                                                                });
                                                                            }
                                                                        }}>
                                                                        <IconBuy />
                                                                        <div className="buy-label p-16 font-weight-800">BUY</div>
                                                                    </div>
                                                                </ShowcaseItemDiv>
                                                            );
                                                        }
                                                    })}
                                            </SlideContainerStyled>
                                        </SwiperZoomTargetWrapper>
                                    </SlideZoomContainer>
                                )}
                            </SwiperSlide>
                        );
                    })}
                </Swiper>

                <ZoomStyled className="swiper-zoom-buttons" hide={hide}>
                    <img
                        className="cursor-pointer"
                        onClick={() => {
                            zoomOut();
                        }}
                        src={ZoomOut}
                        alt="out"
                        style={{ opacity: zoomStep === 0 ? '0.3' : '1' }}
                    />
                    <img
                        className="cursor-pointer"
                        src={Fit}
                        alt="fit"
                        onClick={() => {
                            resetZoom();
                        }}
                    />
                    <img
                        className="cursor-pointer"
                        onClick={() => {
                            zoomIn();
                        }}
                        src={ZoomIn}
                        alt="in"
                        style={{ opacity: zoomStep === zoomLevels.length - 1 ? '0.3' : '1' }}
                    />
                </ZoomStyled>
            </div>
        </ContentStyled>
    );
};

const ViewerModal = () => {};
ViewerModal.Show = (slideId, arr) => {
    Modal.show(undefined, undefined, <Content slideId={slideId} arr={arr} />, undefined, {
        hasBack: false,
        hasClose: false,
        mobile: true,
        bgColor: true
    });
    Modal.setLoading(true);
    Modal.headerStyle({ marginBottom: 0 });
    Modal.contentStyle({ height: '100%' });
};
export default ViewerModal;
