import styled from 'styled-components';
const DropDownStyledWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 999997;
    margin: 0 !important;
    padding: 0 !important;
`;
const DropDownStyled = styled.div`
    position: fixed;
    display: flex;
    height: 180px;
    top: 100px;
    left: 0;
    width: 100vw;
    padding: 0 180px;
    transition: opacity 1s ease-in-out;
    opacity: 0;
    z-index: -5;
    &.classAdd {
        opacity: 1;
        z-index: 999999;
    }
`;

export { DropDownStyled, DropDownStyledWrapper };
