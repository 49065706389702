import React from 'react';

import { InputContainer } from 'assets/styles/common/Input.styled.js';

const Input = (props) => {
    return (
        <InputContainer error={props.error} className={props.className ? props.className : ''}>
            <input
                min={props.min}
                max={props.max}
                disabled={props.disabled}
                value={props.value}
                name={props.name}
                className="input"
                placeholder=" "
                autoComplete="off"
                id={props.id}
                type={props.type || 'text'}
                onChange={props.onChange}
            />
            <label htmlFor={props.id} className="label">
                {props.placeholder}
            </label>
            {props.status && <p className="p-14 mb-0 input-status">{props.status}</p>}
        </InputContainer>
    );
};

export default Input;
