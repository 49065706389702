import React, { useState, useEffect, useId } from 'react';

// Libs
import { useSelector, useDispatch } from 'react-redux';
import { BigNumber } from 'ethers';
// Helpers
import { ASSETS_TYPE, ASSETS_DETAILS_PAGES, MARKETPLACE_SORT } from 'helpers/data';
import { getCoverMetadata } from 'helpers/common';
// Actions
import { getListing } from 'store/actions';
// Provider
import { useAddress } from '@thirdweb-dev/react';
import { useMoralisDapp } from 'providers/MoralisDappProvider/provider';
import { useMP } from 'providers/MarketplaceProvider/provider';
// Components
import Loading from 'components/Common/Loading';
import Button from 'components/Common/Button';
import Radio from 'components/Common/Radio';
import SellAssetModal from 'components/Modals/SellAssetModal';
// import LimitModal from 'components/Modals/LimitModal';
import Loader from 'components/Common/Loader';
// Styled Components
import {
    AssetsDetailsContainer,
    AssetsDetailsBackContainer,
    AssetsDetailsItemContainer,
    AssetsDetailsItemImageContainer,
    AssetsDetailsItemImage,
    AssetsDetailsItemDataContainer,
    AssetsDetailsItemListForSaleContainer,
    AssetsDetailsItemSaleButton,
    AssetsDetailsPriceInfosContainer
} from 'assets/styles/pages/AssetsDetails/index.styled';
// Images
import ArrowBack from 'assets/images/svg/arrow-back-white.svg';
import StatisticsPriceIcon from 'assets/images/svg/statistics-price.svg';
import PageIcon from 'assets/images/assets/assets-page.png';
import OrderIcon from 'assets/images/comic/market/ico-order.svg';

/**
 * AssetsDetails
 * * Component for single asset details
 * @param {*} props
 * @returns
 */
const AssetsDetails = (props) => {
    // Constants
    const options = [
        { value: 'last_30_sales', label: 'Last 30 sales' },
        { value: 'last_30_sales_1', label: 'Last 30 sales 1' }
    ];
    // States
    const [currentAsset, setCurrentAsset] = useState(null);

    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [salesFilter, setSalesFilter] = useState(options[0]);
    const [showPrices, setShowPrices] = useState(false);
    const [waitingForBuy, setWaitingForBuy] = useState(false);

    // Reducer
    const { allPage, allPanel, allCover } = useSelector((state) => state.Assets);
    const { forSale } = useSelector((state) => state.Marketplace);
    // Hooks
    const address = useAddress();
    const { chainId } = useMoralisDapp();

    /**
     * getCovers
     * * Method for get covers
     * @param {object} values Cover information
     * @param {string} token Token of asset
     * @returns
     */
    const getCovers = async (values, token) => {
        try {
            const data = await getCoverMetadata(chainId, token);
            setCurrentAsset({ ...values, ...data });
        } catch (err) {
            console.log(err);
        }
    };

    /**
     * getAssetInformation
     * * Method for get single asset information by type and id
     */
    const getAssetInformation = () => {
        try {
            if (props.params?.page) {
                if ([ASSETS_DETAILS_PAGES.ASSETS].includes(props.params?.page)) {
                    if (props.params?.type && props.params?.id && (allPage?.length || allPanel?.length || allCover?.length)) {
                        switch (props.params?.type) {
                            case ASSETS_TYPE.PANEL: {
                                let panel = allPanel?.find((panel) => parseInt(panel.id) === parseInt(props.params?.id));
                                if (panel && Object.keys(panel)?.length) {
                                    setCurrentAsset({ ...panel, type: props.params?.type });
                                } else {
                                    props.history(`/${props.params?.page}`, { replace: true });
                                }
                                break;
                            }
                            case ASSETS_TYPE.PAGE: {
                                let page = allPage?.find((page) => parseInt(page.id) === parseInt(props.params?.id));
                                if (page && Object.keys(page)?.length) {
                                    setCurrentAsset({ ...page, type: props.params?.type });
                                } else {
                                    props.history(`/${props.params?.page}`, { replace: true });
                                }
                                break;
                            }
                            case ASSETS_TYPE.COVER: {
                                let cover = allCover?.find((cover) => parseInt(cover.id) === parseInt(props.params?.id));
                                if (cover && Object.keys(cover)?.length) {
                                    getCovers({ ...cover, type: props.params?.type }, props.params.id);
                                } else {
                                    props.history(`/${props.params?.page}`, { replace: true });
                                }
                                break;
                            }
                            default: {
                                props.history(`/${props.params?.page}`, { replace: true });
                                break;
                            }
                        }
                    }
                } else if ([ASSETS_DETAILS_PAGES.MARKET].includes(props.params?.page)) {
                    if (props.params?.type && props.params?.id && forSale && forSale?.length) {
                        switch (props.params?.type) {
                            case ASSETS_TYPE.PANEL: {
                                let panel = forSale?.find((asset) => {
                                    let id = BigNumber.from(asset.id).toNumber();

                                    return asset.type === ASSETS_TYPE.PANEL && id === parseInt(props.params.id);
                                });
                                if (panel && Object.keys(panel)?.length) {
                                    setCurrentAsset(panel);
                                } else {
                                    props.history(`/${props.params?.page}`, { replace: true });
                                }
                                break;
                            }
                            case ASSETS_TYPE.PAGE: {
                                let page = forSale?.find((asset) => {
                                    let id = BigNumber.from(asset.id).toNumber();

                                    return asset.type === ASSETS_TYPE.PAGE && id === parseInt(props.params.id);
                                });
                                if (page && Object.keys(page)?.length) {
                                    setCurrentAsset(page);
                                } else {
                                    props.history(`/${props.params?.page}`, { replace: true });
                                }
                                break;
                            }
                            case ASSETS_TYPE.COVER: {
                                let cover = forSale?.find((asset) => {
                                    let id = BigNumber.from(asset.id).toNumber();

                                    return asset.type === ASSETS_TYPE.COVER && id === parseInt(props.params.id);
                                });
                                if (cover && Object.keys(cover)?.length) {
                                    getCovers(cover, props.params.id);
                                } else {
                                    props.history(`/${props.params?.page}`, { replace: true });
                                }
                                break;
                            }
                            default: {
                                props.history(`/${props.params?.page}`, { replace: true });
                                break;
                            }
                        }
                    }
                }
            } else {
                throw Object.assign(new Error('Bad page name'));
            }
        } catch (error) {
            console.error(error);
            props.history('/comic', { replace: true });
        }
    };

    // On component mount get asset by type and id
    useEffect(() => {
        getAssetInformation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getAssetInformation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allPage, allPanel, allCover, address, forSale]);

    /**
     * renderAssetItem
     * * Method for get asset image or video
     */
    const renderAssetItem = () => {
        try {
            switch (props.params?.type) {
                case ASSETS_TYPE.COVER: {
                    if (currentAsset?.animation_url) {
                        return (
                            <div className="asset-card-video-page">
                                <video
                                    type="video/mp4"
                                    width={'100%'}
                                    height={'100%'}
                                    src={currentAsset?.animation_url}
                                    autoPlay={true}
                                    controls={false}
                                    alt={currentAsset?.name}
                                    loop={true}
                                    muted={true}
                                />
                            </div>
                        );
                    }

                    return (
                        <div className="asset-details-image-loader">
                            <Loader />
                        </div>
                    );
                }
                case ASSETS_TYPE.PANEL: {
                    // Check if image is vertical or horizontal position
                    let assetImage;
                    switch (props.params?.page) {
                        case ASSETS_DETAILS_PAGES.ASSETS: {
                            assetImage = currentAsset?.imageBig;
                            break;
                        }
                        case ASSETS_DETAILS_PAGES.MARKET: {
                            assetImage = currentAsset?.imagePixelized;
                            break;
                        }
                        default: {
                            assetImage = currentAsset?.imagePixelized;
                            break;
                        }
                    }
                    const img = new Image();
                    img.src = assetImage;
                    img.onload = function () {
                        setWidth(parseInt(this.width));
                        setHeight(parseInt(this.height));
                    };

                    if (assetImage) {
                        return (
                            <img
                                src={assetImage}
                                className={`${width > height ? 'horizontal-asset' : 'vertical-asset'}`}
                                alt={`Assets Details Item ${props.params?.id}`}
                            />
                        );
                    }

                    return (
                        <div className="asset-details-image-loader">
                            <Loader />
                        </div>
                    );
                }
                case ASSETS_TYPE.PAGE: {
                    return (
                        <div className="asset-card-page">
                            <img src={PageIcon} alt={`ID of page ${props.params?.id}`} className="asset-card-image" />
                            <div className="asset-card-page-id p-28 font-weight-800">{`#${currentAsset?.pageID}`}</div>
                            <div className="asset-card-page-episode p-16 font-weight-800">{`${currentAsset?.episode}`}</div>
                        </div>
                    );
                }
                default:
                    return <Loader className="asset-details-image-loader" />;
            }
        } catch (error) {
            console.error(error);
        }
    };

    const renderAssetsDetailsButton = () => {
        if (props.params?.page) {
            switch (props.params?.page) {
                case ASSETS_DETAILS_PAGES.ASSETS: {
                    return (
                        <Button onClick={() => SellAssetModal.Show(currentAsset)} className="assets-details-item-list-for-sale-button">
                            List for sale
                        </Button>
                    );
                }
                case ASSETS_DETAILS_PAGES.MARKET: {
                    return (
                        <Button onClick={() => setShowPrices(true)} className="assets-details-item-list-for-sale-button">
                            Select and buy
                        </Button>
                    );
                }
                default:
                    return null;
            }
        }

        return null;
    };

    return (
        <AssetsDetailsContainer>
            <AssetsDetailsBackContainer
                onClick={() => {
                    switch (props.params?.page) {
                        case ASSETS_DETAILS_PAGES.MARKET: {
                            props.history(`/${props.params?.page ? props.params?.page : 'comic'}`, {
                                replace: true,
                                state: props.location.state || {}
                            });
                            break;
                        }
                        case ASSETS_DETAILS_PAGES.ASSETS: {
                            props.history(`/${props.params?.page ? props.params?.page : 'comic'}`, { replace: true });
                            break;
                        }
                        default:
                            break;
                    }
                }}>
                <img src={ArrowBack} alt="Assets Details Back Button" />
                <span className="p-12 font-weight-700">BACK TO ASSETS LIST</span>
            </AssetsDetailsBackContainer>
            <AssetsDetailsItemContainer>
                <AssetsDetailsItemImageContainer>
                    <AssetsDetailsItemImage>{renderAssetItem()}</AssetsDetailsItemImage>
                </AssetsDetailsItemImageContainer>
                <AssetsDetailsItemDataContainer>
                    <div className="assets-details-item-data-position">
                        {currentAsset?.title && (
                            <span className="assets-details-item-data-position-title p-24 font-weight-800">{currentAsset?.title}</span>
                        )}
                        {currentAsset?.description && (
                            <span className="assets-details-item-data-position-description p-16 font-weight-500">
                                {currentAsset?.description}
                            </span>
                        )}
                    </div>
                    {showPrices && props.params?.page === ASSETS_DETAILS_PAGES.MARKET ? (
                        <AssetsDetailsPriceInfos
                            asset={currentAsset}
                            id={currentAsset.id}
                            type={props.params?.type}
                            setShowPrices={setShowPrices}
                            waitingForBuy={waitingForBuy}
                            setWaitingForBuy={setWaitingForBuy}
                            currentAsset={currentAsset}
                        />
                    ) : (
                        <>
                            <AssetsDetailsSaleInfos
                                asset={currentAsset}
                                salesFilter={salesFilter}
                                setSalesFilter={setSalesFilter}
                                options={options}
                            />
                            <AssetsDetailsItemSaleButton>{renderAssetsDetailsButton()}</AssetsDetailsItemSaleButton>
                        </>
                    )}
                </AssetsDetailsItemDataContainer>
            </AssetsDetailsItemContainer>
        </AssetsDetailsContainer>
    );
};

export default AssetsDetails;

const AssetsDetailsSaleInfos = (props) => {
    return (
        <>
            <div className="assets-details-item-data-sell">
                {/* <div className="assets-details-item-data-sold-by p-16 font-weight-500">
                    <p className="assets-details-item-data-sold-by-label">Sold by</p>
                    <p className="assets-details-item-data-sold-by-value">1779 people</p>
                </div> */}
                <div className="assets-details-item-data-for-sale">
                    <p className="assets-details-item-data-for-sale-value p-24 font-weight-700">{props.asset?.forSale}</p>
                    <p className="assets-details-item-data-for-sale-label p-16 font-weight-500">For sale</p>
                </div>
            </div>

            {/* <AssetsDetailsItemGraphContainer>
                <div className="asset-details-select">
                    <Select defaultValue={props.salesFilter} onChange={props.setSalesFilter} options={props.options} />
                </div>
                <AssetSalesChart id={Math.random()} data={FAKE_CHART_DATA} activeFilter={props.salesFilter} />
            </AssetsDetailsItemGraphContainer> */}

            <AssetsDetailsItemListForSaleContainer>
                <div className="assets-details-item-statistics-price-container">
                    {/* <div className="assets-details-item-statistics-price-item">
                        <div className="assets-details-item-statistics-price-item-value p-16 font-weight-700">
                            <img src={StatisticsPriceIcon} alt={`Statistics price item 1`} />
                            <span>0.005</span>
                        </div>
                        <p className="assets-details-item-statistics-price-item-label p-14">Last 30 sales</p>
                    </div> */}
                    <div className="assets-details-item-statistics-price-item">
                        <div className="assets-details-item-statistics-price-item-value p-16 font-weight-700">
                            <img src={StatisticsPriceIcon} alt={`Statistics price item 1`} />
                            <span>{props.asset?.lowestAsk || 0}</span>
                        </div>
                        <p className="assets-details-item-statistics-price-item-label p-14">Lowest ask</p>
                    </div>
                    <div className="assets-details-item-statistics-price-item">
                        <div className="assets-details-item-statistics-price-item-value p-16 font-weight-700">
                            <img src={StatisticsPriceIcon} alt={`Statistics price item 1`} />
                            <span>{props.asset?.topSale || 0}</span>
                        </div>
                        <p className="assets-details-item-statistics-price-item-label p-14">Top sale</p>
                    </div>
                </div>
            </AssetsDetailsItemListForSaleContainer>
        </>
    );
};

const AssetsDetailsPriceInfos = (props) => {
    // Hooks
    const { buyAsset, isProcessingToBuy } = useMP();
    // States
    const [sortPrice, setSortPrice] = useState(MARKETPLACE_SORT.LOW);
    const [element, setElement] = useState(undefined);
    const [selectedPrice, setSelectedPrice] = useState('');
    const [selectedPriceItem, setSelectedPriceItem] = useState('');
    const id = useId();
    // Redux
    const dispatch = useDispatch();
    // const { singleListing } = useSelector((state) => state.Marketplace);

    useEffect(() => {
        dispatch(getListing(props.id));
        props.setWaitingForBuy(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AssetsDetailsPriceInfosContainer>
            <div className="assets-details-price-infos-title">
                <span className="p-14 font-weight-600">Select panel to buy</span>
                <div
                    className="assets-on-sale-sort-container"
                    onClick={() => {
                        if (sortPrice === MARKETPLACE_SORT.LOW) {
                            setSortPrice(MARKETPLACE_SORT.HIGH);
                        } else {
                            setSortPrice(MARKETPLACE_SORT.LOW);
                        }
                    }}>
                    <img src={OrderIcon} alt="order icon" />
                    <p className="p-12 font-weight-500">{sortPrice}</p>
                </div>
            </div>

            <div className="assets-details-price-info-labels-container">
                <span className="p-10 font-weight-500">Price</span>
                <span className="p-10 font-weight-500">Owner's wallet</span>
            </div>

            <div className="assets-details-price-info-select-container scrollBar">
                {props.asset?.priceList
                    ?.sort((a, b) => {
                        if (parseFloat(a.price) < parseFloat(b.price)) {
                            return sortPrice === MARKETPLACE_SORT.LOW ? -1 : 1;
                        }
                        if (parseFloat(a.price) > parseFloat(b.price)) {
                            return sortPrice === MARKETPLACE_SORT.LOW ? 1 : -1;
                        }
                        return 0;
                    })
                    .map((item, key) => {
                        return (
                            <div className="assets-details-price-info-radio-container" onClick={() => setSelectedPriceItem(item)} key={key}>
                                <Radio
                                    id={id}
                                    name={`price-button`}
                                    label={`Ξ ${item?.price}`}
                                    className="price-radio-button"
                                    defaultChecked={selectedPrice}
                                    onChange={setSelectedPrice}
                                />
                                <div className={`address-long ${element === key ? 'd-block' : 'd-none'}`}>
                                    <span>{item?.address}</span>
                                </div>
                                <p
                                    onMouseOver={() => setElement(key)}
                                    onMouseOut={() => setElement(undefined)}
                                    className="address-short p-16 font-weight-500">{`...${item?.address?.slice(-10)}`}</p>
                            </div>
                        );
                    })}
            </div>

            {isProcessingToBuy && <Loading backgroundColor={'rgba(22, 50, 60, 0.3)'} title="Buying of the listing is in progress" />}

            <div className="assets-details-price-info-buttons-container">
                <Button outline outlineColored={'white'} onClick={() => props.setShowPrices(false)} className="m-0">
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        if (selectedPriceItem?.price && selectedPriceItem?.address && selectedPriceItem?.listingID) {
                            props.setWaitingForBuy(true);
                            buyAsset(selectedPriceItem?.listingID, `${props.currentAsset.title} ${props.currentAsset.description || ''}`);
                        }
                    }}
                    className="assets-details-price-info-button-buy"
                    disabled={!selectedPrice || isProcessingToBuy}
                    disabledCustom={'disabled-second'}>
                    {`Buy ${props.type || 'asset'}`}
                </Button>
            </div>
        </AssetsDetailsPriceInfosContainer>
    );
};
