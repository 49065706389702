import React, { Component } from 'react';

// Components
import { Landing } from 'components/Landing/Landing';

export class LandingPage extends Component {
    render() {
        return <Landing />;
    }
}

export default LandingPage;
