import styled from 'styled-components';

const MyListingsContainer = styled.div`
    position: relative;
    width: 100%;
    &.my-listings-no-assets {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .assets-my-listings-masonry {
        position: relative;
        width: 100%;
    }
`;

const HistoryContainer = styled.div`
    position: relative;
    width: 100%;
    &.history-listings-no-assets {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .assets-history-masonry {
        position: relative;
        width: 100%;
    }
`;

export { MyListingsContainer, HistoryContainer };
