import styled from 'styled-components';

import { COLORS } from 'assets/styles/common/Colors.styled';

const LoadingContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    flex: 1 !important;
    margin-bottom: 20px;
    margin-top: -20px;
`;
const LoadingWrapper = styled.div`
    max-width: 30em;
    width: 100%;
    height: 100%;
    padding: 20px;
    border-radius: 20px;
    margin: 0 auto;
    background: rgba(7, 12, 15, 0.3);
    text-align: center;
    > div {
        height: 55px;
        width: 55px;
    }
    .loading-create-listing-title {
        color: ${COLORS.white};
        margin-bottom: 8px;
    }
    .loading-create-listing-description {
        color: ${COLORS.red};
        text-align: center;
        margin: 0;
        line-height: 1.5em;
    }
`;

export { LoadingContainer, LoadingWrapper };
