import React from 'react';
// Icons
import SuccessIcon from 'assets/images/toast/success.svg';

const CancelListingToast = (props) => {
    return (
        <div className="toast-message-container">
            <div className="toast-message">
                {props.success && (
                    <div className="message-success-sell">
                        <span>All good!</span> <span>Your asset cancelled</span>
                        <span></span>
                    </div>
                )}
            </div>
            <div className="toast-icon t-success">
                <img src={SuccessIcon} alt="Success Icon" />
            </div>
        </div>
    );
};

export default CancelListingToast;
