import styled from 'styled-components';
//colors
import { COLORS } from 'assets/styles/common/Colors.styled';

const CoverWrapperStyled = styled.div`
    display: flex;
    align-items: center;
    video {
        border-radius: 4px;
    }
    .cover-card {
        display: flex;
        flex-direction: column;
        height: 274px;
        width: 176px;
        margin-right: 32px;
        .cover-card__image-wrapper {
            border: 3px solid ${(props) => (props.completed ? COLORS.primary : COLORS.borderGray)};
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }
        .cover-card__image {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 229px;
            width: ${(props) => (props.completed ? '170px' : '160px')};
            border-radius: 4px;
            margin: 5px auto;
            margin: ${(props) => (props.completed ? '0' : '5px auto')};
            background-image: url(${(props) => props.img});
            background-size: cover;
            background-repeat: no-repeat;
            .not-minted {
                background-color: ${COLORS.black};
                padding: 4px;
                border-radius: 4px;
                p {
                    color: ${COLORS.secondary};
                    margin-bottom: 0;
                }
            }
        }
        .cover-card__title {
            display: flex;
            justify-content: space-between;
            padding: 0 10px;
            width: 100%;
            height: 47px;
            background-color: ${(props) => (props.completed ? COLORS.primary : COLORS.borderGray)};
            color: ${COLORS.layout};
            border: 3px solid ${(props) => (props.completed ? COLORS.primary : COLORS.borderGray)};
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            .issue-number {
                text-align: right;
            }
        }
    }
    .cover-card__desc {
        max-width: 384px;
        .title {
            max-width: 21.5em;
            text-transform: uppercase;
        }
        .description-main {
            margin-top: 16px;
            margin-bottom: 0;
            line-height: 24px;
        }
    }
`;

export { CoverWrapperStyled };
