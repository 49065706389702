import React from 'react';

// Styled Components
import { Loader as LoaderStyled } from 'assets/styles/common/Loader.styled';

const Loader = (props) => {
    return (
        <LoaderStyled disableFlex={props.disableFlex} className={props.className}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </LoaderStyled>
    );
};

export default Loader;
