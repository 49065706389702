import React, { useState } from 'react';

// Libs
import Masonry from 'react-masonry-component';
// Helpers
import { ASSETS_TYPE } from 'helpers/data';
import { withRouter } from 'helpers/common';
// Components
import Checkbox from 'components/Common/Checkbox';
import Loader from 'components/Common/Loader';
import Card from './Card';
// Styled Component
import { AssetsSectionContainer, AssetsSectionMasonry } from 'assets/styles/components/Assets/index.styled';

const Covers = (props) => {
    // States
    const [showDuplicates, setShowDuplicates] = useState(false);
    const [numberOfShowenAssets, setNumberOfShowenAssets] = useState(props.pagination);
    const [disableShowAssets, setDisableShowAssets] = useState(false);

    return (
        <AssetsSectionContainer id="covers" marginHeader={disableShowAssets}>
            <div className="assets-header">
                <div className="assets-title font-weight-600">
                    <span>Covers</span> <span>({props.assets?.length ? props.assets?.length : 0})</span>
                </div>
                <Checkbox
                    name="duplicates-covers"
                    label="Show only duplicates"
                    defaultChecked={showDuplicates}
                    onChange={() => {
                        setShowDuplicates(!showDuplicates);
                        setNumberOfShowenAssets(props.pagination);
                        setDisableShowAssets(false);
                    }}
                />
            </div>
            {props.loading ? (
                <div className="assets-loading-wrapper">
                    <Loader className={'assets-loading'} />
                </div>
            ) : props.assets?.length ? (
                <AssetsSectionMasonry checkOverflow={disableShowAssets} className="scrollBar">
                    <Masonry
                        className="assets-masonry"
                        options={{
                            gutter: 25,
                            columnWidth: 176,
                            transitionDuration: 0,
                            resize: true
                        }}>
                        {props.assets
                            ?.sort((a, b) => a.id - b.id)
                            ?.slice(0, numberOfShowenAssets)
                            .map((asset, key) => {
                                if (showDuplicates && parseInt(asset.amount) <= 1) {
                                    return <React.Fragment key={key}></React.Fragment>;
                                }

                                return <Card history={props.history} type={ASSETS_TYPE.COVER} asset={asset} key={key} />;
                            })}
                    </Masonry>
                </AssetsSectionMasonry>
            ) : (
                <p className="assets-no-minted p-16 font-weight-600">No covers</p>
            )}
            {props.assets && props.assets?.length && props.assets?.length > 10 && !props.loading ? (
                <div
                    className={`assets-btn p-12 font-weight-800${disableShowAssets ? ' disabled-show-assets' : ''}`}
                    onClick={() => {
                        setNumberOfShowenAssets(props.assets?.length);
                        setDisableShowAssets(true);
                    }}>
                    <span>SHOW ALL COVERS</span>
                </div>
            ) : (
                <></>
            )}
        </AssetsSectionContainer>
    );
};

export default withRouter(Covers);
