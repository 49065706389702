import { combineReducers } from 'redux';

import Assets from './Assets/reducer';
import Marketplace from './Marketplace/reducer';

const rootReducer = combineReducers({
    Assets,
    Marketplace
});

export default rootReducer;
