import React from 'react';

// Libs
import Media from 'react-media';
// Helpers
import { TEAM_ITEMS } from 'helpers/data';
// Components
import Title from 'components/Common/Title';
import { Item } from './item';

const Team = () => {
    /**
     * getMembers
     * * This method return team members
     * @param index
     **/
    const getMembers = (index) => {
        return TEAM_ITEMS[index]?.map((member, key) => <Item {...member} key={key} />);
    };

    return (
        <Media queries={{ xs: '(max-width: 768px)' }}>
            {(matches) => {
                return (
                    <section id="team" className="team-container">
                        <div className="team-wrapper">
                            <Title
                                title={
                                    matches.xs ? (
                                        <>
                                            Meet <br /> the Team
                                        </>
                                    ) : (
                                        'Meet the Team'
                                    )
                                }
                                fontSize="display2"
                                textAlign="center"
                                tag="h2"
                            />
                            <div className="team-content">
                                <div className="d-flex justify-content-center team-items-first-row">{getMembers(0)}</div>
                                <div className="d-flex justify-content-between team-items-second-row">{getMembers(1)}</div>
                                <div className="d-flex justify-content-center team-items-first-row">{getMembers(2)}</div>
                            </div>
                        </div>
                    </section>
                );
            }}
        </Media>
    );
};

export default Team;
