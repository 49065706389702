import React from 'react';

import Description from 'components/Common/Description';

export const Item = (props) => {
    return (
        <div className="how-to-mint-item">
            <div className="how-to-mint-item-icon">
                {props.image && props.image}
            </div>
            <Description gradient tag="p" fontSize="20" textAlign="left" className="how-to-mint-description">
                {props.description}
            </Description>
            {props.ellipse && <div className="how-to-mint-ellipse-3"></div>}
        </div>
    );
};
