import React from 'react';
// Modal
import Modal from '../Common/Modal';
// Libs
import { useDisconnect } from '@thirdweb-dev/react';
// Components
import Title from 'components/Common/Title';
import DescriptionText from 'components/Common/Description';
import Button from 'components/Common/Button';
// Images
import { ReactComponent as NoComicIcon } from 'assets/images/modal/no-comic.svg';
import CloseButtonIcon from 'assets/images/svg/close.svg';

const Header = () => {
    return (
        <div className="responsive-header">
            <NoComicIcon />
        </div>
    );
};
const Description = (props) => {
    const disconnectFromMetamask = useDisconnect();

    return (
        <div className="responsive-page">
            {!props.freeMint && (
                <Button
                    small
                    onClick={() => {
                        Modal.hide();
                        disconnectFromMetamask();
                    }}
                    id="comic-modal-exit"
                    className="comic-modal-exit free-mint"
                    outline>
                    <img src={CloseButtonIcon} alt="Close button" />
                </Button>
            )}
            <div className="responsive-page-content">
                <Title tag="h2" fontSize="display2" textAlign="left" title="mobile experience is under way!" />
                <DescriptionText textAlign="left" tag="p" fontSize="18" gradient>
                    We are working on providing the best possible mobile experience for you. Until then, please use the desktop version of
                    the platform.
                </DescriptionText>
            </div>
        </div>
    );
};

const ResponsiveModal = () => {};
ResponsiveModal.Show = (freeMint) => {
    Modal.show(<Header />, <Description freeMint={freeMint} />, undefined, undefined, {
        hasBack: false,
        hasClose: freeMint ? freeMint : false,
        mobile: true
    });
    Modal.headerStyle({ marginBottom: 0 });
    Modal.contentStyle({ minHeight: 0 });
};
export default ResponsiveModal;
