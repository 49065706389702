import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

// Libs
import { useAddress } from '@thirdweb-dev/react';
//import styled
import { CoverWrapperStyled } from 'assets/styles/pages/ComicBook/ShowCase/Cover/index.styled';
import Title from 'components/Common/Title';
import Description from 'components/Common/Description';
//images
import PixelCard from 'assets/images/comic/show_case/card_pixel-image.png';
// Provider
import { useMoralisDapp } from 'providers/MoralisDappProvider/provider';
// Helpers
import { getCoverMetadata } from 'helpers/common';

export const Cover = () => {
    // store
    const { allCover } = useSelector((state) => state.Assets);

    const videoRef = useRef();
    const address = useAddress();
    const { chainId } = useMoralisDapp();
    // State
    const [data, setData] = useState({});
    const [coverId, setCoverId] = useState('');

    useEffect(() => {
        const completed = allCover.length ? allCover[allCover.length - 1] : false;
        setCoverId(completed.id);

        (async () => {
            try {
                const data = await getCoverMetadata(chainId, completed.id);
                setData(data);
            } catch (err) {
                console.log(err);
            }
        })();
    }, [address, chainId, allCover]);

    return (
        <CoverWrapperStyled img={!coverId && PixelCard} completed={coverId}>
            <div className="cover-card">
                <div className="cover-card__image-wrapper">
                    <div className="cover-card__image">
                        {coverId ? (
                            <video
                                type="video/mp4"
                                width={'100%'}
                                height={'100%'}
                                src={data.animation_url}
                                autoPlay={true}
                                ref={videoRef}
                                controls={false}
                                alt={data.name}
                                loop={true}
                                muted={true}
                            />
                        ) : (
                            <></>
                        )}

                        {!coverId && (
                            <div className="not-minted">
                                <p className="p-12 font-weight-900">NOT MINTED</p>
                            </div>
                        )}
                    </div>
                </div>
                <div className="cover-card__title">
                    <p className="hash-number p-27 font-weight-800">#{coverId}</p>
                    <p className="issue-number p-12 font-weight-800">
                        COVER PAGE <br></br> ISSUE 1
                    </p>
                </div>
            </div>
            <div className="cover-card__desc">
                <Title
                    notMargin
                    textTransform
                    textAlign
                    title="2142 AD - Issue #1 “As Above, So Below”"
                    fontSize={'display5'}
                    className="font-weight-900"
                    tag="h3"
                />
                <Description textAlign="left" tag="p" fontSize={16}>
                    While the world dwells in chaos and riots are raging across the western megalopolis MegaCali, three ancient entities
                    entered the University in Metaverse hunting for Soma, an almost-awakened young woman with a rebellious spirit.
                </Description>
            </div>
        </CoverWrapperStyled>
    );
};
