import styled from 'styled-components';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import { COLORS } from 'assets/styles/common/Colors.styled';

const MarketTabs = styled(Tabs)`
    margin-top: 24px;
    width: 100%;
`;

const MarketTabList = styled(TabList)`
    list-style-type: none;
    padding: 4px;
    display: flex;
    margin: 0;
    margin-bottom: 56px;
`;
MarketTabList.tabsRole = 'TabList';

const MarketTab = styled(Tab)`
    margin-right: 50px;
    user-select: none;
    padding-bottom: 8px;
    cursor: pointer;
    p {
        margin-bottom: 0;
    }
    &.is-selected {
        border-bottom: 2px solid ${COLORS.primary};
    }
    &:focus {
        outline: none;
    }
    &.my-listings-tab {
        position: relative;
        .my-listings-badge {
            position: absolute;
            top: -8px;
            left: 106%;
            min-width: 17px;
            min-height: 17px;
            padding: 3px;
            border-radius: 50%;
            background: ${COLORS.white};
            color: ${COLORS.black};
            text-align: center;
            line-height: 14px;
        }
    }
`;
MarketTab.tabsRole = 'Tab';

const MarketTabPanel = styled(TabPanel)`
    display: none;
    min-height: 40vh;
    padding: 4px;
    margin-top: -5px;
    &.is-selected {
        display: flex;
    }
    .marketplace-loading-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;
MarketTabPanel.tabsRole = 'TabPanel';

const AssetsOnSaleContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 34px;
`;

const AssetsOnSaleHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 16px;
    .assets-on-sale-sort-container {
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
            margin-right: 6px;
        }
        p {
            margin-bottom: 0;
            color: ${COLORS.morningBlue};
        }
    }
    .assets-on-sale-search {
        margin-bottom: 0;
        color: ${COLORS.blueLight};
    }
`;

const AssetsOnSaleMasonryContainer = styled.div`
    position: relative;
    margin-top: 23px;
    &.on-sale-listings-no-assets {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .assets-on-sale-masonry {
        position: relative;
        width: 100%;
    }
`;

export { MarketTabs, MarketTab, MarketTabList, MarketTabPanel, AssetsOnSaleContainer, AssetsOnSaleHeader, AssetsOnSaleMasonryContainer };
