import React from 'react';

// Libs
import Media from 'react-media';
// Components
import Title from 'components/Common/Title';
import Description from 'components/Common/Description';
import Slider from 'components/Common/Slider';

const Mechanics = () => {
    return (
        <Media queries={{ xs: '(max-width: 768px)' }}>
            {(matches) => {
                return (
                    <section id="mechanics" className="mechanics-container">
                        <div className="mechanics-content">
                            <Title
                                tag="h2"
                                title={
                                    matches.xs ? (
                                        <>
                                            Mint,
                                            <br /> collect and trade the whole Universe!
                                        </>
                                    ) : (
                                        `Mint, collect and trade the whole Universe`
                                    )
                                }
                                fontSize={'display2'}
                                className="mechanics-title"
                            />
                            <Description tag="p" fontSize={24} className="mechanics-description" gradient>
                                Assemble and collect comic book issues by minting and trading panels, pages, and covers.
                            </Description>
                            <Description tag="p" fontSize={20} className="mechanics-details-description" gradient>
                                Collect them all and burn them into one complete NTF webcomic issue to get ownership over unique NFT
                                characters, locations, and items featured in this futuristic psychedelic tour de force.
                            </Description>
                        </div>
                        <Slider />
                    </section>
                );
            }}
        </Media>
    );
};

export default Mechanics;
