import styled from 'styled-components';

import { COLORS } from 'assets/styles/common/Colors.styled';

const AssetsStatus = styled.div`
    display: flex;
    height: 22px;
    .number-of-assets {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 7px;
        .number {
            line-height: 12px;
            @media screen and (min-width: 1920px) {
                &:first-child {
                    margin-bottom: 1px;
                }
            }
        }
        .desc {
            color: ${COLORS.blueLight};
            line-height: 10px;
        }
    }
`;

const MiddleHeaderContent = styled.div`
    display: flex;
    width: 100%;
    padding: 0 62px 0 50px;
    margin: auto 0;
    white-space: nowrap;
    p {
        margin: 0;
    }
    .header-dropdown-wrapper {
        display: flex;
        padding: 4px 20px;
        border-radius: 16px;
        align-items: center;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        > p {
            margin-right: 8px;
        }
        > img {
            transition: 0.3s;
            transform: rotate(0deg);
        }
        .active-icon {
            transition: 0.3s;
            transform: rotate(180deg);
        }
        &.active {
            background-color: ${COLORS.priceButton};
        }
        &:hover {
            background-color: ${COLORS.priceButton};
        }
    }
    .progress-bar-wrapper {
        display: flex;
        margin-left: 50px;
        margin-right: 35px;
        width: 100%;
        min-width: 200px;
        .p-12 {
            color: #21ffef;
            font-weight: 800;
        }
    }
    .number-of-assets-wrapper {
        display: flex;
        margin: 0 0 0 auto;
        > * + * {
            margin-left: 23px;
        }
    }
`;

const StyledHeader = styled.header`
    position: fixed;
    height: 96px;
    display: flex;
    padding: 24px 24px 32px 24px;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1002;

    &:before {
        z-index: -1;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: ${(props) => (props.isExpand ? '100vh' : '120px')};
        transition: all 0.3s ease-in-out;
        background: ${(props) =>
            props.isExpand
                ? 'linear-gradient(180deg, rgba(11, 21, 27, 0.8) 0%, #0b151b 20%, rgba(11, 21, 27, 0) 100%)'
                : 'linear-gradient(180deg, #0b151b 16.6%, rgba(11, 21, 27, 0) 100%)'};
    }

    .header-minting-button {
        height: 40px;
        margin: 0;
        white-space: nowrap;
        @media screen and (min-width: 1920px) {
            padding: 0.5em 0.875em;
        }
    }
`;

const Nav = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 40px;
    .dao-navigation-link {
        font-size: 0.6875em;
        color: ${COLORS.morningBlue};
        text-transform: uppercase;
        letter-spacing: 0.04em;
        line-height: 10px;
        display: inline-block;
        position: relative;
        &::before {
            content: '';
            width: 100%;
            position: absolute;
            height: 2px;
            background-color: ${COLORS.primary};
            bottom: -8px;
            left: 0;
            transform-origin: bottom right;
            transition: transform 0.25s ease-out;
            transform: scaleX(0);
        }
        &.active {
            color: ${COLORS.primary};
            position: relative;
            &::before {
                transform: scaleX(1);
                transform-origin: bottom left;
            }
        }
        &:hover {
            color: ${COLORS.primary};
        }
    }
`;

export { StyledHeader, MiddleHeaderContent, AssetsStatus, Nav };
