import React, { useState } from 'react';
// Libs
import * as yup from 'yup';
// Common
import Modal from 'components/Common/Modal';
// Components
import Title from 'components/Common/Title';
import DescriptionText from 'components/Common/Description';
import Input from 'components/Common/Input';
import Button from 'components/Common/Button';
import Form from 'components/Common/Form';
import Card from 'components/Assets/sections/Card';
import ConfirmListings from './CustomModal/ConfirmListings';
// Style
import { DescriptionSellAsset, ContentSellAsset, ContainerSellAsset } from 'assets/styles/components/Modal/index.styled';
// Images
import Arrow from 'assets/images/arrowUp.png';
// Provider
import { useEthPrice } from 'providers/EthPrice/provider';

const Header = () => {
    return <Title tag="h2" fontSize="display5" textAlign="left" title="List asset for sale" />;
};
const Description = () => {
    return (
        <DescriptionSellAsset>
            <DescriptionText tag="p" textAlign="left" fontSize="16" gradient>
                Submitting this form will list this asset for sale on the marketplace, visible to all members to purchase for a set price
            </DescriptionText>
        </DescriptionSellAsset>
    );
};
const Content = (props) => {
    const [assetPrice, setAssetPrice] = useState('0$');
    const [values, setValues] = useState({
        quantity: props.amount > 1 ? '' : 1,
        price: ''
    });
    const ethPrice = useEthPrice();

    const validationSchema = (amount) => {
        return yup.object().shape({
            quantity:
                amount > 1
                    ? yup
                          .number()
                          .integer()
                          .positive()
                          .required()
                          .lessThan(amount + 1, `Amount must be less than ${amount + 1}`)
                          .moreThan(0, `Amount must be more than ${0}`)
                    : yup.string(),
            price: yup.number().positive().min(0).required()
        });
    };

    return (
        <Form
            enableReinitialize={true}
            initialValues={{
                quantity: values.quantity,
                price: values.price
            }}
            onSubmit={(values, actions) => {
                values &&
                    Modal.message(
                        <ConfirmListings
                            asset={props.asset}
                            amount={props.amount}
                            quantity={Number(values.quantity)}
                            price={Number(values.price)}
                            assetPrice={assetPrice}
                        />
                    );
            }}
            validationSchema={validationSchema(props.amount)}>
            {({ handleSubmit, values, errors, touched, setFieldValue }) => {
                return (
                    <ContainerSellAsset>
                        <ContentSellAsset hiddenCheckbox>
                            <Card
                                className="sell-asset-modal-card"
                                type={props.asset.type}
                                modal
                                hideFromSale
                                hideDuplicate
                                asset={props.asset}
                            />
                            <div className="sell-asset-form">
                                {props.amount > 1 ? (
                                    <p className="sell-asset-form-title p-14">
                                        Enter quantity below if you want to list for sale more than one duplicate of this panel
                                    </p>
                                ) : (
                                    <p className="sell-asset-form-title p-14">Enter the asking price below:</p>
                                )}

                                {props.amount > 1 && (
                                    <Input
                                        min="0"
                                        type="number"
                                        error={errors['quantity'] && touched['quantity']}
                                        onChange={(e) => {
                                            setFieldValue('quantity', e.target.value);
                                            setValues({
                                                ...values,
                                                quantity: e.target.value
                                            });
                                        }}
                                        value={values.quantity}
                                        name="quantity"
                                        className="sell-asset-input"
                                        id="quantity"
                                        status={`${props.amount} available`}
                                        placeholder="Enter quantity"
                                    />
                                )}
                                <Input
                                    min="0"
                                    type="number"
                                    error={errors['price'] && touched['price']}
                                    id="price"
                                    onChange={(e) => {
                                        setAssetPrice(`~${parseInt(e.target.value * ethPrice)}$`);
                                        setFieldValue('price', e.target.value);
                                        setValues({
                                            ...values,
                                            price: e.target.value
                                        });
                                    }}
                                    value={values.price}
                                    name="price"
                                    status={assetPrice}
                                    placeholder="Enter price (Ξ)"
                                />
                            </div>
                        </ContentSellAsset>
                        <Button onClick={handleSubmit} type="submit">
                            <img src={Arrow} alt="Arrow" />
                            <span>LIST FOR SALE</span>
                        </Button>
                    </ContainerSellAsset>
                );
            }}
        </Form>
    );
};

const SellAssetModal = () => {};
SellAssetModal.Show = (asset) => {
    Modal.show(<Header />, <Description />, <Content asset={asset} amount={parseInt(asset.amount)} />, undefined, {
        hasClose: true
    });
    Modal.headerStyle({ marginBottom: 16 });
    Modal.descriptionStyle({ marginBottom: 32 });
    Modal.modalStyle({ width: 816 });
};
export default SellAssetModal;
