import React from 'react';
// Modal
import Modal from '../Common/Modal';
// Components
import Title from 'components/Common/Title';
import DescriptionText from 'components/Common/Description';
import Button from 'components/Common/Button';
// Images
import CloseButtonIcon from 'assets/images/svg/close.svg';

const Description = (props) => {
    return (
        <div className="no-metamask">
            <Button
                small
                onClick={() => {
                    Modal.hide();
                }}
                id="comic-modal-exit"
                className="comic-modal-exit free-mint"
                outline>
                <img src={CloseButtonIcon} alt="Close button" />
            </Button>
            <div className="responsive-page">
                <Title tag="h2" title="NO METAMASK DETECTED" textAlign="left" fontSize="display4" className="coming-soon-title" />
                <DescriptionText tag="p" textAlign="left" className="coming-soon-description" fontSize="16" gradient>
                    It looks like you don't have a Metamask account installed on your device. In order to start assembling 2142 comic book
                    you need have a Metamask wallet.
                </DescriptionText>
                <Button tag="a" href="https://metamask.io/download/" className="coming-soon-button">
                    INSTALL METAMASK
                </Button>
            </div>
        </div>
    );
};

const NoMetamaskModal = () => {};
NoMetamaskModal.Show = (freeMint) => {
    Modal.show(undefined, <Description freeMint={freeMint} />, undefined, undefined, {
        hideClose: true,
        bgImage: true,
        mobile: window.innerWidth >= 1200 ? false : true
    });
    Modal.headerStyle({ marginBottom: 0 });
    Modal.contentStyle({ minHeight: 0 });
};
export default NoMetamaskModal;
