import styled from 'styled-components';

export const ShowcaseWrapper = styled.div`
    padding-top: 1.5vw;
`;

export const CoverRewardsContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;
