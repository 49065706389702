import React, { useEffect, useState } from 'react';

import { useMoralis } from 'react-moralis';

import MoralisDappContext from './context';

function MoralisDappProvider({ children }) {
    const [walletAddress, setWalletAddress] = useState();
    const [chainId, setChainId] = useState(process.env.REACT_APP_CHAINID);

    const { Moralis, user, isWeb3Enabled } = useMoralis();

    useEffect(() => {
        if (isWeb3Enabled) {
            if (Moralis.provider.givenProvider && Moralis.provider.givenProvider.chainId !== '0x1') {
                console.log('triggered');
                Moralis.switchNetwork('0x1');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isWeb3Enabled]);

    useEffect(() => {
        if (user) {
            Moralis.onChainChanged(function (chain) {
                setChainId(chain);
            });

            Moralis.onAccountChanged(async function (address) {
                setWalletAddress(address);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    //  useEffect(() => setChainId(Moralis.provider?.givenProvider?.chainId));
    useEffect(() => setWalletAddress(window.ethereum?.selectedAddress), []);

    return <MoralisDappContext.Provider value={{ walletAddress, chainId }}>{children}</MoralisDappContext.Provider>;
}

function useMoralisDapp() {
    const context = React.useContext(MoralisDappContext);

    if (context === undefined) {
        throw new Error('useMoralisDapp must be used within a MoralisDappProvider');
    }

    return context;
}

export { MoralisDappProvider, useMoralisDapp };
