import React from 'react';

const MenuToggleIcon = (props) => {
    return (
        <div className="mobile-toggle-icon" onClick={props.onClick}>
            <div className="mobile-toggle-background">
                <div className={`mobile-toggle-hamburger-menu${props.expanded ? ' open' : ''}`}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
    );
};

export default MenuToggleIcon;
