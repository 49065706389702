import React from 'react';
import Media from 'react-media';

import SliderDesktop from './Desktop';
import SliderMobile from './Mobile';

const Slider = (props) => {
    return (
        <Media queries={{ xs: '(max-width: 992px)' }}>
            {(matches) => {
                return matches.xs ? <SliderMobile /> : <SliderDesktop />;
            }}
        </Media>
    );
};

export default Slider;
