import React, { useState } from 'react';

// Libs
import Web3 from 'web3';
// Helpers
import { getMethods } from 'helpers/common';
// Common
import Toast from 'components/Common/Toast';
import Loader from 'components/Common/Loader';
// Components
import Title from 'components/Common/Title';
import Description from 'components/Common/Description';
import Button from 'components/Common/Button';
import ResponsiveModal from 'components/Modals/ResponsiveModal';
import MintToast from 'components/Toast/MintToast';
// Styled Components
import { StartMintingContainer } from 'assets/styles/components/Mint/index.styled';
import { LoadingMintingWrapper } from 'assets/styles/components/LoadingMinting/index.styled';
// Images
import Plus from 'assets/images/svg/ico-plus.svg';

const FreeMintMobile = () => {
    // State
    const [isMinting, setIsMinting] = useState(false);

    const freeMint = async () => {
        try {
            const web3 = new Web3(window.ethereum);
            const { factory_contract } = getMethods(web3);

            const tx = factory_contract.methods.freeMint(0);
            const estimation = await tx.estimateGas({ from: web3.currentProvider?.selectedAddress }).catch((error) => {
                Toast.error(<MintToast error message={`${error.message.split('\n')[0]}`} />);
                return error.message.split('\n')[0];
            });

            tx.send({ from: web3.currentProvider?.selectedAddress, gas: estimation })
                .on('receipt', function (receipt) {
                    if (receipt.status === true) {
                        setIsMinting(false);
                        ResponsiveModal.Show(false);
                    }
                })
                .on('transactionHash', function (transactionHash) {
                    setIsMinting(true);
                });
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <StartMintingContainer className="free-mint-mobile">
            {isMinting ? (
                <LoadingMintingWrapper>
                    <div>
                        <Loader />
                    </div>
                    <p className="loading-minting-title p-18 font-weight-700">Minting in progress</p>
                    <p className="loading-minting-description p-16">
                        Patience, our DAO friend. Minting usually takes a couple of minutes to complete.
                    </p>
                </LoadingMintingWrapper>
            ) : (
                <>
                    <Title tag="h1" fontSize="display2" title="GRAB YOUR FREE BUNDLE OF NFTS!" />
                    <Description tag="p" fontSize="20" className="start-minting-description">
                        It's so awesome you joined our DAO! To welcome you to the 2142 universe, you are entitled to mint your DAO token
                        plus seven NFT comic book assets (one empty page and six panels) to begin assembling the 2142 issue #1 "As Above, So
                        Below." You only have to pay the gas fee.
                    </Description>
                    <Button onClick={freeMint}>
                        <img src={Plus} alt="Plus icon" />
                        MINT 7 FREE NFTS
                    </Button>
                </>
            )}
        </StartMintingContainer>
    );
};

export default FreeMintMobile;
