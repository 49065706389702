import React from 'react';

// Libs
import Media from 'react-media';
// Providers
import { useDiscordTracking } from 'providers/DiscordTracking/provider';
// Components
import Title from 'components/Common/Title';
import Description from 'components/Common/Description';
import Button from 'components/Common/Button';
// Images
import { ReactComponent as Discord } from 'assets/images/svg/socials_media/discord.svg';

const Mint = () => {
    // Hooks
    const discord = useDiscordTracking();
    return (
        <Media queries={{ xs: '(max-width: 768px)' }}>
            {(matches) => {
                return (
                    <section className="mint-container">
                        <div className="mint-content">
                            <div className="mint-wrapper">
                                <Title
                                    title={
                                        matches.xs ? (
                                            <>
                                                Mint your <br /> free NFT <br /> bundle
                                            </>
                                        ) : (
                                            <>
                                                Mint your free <br /> NFT bundle
                                            </>
                                        )
                                    }
                                    fontSize="display2"
                                    textAlign="left"
                                    tag="h2"
                                />
                                <Description gradient tag="p" textAlign="left" fontSize="24" className="mint-description">
                                    Connect your Metamask account and get a bundle of free NFTs to kick-start your comic book adventure. You
                                    only have to cover the usual Ethereum gas fee.
                                </Description>
                                <div className="mint-button">
                                    <Button tag="a" href={discord.link}>
                                        <Discord /> JOIN DISCORD
                                    </Button>
                                </div>
                            </div>
                            <div className="mint-ellipse"></div>
                        </div>
                    </section>
                );
            }}
        </Media>
    );
};

export default Mint;
