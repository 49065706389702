import React from 'react';

// Helpers
import { PROGRESS_BARS } from 'helpers/data';
// Components
import Title from 'components/Common/Title';
import Description from 'components/Common/Description';
import ProgressBar from 'components/Common/ProgressBar';

const DAO = () => {
    return (
        <section className="dao-container" id="dao">
            <div className="dao-content">
                <Title title="In DAO we trust!" fontSize={'display2'} tag="h2" />
                <Description tag="p" fontSize={24} className="dao-description">
                    You vote and decide how the story will progress and what will happen to the characters, whose NFT value is determined by
                    a decentralized storyline driven by the decisions of active DAO members.
                </Description>
                <div className="dao-progress-bars">
                    {PROGRESS_BARS?.map((progress, key) => (
                        <ProgressBar {...progress} key={key} />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default DAO;
