import styled from 'styled-components';

export const Flex = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${(props) => props.height || 'auto'};
    & > div {
        flex: ${(props) => props.flex || 0};
    }
`;
